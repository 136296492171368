import ReactGA from 'react-ga4';

export const initGA = () => {
  ReactGA.initialize('UA-149384559-1');
};

/**
 *
 *  @param {String} pathname - The pathname of the current page
 *  @returns {void}
 *  @description Tracks the page view of the current page
 *  @example
 *  logPageView('/about')
 *  logPageView('/contact')
 *
 */
export function logPageView(pathname: string) {
  const pageViewObject = {
    event_category: 'page_view',
    event_action: 'page_view',
    event_label: pathname,
  };
  if (process.env.NODE_ENV !== 'production') {
    // console.log('Tracking Page View', pageViewObject);
    return;
  }
  ReactGA.gtag('event', 'page_view', pageViewObject);
}

export const logEvent = (category = '', action = '') => {
  if (category && action) {
    ReactGA.event({ category, action });
  }
};

/**
 * Google Analytics tracking for user sign up
 *
 * @param {String} userType - The type of user that signed up. Either 'Company' or 'Professional'
 * @returns {void}
 * @description Tracks the event of a user signing up
 * @example
 * trackSignupEvent('Company')
 * trackSignupEvent('Professional')
 */
export function trackSignupEvent(userType: 'Company' | 'Professional') {
  if (process.env.NODE_ENV !== 'production') {
    // console.log('Tracking Signup Event', userType);
    return;
  }
  ReactGA.gtag('event', 'signup', {
    event_category: 'Signup',
    event_action: 'Completed',
    event_label: userType,
  });
}
