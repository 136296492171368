export const API_SERVER = process.env.REACT_APP_API_SERVER;
export const ADMIN_URL = process.env.REACT_APP_ADMIN_URL || 'http://localhost:4000';
export const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '';
export const REVIEW_URL = process.env.REACT_APP_REVIEW_URL;
export const CAMELBACK_BACKEND_API_URL = process.env.REACT_APP_CAMELBACK_BACKEND_API_URL;
export const DNS_SENTRY = process.env.REACT_APP_DNS_SENTRY;
export const MAPS_KEY = process.env.REACT_APP_MAPS_KEY;
export const HOTJAR_SITE_ID = process.env.REACT_APP_HOTJAR_SITE_ID || '3848392';
export const LAUNCH_DARKLY_CLIENT_ID = process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID || '';
export const CONTACT_US_1800_NUMBER = process.env.CONTACT_US_1800_NUMBER || '1-888-780-3935';
