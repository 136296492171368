import React from 'react';

import './dashboardLoading.css';

const UserDashboardSkeleton = () => (
  <div className='dashboard-skeleton'>
    <div className='skeleton-header'></div>
    <div className='skeleton-box'></div>
    <div className='skeleton-row'>
      <div className='skeleton-dashboard-div'></div>
      <div className='skeleton-dashboard-div second-skeleton-dashboard-div'></div>
    </div>
    <div className='skeleton-box big-skeleton-box'></div>
  </div>
);

export default UserDashboardSkeleton;
