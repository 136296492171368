import React from 'react';

import './radio.scss';

const Radio = ({ children, overrideContainerStyle, ...props }) => {
  return (
    <div className='radio-container' style={overrideContainerStyle || {}}>
      <input type='radio' {...props} />
      <label htmlFor={props.id} className='radio-label'>
        {children}
      </label>
    </div>
  );
};

export default Radio;
