import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'src';

import { signOutUser } from '../slices/worker.slice';

export default function useSignOut() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const signOut = async () => {
    await dispatch(signOutUser()).unwrap();
    localStorage.removeItem('token');
    navigate('/');
  };

  return signOut;
}
