import { Avatar } from 'primereact/avatar';

type Props = {
  firstName: string;
  lastName: string;
  customStyles?: string;
};

const ProfilePictureInitials: React.FC<Props> = ({ firstName, lastName, customStyles }) => {
  function getInitials() {
    const firstInitial = firstName[0];
    const lastInitial = lastName[0];
    return `${firstInitial}${lastInitial}`;
  }

  const initials = getInitials();

  return <Avatar label={initials} shape='circle' className={`w-full h-full bg-yellow-yellowbird font-semibold ${customStyles}`} />;
};

export default ProfilePictureInitials;
