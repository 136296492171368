import { combineReducers } from 'redux';
import { workerSlice } from 'src/slices/worker.slice';

import { authSlice } from '../slices/auth.slice';
import { companySlice } from '../slices/company.slice';
import { documentSlice } from '../slices/document.slice';
import { googleCalendarSlice } from '../slices/googleCalendar.slice';
import { jobSlice } from '../slices/job.slice';
import { nonRegisteredSlice } from '../slices/nonRegistered.slice';
import { onboardingSlice } from '../slices/onboarding.slice';
import { staticSlice } from '../slices/professionalDetails.slice';
import { proReviewByCoSlice } from '../slices/proReviewByCo.slice';
import { referralsSlice } from '../slices/referrals.slice';
import { tasksSlice } from '../slices/task.slice';
// import { canopySlice } from '../slices/canopy.slice';
import { userSlice } from '../slices/user.slice';

const rootReducer = combineReducers({
  user: userSlice.reducer,
  auth: authSlice.reducer,
  staticData: staticSlice.reducer,
  worker: workerSlice.reducer,
  job: jobSlice.reducer,
  document: documentSlice.reducer,
  onboarding: onboardingSlice.reducer,
  googleCalendar: googleCalendarSlice.reducer,
  company: companySlice.reducer,
  referrals: referralsSlice.reducer,
  nonRegistered: nonRegisteredSlice.reducer,
  proReviewByCo: proReviewByCoSlice.reducer,
  //canopy: canopySlice.reducer,
  tasks: tasksSlice.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
