import React, { useRef, useState } from 'react';
import { Tab } from '@headlessui/react';
import { X } from 'lucide-react';
import { FileUpload } from 'primereact/fileupload';
import { Button } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'src';
import HorizontalRule from 'src/components/HorizontalRule';
import Modal from 'src/components/Modal';
import UploadedDoc from 'src/components/ProUploadDocs/UploadedDoc';
import Spacer from 'src/components/Spacer';
import Text from 'src/components/Text';

import { Events } from '../../lib/amplitude';
import { getDocuments, uploadDocument } from '../../slices/document.slice';

const fileCategories = [
  { label: 'Resume', value: 'resume' },
  { label: 'Certification', value: 'certification' },
  { label: 'Miscellaneous', value: 'miscellaneous' },
];

const validMimeTypes = [
  'application/pdf',
  'image/png',
  'image/jpeg',
  'image/tiff',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword',
];

export default function ProUploadDocs() {
  const fileUploadRef = useRef(null);
  const uploadedDocuments = useAppSelector(state => state.document.allDocuments) || [];
  const worker = useAppSelector(state => state.worker.worker);
  const dispatch = useDispatch();
  const [displayModal, setDisplayModal] = useState(false);
  const [totalSize, setTotalSize] = useState(0);
  const amplitude = require('../../lib/amplitude');

  const onTemplateRemove = (file, callback) => {
    setTotalSize(totalSize - file.size);
    callback();
  };

  const onTemplateClear = () => {
    setTotalSize(0);
  };

  const emptyTemplate = () => {
    return (
      <button
        type='button'
        className='p-4 bg-white rounded-md flex items-center flex-column'
        style={{ border: 'none', width: '100%', height: '100%', background: 'none' }}
        onClick={() => {
          const chooseInput = document.querySelector('.p-fileupload-choose input[type="file"]');
          chooseInput.click();
        }}
      >
        <div className='p-icon flex items-center justify-center bg-light text-secondary rounded-circle' style={{ width: '5em', height: '5em', padding: '' }}>
          <i className='pi pi-file m-0' style={{ fontSize: '3em', color: 'lightgray' }}></i>
        </div>
        <span className='fs-5 text-secondary'>Drag and Drop Files Here or Click to Select</span>
        <span className='fs-6 text-secondary'>Accepted File Types: .pdf, .jpg, .jpeg, .png, .doc, .docx, .tiff</span>
      </button>
    );
  };

  const handleUploadClick = async files => {
    if (!files) {
      toast.error('Please select a file to upload');
      return;
    }

    // check if files have valid mime types
    const isRequestedMimetype = files.every(file => validMimeTypes.includes(file.type));
    if (!isRequestedMimetype) {
      toast.error('Please upload only PDF, PNG, JPEG, DOC or  DOCX, or TIFF files');
      return;
    }

    // check if every file has a category key and the values are one of the following: resume, certification, miscellaneous
    const isCategoryValid = files.every(file => {
      return file.category && fileCategories.map(cat => cat.value).includes(file.category);
    });
    if (!isCategoryValid) {
      toast.error(`Please select a ${files.length === 1 ? 'file type.' : 'type for each file'}`);
      return;
    }

    // check that every file is less than 50MB
    const isFileSizeValid = files.every(file => file.size < 50000000);
    if (!isFileSizeValid) {
      toast.error('Please upload files less than 50MB');
      return;
    }

    const promises = Promise.all(
      files.map(file => {
        const formData = new FormData();
        formData.append('file', file);
        return dispatch(uploadDocument({ workerId: worker.id, category: file.category, docs: formData }));
      })
    );

    const buttonBarElements = document.querySelectorAll('.p-fileupload-buttonbar *');
    let selectElements = [];
    let removeItemButtons = [];
    removeItemButtons = document.querySelectorAll('.p-fileupload-row .remove-btn');
    selectElements = document.querySelectorAll('.p-fileupload-row select');
    try {
      // add to every parent element inside p-fileupload-buttonbar class has a disabled attribute and disabled class
      [...buttonBarElements, ...selectElements, ...removeItemButtons].forEach(element => {
        element.setAttribute('disabled', true);
        element.classList.add('disabled');
      });
      await toast.promise(promises, {
        loading: `Uploading ${files.length} file(s)`,
        success: `Uploaded ${files.length} file(s) successfully`,
        error: 'Failed to upload. Please try again later',
      });
      await dispatch(getDocuments());
      amplitude.sendEvent(Events.PRO_UPLOADED_DOCUMENTS);
      fileUploadRef.current.clear();
    } catch (error) {
      return error.message;
    } finally {
      [...buttonBarElements, ...selectElements, ...removeItemButtons].forEach(element => {
        element.removeAttribute('disabled');
        element.classList.remove('disabled');
      });
    }
  };

  const filterDocuments = category => {
    const result = uploadedDocuments
      .filter(item => item.category === category)
      .map((item, index) => <UploadedDoc key={`uploaded-${index}`} doc={item} index={index} length={uploadedDocuments.length} />);
    return result;
  };

  return (
    <>
      <div className='p-4 flex-col flex bg-white drop-shadow-lg rounded-md w-full'>
        <div className='flex justify-between items-center'>
          <h3 className='text-xl font-semibold text-slate-700'>My Documents</h3>
        </div>
        <Tab.Group>
          <Tab.List className='flex flex-wrap text-sm gap-2 my-4'>
            <Tab>
              {({ selected }) => (
                <button type='button' className={`px-2 duration-100 ${selected ? 'bg-gray-200 rounded' : null}`}>
                  Resume
                </button>
              )}
            </Tab>
            <Tab>
              {({ selected }) => (
                <button type='button' className={`px-2 duration-100 ${selected ? 'bg-gray-200 rounded' : null}`}>
                  Certifications
                </button>
              )}
            </Tab>
            <Tab>
              {({ selected }) => (
                <button type='button' className={`px-2 duration-100 ${selected ? 'bg-gray-200 rounded' : null}`}>
                  Other
                </button>
              )}
            </Tab>
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel>{filterDocuments('resume')}</Tab.Panel>
            <Tab.Panel>{filterDocuments('certification')}</Tab.Panel>
            <Tab.Panel>{filterDocuments('miscellaneus')}</Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
        <button
          type='button'
          className='bg-yellow-400 self-end w-full py-2.5 px-4 font-bold rounded hover:scale-105 duration-200'
          onClick={() => setDisplayModal(true)}
        >
          Upload New
        </button>
      </div>
      <Modal size='lg' dialogClassName show={displayModal} centered onHide={() => setDisplayModal(!displayModal)}>
        <div>
          <Text t='boldHeading'>Manage My Documents</Text>
        </div>
        <Spacer h={16} />
        <HorizontalRule />
        <Spacer h={16} />
        <div>
          <Text>
            Upload your resume, certificates and any other credentials that you have. In order for you to reach 'Match Ready' Status, your resume and all
            certifications selected during registration must be uploaded.
          </Text>
        </div>
        <Spacer h={16} />
        <div>
          {uploadedDocuments.length > 0 ? (
            <>
              <Text t='smallBoldBlack'>Documents Uploaded</Text>
              <Spacer h={16} />
              <div
                style={{
                  maxHeight: '200px',
                  overflowY: 'scroll',
                  border: '1px solid #e5e5e5',
                  padding: '1rem',
                  borderRadius: '5px',
                }}
              >
                {uploadedDocuments.map((item, index) => (
                  <UploadedDoc key={`uploaded-${index}`} doc={item} index={index} length={uploadedDocuments.length} />
                ))}
              </div>
              <Spacer h={16} />
            </>
          ) : null}
          <div>
            <Text t='smallBoldBlack'>Attach Documents</Text>
            <Spacer h={16} />
            <div id='upload-documents'>
              <FileUpload
                mode='advanced'
                ref={fileUploadRef}
                name='demo[]'
                multiple
                customUpload
                uploadHandler={event => handleUploadClick(event.files)}
                accept={validMimeTypes.join(',')}
                maxFileSize={50000000} // 50 MB
                onError={onTemplateClear}
                onClear={onTemplateClear}
                itemTemplate={(file, props) => <UploadItemTemplate file={file} props={props} onTemplateRemove={onTemplateRemove} />}
                emptyTemplate={emptyTemplate}
                style={{
                  border: '1px solid #e5e5e5',
                  borderRadius: '5px',
                }}
                chooseOptions={{
                  className: 'bg-yellow-400 text-white py-2 px-3 hover:bg-blue-600',
                }}
                uploadOptions={{
                  className: 'bg-yellow-400 text-black font-semibold py-2 px-3 hover:bg-green-600',
                }}
                cancelOptions={{
                  className: 'bg-yellow-400 text-black font-semibold py-2 px-3 hover:bg-red-600',
                }}
                pt={{
                  buttonbar: {
                    className: 'flex gap-3', // Adds gap between buttons
                  },
                }}
              />
            </div>
          </div>
        </div>
        <Spacer h={48} />
      </Modal>
    </>
  );
}

function UploadItemTemplate({ file, props, onTemplateRemove }) {
  const [docType, setDocType] = React.useState('');
  return (
    <>
      <p className='p-fileupload-row__name-and-size'>
        <span title={file.name} className='p-fileupload-row__name'>
          {file.name}
        </span>
        <span className='p-fileupload-row__size'>{props.formatSize}</span>
      </p>
      <div className='p-fileupload-row__right'>
        <select
          value={docType}
          onChange={e => {
            setDocType(e.target.value);
            file.category = e.target.value;
          }}
          className='p-inputtext p-component p-inputtext p-filled'
        >
          <option value=''>-- Select type</option>
          {fileCategories.map(category => (
            <option key={category.value} value={category.value}>
              {category.label}
            </option>
          ))}
        </select>
        <Button
          type='button'
          size='sm'
          variant='outline-danger'
          className='remove-btn rounded-circle ml-2'
          title='Delete Document'
          onClick={() => onTemplateRemove(file, props.onRemove)}
        >
          <X size={16} />
        </Button>
      </div>
    </>
  );
}
