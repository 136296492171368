import React from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { NavLink, useLocation } from 'react-router-dom';

import yellowbirdHeaderLogo from '../../../assets/images/yellowbird-header-logo.svg';
import { useAppSelector } from '../../../index';

import './header.scss';

const inactiveStyling = {
  fontSize: '14px',
  color: '#586f7c',
  paddingBottom: '5px',
  paddingTop: '15px',
};

const activeStyling = {
  fontSize: '14px',
  textDecoration: 'underline',
  textDecorationColor: 'orange',
  textUnderlineOffset: '6px',
  textDecorationThickness: '3px',
  fontWeight: 600,
  paddingBottom: '5px',
  paddingTop: '15px',
};

export default function Header() {
  const location = useLocation();
  const { token } = useAppSelector(state => state.auth);
  const localToken = localStorage.getItem('token');
  const isLoggedIn = token || localToken ? true : false;
  return (
    <Navbar className='pe-0 ps-4 ps-sm-4 ps-md-5 nav-underline site-header fixed-top' expand='lg'>
      <Container
        fluid
        style={{ maxWidth: '1280px' }}
        className={`ps-4 ps-sm-1 ${!isLoggedIn ? 'justify-content-center justify-content-md-between' : 'justify-content-between'}`}
      >
        <LeftBrand />
        <div className='me-2 me-md-3 me-lg-0'>
          <Nav className='justify-content-center d-flex flex-row'>
            <NavLink className='nav-link d-none d-md-block' style={({ isActive }) => (isActive ? activeStyling : inactiveStyling)} to='/'>
              Log In
            </NavLink>
            <NavLink
              className='nav-link d-none d-md-block'
              style={({ isActive }) =>
                isActive || location.pathname === '/sign-up' || location.pathname === '/register-pro' ? activeStyling : inactiveStyling
              }
              to='/register'
            >
              Sign up
            </NavLink>
          </Nav>
        </div>
      </Container>
    </Navbar>
  );
}
const LeftBrand = () => (
  <div className='d-flex flex-row justify-content-center'>
    <Navbar.Brand href='https://www.goyellowbird.com/'>
      <img style={{ marginLeft: '-20px', paddingTop: '5px' }} src={yellowbirdHeaderLogo} className='d-inline-block align-top' alt='YellowBird Logo' />
    </Navbar.Brand>
  </div>
);
