import { useFormContext } from 'react-hook-form';

const RadioOption = ({ value, htmlForId, label }) => {
  const { register } = useFormContext();

  return (
    <div className='radioContainerOptions'>
      <input className='inputRadioOption' value={value} {...register('referral.value')} id={htmlForId} type='radio' />
      <label className='inputRadioLabel' htmlFor={htmlForId}>
        {label}
      </label>
    </div>
  );
};

export default RadioOption;
