import React from 'react';

const ConditionalInfo = ({ jobInfo, label }) => {
  return (
    <>
      {jobInfo && (
        <div>
          <h5 className='labelText'>{label}</h5>
          <p className='descriptionText'>{jobInfo}</p>
        </div>
      )}
    </>
  );
};

export default ConditionalInfo;
