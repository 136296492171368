import { useState } from 'react';
import { useSelector } from 'react-redux';

import close from '../../assets/icons/close.png';
import modalGif from '../../assets/images/Phone-to-Laptop.gif';

import './infoModal.scss';

const FirstTimeUserModal = ({ onClose }) => {
  const [opening, setIsOpening] = useState(true);
  const state = useSelector(state => state);
  const { user } = state.user;

  const handleClose = () => {
    setIsOpening(false);
    setTimeout(() => {
      onClose();
    }, 250);
  };

  return (
    <div className={`info-modal-main ${opening ? 'opening' : 'closing'}`}>
      <div className='info-modal-pop-up'>
        <div className='close-div-pop-up-icon'>
          <button style={{ border: 'none', background: 'none' }} onClick={handleClose}>
            <img className='close-icon-pop-up' src={close} alt='close' />
          </button>
        </div>
        <div className='info-modal-text-part'>
          <div className='info-modal-text-div'>
            <p className='info-modal-header'>
              Welcome to YellowBird,
              <br />
              {user.firstName}!
            </p>
            <p style={{ marginBottom: '2rem' }} className='info-modal-body-text'>
              {user.role === 'worker'
                ? 'Complete the onboarding process to become eligible for matching with YellowBird job opportunities. You are just steps away from matching with short- and long-term jobs on your schedule.'
                : 'Post a job to the platform today to get started. Our technology can match you to the right EHS or Risk Management professional within hours. Together, we can make workplaces safe for all.'}
            </p>
          </div>
        </div>
        <div className='info-modal-img'>
          <img src={modalGif} alt='Modal gif' />
        </div>
        <div style={{ padding: '1.5rem' }}>
          <button style={{ width: '100%' }} onClick={handleClose} className='info-modal-button info-modal-continue dashboard-component-animation'>
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default FirstTimeUserModal;
