import React from 'react';

import ResetPasswordContainer from './ResetPasswordContainer';

import './resetPassword.scss';

const ResetPassword = () => {
  return <ResetPasswordContainer />;
};

export default ResetPassword;
