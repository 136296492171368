import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { camelizeKeys } from 'humps';

import api from '../api';
import { Todo } from '../types';
import apiCamelback from "../api/apiCamelback";

interface ProfessionalDetailsState {
  error: Todo;
  isLoading: boolean;
  staticData: Todo;
}

const initialState: ProfessionalDetailsState = {
  error: null,
  isLoading: false,
  staticData: {},
};

interface FetchProfessionalDetailsArg {}

export const fetchProfessionalDetails = createAsyncThunk<
  Todo, // The type of the return value of the payload creator
  FetchProfessionalDetailsArg, // The type of the first argument to the payload creator
  {
    rejectValue: Todo; // The type for `thunkApi.rejectWithValue`, if error handling is customized
  }
>('static/fetchProDetails', async (_, thunkApi) => {
  try {
    const response = await apiCamelback.get('/api/v1/professional_details');
    let camelized = camelizeKeys(response.data);
    return camelized;
  } catch (error) {
    return error;
  }
});

export const staticSlice = createSlice({
  name: 'static',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchProfessionalDetails.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchProfessionalDetails.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(fetchProfessionalDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.staticData = action.payload;
      });
  },
});
