import { useRef, useState } from 'react';

import Navigation from '../layout/navigation';

import './step-5.css';

const Step5 = () => {
  const [read, setRead] = useState(false);
  const viewportRef = useRef(null);
  const handlingScroll = () => {
    const viewport = viewportRef.current;
    if (read) return;
    if (viewport) {
      const rest = 2000;
      const { scrollHeight, scrollTop, clientHeight } = viewport;
      const isScrolledToBottom = scrollTop + clientHeight + rest >= scrollHeight;
      setRead(isScrolledToBottom);
    }
  };

  return (
    <div className='multiStepContainer'>
      <div className='scrollAreaMulstiStep' ref={viewportRef} onScroll={handlingScroll}>
        <h1 className='termsAndConditionsh1'>
          Please read and accept the terms <br />
          and conditions.
        </h1>
        <div>
          <p>
            YellowBird Holdings, Inc. (“YellowBird” or “we”) operates a website-based marketplace allowing businesses seeking to obtain certain services
            (“Users”) to locate and book third-party vendors (“Vendors”) that provide such services (“Vendor Services”). These Terms of Service (“Terms”) apply
            to your access to and use of the YellowBird website and any other online products or services provided by YellowBird (collectively, the “Services”).
            You may be using the Services in the capacity of a User or a Vendor, and the terms applicable to Users or Vendors will apply to you in your capacity
            as a User or Vendor.
          </p>
          <p>
            PLEASE READ THESE TERMS CAREFULLY, INCLUDING THE MANDATORY ARBITRATION PROVISION IN SECTION 21, WHICH REQUIRES THAT DISPUTES BE RESOLVED BY FINAL
            AND BINDING ARBITRATION ON AN INDIVIDUAL BASIS, NOT A CLASS-WIDE OR CONSOLIDATED BASIS. IF YOU DO NOT WISH TO BE SUBJECT TO ARBITRATION, YOU MAY OPT
            OUT OF THE ARBITRATION PROVISION BY FOLLOWING THE INSTRUCTIONS PROVIDED IN SECTION 21. <br /> <br />
            BY ACCESSING OR USING OUR SERVICES, YOU AGREE TO BE BOUND BY THESE TERMS AND ALL TERMS INCORPORATED BY REFERENCE. IF YOU DO NOT AGREE TO THESE TERMS
            IN THEIR ENTIRETY, DO NOT USE OUR SERVICES.
          </p>
          <p>
            We may make changes to these Terms from time to time. If we make changes, we will provide you with notice of such changes, such as by sending an
            email, providing a notice through our Services or updating the date at the top of these Terms. Unless we say otherwise in our notice, the amended
            Terms will be effective immediately, and your continued use of our Services after we provide such notice will confirm your acceptance of the
            changes. If you do not agree to the amended Terms, you must stop using our Services.
          </p>
          <p>If you have any questions about these Terms or our Services, please contact us at operations@goYellowBird.com</p>
          <h1 className='h1terms'>1. The Services</h1>
          <p>
            The Services are a neutral marketplace that enables communication and transactions between Users seeking to book, and Vendors seeking to offer,
            Vendor Services. A User may book Vendor Services (a “Booking”) by submitting a request to YellowBird. YellowBird will connect the User with a Vendor
            providing the Vendor Services sought by the User and the Vendor will perform the Vendor Services directly for the User.
          </p>
          <p>
            YellowBird is a neutral marketplace. As a provider of a neutral marketplace, YellowBird does not sell, resell, provide, control, manage, offer,
            deliver, or supply any Vendor Services. Vendors alone are responsible for their Vendor Services. When a User makes a Booking, the User is receiving
            Vendor Services directly from the Vendor, not YellowBird, and YellowBird is not and does not become a party to, or other participant in, any
            relationship between Users and Vendors. YellowBird is not acting as an agent in any capacity for any User or Vendor. Without limiting the foregoing,
            YellowBird assumes no liability or responsibility for damages associated with the Vendor Services or resulting from any other transactions between
            Users and Vendors.
          </p>
          <p>
            While we may help facilitate the resolution of disputes between you and Users or Vendors, as applicable (see Section 8), we have no control over and
            do not guarantee (a) the existence, quality, safety, suitability, or legality of any Vendor Services, (b) the truth or accuracy of any Vendor
            Service descriptions, ratings, reviews, or Content (as defined below), or (c) the performance or conduct of any Vendor, User, or third party.
            YellowBird does not endorse, or make any representations or warranties with respect to, any Vendor, User, or Vendor Services. Any description of any
            User or Vendor is not an endorsement, certification or guarantee by YellowBird about any such User or Vendor, including, without limitation, of the
            User’s or Vendor’s identity or background or whether the User or Vendor is trustworthy, safe or suitable. You should always exercise due diligence
            and care when deciding whether to use the Vendor Services, accept a Booking request that a User submits, or communicate and interact with other
            Users or Vendors, whether online or in person. Any Content posted to the Services is not an endorsement by YellowBird of any User, Vendor, or Vendor
            Services.
          </p>
          <h1 className='h1terms'>2. Privacy</h1>
          <p>
            For information about how we collect, use, share or otherwise process information about you, please see our Privacy Policy at
            www.goYellowBird/privacy.
          </p>
          <h1 className='h1terms'>3. Eligibility</h1>
          <p>
            You must be at least 18 years of age (or the age of legal majority where you live) to use our Services. If you use our Services on behalf of another
            person or entity, (a) all references to “you” throughout these Terms will include that person or entity, (b) you represent that you are authorized
            to accept these Terms on that person’s or entity’s behalf, and (c) in the event you or the person or entity violates these Terms, the person or
            entity agrees to be responsible to us.
          </p>
          <h1 className='h1terms'>4. Accounts and Account Security</h1>
          <p>
            You may need to register for an account to access some or all of our Services. If you register for an account, you must provide accurate account
            information and promptly update this information if it changes. You also must maintain the security of your account and promptly notify us if you
            discover or suspect that someone has accessed your account without your permission. You are responsible for all activities that occur in connection
            with your account. If we terminate or suspend your account in accordance with Section 23 of these Terms, you may not create additional accounts.
          </p>
          <h1 className='h1terms'>5. Mobile Services</h1>
          <p>
            By using the Services, you consent to receive information from us via SMS and/or MMS messages sent through an automated telephone dialing system
            (“Text Service”), even if you have opted in to the National Do Not Call List, any state Do not Call List, or the internal Do Not Call List of any
            company. You may be required to respond to an initial message as instructed to complete your registration and confirm enrollment in the Text
            Service. The enrollment process will disclose the program, frequency of messages, and options to cancel your enrollment. You do not have to
            participate in the Text Service in order to use the Services. In the event you no longer want to participate in the Text Service, you agree to
            notify us directly. In the event you change or deactivate your mobile telephone number, you agree to promptly update your Company account
            information to ensure that your messages are not sent to the person that acquires your old number.
          </p>
          <p>
            There is no additional charge for the Text Service, but your mobile carrier’s standard message and data rates apply to any messages you send or
            receive through the Text Service, including confirmations and subsequent texts. Your carrier may prohibit or restrict certain mobile features and
            certain mobile features may be incompatible with your carrier or mobile device. We are not liable for any delays in the receipt of, or any failures
            to receive, any SMS or MMS messages, as delivery is subject to effective transmission by your mobile carrier and compatibility of your mobile
            device. Please contact your mobile carrier if you have any questions regarding these issues or your mobile data and messaging plan.
          </p>
          <p>
            As described in the Text Service enrollment and welcome messages, including messages sent to a shortcode associated with the Text Service or by
            reply to any message you receive from us, you may text “STOP” to cancel or “HELP” for customer support information. If you choose to cancel your
            Text Service, you agree to receive a final text message from the Text Service confirming your cancellation.
          </p>
          <h1 className='h1terms'>6. User-Specific Terms</h1>
          <p>
            Subject to meeting any requirements set by YellowBird and/or the Vendor, you can initiate and complete a Booking by following the Booking process
            set forth on the Services. All applicable fees for Vendor Services will be presented to you prior to completing the Booking. Upon receipt of a
            Booking confirmation from YellowBird, you agree to pay the total fees charged for the Booking. Except for taxes on YellowBird’s income and gross
            receipts or where YellowBird is otherwise required to collect taxes, you are solely responsible for paying any applicable taxes that arise as a
            result of your use of the Services.
          </p>
          <p>
            YellowBird will collect fees from you after the Booking is confirmed. When you pay for Vendor Services or for other services on the Services, you
            will be required to provide us with valid, up-to-date credit card, debit card, or other payment information and to maintain that payment information
            (or an acceptable alternative payment method) on file with your account so long as you have any outstanding, confirmed Bookings. You authorize us to
            store your credit card or other payment method and to charge your credit card or other payment method for fees you incur on the Services as they
            become due and payable, and to charge any alternative payment method YellowBird has on record for you in the event your primary payment method is
            expired, invalid, or otherwise not able to be charged. You are responsible for maintaining up-to-date payment information. If we cannot charge you
            for any fees when due because your payment information is no longer valid, or if we do not receive your payment when due, then you understand that
            neither YellowBird nor Vendor will be responsible for any failure to provide services, including, without limitation, Vendor Services, associated
            with those fees. Except as expressly provided in these Terms or our cancellation policy, all fees are non-cancelable and non-refundable once
            confirmed.
          </p>
          <p>
            If we are unable to collect any amounts you owe under these Terms, we may engage in collection efforts to recover such amounts from you. We will
            deem any owed amounts overdue when seven (7) days have elapsed after we first attempt to charge your payment method or the associated Vendor
            Services have been provided.
          </p>
          <h1 className='h1terms'>7. Vendor-Specific Terms</h1>
          <p>
            When registering for a Vendor account on the Services, you must (a) provide complete and accurate information about your Vendor Services, (b)
            disclose any deficiencies, restrictions, and requirements that apply, and (c) provide any other pertinent information required by YellowBird. You
            are responsible for keeping information about your Vendor Services up-to-date at all times. When you accept a Booking request submitted by a User,
            you are required to provide the Vendor Services to that User as described to the User (including, without limitation, with respect to the quality,
            timeliness, and other characteristics of the deliverables promised to the User). You are required to bear all the expenses you incur in connection
            with your provision of Vendor Services and are responsible for taxes on your own income.
          </p>
          <p>
            We will (either directly or indirectly through an authorized third party) collect the fee for the Vendor Services from the User when we confirm the
            Booking and initiate payment to your account after we receive a User’s Booking fees. We will deduct our service fee, the amount of which will be
            communicated separately to you, before remitting payment to you. You understand that our obligation to pay you is subject to, and conditional upon,
            our successful receipt of the associated payment from the User associated with the Booking. You further understand that all or substantially all
            payments paid to you are based on your performance of the Vendor Services for the User.
          </p>
          <p>
            All Vendors shall, during all times in which Vendor is an eligible provider of Vendor Services, carry Automobile Liability Insurance covering all
            motor vehicles, including owned, hired, borrowed and non-owned vehicles, used in connection with any Vendor Services. Limits of Coverage shall be
            not less than: $300,000 Combined Single Limit. In turn, YellowBird shall provide Vendor with Occupation Accident coverage (up to $1,000,000 Combined
            Single Limit) and Professional Liability coverage (up to $1,000,000 Combined Single Limit).
          </p>
          <p>
            If you choose to use the Services as a Vendor, your relationship with YellowBird is limited to being an independent, third-party contractor, and not
            an employee, agent, joint venturer or partner of YellowBird for any reason, and you act exclusively on your own behalf and for your own benefit, and
            not on behalf, or for the benefit, of YellowBird. YellowBird does not, and will not be deemed to, direct or control you generally or in your
            performance under these Terms specifically, including, without limitation, in connection with your provision of the Vendor Services. You acknowledge
            and agree that (i) you have complete discretion whether to provide Vendor Services or otherwise engage in other business activities, (ii) you may
            work any hours or schedules that you choose, subject to your agreement to provide the Vendor Services during certain specified hours or schedules,
            and (iii) these Terms do not restrict your ability to perform any services (including, without limitation, Vendor Services) to any other party. You
            represent and warrant that you are licensed, bonded, and insured, and are otherwise legally authorized to provide the Vendor Services and that you
            will provide the Vendor Services in compliance with applicable laws and in a professional and workmanlike manner.
          </p>
          <p>These Terms may be terminated without cause by either of us on reasonable notice to the other party.</p>
          <h1 className='h1terms'>8. Modifications, Cancellations, and Disputes</h1>
          <p>
            Vendors and Users are responsible for any modifications to a Booking that they make via the Services or direct YellowBird to make (“Modifications”),
            which may include, without limitation, additional fees, modified deliverables and/or timeframes, and/or substituting different service providers to
            perform the Vendor Services. If the Modification contains additional fees or taxes, you agree to pay any additional fees and taxes associated with
            such Modifications. A Vendor may appoint a substitute provider or providers of Vendor Services under a Booking if and only if (a) agreed to in
            writing by the User, (b) the substitute provider has an active account on the Services and has agreed in writing to accept the Booking, and (c)
            Vendor obtains YellowBird’s consent to the Modification and furnishes YellowBird with all information, in YellowBird’s sole discretion, necessary or
            desirable for YellowBird to consent to the Modification.
          </p>
          <p>
            Cancellations of confirmed Bookings are treated in accordance with the terms of our cancellation policy that we make available to you at the time of
            Booking.
          </p>
          <p>
            In certain circumstances, YellowBird may decide, in its sole discretion, that it is necessary to cancel a pending or confirmed Booking and initiate
            a full refund to the User. This may, but does not have to, occur where (i) YellowBird believes in good faith that it is necessary to avoid
            significant harm to YellowBird, other Users, Vendors, other third parties or property, or (ii) for any of the reasons set out in these Terms or our
            cancellation policy. You are solely responsible for your interactions with Users or Vendors, as applicable, and YellowBird is not a party to any
            disputes that may arise between you and the User or Vendor, nor does YellowBird have any obligation to mediate or facilitate any resolution. Without
            limiting the foregoing, once payment has been remitted to a Vendor, any payment disputes occur solely between the User and the Vendor.
          </p>
          <p>
            You are solely responsible for your interactions with Users or Vendors, as applicable, and YellowBird is not a party to any disputes that may arise
            between you and the User or Vendor, nor does YellowBird have any obligation to mediate or facilitate any resolution. Without limiting the foregoing,
            once payment has been remitted to a Vendor, any payment disputes occur solely between the User and the Vendor.
          </p>
          <p>
            However, YellowBird reserves the right to monitor and mediate disputes if it believes, in its sole discretion, that it is necessary or desirable to
            do so. If YellowBird chooses to exercise this right, then you agree to cooperate with and assist YellowBird in good faith, and to provide YellowBird
            with such information and take such actions as may be reasonably requested by YellowBird, in connection with any disputes or other complaints or
            claims made by a User or Vendor relating to provision or receipt of the Vendor Services. You will, upon YellowBird’s reasonable request and at no
            cost to you, participate in mediation or a similar resolution process with another User or Vendor, which process will be conducted by YellowBird or
            a third party selected by YellowBird or its insurer, with respect to losses for which a User or Vendor is requesting payment from YellowBird. You
            are entitled to terminate your participation in the mediation or similar resolution process at any time. Your right to take legal action before a
            court of law remains unaffected.
          </p>
          <h1 className='h1terms'>9. Content</h1>
          <p>
            Our Services may allow you to create, post, store and share content, including messages, text, photos, and other materials (collectively,
            “Content”). Except for the license you grant below, you retain all rights in and to your Content, as between you and YellowBird.
          </p>
          <p>
            You grant YellowBird a perpetual, irrevocable, nonexclusive, royalty-free, worldwide, fully paid, and sublicensable license to use, reproduce,
            modify, adapt, publish, translate, create derivative works from, distribute, publicly perform and display your Content and any name, username or
            likeness provided in connection with your Content in all media formats and channels now known or later developed without compensation to you. When
            you post or otherwise share Content on or through our Services, you understand that your Content and any associated information (such as your
            username or profile photo) may be visible to others. If your name, voice, image, persona, likeness, or performance is included in any of Content,
            you hereby waive and release YellowBird and its Users and Vendors from any claim or cause of action, whether known or unknown, for defamation,
            copyright infringement, invasion of the rights of privacy, publicity, or personality, or any similar claim arising out of the use of Content in
            accordance with the license in these Terms.
          </p>
          <p>
            You may not create, post, store or share any Content that violates these Terms or for which you do not have all the rights necessary to grant us the
            license described above. You represent and warrant that your Content, and our use of such Content as permitted by these Terms, does not and will not
            violate any rights of or cause injury to any person or entity. Although we have no obligation to screen, edit or monitor Content, we may delete or
            remove Content at any time and for any reason with or without notice.
          </p>
          <h1 className='h1terms'>10. Logo Use</h1>
          <p>
            Users authorizes YellowBird to use its name, company name, logo and/or trademark without notice to or consent, in connection with certain
            promotional materials that YellowBird may disseminate to the public. The promotional materials may include, but are not limited to, brochures, video
            tape, internet website, press releases, advertising in newspaper and/or other periodicals, and any other materials relating the fact that YellowBird
            has a service partnership with User and such materials may be developed, disseminated and used without Users’ review. Nothing herein obligates
            YellowBird to use a user’s name, company name, logo and/or trademark, in any promotional materials of YellowBird.
          </p>
          <p>
            Users shall not, and shall not permit any of its respective colleagues to, issue any press release or other public disclosure using the name, logo
            or otherwise referring to YellowBird Holdings Inc. or of any of their affiliates or any transaction contemplated herein or therein without at least
            two (2) Business Days prior written notice to and the prior written consent of YellowBird.
          </p>
          <h1 className='h1terms'>11. Prohibited Conduct and Content</h1>
          <p>
            You will not violate any applicable law, contract, intellectual property right or other third-party right or commit a tort, and you are solely
            responsible for your conduct while using our Services. You will not:
          </p>
          <ul>
            <li>Engage in any harassing, threatening, intimidating, predatory or stalking conduct;</li>
            <li>Use or attempt to use another User or Vendor’s account without authorization from that User or Vendor and YellowBird;</li>
            <li>
              Engage in tactics to bypass the Services or other YellowBird systems to avoid complying with any of our applicable policies, including these
              Terms, paying any applicable fees, or complying with other contractual obligations;
            </li>
            <li>Impersonate or post on behalf or any person or entity or otherwise misrepresent your affiliation with a person or entity;</li>
            <li>Sell, resell or commercially use our Services;</li>
            <li>
              Copy, reproduce, distribute, publicly perform or publicly display all or portions of our Services, except as expressly permitted by us or our
              licensors;
            </li>
            <li>Modify our Services, remove any proprietary rights notices or markings, or otherwise make any derivative works based upon our Services;</li>
            <li>
              Use our Services other than for their intended purpose and in any manner that could interfere with, disrupt, negatively affect or inhibit other
              Users or Vendors from fully enjoying our Services or that could damage, disable, overburden or impair the functioning of our Services in any
              manner;
            </li>
            <li>
              Reverse engineer any aspect of our Services or do anything that might discover source code or bypass or circumvent measures employed to prevent or
              limit access to any part of our Services;
            </li>
            <li>
              Attempt to circumvent any content-filtering techniques we employ or attempt to access any feature or area of our Services that you are not
              authorized to access;
            </li>
            <li>Use any data mining, robots or similar data gathering or extraction methods designed to scrape or extract data from our Services;</li>
            <li>Develop or use any applications that interact with our Services without our prior written consent;</li>
            <li>Send, distribute or post spam, unsolicited or bulk commercial electronic communications, chain letters, or pyramid schemes;</li>
            <li>Bypass or ignore instructions contained in our robots.txt file; or</li>
            <li>Use our Services for any illegal or unauthorized purpose, or engage in, encourage or promote any activity that violates these Terms.</li>
          </ul>
          <p>
            You may also post or otherwise share only Content that is nonconfidential and that you have all necessary rights to disclose. You may not create,
            post, store or share any Content that:
          </p>
          <ul>
            <li>
              Is unlawful, libelous, defamatory, obscene, pornographic, indecent, lewd, suggestive, harassing, threatening, invasive of privacy or publicity
              rights, abusive, inflammatory or fraudulent;
            </li>
            <li>
              Would constitute, encourage or provide instructions for a criminal offense, violate the rights of any party or otherwise create liability or
              violate any local, state, national or international law;
            </li>
            <li>May infringe any patent, trademark, trade secret, copyright or other intellectual or proprietary right of any party;</li>
            <li>Contains or depicts any statements, remarks or claims that do not reflect your honest views and experiences;</li>
            <li>Impersonates, or misrepresents your affiliation with, any person or entity;</li>
            <li>Contains any unsolicited promotions, political campaigning, advertising or solicitations;</li>
            <li>Contains any private or personal information of a third party without such third party’s consent;</li>
            <li>Contains any viruses, corrupted data or other harmful, disruptive or destructive files or content; or</li>
            <li>
              In our sole judgment, is objectionable, restricts or inhibits any other person from using or enjoying our Services, or may expose YellowBird or
              others to any harm or liability of any type.
            </li>
          </ul>
          <p>
            Enforcement of this Section is solely at YellowBird’s discretion, and failure to enforce this Section in some instances does not constitute a waiver
            of our right to enforce it in other instances. In addition, this Section does not create any private right of action on the part of any third party
            or any reasonable expectation that the Services will not contain any content that is prohibited by such rules.
          </p>
          <h1 className='h1terms'>12. Ownership; Limited License</h1>
          <p>
            The Services, including the text, graphics, images, photographs, videos, illustrations and other content contained therein, are owned by YellowBird
            or our licensors and are protected under both United States and foreign laws. Except as explicitly stated in these Terms, all rights in and to the
            Services are reserved by us or our licensors. Subject to your compliance with these Terms, you are hereby granted a limited, nonexclusive,
            nontransferable, non-sublicensable, revocable license to access and use our Services for your own personal, noncommercial use. Any use of the
            Services other than as specifically authorized herein, without our prior written permission, is strictly prohibited, will terminate the license
            granted herein and violate our intellectual property rights.
          </p>
          <h1 className='h1terms'>13. Trademarks</h1>
          <p>
            The YellowBird name and our logos, our product or service names, our slogans and the look and feel of the Services are trademarks of YellowBird and
            may not be copied, imitated or used, in whole or in part, without our prior written permission. All other trademarks, registered trademarks, product
            names and company names or logos mentioned on the Services are the property of their respective owners. Reference to any products, services,
            processes or other information by trade name, trademark, manufacturer, supplier or otherwise does not constitute or imply endorsement, sponsorship
            or recommendation by us.
          </p>
          <h1 className='h1terms'>14. Feedback</h1>
          <p>
            You may voluntarily post, submit or otherwise communicate to us any questions, comments, suggestions, ideas, original or creative materials or other
            information about YellowBird or our Services (collectively, “Feedback”). You understand that we may use such Feedback for any purpose, commercial or
            otherwise, without acknowledgment or compensation to you, including, without limitation, to develop, copy, publish, or improve the Feedback in
            YellowBird’s sole discretion. You understand that YellowBird may treat Feedback as nonconfidential.
          </p>
          <h1 className='h1terms'>15. Repeat Infringer Policy; Copyright Complaints</h1>
          <p>
            In accordance with the Digital Millennium Copyright Act and other applicable law, we have adopted a policy of terminating, in appropriate
            circumstances, the accounts of Users or Vendors who repeatedly infringe the intellectual property rights of others. If you believe that anything on
            our Services infringes any copyright that you own or control, you may notify YellowBird’s designated agent as follows:
          </p>
          <br />
          <span>
            Designated Agent: <br />
            Legal
          </span>
          <br />
          <br />
          <span>
            Address: <br />
            2111 E Highland Ave <br />
            Suite 145 <br />
            Phoenix, AZ 85016 <br />
            <br />
            Telephone Number: 888.533.2994 <br />
            E-Mail Address: legal@goyellowbird.com
          </span>
          <p>
            Please see 17 U.S.C. § 512(c)(3) for the requirements of a proper notification. Also, please note that if you knowingly misrepresent that any
            activity or material on our Services is infringing, you may be liable to YellowBird for certain costs and damages.
          </p>
          <h1 className='h1terms'>16. Third-Party Content</h1>
          <p>
            We may provide information about third-party products, services, activities or events, or we may allow third parties to make their content and
            information available on or through the Services (collectively, “Third-Party Content”). We provide Third-Party Content as a service to those
            interested in such content. Your dealings or correspondence with third parties and your use of or interaction with any Third-Party Content are
            solely between you and the third party. YellowBird does not control or endorse, and makes no representations or warranties regarding, any
            Third-Party Content, and your access to and use of such Third-Party Content is at your own risk.
          </p>
          <h1 className='h1terms'>17. Indemnification</h1>
          <p>
            To the fullest extent permitted by applicable law, you will indemnify, defend and hold harmless YellowBird and our officers, directors, agents,
            partners and employees (individually and collectively, the “YellowBird Parties”) from and against any losses, liabilities, claims, demands, damages,
            expenses or costs (“Claims”) arising out of or related to (a) your access to or use of the Services and/or Vendor Services; (b) your Content or
            Feedback; (c) your violation of these Terms; (d) your violation, misappropriation or infringement of any rights of another (including intellectual
            property rights or privacy rights); or (e) your conduct in connection with the Services. You agree to cooperate with the YellowBird Parties in
            defending such Claims.
          </p>
          <h1 className='h1terms'>18. Disclaimers</h1>
          <p>
            Your use of our Services and the Vendor Services is at your sole risk. Except as otherwise provided in a writing by us, our Services and any content
            therein, and any Vendor Services, are provided “as is” and “as available” without warranties of any kind, either express or implied, including, but
            not limited to, implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement. In addition, YellowBird does
            not represent or warrant that our Services are accurate, complete, reliable, current or error-free. While YellowBird attempts to make your use of
            our Services and any content therein safe, we cannot and do not represent or warrant that our Services or servers are free of viruses or other
            harmful components. You assume the entire risk as to the quality and performance of the Services and the Vendor Services.
          </p>
          <h1 className='h1terms'>19. Limitation of Liability</h1>
          <p>
            To the fullest extent permitted by applicable law, YellowBird and the other YellowBird Parties will not be liable to you under any theory of
            liability—whether based in contract, tort, negligence, strict liability, warranty, or otherwise—for any indirect, consequential, exemplary,
            incidental, punitive or special damages or lost profits, even if YellowBird or the other YellowBird Parties have been advised of the possibility of
            such damages.
          </p>
          <p>
            The total liability of YellowBird and the other YellowBird Parties for any claim arising out of or relating to these Terms, our Services, or the
            Vendor Services, regardless of the form of the action, is limited to the amount paid by you to use our Services.
          </p>
          <p>
            The limitations set forth in this Section will not limit or exclude liability for the gross negligence, fraud or intentional misconduct of
            YellowBird or the other YellowBird Parties or for any other matters in which liability cannot be excluded or limited under applicable law.
            Additionally, some jurisdictions do not allow the exclusion or limitation of incidental or consequential damages, so the above limitations or
            exclusions may not apply to you.
          </p>
          <h1 className='h1terms'>20. Release</h1>
          <p>
            To the fullest extent permitted by applicable law, you release YellowBird and the other YellowBird Parties from responsibility, liability, claims,
            demands and/or damages (actual and consequential) of every kind and nature, known and unknown (including, but not limited to, claims of negligence),
            arising out of or related to (a) disputes between you and a User or Users or you and a Vendor or Vendors, (b) the acts or omissions of Vendors
            and/or third parties, and/or (c) the provision of the Vendor Services.
          </p>
          <p>
            If you are a consumer who resides in California, you hereby waive your rights under California Civil Code § 1542, which provides: “A general release
            does not extend to claims that the creditor or releasing party does not know or suspect to exist in his or her favor at the time of executing the
            release and that, if known by him or her, would have materially affected his or her settlement with the debtor or released party.”
          </p>
          <h1 className='h1terms'>21. Transfer and Processing Data</h1>
          <p>
            In order for us to provide our Services, you agree that we may process, transfer and store information about you in the United States and other
            countries, where you may not have the same rights and protections as you do under local law.
          </p>
          <h1 className='h1terms'>22. Dispute Resolution; Binding Arbitration</h1>
          <p>
            Please read the following section carefully because it requires you to arbitrate certain disputes and claims with YellowBird and limits the manner
            in which you can seek relief from us, unless you opt out of arbitration by following the instructions set forth below. No class or representative
            actions or arbitrations are allowed under this arbitration agreement. In addition, arbitration precludes you from suing in court or having a jury
            trial.
          </p>
          <p>
            No Representative Actions. You and YellowBird agree that any dispute arising out of or related to these Terms or our Services is personal to you and
            YellowBird and that any dispute will be resolved solely through individual action, and will not be brought as a class arbitration, class action or
            any other type of representative proceeding.
          </p>
          <p>
            Arbitration of Disputes. Except for small claims disputes in which you or YellowBird seeks to bring an individual action in small claims court
            located in the county of your billing address or disputes in which you or YellowBird seeks injunctive or other equitable relief for the alleged
            unlawful use of intellectual property, you and YellowBird waive your rights to a jury trial and to have any dispute arising out of or related to
            these Terms or our Services resolved in court. Instead, for any dispute or claim that you have against YellowBird or relating in any way to the
            Services, you agree to first contact YellowBird and attempt to resolve the claim informally by sending a written notice of your claim (“Notice”) to
            YellowBird by email at legal@goYellowBird.com or by certified mail addressed to YellowBird Holding, Inc., 9590 E Ironwood Square Drive, Suite 203,
            Scottsdale, Arizona 85258. The Notice must (a) include your name, residence address, email address, and telephone number; (b) describe the nature
            and basis of the claim; and (c) set forth the specific relief sought. Our notice to you will be similar in form to that described above. If you and
            YellowBird cannot reach an agreement to resolve the claim within thirty (30) days after such Notice is received, then either party may submit the
            dispute to binding arbitration administered by JAMS or, under the limited circumstances set forth above, in court. All disputes submitted to JAMS
            will be resolved through confidential, binding arbitration before one arbitrator. Arbitration proceedings will be held in Maricopa County, Arizona
            in accordance with the JAMS Streamlined Arbitration Rules and Procedures (“JAMS Rules”). The most recent version of the JAMS Rules are available on
            the JAMS website and are hereby incorporated by reference. You either acknowledge and agree that you have read and understand the JAMS Rules or
            waive your opportunity to read the JAMS Rules and waive any claim that the JAMS Rules are unfair or should not apply for any reason.
          </p>
          <p>
            You and YellowBird agree that these Terms affect interstate commerce and that the enforceability of this Section will be substantively and
            procedurally governed by the Federal Arbitration Act, 9 U.S.C. § 1, et seq. (the “FAA”), to the maximum extent permitted by applicable law. As
            limited by the FAA, these Terms and the JAMS Rules, the arbitrator will have exclusive authority to make all procedural and substantive decisions
            regarding any dispute and to grant any remedy that would otherwise be available in court, including the power to determine the question of
            arbitrability. The arbitrator may conduct only an individual arbitration and may not consolidate more than one individual’s claims, preside over any
            type of class or representative proceeding or preside over any proceeding involving more than one individual.
          </p>
          <p>
            The arbitrator, YellowBird, and you will maintain the confidentiality of any arbitration proceedings, judgments and awards, including, but not
            limited to, all information gathered, prepared and presented for purposes of the arbitration or related to the dispute(s) therein. The arbitrator
            will have the authority to make appropriate rulings to safeguard confidentiality, unless the law provides to the contrary. The duty of
            confidentiality does not apply to the extent that disclosure is necessary to prepare for or conduct the arbitration hearing on the merits, in
            connection with a court application for a preliminary remedy or in connection with a judicial challenge to an arbitration award or its enforcement,
            or to the extent that disclosure is otherwise required by law or judicial decision.
          </p>
          <p>
            You and YellowBird agree that for any arbitration you initiate, you will pay the filing fee and YellowBird will pay the remaining JAMS fees and
            costs. For any arbitration initiated by YellowBird, YellowBird will pay all JAMS fees and costs. You and YellowBird agree that the state or federal
            courts of the State of Arizona and the United States sitting in Maricopa County, Arizona have exclusive jurisdiction over any appeals and the
            enforcement of an arbitration award.
          </p>
          <p>
            Any claim arising out of or related to these Terms or our Services must be filed within one year after such claim arose; otherwise, the claim is
            permanently barred, which means that you and YellowBird will not have the right to assert the claim.
          </p>
          <p>
            You have the right to opt out of binding arbitration within 30 days of the date you first accepted the terms of this Section by sending an email to
            legal@goYellowBird.com. In order to be effective, the opt-out notice must include your full name and address and clearly indicate your intent to opt
            out of binding arbitration. By opting out of binding arbitration, you are agreeing to resolve disputes in accordance with Section 22.
          </p>
          <p>
            If any portion of this Section is found to be unenforceable or unlawful for any reason, (a) the unenforceable or unlawful provision shall be severed
            from these Terms; (b) severance of the unenforceable or unlawful provision shall have no impact whatsoever on the remainder of this Section or the
            parties’ ability to compel arbitration of any remaining claims on an individual basis pursuant to this Section; and (c) to the extent that any
            claims must therefore proceed on a class, collective, consolidated, or representative basis, such claims must be litigated in a civil court of
            competent jurisdiction and not in arbitration, and the parties agree that litigation of those claims shall be stayed pending the outcome of any
            individual claims in arbitration. Further, if any part of this Section is found to prohibit an individual claim seeking public injunctive relief,
            that provision will have no effect to the extent such relief is allowed to be sought out of arbitration, and the remainder of this Section will be
            enforceable.
          </p>
          <h1 className='h1terms'>23. Governing Law and Venue</h1>
          <p>
            Any dispute arising from these Terms and your use of the Services will be governed by and construed and enforced in accordance with the laws of the
            State of Arizona, except to the extent preempted by U.S. federal law, without regard to conflict of law rules or principles (whether of the State of
            Arizona or any other jurisdiction) that would cause the application of the laws of any other jurisdiction. Any dispute between the parties that is
            not subject to arbitration or cannot be heard in small claims court will be resolved in the state or federal courts of the State of Arizona and the
            United States, respectively, sitting in Maricopa County, Arizona.
          </p>
          <h1 className='h1terms'>24. Modifying and Terminating our Services</h1>
          <p>
            We reserve the right to modify our Services or to suspend or stop providing all or portions of our Services to you (including, without limitation,
            by suspending or terminating your account) at any time on reasonable notice to you. You also have the right to stop using our Services at any time.
            We are not responsible for any loss or harm related to your inability to access or use our Services.
          </p>
          <h1 className='h1terms'>25. Severability</h1>
          <p>
            If any provision or part of a provision of these Terms is unlawful, void or unenforceable, that provision or part of the provision is deemed
            severable from these Terms and does not affect the validity and enforceability of any remaining provisions.
          </p>
          <h1 className='h1terms'>26. Miscellaneous</h1>
          <p>
            These Terms constitute the entire agreement between you and YellowBird relating to your access to and use of our Services. Notwithstanding any other
            provisions of these Terms, any terms or conditions that by their nature should survive any expiration or termination will survive, including the
            terms and conditions relating to proprietary rights and confidentiality, restrictions, disclaimers, indemnification, limitations of liability,
            termination and this miscellaneous provision. The failure of YellowBird to exercise or enforce any right or provision of these Terms will not
            operate as a waiver of such right or provision. The section titles in these Terms are for convenience only and have no legal or contractual effect.
            Except as otherwise provided herein, these Terms are intended solely for the benefit of the parties and are not intended to confer third-party
            beneficiary rights upon any other person or entity. You agree that communications and transactions between us may be conducted electronically. These
            Terms may not be transferred, assigned or delegated by you, by operation of law or otherwise, without our prior written consent, and any attempted
            transfer, assignment or delegation without such consent will be void and without effect. We may freely transfer, assign or delegate these Terms or
            the Services, in whole or in part, without your prior written consent. Under California Civil Code Section 1789.3, California consumers are entitled
            to the following specific consumer rights notice: The Complaint Assistance Unit of the Division of Consumer Services of the California Department of
            Consumer Affairs may be contacted in writing at 1625 N. Market Blvd., Suite N-112, Sacramento, California 95834, or by telephone at 1 (800)
            952-5210.
          </p>
          <h1 className='yellowBird h1terms'>YellowBird Company Cancellation Policy</h1>
          <h1 className='h1terms'>Rescheduling and Cancellations</h1>
          <p>
            Customers. For YellowBird to prepare appropriate accommodations, you must notify YellowBird of your need to cancel or reschedule services at least
            one (1) business day prior to the date on which services are to be rendered. Notifications received within one (1) business day of the date on which
            services are to be rendered shall not result in the dismissal of outstanding invoices or nullify previously agreed charges.
          </p>
          <p>
            Our Professionals. If one of our professionals needs to cancel or reschedule services, YellowBird will immediately contact you and, at your
            discretion, substitute an equally qualified professional or reschedule.
          </p>
          <h1 className='h1terms'>Payments</h1>
          <p>
            If you cancel services before making the initial deposit or first payment, such outstanding amounts shall be voided. YellowBird Refunds of initial
            deposits and payments made in consideration of services rendered shall not be available if you cancel services after one of our professionals has
            begun providing services as scheduled. However, in the case of a cancellation, you will not be billed for additional services and all additional
            invoiced amounts that would have otherwise been billed shall be cancelled.
          </p>
          <p id='last'>All rescheduling and cancellation notices should be made via email to operations@goyellowbird.com.</p>
        </div>
      </div>
      <Navigation disabled={read} />
    </div>
  );
};

export default Step5;
