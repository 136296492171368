import React from 'react';

import './info-box.scss';

const InfoBox = ({ children, image }) => {
  return (
    <div className='info-box'>
      <div className='info-box__image-container'>
        <img className='info-box__image' src={image} alt='Icon' />
      </div>
      <div className='info-box__content'>{children}</div>
    </div>
  );
};

export default InfoBox;
