import React from 'react';
import { Edit } from 'lucide-react';

const DraftJobRow = ({ job }) => {
  return (
    <div style={{ color: '#586f7c' }}>
      <div className='draftRow'>
        <div>
          <p style={{ color: '#586f7c', fontWeight: 600, wordWrap: 'break-word' }}>{job.name || 'None Given'}</p>
        </div>
        <div>
          <p>{job.service || 'None Given'}</p>
        </div>
        <div>
          <p>{job.siteState || 'None Given'}</p>
        </div>
        <div>
          <p>{job.commuteType || 'None Given'}</p>
        </div>
        <div>
          <p>Draft</p>
        </div>
        <div style={{ position: 'absolute', right: '12px' }}>
          <Edit />
        </div>
      </div>
      <hr className='my-3' />
    </div>
  );
};

export default DraftJobRow;
