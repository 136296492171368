import React from 'react';

import './category-checkbox.scss';

const CategoryCheckbox = ({ name, required, register, cardType, nameOfIndustries, language, id, field }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'start' }}>
      <input
        className='checkBoxCategoryFieldset'
        id={`${field}-${id}`}
        {...register(field, required && { required: true })}
        type='checkbox'
        value={Number(id)}
      />
      <label htmlFor={`${field}-${id}`}>{name || cardType || nameOfIndustries || language}</label>
    </div>
  );
};

export default CategoryCheckbox;
