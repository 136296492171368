import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { camelizeKeys, decamelizeKeys } from 'humps';

import api from '../api';
import apiCamelback from "../api/apiCamelback";

// Thunks
export const retrieveOrientationEvents = createAsyncThunk('googleCalendar/orientationEvents', async (_, thunkApi) => {
  try {
    const response = await apiCamelback.get('/api/v1/google_calendar/orientation_events');
    if (response.data.errors) return thunkApi.rejectWithValue(response.data.errors);
    return camelizeKeys(response.data);
  } catch (err) {
    return thunkApi.rejectWithValue(err);
  }
});

export const retrieveWorkerOrientation = createAsyncThunk('googleCalendar/workerOrientation', async (_, thunkApi) => {
  try {
    const response = await apiCamelback.get('/api/v1/google_calendar/worker_orientation');
    if (response.data.errors) return thunkApi.rejectWithValue(response.data.errors);
    return camelizeKeys(response.data);
  } catch (err) {
    return thunkApi.rejectWithValue(err);
  }
});

export const signUpForOrientation = createAsyncThunk('googleCalendar/signup', async (event, thunkApi) => {
  try {
    const decamelized = decamelizeKeys(event);
    const response = await apiCamelback.post('/api/v1/google_calendar/signup', { event: decamelized });
    if (response.data.errors) return thunkApi.rejectWithValue(response.data.errors);
    return camelizeKeys(response.data);
  } catch (err) {
    return thunkApi.rejectWithValue(err);
  }
});

const initialState = {
  error: null,
  isLoading: false,
  orientationEvents: {},
  workerOrientation: {},
};

export const googleCalendarSlice = createSlice({
  name: 'googleCalendar',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      // retrieveOnboardingEvents
      .addCase(retrieveOrientationEvents.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(retrieveOrientationEvents.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(retrieveOrientationEvents.fulfilled, (state, action) => {
        state.isLoading = false;
        state.orientationEvents = action.payload;
      })
      // retrieveWorkerOrientation
      .addCase(retrieveWorkerOrientation.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(retrieveWorkerOrientation.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(retrieveWorkerOrientation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.workerOrientation = action.payload;
      })
      // signUpForOrientation
      .addCase(signUpForOrientation.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(signUpForOrientation.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(signUpForOrientation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.workerOrientation = action.payload;
      });
  },
});
