import { Row } from 'react-bootstrap';

import OldCreateJob from '../../pages/OldCreateJob';

export default function Form({ setDisplayModal, page, setPage, fromService, parentId, childId, copy, jobName, prepaid }) {
  return (
    <div className='form'>
      <div className='form-container'>
        <Row className='body'>
          <OldCreateJob
            copy={copy}
            fromService={fromService}
            childId={childId}
            parentId={parentId}
            page={page}
            setPage={setPage}
            setDisplayModal={setDisplayModal}
            jobName={jobName}
            prepaid={prepaid}
          />
        </Row>
      </div>
    </div>
  );
}
