import React, { useState } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useSelector } from 'react-redux';

import HorizontalRule from '../../components/HorizontalRule';
import Loading from '../../components/Loading';
import Spacer from '../../components/Spacer';
import Text from '../../components/Text';
import TextInput from '../../components/TextInput';
import { c } from '../../constants/colors';

const CheckoutForm = ({ intentObj, setCardToUse, setAddNewCard, setValues }) => {
  const [cardHolderName, setCardHolderName] = useState('');
  const [billingAddress, setBillingAddress] = useState('');
  const [billingCity, setBillingCity] = useState('');
  const [billingState, setBillingState] = useState('');
  const [cardLoading, setCardLoading] = useState(false);
  const [error, setError] = useState('');
  const { isLoading } = useSelector(state => state.company);

  const stripe = useStripe();
  const elements = useElements();
  const styles = {
    buttonStyle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '70%',
      height: 48,
      backgroundColor: c.yellow,
      border: 'none',
      borderRadius: 12,
      marginRight: 0,
      fontWeight: 650,
    },
  };
  const handleSubmit = async event => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    const cardElement = elements.getElement(CardElement);
    const isInvalid = cardElement._invalid;
    const isEmpty = cardElement._empty;
    const isValid = !(isEmpty || isInvalid);
    if (!isValid) {
      setError('Your Card Information is Invalid or Incomplete');
      return;
    }
    setCardLoading(true);

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmCardSetup(intentObj.client_secret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: cardHolderName,
          address: {
            line1: billingAddress,
            city: billingCity,
            state: billingState,
          },
        },
      },
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      window.alert(result.error.message);
      setCardLoading(false);
    } else {
      setCardLoading(false);
      setAddNewCard(false);
      setCardToUse(result.setupIntent.payment_method);
    }
  };

  if (!intentObj) return <Loading />;
  return (
    <form onSubmit={handleSubmit}>
      <TextInput label='Billing Details' placeholder='Name on Card' value={cardHolderName} onChange={e => setCardHolderName(e.target.value)} />
      <TextInput placeholder='Billing Address' value={billingAddress} onChange={e => setBillingAddress(e.target.value)} />
      <TextInput placeholder='Billing City' value={billingCity} onChange={e => setBillingCity(e.target.value)} />
      <TextInput placeholder='Billing State' value={billingState} onChange={e => setBillingState(e.target.value)} />
      <Spacer h={24} />
      <CardElement onChange={e => setError(e?.error?.message || '')} />
      <HorizontalRule />
      <Spacer h={8} />
      <Text t='greySmall'>
        By providing your card information, you allow YellowBird Holdings Inc to charge your card for future payments in accordance with their terms.
      </Text>
      <Spacer h={8} />
      <div>
        <Text t='red'>{error}</Text>
      </div>
      <HorizontalRule />
      <Spacer h={24} />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {cardLoading || isLoading ? (
          <Loading />
        ) : (
          <button style={styles.buttonStyle} disabled={!stripe || !cardHolderName || !billingAddress || !billingCity || !billingState}>
            Save Card
          </button>
        )}
      </div>
    </form>
  );
};

export default CheckoutForm;
