import { useEffect, useState } from 'react';
import Hotjar from '@hotjar/browser';

import { HOTJAR_SITE_ID } from '../env';

const HOTJAR_WHITELISTED_HOSTS = /app\.goyellowbird\.com/i;

/**
 * initialize the HotJar integration.
 * Should be used once in the App's init phase.
 */
export default function useHotJar() {
  const [hotjarConfigured, setHotjarConfigured] = useState(false);
  useEffect(() => {
    if (hotjarConfigured) {
      return;
    }
    // HotJar provides this version number as part of the installation and integration instructions.
    try {
      if (process.env.NODE_ENV !== 'production') {
        // if running in non-production mode, skip this integration so we do not record sessions in localhost etc.
        setHotjarConfigured(true);
        return;
      }
      const hostname = window.location.hostname;
      if (!hostname.match(HOTJAR_WHITELISTED_HOSTS)) {
        // hostname is not part of our regex list of allowed hosts, do not initialize it.
        setHotjarConfigured(true);
        return;
      }
      const hotJarVersion = 6;
      Hotjar.init(HOTJAR_SITE_ID, hotJarVersion);
      setHotjarConfigured(true);
    } catch (e) {
      console.error('error initializing HotJar', e);
    }
  }, [hotjarConfigured]);
}
