import React, { Fragment, useState } from 'react';

import CategoryCheckbox from './category-checkbox';

import './category-fieldset.scss';

import { Disclosure, Transition } from '@headlessui/react';
import { ChevronDownIcon } from 'lucide-react';

const CategoryFieldset = ({ step, stepSelected, setStepSelected, getValues, label, category, register, field, required, resetField }) => {
  const shouldOpen = getValues(field).length !== 0;
  const [open, setOpen] = useState(false);
  const isSelected = stepSelected === step;

  return (
    <Disclosure defaultOpen={shouldOpen}>
      <Disclosure.Button
        onClick={() => {
          if (isSelected) {
            setStepSelected(0);
            setOpen(false);
            return;
          }
          setStepSelected(0);
          setOpen(!open);
        }}
        className='disclosureButtonCategory'
      >
        <h6 className='categoryFieldsetH6'>{label}</h6>
        <ChevronDownIcon className={`arrowDownIconCategoryFieldset ${open || isSelected ? 'rotateArrowDown180' : null}`} />
      </Disclosure.Button>
      <Transition
        show={isSelected || open}
        as={Fragment}
        enter='dialogProfileBuilding-enter'
        enterFrom='dialogProfileBuilding-enterFrom'
        enterTo='dialogProfileBuilding-enterTo'
        leave='dialogProfileBuilding-leave'
        leaveFrom='dialogProfileBuilding-leaveFrom'
        leaveTo='dialogProfileBuilding-leaveTo'
      >
        <Disclosure.Panel>
          <div className='categoryFieldSetMapWrapper'>
            {category &&
              category.map(({ id, name, cardType, nameOfIndustries, label: language }) => (
                <CategoryCheckbox
                  key={name}
                  register={register}
                  id={id}
                  cardType={cardType}
                  field={field}
                  required={required}
                  language={language}
                  nameOfIndustries={nameOfIndustries}
                  name={name}
                />
              ))}
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            <button style={{ fontSize: '14px' }} className='clearSelectionCategoryFieldset' onClick={() => resetField(field)} type='button'>
              Clear Selection
            </button>
            <div className='nextAndBackCategoryFieldset'>
              <button
                onClick={() => {
                  setOpen(false);
                  setStepSelected(step - 1);
                }}
                type='button'
                className='backAndNextBtnProfileBuilding'
              >
                Back
              </button>
              <button
                onClick={() => {
                  setOpen(false);
                  setStepSelected(step + 1);
                }}
                type='button'
                className='backAndNextBtnProfileBuilding'
              >
                Next
              </button>
            </div>
          </div>
        </Disclosure.Panel>
      </Transition>
    </Disclosure>
  );
};

export default CategoryFieldset;
