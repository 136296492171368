import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import HowItWorksSVG from '../../assets/images/how-it-works.svg';
import LeftArrow from '../../assets/images/SignUpAsImages/LeftArrow.svg';

const HowItWorksPage = () => {
  const navigate = useNavigate();
  return (
    <Container fluid style={{ width: 768 }}>
      <Row className='d-flex  mb-3'>
        <Col onClick={() => navigate('/dashboard')} className='ps-3' xs={2} md={2}>
          <img style={{ cursor: 'pointer', width: 13 }} src={LeftArrow} alt='' />
        </Col>
        <Col xs={8} md={8} className='d-flex justify-content-center'>
          <h4 className='' style={{ fontWeight: 600 }}>
            How it Works
          </h4>
        </Col>
      </Row>
      <Row>
        <img className='d-flex justify-content-center mt-4 mb-5' src={HowItWorksSVG} alt='' />
      </Row>
    </Container>
  );
};

export default HowItWorksPage;
