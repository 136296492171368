import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

import loader from '../../assets/icons/Spinner@1.25x-1.0s-200px-200px.svg';
import yellowBirdIconYellow from '../../assets/icons/yellowbirdyellow.png';

import './loading-state.scss';

const LoadingState = ({ isOpen, children }) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog style={{ position: 'relative', zIndex: '10' }} as='div' onClose={() => null}>
        <Transition.Child
          as={Fragment}
          enter='transition-opacity duration-75'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='transition-opacity duration-150'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div style={{ position: 'fixed', inset: '0', backgroundColor: 'rgba(0, 0, 0, 0.6)' }} />
        </Transition.Child>
        <div style={{ position: 'fixed', inset: '0', overflowY: 'auto', padding: '12px' }}>
          <div style={{ display: 'flex', minHeight: '100%', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <Dialog.Panel className='dialogPanelLoadingState'>
                <img style={{ width: '120px', margin: '-20px' }} src={loader} />
                <Dialog.Title as='h3' style={{ fontSize: '20px', fontWeight: '700', color: '#49525E' }}>
                  Your resume is uploading and processing!
                </Dialog.Title>
                <div className='mt-2'>
                  <p>
                    This usually takes 1-2 minutes for keywords and other details to be identified. You may open new tabs in your browser, but please do not
                    close this tab until processing is completed.
                  </p>
                </div>
                {children}
                <div>
                  <img style={{ maxWidth: '60px' }} src={yellowBirdIconYellow} />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default LoadingState;
