import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { camelizeKeys, decamelizeKeys } from 'humps';
import moment from 'moment';

import api from '../api';
import apiCamelback from '../api/apiCamelback';

//Thunks
export const retrieveJobInvite = createAsyncThunk('job/retrieveInvite', async (jobInviteRefNumber, thunkApi) => {
  try {
    const response = await apiCamelback.get(`api/v1/user/workers/job-invites/retrieve/${jobInviteRefNumber}`);
    if (response.data.errors) {
      return thunkApi.rejectWithValue(response.data.errors);
    }
    const camelized = camelizeKeys(response.data);
    return camelized;
  } catch (error) {
    return thunkApi.rejectWithValue(error.response);
  }
});

export const selectJobInvite = createAsyncThunk('job/selectInvite');

export const respondToJobInvite = createAsyncThunk(
  'job/respondToInvite',
  async ({ jobInviteRefNumber, response, reasonForDecline, isProDriving, timeWindowSid }, thunkApi) => {
    try {
      const res = await apiCamelback.put(`api/v1/user/workers/job-invites/${jobInviteRefNumber}/accept-or-decline`, {
        time_window_sid: timeWindowSid,
        response,
        reason_for_decline: reasonForDecline,
        is_pro_driving: isProDriving,
      });
      if (res.data.errors) {
        return thunkApi.rejectWithValue(res.data.errors);
      }
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const createJob = createAsyncThunk('job/createJob', async ({ job }, thunkApi) => {
  const rateCents = parseFloat(job.estimatedRate) * 100;
  const enteredRateCents = parseFloat(job.enteredRate) * 100;
  let proHourlyRateCents = 0;
  let estimatedTotalCents = 0;
  let payCents = 0;
  if (job.service !== 'Enter My Own') {
    proHourlyRateCents = rateCents * 0.7;
    payCents = job.priceType === 'Flat' ? rateCents * 0.7 : job.totalHours * rateCents * 0.7;
    estimatedTotalCents = job.priceType === 'Flat' ? rateCents : job.totalHours * rateCents;
  }
  job.numberOfDays = Math.ceil(job.totalHours / 8);
  let decamelized = decamelizeKeys(job);
  decamelized.status = 2;
  decamelized.order_status = 1;
  decamelized.co_hourly_rate_cents = rateCents || 0;
  decamelized.co_entered_rate_cents = enteredRateCents || 0;
  decamelized.professional_hourly_rate_cents = proHourlyRateCents;
  decamelized.estimated_total_cents = estimatedTotalCents;
  decamelized.pay_cents = payCents;

  const response = await apiCamelback.post('/api/v1/user/company/jobs', decamelized);
  if (response.data.errors) {
    return thunkApi.rejectWithValue(response.data.errors);
  }
  try {
    // Calling Camelback API to index new job in Elastic Search
    await apiCamelback.post(`api/v1/elasticsearch/job/${response.data.id}/create`);
  } catch (error) {
    console.error({ message: error?.response?.data?.message || error.message, error });
  }
  return response.data;
});

export const updateNotes = createAsyncThunk('job/updateNotes', async ({ jobId, payload }, thunkApi) => {
  const response = await apiCamelback.post(`/api/v1/user/company/jobs/${jobId}/update_notes`, payload);
  const camelized = camelizeKeys(response.data);
  if (response.data.errors) {
    return thunkApi.rejectWithValue(response.data.errors);
  }
  return camelized;
});

export const getAllDraftJobs = createAsyncThunk('job/getAllDraftJobs', async (_, thunkApi) => {
  const response = await apiCamelback.get('/api/v1/draft/job');
  const camelized = camelizeKeys(response.data);
  if (response.data.errors) {
    return thunkApi.rejectWithValue(response.data.errors);
  }
  return camelized;
});

export const getDraftJob = createAsyncThunk('job/getDraftJob', async (sid, thunkApi) => {
  const response = await apiCamelback.get(`/api/v1/draft/job/${sid}`);
  const camelized = camelizeKeys(response.data);
  if (response.data.errors) {
    return thunkApi.rejectWithValue(response.data.errors);
  }
  return camelized;
});

export const createDraftJob = createAsyncThunk('job/createDraftJob', async ({ job, companyId }, thunkApi) => {
  const decamelized = decamelizeKeys(job);
  const response = await apiCamelback.post('/api/v1/draft/job', { json: { ...decamelized, company: companyId } });
  const camelized = camelizeKeys(response.data);
  if (response.data.errors) {
    return thunkApi.rejectWithValue(response.data.errors);
  }
  return camelized;
});

export const updateDraftJob = createAsyncThunk('job/updateDraftJob', async ({ sid, job, companyId }, thunkApi) => {
  const booleanValues = [job.deliverableRequired, job.coAdditionalContent, job.templateRequired, job.additionalRequirements];
  const convertedValues = booleanValues.map(value => (value === 'yes' ? true : false));
  let decamelized = decamelizeKeys({
    ...job,
    deliverableRequired: convertedValues[0],
    coAdditionalContent: convertedValues[1],
    templateRequired: convertedValues[2],
    additionalRequirements: convertedValues[3],
  });
  let formattedRate;
  if (typeof job.estimatedRate === 'string') {
    if (job.estimatedRate.startsWith('$') && job.estimatedRate !== '') {
      let rate = job.estimatedRate.slice(1);
      rate = rate.replace(/,/g, '');
      formattedRate = parseInt(rate);
      job.estimatedRate = formattedRate;
    }
  }
  if (job.service === 'Enter My Own') {
    decamelized.professional_hourly_rate_cents = 1;
    decamelized.pay_cents = 1;
    decamelized.type_of_service = '';
  }

  const rateCents = parseFloat(job.estimatedRate) * 100;
  const enteredRateCents = parseFloat(job.enteredRate) * 100;
  let proHourlyRateCents = 0;
  let estimatedTotalCents = 0;
  let payCents = 0;

  if (job.service !== 'Enter My Own') {
    proHourlyRateCents = rateCents * 0.7;
    payCents = job.priceType === 'Flat' ? rateCents * 0.7 : job.totalHours * rateCents * 0.7;
    estimatedTotalCents = job.priceType === 'Flat' ? rateCents : job.totalHours * rateCents;
    decamelized.professional_hourly_rate_cents = Math.round(proHourlyRateCents);
    decamelized.pay_cents = Math.round(payCents);
  }

  decamelized.number_of_days = Math.ceil(job.totalHours / 8);
  decamelized.status = 2;
  decamelized.co_hourly_rate_cents = rateCents || 0;
  decamelized.co_entered_rate_cents = enteredRateCents || 0;
  decamelized.estimated_total_cents = estimatedTotalCents;
  decamelized.company = companyId;
  decamelized.payment_method = job.paymentMethodSelection;
  delete decamelized.paymentMethodSelection;
  delete decamelized.paymentMethod;

  const response = await apiCamelback.put(`/api/v1/draft/job/${sid}`, { json: decamelized, sid: sid });
  const camelized = camelizeKeys(response.data);
  if (response.data.errors) {
    return thunkApi.rejectWithValue(response.data.errors);
  }
  return camelized;
});

export const publishDraftJob = createAsyncThunk('job/publishDraftJob', async ({ sid }, thunkApi) => {
  const response = await apiCamelback.post(`/api/v1/draft/job/publish/${sid}`);
  const camelized = camelizeKeys(response.data);
  if (response.data.errors) {
    return thunkApi.rejectWithValue(response.data.errors);
  }
  return camelized;
});

export const deleteDraftJob = createAsyncThunk('job/deleteDraftJob', async (sid, thunkApi) => {
  const response = await apiCamelback.delete(`/api/v1/draft/job/${sid}`);
  const camelized = camelizeKeys(response.data);
  if (response.data.errors) {
    return thunkApi.rejectWithValue(response.data.errors);
  }
  return camelized;
});

export const coRetrieveJobs = createAsyncThunk('job/coRetrieveJobs', async (_, thunkApi) => {
  const response = await apiCamelback.get('/api/v1/user/company/jobs');
  const camelized = camelizeKeys(response.data);
  if (response.data.errors) {
    return thunkApi.rejectWithValue(response.data.errors);
  }
  return camelized;
});

export const getWorkerForJob = createAsyncThunk('job/proForJob', async (jobId, thunkApi) => {
  const response = await api.get(`/api/v1/jobs/worker_for_job/${jobId}`);
  const camelized = camelizeKeys(response.data);
  if (response.data.errors) {
    return thunkApi.rejectWithValue(response.data.errors);
  }
  return camelized;
});
export const getQbeJob = createAsyncThunk('job/getQbeJob', async ({ jobId }, thunkApi) => {
  const response = await api.get(`/api/v1/qbe_jobs/${jobId}`);
  if (response.data.errors) return thunkApi.rejectWithValue(response.data.errors);
  return response.data;
});

export const getQbeJobSsoLink = createAsyncThunk('job/getQbeJobSsoLink', async ({ inspectionId }, thunkApi) => {
  const response = await api.get(`/api/v1/qbe_jobs/get_sso_link/${inspectionId}`);
  if (response.data.errors) return thunkApi.rejectWithValue(response.data.errors);
  return response.data;
});

export const proRetrieveJobs = createAsyncThunk('job/proRetrieveJobs', async (_, thunkApi) => {
  const response = await apiCamelback.get('/api/v1/user/workers/jobs');
  if (response.data.errors) {
    return thunkApi.rejectWithValue(response.data.errors);
  }
  const camelized = camelizeKeys(response.data);
  return camelized;
});

export const markJobCompleteAsCo = createAsyncThunk('job/markJobCompleteAsCo', async (body, thunkApi) => {
  const decamelized = decamelizeKeys(body);
  const response = await api.put(`/api/v1/jobs/${body.jobId}`, decamelized);
  if (response.data.errors) {
    return thunkApi.rejectWithValue(response.data.errors);
  }
  // const allJobs = await api.get('/api/v1/jobs');
  // if (allJobs.data.errors) {
  //   return thunkApi.rejectWithValue(allJobs.data.errors);
  // }
  // const camelized = camelizeKeys(allJobs.data);
  const camelized = camelizeKeys(response.data);
  return camelized;
});

export const markJobCompleteAsPro = createAsyncThunk('job/markJobCompleteAsPro', async (body, thunkApi) => {
  const response = await apiCamelback.post(`/api/v1/user/workers/jobs/${body.jobId}/mark-job-as-complete`, { milesDriven: body.milesDriven });
  if (response.data.errors) {
    return thunkApi.rejectWithValue(response.data.errors);
  }
  const camelized = camelizeKeys(response.data);
  return camelized;
});

export const sendCoJobIncompleteEmail = createAsyncThunk('job/sendCoJobIncomplete', async (body, thunkApi) => {
  const decamelized = decamelizeKeys(body);
  const response = await api.post(`/api/v1/jobs/send_co_job_incomplete_email`, decamelized);
  if (response.data.errors) return thunkApi.rejectWithValue(response.data.errors);
});

export const retrieveSingleCompanyJob = createAsyncThunk('job/retrieveSingleCompanyJob', async ({ referenceNumber }, thunkApi) => {
  try {
    const response = await apiCamelback.get(`/api/v1/user/company/jobs/order/${referenceNumber}`);
    if (response.data.errors) return thunkApi.rejectWithValue(response.data.errors);
    return camelizeKeys(response.data);
  } catch (err) {
    return thunkApi.rejectWithValue(err);
  }
});

export const retrieveSingleWorkerJob = createAsyncThunk('job/retrieveSingleWorkerJob', async ({ referenceNumber }, thunkApi) => {
  try {
    const response = await apiCamelback.get(`/api/v1/user/workers/jobs/${referenceNumber}`);
    if (response.data.errors) return thunkApi.rejectWithValue(response.data.errors);
    return camelizeKeys(response.data);
  } catch (err) {
    return thunkApi.rejectWithValue(err);
  }
});

export const updateJobDetails = createAsyncThunk(
  'job/updateJobDetails',
  async (
    { jobId, startDate, deadline, siteAddress, siteCity, siteState, siteZip, timeWindows, totalHours, schedulingNotes, orderStatus, reasonToDecline },
    thunkApi
  ) => {
    try {
      const response = await apiCamelback.put(`api/v1/user/company/jobs/${jobId}/update_job_details`, {
        ...(startDate && { start_date: startDate }),
        ...(deadline && { deadline: deadline === 'clear' ? null : deadline }),
        ...(timeWindows && { time_windows: timeWindows }),
        ...(totalHours && { total_hours: totalHours }),
        ...(schedulingNotes && { scheduling_notes: schedulingNotes }),
        ...(siteAddress && { site_address: siteAddress }),
        ...(siteCity && { site_city: siteCity }),
        ...(siteState && { site_state: siteState }),
        ...(siteZip && { site_zip: siteZip }),
        ...(orderStatus && { order_status: orderStatus === 'accepted' ? 1 : 2 }),
        ...(reasonToDecline && { reason_to_decline: reasonToDecline }),
      });
      if (response.data.errors) return thunkApi.rejectWithValue(response.data.errors);
      try {
        // Calling Camelback API to update job in Elastic Search
        await apiCamelback.post(`api/v1/elasticsearch/job/${response.data.id}/update`);
      } catch (error) {
        console.error({ message: error?.response?.data?.message || error.message, error });
      }
      return camelizeKeys(response.data);
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const getMatrixData = createAsyncThunk('job/getMatrixData', async (_, thunkApi) => {
  try {
    const response = await apiCamelback.get('/app/api/v1/job_matrix');
    if (response.data.errors) {
      return thunkApi.rejectWithValue(response.data.errors);
    }
    const camelized = camelizeKeys(response.data);

    /**
     * Filtered job children.
     * An array of job children that have been filtered based on their enabled status and date range.
     * @type {Array}
     */
    const now = moment.utc();

    const filteredJobChildren = camelized.jobChildren.filter(child => {
      const { enabled, startDate, endDate } = child;

      const start = startDate ? moment.utc(startDate) : null;
      const end = endDate ? moment.utc(endDate) : null;

      return enabled && (start === null || start.isSameOrBefore(now)) && (end === null || end.isSameOrAfter(now));
    });

    const PROMO_CHILD_LABEL = '*20% Off March Offer* Loss Control/Risk Audit';

    const lossControlRiskAuditId = camelized.jobParents.find(child => child.label === 'Loss Control/Risk Audit')?.id;

    let promoChildJob = null;
    const lossControlRiskAuditChildren = [];
    const restOfTheChildren = [];

    filteredJobChildren.forEach(child => {
      if (child.label === PROMO_CHILD_LABEL) {
        promoChildJob = child;
      } else if (child.parentJobId === lossControlRiskAuditId) {
        lossControlRiskAuditChildren.push(child);
      } else {
        restOfTheChildren.push(child);
      }
    });

    return {
      jobParents: camelized.jobParents,
      jobChildren: [promoChildJob, ...lossControlRiskAuditChildren, ...restOfTheChildren].filter(item => item !== null),
    };
  } catch (err) {
    return thunkApi.rejectWithValue(err);
  }
});

export const copyJob = createAsyncThunk('job/copyJob', async (job, thunkApi) => {
  try {
    return job;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const updateJobPaymentMethod = createAsyncThunk(
  'job/updatePaymentMethod',
  async ({ job_id, payment_method_selection, stripe_payment_method }, thunkApi) => {
    try {
      const response = await api.put('/api/v1/jobs/update_payment_method', { job_id, payment_method_selection, stripe_payment_method });
      if (response.data) {
        return camelizeKeys(response.data);
      }
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const markTaskAsCompleted = createAsyncThunk('job/markTaskAsCompleted', ({ taskSid, stage }) => {
  return { taskSid, stage };
});

const initialState = {
  error: null,
  isLoading: false,
  job: {},
  allJobs: [],
  jobOrder: {},
  jobInvite: {},
  jobParents: [],
  jobChildren: [],
  jobToCopy: {},
  alreadyMatched: false,
  allDraftJobs: [],
  draftJob: {},
};

const acceptedRespBody = 'Sorry, this job has already been accepted.';

export const jobSlice = createSlice({
  name: 'job',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      // retrieveJobInvite
      .addCase(retrieveJobInvite.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(retrieveJobInvite.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(retrieveJobInvite.fulfilled, (state, action) => {
        state.jobInvite = action.payload;
        state.error = null;
        state.isLoading = false;
      })
      // selectJobInvite
      .addCase(selectJobInvite.pending, (state, action) => {})
      .addCase(selectJobInvite.rejected, (state, action) => {})
      .addCase(selectJobInvite.fulfilled, (state, action) => {})
      // respondToJobInvite
      .addCase(respondToJobInvite.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(respondToJobInvite.rejected, (state, action) => {
        const errorMessage = action.payload?.response?.data?.message || action.error?.message;

        if (errorMessage === acceptedRespBody) {
          state.alreadyMatched = true;
        } else {
          state.alreadyMatched = false;
        }
        state.error = errorMessage;
        state.isLoading = false;
      })
      .addCase(respondToJobInvite.fulfilled, (state, action) => {
        state.jobInviteResponse = action.payload;
        state.isLoading = false;
      })
      // createJob
      .addCase(createJob.pending, (state, action) => {
        state.isLoading = true;
        state.job = action.payload;
      })
      .addCase(createJob.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(createJob.fulfilled, (state, action) => {
        state.isLoading = false;
        state.job = action.payload;
      })
      //updateNotes
      .addCase(updateNotes.pending, (state, action) => {
        state.isLoading = true;
        state.job = action.payload;
      })
      .addCase(updateNotes.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(updateNotes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.job = action.payload;
      })
      //createDraftJob
      .addCase(createDraftJob.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createDraftJob.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(createDraftJob.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      //getAllDraftJobs
      .addCase(getAllDraftJobs.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAllDraftJobs.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getAllDraftJobs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.allDraftJobs = action.payload;
      })
      //getDraftJob
      .addCase(getDraftJob.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getDraftJob.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getDraftJob.fulfilled, (state, action) => {
        state.isLoading = false;
        state.draftJob = action.payload;
      })
      // updateDraftJob
      .addCase(updateDraftJob.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateDraftJob.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(updateDraftJob.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      // publishDraftJob
      .addCase(publishDraftJob.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(publishDraftJob.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(publishDraftJob.fulfilled, (state, action) => {
        state.isLoading = false;
        state.publishedDraft = action.payload;
      })
      // coRetrieveJobs
      .addCase(coRetrieveJobs.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(coRetrieveJobs.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(coRetrieveJobs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.allJobs = action.payload;
      })
      //markJobCompleteAsCo
      .addCase(markJobCompleteAsCo.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(markJobCompleteAsCo.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(markJobCompleteAsCo.fulfilled, (state, action) => {
        state.isLoading = false;
        // state.allJobs = action.payload;
      })
      //markJobCompleteAsPro
      .addCase(markJobCompleteAsPro.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(markJobCompleteAsPro.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(markJobCompleteAsPro.fulfilled, (state, action) => {
        state.isLoading = false;
        // state.allJobs = action.payload;
      })
      // retrieveSingleCompanyJob
      .addCase(retrieveSingleCompanyJob.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(retrieveSingleCompanyJob.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(retrieveSingleCompanyJob.fulfilled, (state, action) => {
        state.isLoading = false;
        state.jobOrder = action.payload;
      })
      // retrieveSingleWorkerJob
      .addCase(retrieveSingleWorkerJob.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(retrieveSingleWorkerJob.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(retrieveSingleWorkerJob.fulfilled, (state, action) => {
        state.isLoading = false;
        state.jobOrder = action.payload;
      })
      //get jobs for worker job widget
      .addCase(proRetrieveJobs.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(proRetrieveJobs.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(proRetrieveJobs.fulfilled, (state, action) => {
        state.allJobs = action.payload;
        state.isLoading = false;
      })
      //get job matrix data
      .addCase(getMatrixData.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getMatrixData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getMatrixData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.jobParents = action.payload.jobParents;
        state.jobChildren = action.payload.jobChildren;
      })
      //copy job
      .addCase(copyJob.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(copyJob.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(copyJob.fulfilled, (state, action) => {
        state.jobToCopy = action.payload;
        state.isLoading = false;
      })
      //worker for the job
      .addCase(getWorkerForJob.pending, (state, action) => {})
      .addCase(getWorkerForJob.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(getWorkerForJob.fulfilled, (state, action) => {
        state.workerForJob = action.payload;
      })
      //update the job's payment method/info
      .addCase(updateJobPaymentMethod.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateJobPaymentMethod.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(updateJobPaymentMethod.fulfilled, (state, action) => {
        state.isLoading = false;
        state.jobOrder = action.payload;
      })
      //update the Jobs Details(Dates and Address)
      .addCase(updateJobDetails.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateJobDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(updateJobDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.jobOrder = action.payload;
      })
      // markTaskAsCompleted
      .addCase(markTaskAsCompleted.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(markTaskAsCompleted.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(markTaskAsCompleted.fulfilled, (state, action) => {
        state.isLoading = false;
        const { taskSid, stage } = action.payload;
        state.jobOrder = {
          ...state.jobOrder,
          allTasks: state.jobOrder.allTasks.map(task => {
            if (task.sid === taskSid) {
              return {
                ...task,
                stage,
              };
            }
            return task;
          }),
        };
      });
  },
});
