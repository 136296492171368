import React from 'react';

import './progress-bar.scss';

const ProgressBarStep = ({ color }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' className='progress-bar-step__container' height='20'>
      <defs>
        <filter id='progress-bar-step__inset-shadow'>
          <feOffset dx='0' dy='3' />
          <feGaussianBlur stdDeviation='2' result='offset-blur' />
          <feComposite operator='out' in='SourceGraphic' in2='offset-blur' result='inverse' />
          <feFlood floodColor='black' floodOpacity='.5' result='color' />
          <feComposite operator='in' in='color' in2='inverse' result='shadow' />
          <feComponentTransfer in='shadow' result='shadow'>
            <feFuncA type='linear' slope='.75' />
          </feComponentTransfer>
          <feComposite operator='over' in='shadow' in2='SourceGraphic' />
        </filter>
      </defs>

      <rect filter='url(#progress-bar-step__inset-shadow)' width='95%' height='20' x='0%' fill={color || '#f5f5f5'} rx='12' />
    </svg>
  );
};

export default ProgressBarStep;
