import React from 'react';

import PostAJob from '../../PostAJob';

const PostAJobButton = ({ setOpen, name, icon }) => {
  return (
    <div role='button' style={{ background: 'none', border: 'none' }}>
      <PostAJob setOpen={setOpen} name={name} icon={icon} isSideBar={true} />
    </div>
  );
};

export default PostAJobButton;
