import React from 'react';
import { Edit } from 'lucide-react';

import './draft-job-row.scss';

const DraftJobRowMobile = ({ job }) => {
  return (
    <div style={{ color: 'black', position: 'relative', marginBottom: '50px', marginTop: '50px' }}>
      <div>
        <div className='text-nowrap'>
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2,1fr)', marginBottom: '24px' }}>
            <div style={{ fontWeight: '600', color: '#586f7c' }}>Job Name</div>
          </div>
          <div className='rowGridColumnTwo'>
            <div>Service</div>
            <div>{job.service || 'None Given'}</div>
          </div>
          <hr />
          <div className='rowGridColumnTwo'>
            <div>State</div>
            <div>{job.siteState || 'None Given'}</div>
          </div>
          <hr />
          <div className='rowGridColumnTwo'>
            <div>Commute</div>
            <div>{job.commuteType || 'None Given'}</div>
          </div>
          <hr />
          <div className='rowGridColumnTwo'>
            <div>Status</div>
            <div>Draft</div>
          </div>
          <hr />
        </div>
        <div style={{ position: 'absolute', right: '12px', top: '0px' }}>
          <Edit />
        </div>
      </div>
    </div>
  );
};

export default DraftJobRowMobile;
