import React from 'react';
import { withFormik } from 'formik';
import { Alert, Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import { resetPassword } from '../../../../slices/auth.slice';

import './forgotPasswordForm.scss';

import Button from '../../../../components/Button';
import InputError from '../../../../components/InputError';
import TextInput from '../../../../components/TextInput';

const ForgotPassword = ({ handleSubmit, handleChange, handleBlur, values, errors, touched, onCancel }) => {
  const auth = useSelector(state => state.auth);

  return (
    <Container fluid className='forgot-password-container'>
      <Row>
        <Col className='text-center'>
          <h3 className='forgot-password__header'>Forgot Password?</h3>
        </Col>
      </Row>
      <Row>
        <Col className='text-center'>
          <p className='forgot-password__subheader'>Enter your account email and we will send you a recovery password.</p>
        </Col>
      </Row>
      <form onSubmit={handleSubmit}>
        <Row className='mt-3'>
          <Col>
            <div>
              <TextInput
                id='forgotPasswordEmail'
                name='forgotPasswordEmail'
                label='Email Address*'
                placeholder='Enter Your Email Address'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.forgotPasswordEmail}
                type='email'
              />
              {touched.forgotPasswordEmail && errors.forgotPasswordEmail && <InputError>{errors.forgotPasswordEmail}</InputError>}
            </div>
          </Col>
        </Row>
        <Row className='mt-3'>
          <Col className='d-flex justify-content-center'>
            <Button
              disabled={auth.isLoading}
              type='submit'
              className='d-flex justify-content-center button primary-btn'
              style={{ fontWeight: 700, fontSize: '16px' }}
            >
              {auth.isLoading ? 'Loading...' : 'Recover Password'}
            </Button>
          </Col>
        </Row>
        {auth.auth === 'success' && (
          <Row className='mt-3'>
            <Col>
              <Alert variant='success'>Password reset email sent successfully.</Alert>
            </Col>
          </Row>
        )}
      </form>
    </Container>
  );
};

const ForgotPasswordForm = withFormik({
  mapPropsToValues: () => ({
    forgotPasswordEmail: '',
  }),
  validationSchema: Yup.object({
    forgotPasswordEmail: Yup.string().email('Invalid email address').required('Required'),
  }),
  handleSubmit: (values, { props, setSubmitting }) => {
    props.dispatch(
      resetPassword({
        email: values.forgotPasswordEmail,
      })
    );
  },
  displayName: 'ForgotPassword',
})(ForgotPassword);

export default ForgotPasswordForm;
