import React, { useState } from 'react';

import { languages } from '../../lib/options';
import CategoryFieldset from './category-fieldset';
import EducationDropDownSection from './education-dropdown-section';

const CategoryFieldsetWrapper = ({ setValue, workerResume, getValues, resetField, staticData, register }) => {
  const [stepSelected, setStepSelected] = useState(0);

  return (
    <>
      <CategoryFieldset
        step={1}
        stepSelected={stepSelected}
        setStepSelected={setStepSelected}
        getValues={getValues}
        resetField={resetField}
        required={true}
        field='workedIndustries'
        label='Industries Worked In*'
        category={staticData.workedIndustries}
        register={register}
      />
      <EducationDropDownSection step={2} stepSelected={stepSelected} setStepSelected={setStepSelected} setValue={setValue} workerResume={workerResume} />
      <CategoryFieldset
        step={3}
        stepSelected={stepSelected}
        setStepSelected={setStepSelected}
        getValues={getValues}
        resetField={resetField}
        field='otherLanguages'
        label='Additional Languages'
        category={languages}
        register={register}
      />
      <CategoryFieldset
        step={4}
        stepSelected={stepSelected}
        setStepSelected={setStepSelected}
        getValues={getValues}
        resetField={resetField}
        field='certifications'
        label='Professional Certifications'
        category={staticData.certificateCatalogs}
        register={register}
      />
      <CategoryFieldset
        step={5}
        stepSelected={stepSelected}
        setStepSelected={setStepSelected}
        getValues={getValues}
        resetField={resetField}
        field='oshaCertificates'
        label='OSHA Certificates'
        category={staticData.oshaCertificates}
        register={register}
      />
      <CategoryFieldset
        step={4}
        stepSelected={stepSelected}
        setStepSelected={setStepSelected}
        getValues={getValues}
        resetField={resetField}
        field='oshaCourseCertificates'
        label='OSHA Course Certificates'
        category={staticData.oshaCourseCertificates}
        register={register}
      />
      <CategoryFieldset
        step={6}
        stepSelected={stepSelected}
        setStepSelected={setStepSelected}
        getValues={getValues}
        resetField={resetField}
        field='mshaBlueCards'
        label='MSHA Blue Card'
        category={staticData.mshaBlueCards}
        register={register}
      />
      <CategoryFieldset
        step={7}
        stepSelected={stepSelected}
        setStepSelected={setStepSelected}
        getValues={getValues}
        resetField={resetField}
        field='dotCertifications'
        label='DOT Certifications'
        category={staticData.dotCertifications}
        register={register}
      />
      <CategoryFieldset
        step={8}
        stepSelected={stepSelected}
        setStepSelected={setStepSelected}
        getValues={getValues}
        resetField={resetField}
        field='emergencyResponseCertifications'
        label='Emergency Response Certifications'
        category={staticData.emergencyResponseCertifications}
        register={register}
      />
      <CategoryFieldset
        step={9}
        stepSelected={stepSelected}
        setStepSelected={setStepSelected}
        getValues={getValues}
        resetField={resetField}
        field='environmentalCertifications'
        label='Environmental Certifications'
        category={staticData.environmentalCertifications}
        register={register}
      />
      <CategoryFieldset
        step={10}
        stepSelected={stepSelected}
        setStepSelected={setStepSelected}
        getValues={getValues}
        resetField={resetField}
        field='environmentalServices'
        label='Environmental Services'
        category={staticData.environmentalServices}
        register={register}
      />
      <CategoryFieldset
        step={11}
        stepSelected={stepSelected}
        setStepSelected={setStepSelected}
        getValues={getValues}
        resetField={resetField}
        field='training'
        label='Trainings'
        category={staticData.trainings}
        register={register}
      />
      <CategoryFieldset
        step={12}
        stepSelected={stepSelected}
        setStepSelected={setStepSelected}
        getValues={getValues}
        resetField={resetField}
        field='generalSkills'
        label='General Skills'
        category={staticData.generalSkills}
        register={register}
      />
    </>
  );
};

export default CategoryFieldsetWrapper;
