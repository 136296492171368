import React from 'react';

import './shadowedBox.scss';

const ShadowedBox = ({ children, containerStyle, style, withTitle, title, containerRef }) => {
  return (
    <div style={containerStyle}>
      {withTitle && (
        <div className='header'>
          <h5 className='title-text'>{title}</h5>
        </div>
      )}
      <div style={style} ref={containerRef} className='shadowed-box-container'>
        {children}
      </div>
    </div>
  );
};

export default ShadowedBox;
