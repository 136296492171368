export const c = {
  black: '#000000',
  almostBlack: '#010001',
  lightBlack: '#323031',
  grey: '#B4B4B4',
  darkGrey: '#888888',
  lightGrey: '#F5F5F5',
  white: '#FFFFFF',
  darkBlue: '#4B5A63',
  mediumBlue: '#586F7C',
  lightBlue: '#90ACBC',
  yellow: '#FFCD00',
  orange: '#FFA630',
  red: '#F01108',
};
