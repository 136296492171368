import React, { Fragment, useState } from 'react';
import { Disclosure, Transition } from '@headlessui/react';
import { ChevronDownIcon, Trash } from 'lucide-react';

const EducationDropDownSection = ({ step, stepSelected, setStepSelected, setValue, workerResume }) => {
  const [educationList, setEducationList] = useState(
    workerResume?.education ?? [
      {
        school: '',
        degree: '',
        field: '',
        yearGraduated: '',
      },
    ]
  );

  const [open, setOpen] = useState(false);
  const isSelected = stepSelected == step;

  const handleEducationFieldChange = (e, index) => {
    const { name, value } = e.target;
    setEducationList(prevState => {
      const updatedList = [...prevState];
      updatedList[index][name] = value;
      return updatedList;
    });
    const stringifiedList = educationList?.map(education => JSON.stringify(education));
    setValue('education', stringifiedList);
  };

  const addEducation = () => {
    setEducationList(prevState => [
      ...prevState,
      {
        school: '',
        degree: '',
        field: '',
        yearGraduated: '',
      },
    ]);
  };

  return (
    <Disclosure>
      <Disclosure.Button
        onClick={() => {
          if (isSelected) {
            setStepSelected(0);
            setOpen(false);
            return;
          }
          setStepSelected(0);
          setOpen(!open);
        }}
        className='disclosureButtonCategory'
      >
        <h6 className='categoryFieldsetH6'>Education</h6>
        <ChevronDownIcon className={`arrowDownIconCategoryFieldset ${open || isSelected ? 'rotateArrowDown180' : null}`} />
      </Disclosure.Button>
      <Transition
        show={isSelected || open}
        as={Fragment}
        enter='dialogProfileBuilding-enter'
        enterFrom='dialogProfileBuilding-enterFrom'
        enterTo='dialogProfileBuilding-enterTo'
        leave='dialogProfileBuilding-leave'
        leaveFrom='dialogProfileBuilding-leaveFrom'
        leaveTo='dialogProfileBuilding-leaveTo'
      >
        <Disclosure.Panel>
          <section style={{ marginBottom: '-24px' }}>
            <div className='educationMapProfileBuildingWrapper'>
              {educationList.map((education, index) => (
                <section className='educationInputAndButtonWrapper' key={index}>
                  <div className='profileBuildingEducationInputWrapper'>
                    <input
                      style={{ marginTop: '0px', marginBottom: '12px' }}
                      className='profileBuildingInput'
                      type='text'
                      name='school'
                      placeholder='School Name'
                      value={education.school}
                      onChange={e => handleEducationFieldChange(e, index)}
                    />
                    <input
                      style={{ marginTop: '0px', marginBottom: '12px' }}
                      className='profileBuildingInput'
                      type='text'
                      name='degree'
                      placeholder='Degree Name'
                      value={education.degree}
                      onChange={e => handleEducationFieldChange(e, index)}
                    />
                    <input
                      style={{ marginTop: '0px', marginBottom: '12px' }}
                      className='profileBuildingInput'
                      type='text'
                      name='field'
                      placeholder='Field Of Study'
                      value={education.field}
                      onChange={e => handleEducationFieldChange(e, index)}
                    />
                    <input
                      style={{ marginTop: '0px', marginBottom: '12px' }}
                      className='profileBuildingInput'
                      type='text'
                      name='yearGraduated'
                      placeholder='Year Completed'
                      value={education.yearGraduated}
                      onChange={e => handleEducationFieldChange(e, index)}
                    />
                  </div>
                  <button
                    className='deleteEducationProfileBuilding'
                    type='button'
                    onClick={() => {
                      const updatedList = educationList.filter((_, i) => i !== index);
                      setEducationList(updatedList);
                    }}
                  >
                    <Trash />
                  </button>
                </section>
              ))}
            </div>
            <div style={{ position: 'relative', marginBottom: '20px' }}>
              <button className='profileBuildingUploadBtn' type='button' onClick={addEducation}>
                Add Education
              </button>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <button style={{ fontSize: '14px' }} className='clearSelectionCategoryFieldset' onClick={() => setEducationList([])} type='button'>
                Clear Selection
              </button>
              <div className='nextAndBackCategoryFieldset'>
                <button
                  onClick={() => {
                    setOpen(false);
                    setStepSelected(step - 1);
                  }}
                  type='button'
                  className='backAndNextBtnProfileBuilding'
                >
                  Back
                </button>
                <button
                  onClick={() => {
                    setOpen(false);
                    setStepSelected(step + 1);
                  }}
                  type='button'
                  className='backAndNextBtnProfileBuilding'
                >
                  Next
                </button>
              </div>
            </div>
          </section>
        </Disclosure.Panel>
      </Transition>
    </Disclosure>
  );
};

export default EducationDropDownSection;
