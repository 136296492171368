import React from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { Info, Plus, X } from 'lucide-react';
import { useFieldArray, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import PaymentMethodList from '../../components/PaymentMethodList';
import SetUpPaymentsStatusModal from '../../components/SetUpPaymentsForPro/SetUpPaymentsStatusModal';
import useStripeSetUpPaymentsForCompany from '../../hooks/useStripeSetUpPaymentsForCompany';
import { contactMethodOptions, deliverableTypeOptions, optionsForDropForCommute, stateOptions } from '../../lib/options';
import { getCompanyPaymentMethods, retrieveCo } from '../../slices/company.slice';
import { createCoJobDoc } from '../../slices/document.slice';
import { deleteDraftJob, getDraftJob, getMatrixData, publishDraftJob, updateDraftJob } from '../../slices/job.slice';
import { StyledReactTooltip } from '../OldCreateJob';
import CoDocUploader from '../OldCreateJob/CoDocUploader';
import DraftInput from './draft-input';
import DraftInputRadio from './draft-input-radio';
import DropdownEditJob from './dropdown-edit-job';

import './index.scss';

const noChildrenForSelectedLabel = ['Safety Consultation', 'Ergonomics Specialist', 'Environmental Services', 'Enter My Own'];

export default function EditJob() {
  const dispatch = useDispatch();
  const { sid } = useParams();
  const navigate = useNavigate();

  const coTemplates = useSelector(state => state.document.coTemplates);
  const { jobParents, jobChildren } = useSelector(state => state.job);
  const user = useSelector(state => state.user.user);
  const draftJob = useSelector(state => state.job.draftJob?.json);
  const paymentMethods = useSelector(state => state.company.paymentMethods);
  const company = useSelector(state => state.company.company.company);

  const [docsToUse, setDocsToUse] = React.useState([]);
  const [selectedJob, setSelectedJob] = React.useState({ parentJob: null, childJob: null });
  const { parentJob, childJob } = selectedJob;
  const [typeOfServiceOptions, setTypeOfServiceOptions] = React.useState(jobChildren);

  const { description: setUpPaymentsDescription, SetUpPaymentsForCompanyButton } = useStripeSetUpPaymentsForCompany({
    successUrl: `/job/edit-job/${sid}?setup-payment=success`,
    cancelUrl: `/job/edit-job/${sid}`,
  });

  const {
    register,
    control,
    getValues,
    setValue,
    trigger,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: async () => {
      const res = await dispatch(getDraftJob(sid)).unwrap();
      const booleanValues = [res.json.deliverableRequired, res.json.coAdditionalContent, res.json.templateRequired, res.json.additionalRequirements];
      const convertedValues = booleanValues.map(value => (value ? 'yes' : 'no'));
      return {
        ...res.json,
        schedulingNotesCheckbox: false,
        deliverableRequired: convertedValues[0],
        coAdditionalContent: convertedValues[1],
        templateRequired: convertedValues[2],
        additionalRequirements: convertedValues[3],
      };
    },
  });

  const { fields, append, remove } = useFieldArray({ name: 'timeWindows', control });
  const watchFieldArray = watch('timeWindows');
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  const commuteType = watch('commuteType');
  const deliverableRequired = watch('deliverableRequired');
  const additionalRequirements = watch('additionalRequirements');
  const enterMyOwn = watch('service') === 'Enter My Own';
  const serviceOptions = [...jobParents, { id: 99, label: 'Enter My Own' }];
  const parentLabel = getValues('service');
  const paymentMethodSelection = watch('paymentMethodSelection');

  React.useEffect(() => {
    dispatch(getMatrixData());
    dispatch(retrieveCo({ companyId: user.companyId }));
    dispatch(getCompanyPaymentMethods());
  }, [dispatch, user.companyId]);

  const filterJobChildrenOptions = React.useCallback(
    parentJob => {
      if (!parentJob) return;
      setValue('typeOfService', null);
      if (noChildrenForSelectedLabel.includes(parentJob.label)) {
        setValue('jobType', parentJob.label);
        setValue('typeOfWork', parentJob.description);
        setValue('estimatedRate', parentJob.price);
        setTypeOfServiceOptions(null);
        return;
      }
      setValue('estimatedRate', null);
      setValue('typeOfWork', null);
      const filteredOptions = jobChildren.filter(option => option.parentJobId === parentJob.id);
      setTypeOfServiceOptions(filteredOptions);
    },
    [setValue, jobChildren]
  );

  React.useEffect(() => {
    filterJobChildrenOptions(parentJob);
  }, [parentJob, filterJobChildrenOptions]);

  async function autoSaveDraft() {
    const data = getValues();
    await dispatch(updateDraftJob({ sid: sid, job: data, companyId: user.companyId })).unwrap();
  }

  async function onSubmit(e) {
    e.preventDefault();
    if (Object.keys(errors).length >= 1) return;

    const defaultPaymentMethod = paymentMethods.find(method => method.is_default);
    if (!defaultPaymentMethod && paymentMethodSelection === 'STRIPE') {
      toast.error('Please add a default payment method before posting a job');
      return;
    }

    async function submitAll() {
      const data = getValues();
      await dispatch(updateDraftJob({ sid: sid, job: data, companyId: user.companyId })).unwrap();
      const response = await dispatch(publishDraftJob({ sid: sid, job: data })).unwrap();
      const docIds = docsToUse.map(doc => doc.id);
      await dispatch(createCoJobDoc({ docIds: docIds, jobId: response.id })).unwrap();
      return response;
    }

    toast.promise(submitAll(), {
      loading: 'Publishing Job...',
      success: publishedDraft => {
        navigate(`/job/order-review/${publishedDraft.referenceNumber}`);
        return 'Job published successfully!';
      },
      error: 'Failed to publish Job. Please try again later.',
    });
  }

  async function handleSaveDraft() {
    const data = getValues();
    toast.promise(dispatch(updateDraftJob({ sid: sid, job: data, companyId: user.companyId })).unwrap(), {
      loading: 'Saving draft...',
      success: 'Draft saved successfully',
      error: 'Failed to save draft. Please try again later.',
    });
  }

  async function handleDeleteDraft() {
    toast.promise(dispatch(deleteDraftJob(sid)).unwrap(), {
      loading: 'Deleting draft...',
      success: () => {
        navigate('/dashboard');
        return 'Draft deleted successfully!';
      },
      error: 'Failed to delete draft. Please try again later.',
    });
  }

  return (
    <form className='globalDivEditJob' onSubmit={onSubmit}>
      <div className='flexColumnDivEditJob'>
        <DraftInput
          errors={errors}
          required={true}
          label='Job Name'
          trigger={trigger}
          onBlur={autoSaveDraft}
          placeholder='Enter Your Job Name'
          field='name'
          register={register}
        />
        <div className='gridColumnTwoEditJob'>
          <DropdownEditJob
            trigger={trigger}
            errors={errors}
            control={control}
            fieldValue='service'
            label='Select a Service*'
            notSelectedValue='Service'
            arrayToMap={serviceOptions}
            autoSaveDraft={autoSaveDraft}
            onClickFunction={option => {
              setSelectedJob({ ...selectedJob, parentJob: option });
            }}
          />
          {!noChildrenForSelectedLabel.includes(parentLabel) && typeOfServiceOptions && (
            <DropdownEditJob
              trigger={trigger}
              errors={errors}
              control={control}
              fieldValue='typeOfService'
              label='Select Type of Service*'
              notSelectedValue='Type of Service'
              arrayToMap={typeOfServiceOptions}
              autoSaveDraft={autoSaveDraft}
              onClickFunction={option => {
                setSelectedJob({ ...selectedJob, childJob: option });
                setValue('jobType', option.label);
                setValue('typeOfWork', option.description);
                setValue('estimatedRate', option.price);
              }}
            />
          )}
        </div>
        {enterMyOwn && (
          <div className='flexDivEditJob'>
            <div style={{ width: '100%' }}>
              <DraftInput
                errors={errors}
                trigger={trigger}
                required={true}
                label='Enter the Service That You Need'
                onBlur={autoSaveDraft}
                placeholder='Enter Your Custom Service'
                field='roleOrService'
                register={register}
              />
            </div>
          </div>
        )}
        {!enterMyOwn && (
          <div>
            <div className='flexDivEditJob'>
              <div style={{ width: '90%' }}>
                <label className='labelEditJob'>Job Details</label>
                <textarea
                  style={{ width: '100%', border: '1px solid #a8a8a8', borderRadius: '4px', padding: '8px' }}
                  {...register('typeOfWork')}
                  readOnly
                  rows='2'
                  placeholder='Select a Type of Service from the dropdown above or enter your own details'
                  className='invites-optional-message'
                />
              </div>
              <div>
                <label className='labelText'>Rate Type: {childJob?.priceType || parentJob?.priceType || draftJob?.priceType || 'Not set'}</label>
                <DraftInput errors={errors} placeholder='Pick a service' onBlur={autoSaveDraft} readOnly field='estimatedRate' register={register} />
              </div>
            </div>
          </div>
        )}
        {enterMyOwn && (
          <div className='flexDivEditJob'>
            <div style={{ width: '90%' }}>
              <label className='labelEditJob'>Type of Work to be Done*</label>
              <textarea
                onBlur={autoSaveDraft}
                style={{ width: '100%', border: '1px solid #a8a8a8', borderRadius: '4px', padding: '8px' }}
                {...register('typeOfWork', { required: 'Required Field' })}
                rows='2'
                placeholder='Select a Type of Service from the dropdown above or enter your own details'
                className='invites-optional-message'
              />
              <ErrorMessage
                errors={errors}
                name='typeOfWork'
                render={({ message }) => (
                  <p style={{ marginTop: '6px' }} className='errorMessageInputForm'>
                    {message}
                  </p>
                )}
              />
            </div>
            <DraftInput
              errors={errors}
              label='Rate Type: Manual'
              type='number'
              placeholder='Enter your rate'
              trigger={trigger}
              required={true}
              onBlur={autoSaveDraft}
              field='enteredRate'
              register={register}
            />
          </div>
        )}
        <DraftInput
          errors={errors}
          label='Share additional job details'
          onBlur={autoSaveDraft}
          placeholder='Add your notes'
          field='notes'
          register={register}
        />
        <DraftInput
          errors={errors}
          label='Do you have a Specific Pro in Mind?'
          onBlur={autoSaveDraft}
          placeholder='Enter Your EHS PRO Request'
          field='requestProName'
          register={register}
        />
      </div>
      <div className='flexColumnDivEditJob'>
        <DropdownEditJob
          trigger={trigger}
          errors={errors}
          control={control}
          fieldValue='commuteType'
          label='Where will the work be performed?*'
          notSelectedValue='Commute Type'
          arrayToMap={optionsForDropForCommute}
          autoSaveDraft={autoSaveDraft}
          onClickFunction={() => null}
        />
        {(commuteType === 'VIRTUAL' || commuteType === 'HYBRID') && (
          <DraftInput
            errors={errors}
            label='Virtual Job Details'
            onBlur={autoSaveDraft}
            placeholder='Virtual Job Details'
            field='commuteDetails'
            register={register}
          />
        )}
        {(commuteType === 'ON_SITE' || commuteType === 'HYBRID') && (
          <div className='flexColumnDivEditJob'>
            <DraftInput
              errors={errors}
              trigger={trigger}
              required={true}
              label='Street Address'
              onBlur={autoSaveDraft}
              placeholder='Enter Your Street Address'
              register={register}
              field='siteAddress'
            />
            <div className='gridColumnTwoEditJob'>
              <DraftInput
                errors={errors}
                trigger={trigger}
                required={true}
                label='City'
                onBlur={autoSaveDraft}
                placeholder='Enter Your City'
                register={register}
                field='siteCity'
              />
              <div className='gridColumnTwoEditJob'>
                <DropdownEditJob
                  trigger={trigger}
                  errors={errors}
                  control={control}
                  fieldValue='siteState'
                  label='State*'
                  notSelectedValue='State'
                  arrayToMap={stateOptions}
                  autoSaveDraft={autoSaveDraft}
                  onClickFunction={() => null}
                />
                <DraftInput
                  errors={errors}
                  type='number'
                  trigger={trigger}
                  required={true}
                  label='Zip Code'
                  customValidation={value => {
                    if (value.length !== 5) {
                      return 'Zip Code must be 5 length';
                    }
                  }}
                  onBlur={autoSaveDraft}
                  placeholder='Enter Zip Code'
                  register={register}
                  field='siteZip'
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className='flexColumnDivEditJob'>
        <div className='gridColumnTwoEditJob'>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
            <div>
              <p
                data-tip
                data-for='flexibleStartDates'
                style={{
                  background: '#87ddff',
                  borderRadius: 50,
                  padding: '3px 10px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 5,
                  width: 'fit-content',
                  margin: 0,
                }}
              >
                <span style={{ fontWeight: 600, fontStyle: 'italic' }}>NEW</span> Flexible Start Dates Option <Info size={18} style={{ marginLeft: 5 }} />
              </p>
              <StyledReactTooltip id='flexibleStartDates' effect='solid' data-multiline place='right'>
                <span>
                  If your job allows for flexible start dates, provide multiple potential dates for the job and verify the expected number of hours this project
                  requires.
                </span>
              </StyledReactTooltip>
            </div>
            <label className='text-input__label' style={{ margin: 0 }}>
              Potential Start Dates*
            </label>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
              {controlledFields?.map((field, index) => (
                <div style={{ display: 'flex', gap: '12px' }} key={field.id}>
                  <input
                    {...register(`timeWindows.${index}.startDateString`, { required: 'You must select at least one date' })}
                    className='date-picker'
                    style={{ color: '#586F7C', width: '100%' }}
                    type='date'
                    onSelect={e => {
                      setValue(`timeWindows.${index}.start`, e.target.value + 'T00:00:00.000Z');
                      autoSaveDraft();
                    }}
                    value={field.startDateString}
                    min={new Date().toISOString().slice(0, 10)}
                  />
                  <X
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      if (controlledFields.length === 1) {
                        toast('You need at least one date');
                        return;
                      }
                      remove(index);
                      autoSaveDraft();
                    }}
                  />
                </div>
              ))}
            </div>
            <button
              style={{ width: 'max-content', display: 'flex', alignItems: 'center' }}
              className='profileBuildingUploadBtn'
              type='button'
              onClick={() => {
                append({ id: controlledFields.length + 1, startDateString: '', start: '' });
              }}
            >
              <Plus size={18} style={{ marginRight: '8px' }} />
              Add Date
            </button>
          </div>
          <div className='flexColumnDivEditJob'>
            <DraftInput
              errors={errors}
              type='number'
              trigger={trigger}
              required={true}
              label='Expected Number of Hours'
              onBlur={autoSaveDraft}
              placeholder='Enter Hours'
              field='totalHours'
              register={register}
            />
            <div>
              <label className='labelEditJob' htmlFor='schedulingNotesCheckbox'>
                Scheduling Notes
              </label>
              <textarea
                style={{ width: '100%', border: '1px solid #a8a8a8', borderRadius: '4px', padding: '8px' }}
                {...register('schedulingNotes', { onBlur: autoSaveDraft })}
                placeholder='Enter your scheduling notes'
                className='invites-optional-message'
              />
            </div>
          </div>
        </div>
        <ul style={{ fontSize: '14px' }}>
          <li>
            Jobs over 2 weeks in length will be billed every 2 weeks, starting with approval of the Job Order. Any variance in hours worked or other additional
            expenses will be reconciled after each pay period.
          </li>
          <li>Premium Pay will be paid out at 1.5x the hourly rate for any worked hours exceeding 8 in a day and 40 in a week.</li>
          <li>
            Per diem will be based on the GSA set rates by state. A 5% management fee will be added. &nbsp;
            <a href='https://www.gsa.gov/travel/plan-book/per-diem-rates?gsaredirect=perdiem' target='_blank' rel='noreferrer noopener'>
              <p>Per Diem Rates</p>
            </a>
          </li>
        </ul>
      </div>
      <div className='flexColumnDivEditJob'>
        <fieldset>
          <label style={{ marginBottom: '12px' }} className='labelText'>
            Are there additional requirements for this Job?
          </label>
          <div className='flexDivEditJobFieldset'>
            <DraftInputRadio onBlur={autoSaveDraft} labelAndValue='Yes' register={register} field='additionalRequirements' />
            <DraftInputRadio onBlur={autoSaveDraft} labelAndValue='No' register={register} field='additionalRequirements' />
          </div>
        </fieldset>
        {additionalRequirements === 'yes' && (
          <div className='gridColumnTwoEditJob'>
            <DraftInput
              errors={errors}
              label='Specific Certifications or Skills'
              onBlur={autoSaveDraft}
              placeholder='Certifications or Skills'
              field='requiredSkills'
              register={register}
            />
            <DraftInput
              errors={errors}
              label='Language required'
              onBlur={autoSaveDraft}
              placeholder='Ex. Spanish, French, Mandarin, etc.'
              field='languageRequired'
              register={register}
            />
            <DraftInput
              errors={errors}
              label='Years of Experience'
              onBlur={autoSaveDraft}
              placeholder='Enter a Number'
              field='ehsExperience'
              register={register}
            />
            <DraftInput
              errors={errors}
              label='Industry Experience'
              onBlur={autoSaveDraft}
              placeholder='Ex. Manufacturing, Construction, Healthcare, etc.'
              field='industryExperience'
              register={register}
            />
            <DraftInput
              errors={errors}
              label='Other requirements or additional information the Pro needs to be aware of?'
              onBlur={autoSaveDraft}
              placeholder='Ex. Equipment, NDA, etc.'
              field='otherRequirements'
              register={register}
            />
          </div>
        )}
        <fieldset>
          <label style={{ marginBottom: '12px' }} className='labelText'>
            Deliverable Required
          </label>
          <div className='flexDivEditJobFieldset'>
            <DraftInputRadio onBlur={autoSaveDraft} labelAndValue='Yes' register={register} field='deliverableRequired' />
            <DraftInputRadio onBlur={autoSaveDraft} labelAndValue='No' register={register} field='deliverableRequired' />
          </div>
        </fieldset>
        {deliverableRequired === 'yes' && (
          <div className='gridColumnTwoEditJob'>
            <DropdownEditJob
              trigger={trigger}
              errors={errors}
              control={control}
              fieldValue='deliverableType'
              label='Deliverable Type*'
              notSelectedValue='Deliverable Type'
              arrayToMap={deliverableTypeOptions}
              autoSaveDraft={autoSaveDraft}
              onClickFunction={() => null}
            />
            <fieldset>
              <label style={{ marginBottom: '12px' }} className='labelText'>
                Will you be supplying a template?
              </label>
              <div className='flexDivEditJobFieldset'>
                <DraftInputRadio onBlur={autoSaveDraft} labelAndValue='Yes' register={register} field='templateRequired' />
                <DraftInputRadio onBlur={autoSaveDraft} labelAndValue='No' register={register} field='templateRequired' />
              </div>
            </fieldset>
          </div>
        )}
        <fieldset>
          <label style={{ marginBottom: '12px' }} className='labelText'>
            Do you have other content or documentation that the professional will be required to use to deliver the service?
          </label>
          <div className='flexDivEditJobFieldset'>
            <DraftInputRadio onBlur={autoSaveDraft} labelAndValue='Yes' register={register} field='coAdditionalContent' />
            <DraftInputRadio onBlur={autoSaveDraft} labelAndValue='No' register={register} field='coAdditionalContent' />
          </div>
        </fieldset>
      </div>
      <CoDocUploader style={{ fontSize: '12px', fontWeight: 700 }} coTemplates={coTemplates} docsToUse={docsToUse} setDocsToUse={setDocsToUse} />
      <div className='gridColumnTwoEditJob'>
        <DraftInput
          errors={errors}
          trigger={trigger}
          required={true}
          label='First Name'
          onBlur={autoSaveDraft}
          placeholder='Enter Your First Name'
          field='pocFirstName'
          register={register}
        />
        <DraftInput
          errors={errors}
          trigger={trigger}
          required={true}
          label='Last Name'
          onBlur={autoSaveDraft}
          placeholder='Enter Your Last Name'
          field='pocLastName'
          register={register}
        />
        <DraftInput
          errors={errors}
          trigger={trigger}
          required={true}
          label='Job Title'
          onBlur={autoSaveDraft}
          placeholder='Enter Your Job Title'
          field='pocJobTitle'
          register={register}
        />
        <DraftInput
          errors={errors}
          trigger={trigger}
          required={true}
          label='Email'
          onBlur={autoSaveDraft}
          placeholder='Enter Your Email Address'
          field='pocEmail'
          register={register}
        />
        <DraftInput
          errors={errors}
          type='number'
          trigger={trigger}
          required={true}
          label='Mobile Phone Number'
          onBlur={autoSaveDraft}
          placeholder='Enter Your Phone Number'
          field='pocPhone'
          customValidation={value => {
            if (value.length !== 10) {
              return 'Phone Number must be 10 length';
            }
          }}
          register={register}
        />
        <DropdownEditJob
          trigger={trigger}
          errors={errors}
          control={control}
          fieldValue='pocContactMethod'
          label='Preferred Contact Method*'
          notSelectedValue='Contact Method'
          arrayToMap={contactMethodOptions}
          autoSaveDraft={autoSaveDraft}
          onClickFunction={() => null}
        />
      </div>
      <div style={{ margin: '24px 0' }}>
        <div className='mb-4'>
          <h5>Payment Method</h5>
          <ErrorMessage
            errors={errors}
            name='paymentMethodSelection'
            render={({ message }) => (
              <p style={{ position: 'relative' }} className='errorMessageInputForm'>
                {message}
              </p>
            )}
          />
        </div>
        <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
          <input
            value='STRIPE'
            {...register('paymentMethodSelection', { required: 'Required Field', onChange: autoSaveDraft })}
            id='stripeRadio'
            type='radio'
          />
          <label className='labelText' htmlFor='stripeRadio'>
            Pay with Card or Bank Wire (ACH)
          </label>
        </div>
        <p style={{ fontSize: '14px', fontWeight: '600', marginTop: '12px' }}>
          YellowBird will match your job with a qualified EHS Professional once posted. Your selected payment method will be charged for the total amount upon
          job completion.
        </p>
        {paymentMethodSelection === 'STRIPE' && (
          <div className='mt-4'>
            <div>
              <h5 className='labelText mb-2'>Your Payment Methods</h5>
              <p className='mb-3'>{setUpPaymentsDescription}</p>
              <SetUpPaymentsForCompanyButton />
              <PaymentMethodList paymentMethods={paymentMethods} />
            </div>
          </div>
        )}
        {company && company[0]?.payWithRegularInvoice ? (
          <>
            <div style={{ display: 'flex', gap: '12px', alignItems: 'center', marginTop: '3rem' }}>
              <input
                value='REGULAR_INVOICE'
                {...register('paymentMethodSelection', { required: 'Required Field', onChange: autoSaveDraft })}
                id='invoiceRadio'
                type='radio'
              />
              <label className='labelText' htmlFor='invoiceRadio'>
                Pay with Invoice
              </label>
            </div>
            <p style={{ fontSize: '14px', fontWeight: '600', marginTop: '12px' }}>
              We invoice 100% of the total upon Job completion and require Net 30 day terms, unless you have previously arranged other terms with YellowBird
              accounting.
            </p>
          </>
        ) : (
          <div className='mt-4'>
            <p>
              If you would like to pay with an invoice, please contact us at{' '}
              <a
                href={`mailto:operations@goyellowbird.com?subject=Invoice Payment Request&body=Hello, I would like to pay with an invoice.`}
                target='_blank'
                rel='noopener noreferrer'
              >
                operations@goyellowbird.com
              </a>
            </p>
          </div>
        )}
      </div>
      <div className='buttonsDivEditJob'>
        <button type='submit' className='profileBuildingUploadBtn' onMouseEnter={() => trigger('', { shouldFocus: true })}>
          Post Job
        </button>
        <button
          type='button'
          onClick={handleSaveDraft}
          className='profileBuildingUploadBtn'
          style={{ backgroundColor: '#49525E', borderColor: '#49525E', color: 'white' }}
        >
          Save Draft
        </button>
        <button
          type='button'
          onClick={handleDeleteDraft}
          className='profileBuildingUploadBtn'
          style={{ backgroundColor: '#F44336', borderColor: '#F44336', color: 'white' }}
        >
          Delete Draft
        </button>
      </div>
      <SetUpPaymentsStatusModal />
    </form>
  );
}
