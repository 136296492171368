import { ChangeEvent, Fragment, useEffect, useState } from 'react';
import { Dialog, Listbox, Transition } from '@headlessui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { CheckIcon, ChevronDown, Clock, Globe, GraduationCap, Hammer, Mail, MapPin, Medal, Phone, Star, TrashIcon, X } from 'lucide-react';
import { InputMask } from 'primereact/inputmask';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src';
import ProfilePictureInitials from 'src/components/ProfilePictureInitials';
import ProUploadDocs from 'src/components/ProUploadDocs';
import { languages, stateOptions } from 'src/lib/options';
import { fetchWorkerById, updateWorkerById } from 'src/slices/worker.slice';
import { z } from 'zod';

import { Todo } from '../../types';
import EditProfileDropdown from './edit-profile-dropdown';
import EditProfileItemsSelected from './edit-profile-items-selected';
import ProfileWidget from './profile-widget';

export type EducationObject = {
  field: string;
  yearGraduated: string;
  degree: string;
  school: string;
};

export interface Updates {
  mobilePhone?: string;
  address?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  firstName?: string;
  lastName?: string;
  otherLanguages?: number[];
  workedIndustries?: number[];
  extraLanguages?: number[];
  oshaCourseCertificates?: number[];
  oshaCertificates?: number[];
  certifications?: number[];
  environmentalCertifications?: number[];
  dotCertifications?: number[];
  emergencyResponseCertifications?: number[];
  generalSkills?: number[];
  environmentalServices?: number[];
  mshaBlueCards?: number[];
  training?: number[];
  education?: string[];
}

const uploadProfileSchema = z.object({
  mobilePhone: z.string().length(14, 'Must be a complete phone number'),
  address: z.string().min(1, 'Required Field'),
  city: z.string().min(1, 'Required Field'),
  state: z.string().min(1, 'Required Field'),
  zipCode: z.string().length(5, 'Must be 5 characters long'),
});

type UploadProfileValue = z.infer<typeof uploadProfileSchema>;

export default function WorkerProfile() {
  const state = useAppSelector(state => state);
  const dispatch = useAppDispatch();
  const { staticData } = state.staticData;
  const { user } = state.user;
  const { worker } = state.worker;
  const [updates, setUpdates] = useState<Updates>({});
  const [educationList, setEducationList] = useState<EducationObject[]>([]);
  const [newEducation, setNewEducation] = useState<EducationObject>({ field: '', school: '', degree: '', yearGraduated: '' });
  const [changeEmailModal, setChangeEmailModal] = useState(false);
  const [updateEducation, setUpdateEducation] = useState(false);
  const navigate = useNavigate();

  const formMethods = useForm<UploadProfileValue>({
    mode: 'onBlur',
    resolver: zodResolver(uploadProfileSchema),
    defaultValues: async (): Promise<UploadProfileValue> => {
      const res = await dispatch(fetchWorkerById(user.workerId)).unwrap();
      return {
        mobilePhone: res.mobilePhone,
        address: res.address,
        city: res.city,
        state: res.state,
        zipCode: res.zipCode,
      };
    },
  });

  async function onSubmit() {
    if (Object.keys(formMethods.formState.errors).length > 0) {
      return;
    }

    toast.promise(dispatch(updateWorkerById({ workerId: worker.id, updates })), {
      loading: 'Saving Changes...',
      success: 'Profile updated successfully.',
      error: 'Could not update profile. Please try again later',
    });
  }

  const handleDeleteEducation = (index: number) => {
    setEducationList(prevList => {
      const updatedList = prevList.filter((_, i) => i !== index);
      const stringifiedList = updatedList.map(e => JSON.stringify(e));
      setUpdates(prevUpdates => ({ ...prevUpdates, education: stringifiedList }));
      setUpdateEducation(true);
      return updatedList;
    });
  };

  const handleNewEducation = (e: ChangeEvent<HTMLInputElement>, fieldName: string) => {
    setNewEducation(prev => ({ ...prev, [fieldName]: e.target.value }));
  };

  useEffect(() => {
    if (updateEducation) {
      onSubmit();
      setUpdateEducation(false);
    }
  }, [updateEducation]);

  useEffect(() => {
    if (newEducation.school.length > 0) {
      const stringifiedNewEducation = JSON.stringify(newEducation);
      setUpdates(prev => ({ ...prev, education: [...worker.education, stringifiedNewEducation] }));
    }
  }, [newEducation, worker.education]);

  useEffect(() => {
    setEducationList(worker.education?.map((e: Todo) => JSON.parse(e)));
  }, [worker]);

  return (
    <FormProvider {...formMethods}>
      <form className='grid xl:grid-cols-3 gap-6 p-4'>
        <div className='xl:col-span-2 flex flex-col gap-6'>
          <ProfileWidget
            icon={null}
            updates={updates}
            title='About Me'
            editProfileModalChildren={
              <>
                <div className='flex items-center gap-2'>
                  {/* 
                  // PROFILE PICTURE UPLOAD NOT INCLUDED ON THIS RELEASE     
                  <div className='flex gap-2'>
                    <input className='border-b border-slate-600' onChange={handleFileChange} className='hidden' id='profilePicture' type='file' />
                    <label className='cursor-pointer flex items-center gap-1' htmlFor='profilePicture'>
                      <Camera /> Upload Photo
                    </label>
                    {user.profilePicture && (
                      <label onClick={handleRemovePicture} className='cursor-pointer'>
                        Remove <Trash />
                      </label>
                    )}
                  </div>
                  */}
                  <label className='text-slate-500 font-semibold'>Years of Experience:</label>
                  <span>{worker.ehsExperience}</span>
                </div>
                <EditProfileDropdown
                  fieldName='otherLanguages'
                  updates={updates}
                  setUpdates={setUpdates}
                  label='Languages'
                  category={worker.otherLanguages}
                  list={languages}
                />
              </>
            }
          >
            <div className='flex gap-4 items-center'>
              <div className='w-20 h-20 md:w-28 md:h-28'>
                <ProfilePictureInitials firstName={user.firstName} lastName={user.lastName} customStyles='md:text-4xl text-2xl' />
              </div>
              <div>
                <h3 className='text-xl text-slate-600 font-semibold'>
                  {user.firstName} {user.lastName}
                </h3>
                <p className='text-sm text-gray-500 font-semibold'>YellowBird Professional</p>
                <div className='grid gap-2'>
                  <div className='flex gap-2 mt-2'>
                    <div>
                      <Clock />
                    </div>
                    {worker.ehsExperience} Years of Experience
                  </div>
                  <div className='flex gap-2'>
                    <Globe />
                    Languages
                  </div>
                  <EditProfileItemsSelected label='' category={worker.otherLanguages} list={languages} />
                </div>
              </div>
            </div>
          </ProfileWidget>
          <ProfileWidget
            icon={<Hammer />}
            updates={updates}
            title='Industries Worked In'
            editProfileModalChildren={
              <EditProfileDropdown
                fieldName='workedIndustries'
                updates={updates}
                setUpdates={setUpdates}
                label='Industries Worked In'
                category={worker.workedIndustries}
                list={staticData.workedIndustries}
              />
            }
          >
            <EditProfileItemsSelected label='' category={worker.workedIndustries} list={staticData.workedIndustries} />
          </ProfileWidget>
          <ProfileWidget
            icon={<Medal />}
            updates={updates}
            title='Certifications & Credentials'
            editProfileModalChildren={
              <>
                <EditProfileDropdown
                  fieldName='certifications'
                  updates={updates}
                  setUpdates={setUpdates}
                  label='Professional'
                  category={worker.certifications}
                  list={staticData.certificateCatalogs}
                />
                <EditProfileDropdown
                  fieldName='oshaCertificates'
                  updates={updates}
                  setUpdates={setUpdates}
                  label='OSHA Credentials'
                  category={worker.oshaCertificates}
                  list={staticData.oshaCertificates}
                />
                <EditProfileDropdown
                  fieldName='oshaCourseCertificates'
                  updates={updates}
                  setUpdates={setUpdates}
                  label='OSHA Course Credentials'
                  category={worker.oshaCourseCertificates}
                  list={staticData.oshaCourseCertificates}
                />
                <EditProfileDropdown
                  fieldName='environmentalCertifications'
                  updates={updates}
                  setUpdates={setUpdates}
                  label='Environmental Certifications'
                  category={worker.environmentalCertifications}
                  list={staticData.environmentalCertifications}
                />
                <EditProfileDropdown
                  fieldName='dotCertifications'
                  updates={updates}
                  setUpdates={setUpdates}
                  label='DOT Certifications'
                  category={worker.dotCertifications}
                  list={staticData.dotCertifications}
                />
              </>
            }
          >
            <EditProfileItemsSelected label='Professional' category={worker.certifications} list={staticData.certificateCatalogs} />
            <EditProfileItemsSelected label='OSHA Credentials' category={worker.oshaCertificates} list={staticData.oshaCertificates} />
            <EditProfileItemsSelected label='OSHA Course Credentials' category={worker.oshaCourseCertificates} list={staticData.oshaCourseCertificates} />
            <EditProfileItemsSelected
              label='Environmental Certifications'
              category={worker.environmentalCertifications}
              list={staticData.environmentalCertifications}
            />
            <EditProfileItemsSelected label='DOT Certifications' category={worker.dotCertifications} list={staticData.dotCertifications} />
          </ProfileWidget>
          <ProfileWidget
            icon={<Star />}
            updates={updates}
            title='Skills'
            editProfileModalChildren={
              <>
                <EditProfileDropdown
                  fieldName='training'
                  updates={updates}
                  setUpdates={setUpdates}
                  label='Trainings'
                  category={worker.training}
                  list={staticData.trainings}
                />
                <EditProfileDropdown
                  fieldName='generalSkills'
                  updates={updates}
                  setUpdates={setUpdates}
                  label='General Skills'
                  category={worker.generalSkills}
                  list={staticData.generalSkills}
                />
                <EditProfileDropdown
                  fieldName='emergencyResponseCertifications'
                  updates={updates}
                  setUpdates={setUpdates}
                  label='Emergency Response Certifications'
                  category={worker.emergencyResponseCertifications}
                  list={staticData.emergencyResponseCertifications}
                />
                <EditProfileDropdown
                  fieldName='environmentalServices'
                  updates={updates}
                  setUpdates={setUpdates}
                  label='Environmental Services'
                  category={worker.environmentalServices}
                  list={staticData.environmentalServices}
                />
                <EditProfileDropdown
                  fieldName='mshaBlueCards'
                  updates={updates}
                  setUpdates={setUpdates}
                  label='MSHA Blue Cards'
                  category={worker.mshaBlueCards}
                  list={staticData.mshaBlueCards}
                />
              </>
            }
          >
            <EditProfileItemsSelected label='Trainings' category={worker.training} list={staticData.trainings} />
            <EditProfileItemsSelected label='General Skills' category={worker.generalSkills} list={staticData.generalSkills} />
            <EditProfileItemsSelected
              label='Emergency Response Certifications'
              category={worker.emergencyResponseCertifications}
              list={staticData.emergencyResponseCertifications}
            />
            <EditProfileItemsSelected label='Environmental Services' category={worker.environmentalServices} list={staticData.environmentalServices} />
            <EditProfileItemsSelected label='MSHA Blue Cards' category={worker.mshaBlueCards} list={staticData.mshaBlueCards} />
          </ProfileWidget>
          <ProfileWidget
            icon={<GraduationCap />}
            title='Education'
            editProfileModalChildren={
              <>
                <label className='text-slate-500 font-semibold'>School</label>
                <input className='border-b border-slate-600' onChange={e => handleNewEducation(e, 'school')} />
                <label className='text-slate-500 font-semibold'>Degree</label>
                <input className='border-b border-slate-600' onChange={e => handleNewEducation(e, 'degree')} />
                <label className='text-slate-500 font-semibold'>Field</label>
                <input className='border-b border-slate-600' onChange={e => handleNewEducation(e, 'field')} />
                <label className='text-slate-500 font-semibold'>Year Graduated</label>
                <input className='border-b border-slate-600' type='number' onChange={e => handleNewEducation(e, 'yearGraduated')} />
              </>
            }
            updates={updates}
          >
            {educationList?.map((e, index) => {
              return (
                <div className='flex justify-between'>
                  <div>
                    <span className='font-semibold'>{e.school}</span>
                    <div className='flex'>
                      <span>{e.degree}</span>,&nbsp; <span>{e.field}</span>
                    </div>
                    <span className='text-gray-500'>{e.yearGraduated}</span>
                  </div>
                  <button
                    type='button'
                    onClick={() => {
                      handleDeleteEducation(index);
                    }}
                  >
                    <TrashIcon className='cursor-pointer' />
                  </button>
                </div>
              );
            })}
          </ProfileWidget>
        </div>
        <div className='flex flex-col md:flex-row xl:flex-col gap-6 xl:col-span-1'>
          <ProfileWidget
            icon={null}
            updates={updates}
            title='Contact Details'
            editProfileModalChildren={
              <>
                <div className='flex flex-col relative'>
                  <div className='flex justify-between'>
                    <label className='text-slate-500 font-semibold'>Email</label>
                    <span onClick={() => setChangeEmailModal(true)} className='cursor-pointer text-green-600 text-xs font-semibold'>
                      Change Email
                    </span>
                    <Transition appear show={changeEmailModal} as={Fragment}>
                      <Dialog as='div' className='relative z-10' onClose={() => null}>
                        <Transition.Child
                          as={Fragment}
                          enter='ease-out duration-300'
                          enterFrom='opacity-0'
                          enterTo='opacity-100'
                          leave='ease-in duration-200'
                          leaveFrom='opacity-100'
                          leaveTo='opacity-0'
                        >
                          <div className='fixed inset-0 bg-black/50' />
                        </Transition.Child>
                        <div className='fixed inset-0 overflow-y-auto'>
                          <div className='flex min-h-full items-center justify-center p-4 text-center'>
                            <Transition.Child
                              as={Fragment}
                              enter='ease-out duration-300'
                              enterFrom='opacity-0 scale-95'
                              enterTo='opacity-100 scale-100'
                              leave='ease-in duration-200'
                              leaveFrom='opacity-100 scale-100'
                              leaveTo='opacity-0 scale-95'
                            >
                              <Dialog.Panel className='w-full max-w-md transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                                <div className='flex justify-between items-start'>
                                  <p className='font-semibold text-slate-700'>Are you sure you want to continue? You will lose all the progress not saved.</p>
                                  <div>
                                    <X className='pointer-cursor' onClick={() => setChangeEmailModal(false)} />
                                  </div>
                                </div>
                                <div className='flex justify-center gap-3 mt-4'>
                                  <button
                                    type='button'
                                    className='bg-slate-500 text-white py-2.5 px-4 font-bold rounded hover:scale-105 duration-200'
                                    onClick={() => setChangeEmailModal(false)}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    type='button'
                                    className='py-2.5 px-4 border-2 font-bold rounded hover:scale-105 duration-200 min-w-44 bg-yellow-400 border-yellow-400'
                                    onClick={() => navigate('/myaccount')}
                                  >
                                    Continue
                                  </button>
                                </div>
                              </Dialog.Panel>
                            </Transition.Child>
                          </div>
                        </div>
                      </Dialog>
                    </Transition>
                  </div>
                  <input className='border-b border-slate-600' readOnly value={worker.email} />
                </div>
                <div className='flex flex-col relative'>
                  <label className='text-slate-500 font-semibold'>Phone</label>
                  <Controller
                    name='mobilePhone'
                    control={formMethods.control}
                    defaultValue={''}
                    render={({ field }) => {
                      return <InputMask {...field} autoClear={false} className='border-b border-slate-600 ring-0' mask='(999) 999-9999' />;
                    }}
                  />
                  <p className='absolute -bottom-5 text-sm text-red-500'>{formMethods.formState.errors.mobilePhone?.message?.toString()}</p>
                </div>
                <div className='flex flex-col relative'>
                  <label className='text-slate-500 font-semibold'>Address</label>
                  <input className='border-b border-slate-600' {...formMethods.register('address')} />
                  <p className='absolute -bottom-5 text-sm text-red-500'>{formMethods.formState.errors.address?.message?.toString()}</p>
                </div>
                <div className='flex flex-col relative'>
                  <label className='text-slate-500 font-semibold'>City</label>
                  <input className='border-b border-slate-600' {...formMethods.register('city')} />
                  <p className='absolute -bottom-5 text-sm text-red-500'>{formMethods.formState.errors.city?.message?.toString()}</p>
                </div>
                <div className='flex justify-between gap-4'>
                  <div className='min-w-32'>
                    <label className='text-slate-500 font-semibold'> State</label>
                    <Controller
                      name='state'
                      control={formMethods.control}
                      defaultValue={''}
                      rules={{ required: 'Required field.' }}
                      render={({ field }) => {
                        return (
                          <Listbox {...field}>
                            <div className='relative'>
                              <Listbox.Button className='flex justify-between w-full border-b border-slate-600'>
                                {field.value ? <span>{field.value}</span> : <span>State*</span>}
                                <ChevronDown style={{ width: '24px', height: '24px' }} className='chevrowDownForm' aria-hidden='true' />
                              </Listbox.Button>
                              <p className='errorMessageInputProfilebuilding'>{formMethods.formState.errors.state?.message?.toString()}</p>
                              <Transition
                                as={Fragment}
                                enter='transition ease-in duration-150'
                                leave='transition ease-in duration-100'
                                enterFrom='opacity-0'
                                leaveFrom='opacity-0'
                                leaveTo='opacity-0'
                              >
                                <Listbox.Options
                                  className='overflow-y-auto max-h-40 shadow-2xl pl-8 rounded absolute bg-white min-w-60'
                                  onBlur={() => formMethods.trigger('state')}
                                >
                                  {stateOptions.map(option => (
                                    <Listbox.Option className={({ active }) => `optionField ${active ? 'activeField' : 'unactiveField'}`} value={option.value}>
                                      {({ selected }) => (
                                        <>
                                          {selected ? (
                                            <span className='checkIconStep3'>
                                              <CheckIcon className='checkIconInside' aria-hidden='true' />
                                            </span>
                                          ) : null}
                                          <span className={`valueDropDown ${selected ? 'font-medium' : 'font-normal'}`}>{option.name}</span>
                                        </>
                                      )}
                                    </Listbox.Option>
                                  ))}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          </Listbox>
                        );
                      }}
                    />
                  </div>
                  <div className='flex flex-col w-full relative'>
                    <label className='text-slate-500 font-semibold'>Zip Code</label>
                    <input type='number' className='border-b border-slate-600' {...formMethods.register('zipCode')} />
                    <p className='absolute -bottom-5 text-sm text-red-500'>{formMethods.formState.errors.zipCode?.message?.toString()}</p>
                  </div>
                </div>
              </>
            }
          >
            <div className='flex flex-col gap-3'>
              <div className='flex gap-2'>
                <div>
                  <Mail />
                </div>
                <div>
                  <label className='text-slate-500 font-semibold'>Email</label>
                  <p className='truncate w-64 sm:w-full lg:w-60'>{worker.email}</p>
                </div>
              </div>
              <div className='flex gap-2'>
                <div>
                  <Phone />
                </div>
                <div>
                  <label className='text-slate-500 font-semibold'>Phone</label>
                  <div>{worker.mobilePhone}</div>
                </div>
              </div>
              <div className='flex gap-2'>
                <div>
                  <MapPin />
                </div>
                <div>
                  <label className='text-slate-500 font-semibold'>Address</label>
                  <div>
                    {worker.address}, {worker.city} <br /> {worker.state}, {worker.zipCode}
                  </div>
                </div>
              </div>
            </div>
          </ProfileWidget>
          <ProUploadDocs />
        </div>
      </form>
    </FormProvider>
  );
}
