import React from 'react';
import { v4 as uuidv4 } from 'uuid';

const JobInviteReusableRadio = ({ value, register, formValue, required }) => {
  const id = uuidv4();

  return (
    <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
      <div>
        <input {...register(formValue, required && { required: true })} value={value} id={id} type='radio' />
      </div>
      <label className='labelText' htmlFor={id}>
        {value}
      </label>
    </div>
  );
};

export default JobInviteReusableRadio;
