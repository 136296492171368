import React from 'react';
import { Col, Row } from 'react-bootstrap';

import ChevronLeft from '../../assets/images/chevron-left.svg';
import ProgressBarStep from './ProgressBarStep';

const ProgressBar = ({
  totalSteps,
  currentStep,
  onBack,
  doneColor = '#ffce07',
  pendingColor = '#f5f5f5',
  includeStepsInfo = false,
  showBackButton = false,
  showBackOnFirstStep = false,
  pageTitle,
  stepTitles = [],
}) => {
  const renderSteps = () => {
    const stepComponentArray = [];
    for (let i = 0; i < totalSteps; i++) {
      stepComponentArray.push(<ProgressBarStep key={i} color={currentStep >= i + 1 ? doneColor : pendingColor} />);
    }
    return stepComponentArray;
  };

  return (
    <>
      {includeStepsInfo && (
        <Row className='progress-bar-container' style={{ paddingRight: 0 }}>
          <Col xs={12} md={4}>
            {showBackButton && (currentStep > 1 || showBackOnFirstStep) && (
              <img src={ChevronLeft} alt='Back Arrow' style={{ verticalAlign: 'sub', cursor: 'pointer' }} onClick={onBack} />
            )}
            <p style={{ display: 'inline-block', color: '#4b5a63', fontWeight: 'bold', fontSize: '3.5rem', marginLeft: '1.8rem', marginBottom: 0 }}>
              {String(currentStep).padStart(2, '0')}
            </p>
            <p style={{ display: 'inline-block', color: '#b4b4b4', fontWeight: 500, fontSize: '2.25rem', marginLeft: '1rem', marginBottom: 0 }}>
              /{String(totalSteps).padStart(2, '0')}
            </p>
          </Col>
          <Col xs={12} md={8} className='d-none d-md-flex align-items-end justify-content-end'>
            <p>
              <span style={{ color: '#000000', fontWeight: 'bold', fontSize: '1.5rem' }}>
                {pageTitle}
                <span style={{ marginLeft: 4, marginRight: 4 }}>|</span>
              </span>
              <span style={{ color: '#ffa630', fontWeight: 600, fontSize: '1.2rem' }}>{stepTitles[currentStep - 1]}</span>
            </p>
          </Col>
        </Row>
      )}
      <Row>
        <Col className='d-flex'>{renderSteps()}</Col>
      </Row>
    </>
  );
};

export default ProgressBar;
