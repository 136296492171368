import React from 'react';

import './checkbox.scss';

const Checkbox = ({ checked, children, value, ...props }) => {
  return (
    <>
      <label className='checkbox-container'>
        <input type='checkbox' {...props} />
        {children}
        <span className='checkbox-box'></span>
      </label>
    </>
  );
};

export default Checkbox;
