import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import Loading from '../../components/Loading';
import { submitReview } from '../../slices/proReviewByCo.slice';
import NumberButtons from './NumberButtons';

const CoReviewsPro = () => {
  const state = useSelector(state => state);
  const { job, worker, company } = state.proReviewByCo.reviewObj;
  const { isLoading } = state.proReviewByCo;
  const { id } = state.user.user;
  const questions = [
    { id: 1, question: 'Scheduling and Punctuality', answerType: 'buttons', quantity: 5, required: true },
    { id: 2, question: 'Preparedness For Job', answerType: 'buttons', quantity: 5, required: true },
    { id: 3, question: 'Communication Effectiveness', answerType: 'buttons', quantity: 5, required: true },
    { id: 4, question: 'Knowledge Of Job Performed', answerType: 'buttons', quantity: 5, required: true },
    {
      id: 5,
      question:
        '(Optional) Leave any additional comments about your experience with ' +
        worker.firstName +
        ' and YellowBird here. \nThis will not be visible to anyone besides the YellowBird team.',
      answerType: 'text',
      quantity: 0,
      required: false,
    },
    {
      id: 6,
      question: 'Did we earn your future business? Please share ' + company.name + "'s thoughts on using YellowBird services in the future",
      answerType: 'textButtons',
      quantity: 3,
      required: true,
      options: [
        {
          text: 'Absolutely',
          value: 'Absolutely',
        },
        {
          text: 'Not Sure Yet',
          value: 'Not Sure Yet',
        },
        {
          text: 'Sorry, Not Likely',
          value: 'Sorry, Not Likely',
        },
      ],
    },
    {
      id: 7,
      question: 'How can YellowBird best support ' + company.name + ' over the next 180 days?',
      answerType: 'textButtons',
      quantity: 7,
      required: true,
      options: [
        {
          text: 'Industrial Hygiene Assessments',
          value: 'Industrial Hygiene Assessments',
        },
        {
          text: 'OSHA Training',
          value: 'OSHA Training',
        },
        {
          text: 'EHS Training (ex: Fall Prevention, CPR & First Aid, Forklift)',
          value: 'EHS Training (ex: Fall Prevention, CPR & First Aid, Forklift)',
        },
        {
          text: 'Workplace Safety & Mock OSHA Inspection',
          value: 'Workplace Safety & Mock OSHA Inspection',
        },
        {
          text: 'Policy Development',
          value: 'Policy Development',
        },
        {
          text: 'Onsite Safety Consultation',
          value: 'Onsite Safety Consultation',
        },
        {
          text: 'Loss Control Audits',
          value: 'Loss Control Audits',
        },
      ],
    },
    {
      id: 8,
      question:
        "Know of a Company who could use YellowBird's services? We love referrals! And to show our gratitude, you will receive $250 for every successful referral. \n*Referral fee paid upon successful engagement with referred Company",
      answerType: 'text',
      quantity: 0,
      required: false,
    },
  ];

  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState(null);
  const [answers, setAnswers] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onFinish = async e => {
    let scoresArr = [];
    window.alert('Thanks for submitting the review!');
    for (const property in answers) {
      if (!isNaN(parseInt(answers[property].a))) {
        scoresArr.push(parseInt(answers[property].a));
      }
    }
    const rating = (await scoresArr.reduce((a, b) => a + b)) / scoresArr.length;
    const parsedAnswers = JSON.stringify(answers);
    let review = {
      jobId: job.id,
      coId: company.id,
      proId: worker.id,
      payload: parsedAnswers,
      userId: id,
      rating,
    };
    dispatch(submitReview(review)).then(res => {
      navigate('/dashboard');
    });
  };
  if (isLoading) return <Loading />;
  return (
    <div>
      <h1>Review of {worker.firstName + ' ' + worker.lastName}</h1>
      <h2>For {job.name}</h2>
      <hr />
      <NumberButtons
        onFinish={onFinish}
        answers={answers}
        setAnswers={setAnswers}
        selected={selected}
        setSelected={setSelected}
        page={page}
        setPage={setPage}
        question={questions[page]}
        questionLength={questions.length - 1}
      />
    </div>
  );
};

export default CoReviewsPro;
