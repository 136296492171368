import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import ArrowRight from '../../assets/images/right-arrow.svg';
import { useAppDispatch, useAppSelector } from '../../index';
import { fetchCompanyUncompletedTasks, fetchProUncompletedTasks } from '../../slices/task.slice';

import './myJobs.scss';

export default function MyJobs() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const draftJobs = useAppSelector(state => state.job.allDraftJobs);
  const user = useAppSelector(state => state.user.user);
  const { tasksCount, isLoading } = useAppSelector(select => select.tasks);

  React.useEffect(() => {
    if (user.role === 'worker') {
      dispatch(fetchProUncompletedTasks());
    } else if (user.role === 'user') {
      dispatch(fetchCompanyUncompletedTasks());
    }
  }, [user, dispatch]);

  return (
    <Link className='my-jobs-link' to='/my-jobs/current'>
      <div className='p-3'>
        <div className='d-flex justify-content-between align-items-center gap-2 mb-3'>
          <h4 className='my-jobs-bottom-text'>My Jobs</h4>
          <div className='d-flex gap-2 align-items-center'>
            {isLoading ? (
              <div className='skeleton-header' />
            ) : (
              tasksCount?.uncompleted > 0 && (
                <p className='my-jobs__pending-tasks'>
                  <span>Tasks</span>
                  <span className='my-jobs__pending-tasks_number'>{tasksCount?.uncompleted}</span>
                </p>
              )
            )}

            {user.role === 'user' && (
              <button
                type='button'
                onClick={e => {
                  e.stopPropagation();
                  navigate('/my-jobs/draft');
                }}
                style={{
                  border: 'none',
                  backgroundColor: '#FFCD03',
                  padding: '4px 12px',
                  borderRadius: '6px',
                  color: 'black',
                  margin: 0,
                  fontSize: '15px',
                  fontWeight: 'bold',
                }}
              >
                Draft Jobs: {draftJobs?.length}
              </button>
            )}
          </div>
        </div>
        <p className='my-jobs-bottom-description'>Once jobs are in progress or completed, they can be reviewed and accessed by clicking the arrow below.</p>
      </div>

      <div className='my-jobs-bottom-link'>
        <h4 className='my-jobs-link-text'>Review My Jobs</h4>
        <img src={ArrowRight} alt='arrow' />
      </div>
    </Link>
  );
}
