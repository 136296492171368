import React from 'react';
import { validate } from 'uuid';

const DraftInput = ({ label, errors, register, trigger, onBlur, field, required, customValidation, children, ...props }) => {
  return (
    <div>
      <p className='labelEditJob'>
        {label}
        {required && '*'}
      </p>
      <input
        style={{ width: '100%' }}
        {...props}
        className='drafInputRadio'
        {...register(
          field,
          required
            ? {
                required: 'Required Field',
                onBlur: onBlur,
                onChange: () => {
                  trigger(field);
                },
                validate: customValidation || null,
              }
            : {
                onBlur: onBlur,
              }
        )}
      />
      <p style={{ marginTop: '6px' }} className='errorMessageInputForm'>
        {errors?.[field]?.message?.toString()}
      </p>
    </div>
  );
};

export default DraftInput;
