import React from 'react';
import { Button } from 'react-bootstrap';

import CircularProgressBar from '../../../components/CircularProgressBar';

import './OnboardingStatusSummary.scss';

const OnboardingStatusSummary = ({ progress, onClick }) => {
  return (
    <>
      <Button onClick={onClick} className='d-flex flex-column align-items-center' variant='custom' style={{ backgroundColor: 'transparent', border: 'none' }}>
        <CircularProgressBar size={108} progress={progress} />
        <h4 className='onboarding-status-summary__text'>Onboarding</h4>
      </Button>
    </>
  );
};

export default OnboardingStatusSummary;
