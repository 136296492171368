import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';

import Button from '../../components/Button';
import useQuery from '../../hooks/useQuery';

// the name of the CTA of this form
const CTA = 'Post My Job';

export default function PostPrepaidJob() {
  const { parentId, childId } = useParams();
  const query = useQuery();
  const name = query.get('name');
  const navigate = useNavigate();

  const onContinue = () => {
    navigate(`/dashboard/?action=post-a-job&parentId=${parentId}&childId=${childId}&prepaid=true&jobName=${name}`);
  };

  return (
    <Container fluid as='form' onSubmit={onContinue} style={{ maxWidth: '500px' }}>
      <h2 className='d-flex justify-content-center pb-3 pe-2' style={{ fontSize: '18px', fontWeight: 700 }}>
        Customize Your Prepaid Job
      </h2>
      <Row className='mb-3 justify-content-center text-center'>
        <Col>
          <p>
            Thank you for purchasing the "{name}" service from us. To complete your purchase, please click <b>{CTA}</b> to complete your order.
          </p>
          <p>This may prompt you to sign in (or sign up if this is your first time using the YellowBird platform).</p>
          <p>Once you are authenticated we will redirect you to complete your job posting.</p>
          <p>NOTE: Please disregard the fee and the 'pay by invoice' as these do not apply to prepaid orders. </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button className={`button primary-btn mt-3 py-2 justify-content-center w-100`} style={{ fontWeight: 700, fontSize: '16px' }} type='submit'>
            {CTA}
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
