import React from 'react';
import { CreditCard, Landmark, Trash2 } from 'lucide-react';

import { Todo } from '../../types';
import classes from './paymentMethodList.module.scss';

type PaymentMethodProps = {
  paymentMethod: Todo;
  setShowDeletePaymentMethodModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setPaymentMethodToDelete?: React.Dispatch<React.SetStateAction<Todo>>;
  setShowSetPaymentMethodAsDefaultModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setPaymentMethodToDefault?: React.Dispatch<React.SetStateAction<Todo>>;
  isModal?: boolean;
};

export default function PaymentMethod({
  paymentMethod,
  setShowDeletePaymentMethodModal,
  setPaymentMethodToDelete,
  setShowSetPaymentMethodAsDefaultModal,
  setPaymentMethodToDefault,
  isModal,
}: PaymentMethodProps) {
  function handleOpenDeletePaymentMethodModal() {
    if (!setPaymentMethodToDelete || !setShowDeletePaymentMethodModal) return;
    setPaymentMethodToDelete(paymentMethod);
    setShowDeletePaymentMethodModal(true);
  }

  function handleOpenSetPaymentMethodAsDefaultModal() {
    if (!setPaymentMethodToDefault || !setShowSetPaymentMethodAsDefaultModal) return;
    setPaymentMethodToDefault(paymentMethod);
    setShowSetPaymentMethodAsDefaultModal(true);
  }

  return (
    <li className={classes.payment_method}>
      {paymentMethod.type === 'card' && (
        <>
          <div className={classes.payment_method__left}>
            <CreditCard className={classes.payment_method__icon} />
            <div>
              <p className={classes.payment_method__card__text}>
                {paymentMethod.card.brand.toUpperCase()} ending in {paymentMethod.card.last4}
              </p>
              <p className={classes.payment_method__card__exp}>
                Exp. date {paymentMethod.card.exp_month}/{paymentMethod.card.exp_year.toString().slice(-2)}
              </p>
            </div>
          </div>
          {!isModal && (
            <div className={classes.payment_method__right}>
              {paymentMethod.is_default ? (
                <p className={classes.payment_method__default}>Default</p>
              ) : (
                <button type='button' className={classes.payment_method__set_default_btn} onClick={handleOpenSetPaymentMethodAsDefaultModal}>
                  Set as Default
                </button>
              )}

              <button type='button' className={classes.payment_method__delete_btn} onClick={handleOpenDeletePaymentMethodModal}>
                <Trash2 />
              </button>
            </div>
          )}
        </>
      )}
      {paymentMethod.type === 'us_bank_account' && (
        <>
          <div className={classes.payment_method__left}>
            <Landmark className={classes.payment_method__icon} />
            <p className={classes.payment_method__card__text}>
              Bank Account ({paymentMethod.us_bank_account.bank_name}) ending in {paymentMethod.us_bank_account.last4}
            </p>
          </div>
          {!isModal && (
            <div className={classes.payment_method__right}>
              {paymentMethod.is_default ? (
                <p className={classes.payment_method__default}>Default</p>
              ) : (
                <button type='button' className={classes.payment_method__set_default_btn} onClick={handleOpenSetPaymentMethodAsDefaultModal}>
                  Set as Default
                </button>
              )}

              <button type='button' className={classes.payment_method__delete_btn} onClick={handleOpenDeletePaymentMethodModal}>
                <Trash2 />
              </button>
            </div>
          )}
        </>
      )}
    </li>
  );
}
