import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import './navigation.css';

import { checkEmailCo } from '../../../slices/auth.slice';
import { MethodsContext } from '../context';

const Navigation = ({ disabled }) => {
  const { setError, getValues, clearErrors, trigger } = useFormContext();
  const { back, next, percentage } = useContext(MethodsContext);
  const dispatch = useDispatch();

  const step3Back = () => {
    trigger('workNumber');
    back();
  };

  const validateEmail = async e => {
    console.log('validateEmail executed');
    e.preventDefault();
    const values = {
      email: getValues('emailAddress'),
      firstName: getValues('firstName'),
      lastName: getValues('lastName'),
    };
    try {
      const res = await dispatch(checkEmailCo(values)).unwrap();
      console.log('checkEmailCo res', res);
      if (res === 'Email is available') {
        clearErrors('emailAddress');
        next();
      }
    } catch (error) {
      console.log('Error catched in validateEmail', error);
      const errorMessage = error.response?.data?.message || error.message || 'We could not validate your email, please try again later.';
      setError('emailAddress', { message: errorMessage });
    }
  };

  const buttonValue = () => {
    if (percentage === 84) return 'Accept';
    else if (percentage === 28 && !getValues('referral.value')) return 'Skip';
    else return 'Next';
  };

  return (
    <div className='navigationContainer'>
      <button disabled={!disabled} className='navigationButtonForm navigationNext' onClick={percentage === 14 ? validateEmail : next}>
        {buttonValue()}
      </button>
      <button className='navigationButtonForm navigationBack' onClick={percentage === 56 ? step3Back : back}>
        Back
      </button>
    </div>
  );
};

export default Navigation;
