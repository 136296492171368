const DEFAULT_URL = 'https://admin-api.goyellowbird.com';

const BASE_URL_MAP = new Map([
  ['local', 'http://localhost:3000'],
  ['dev', 'https://stgadmin-api.yellowbirdstage.com'],
  ['app', DEFAULT_URL],
]);

export default function useBaseURL() {
  const hostname = window.location.hostname;

  for (let [key, url] of Array.from(BASE_URL_MAP.entries())) {
    if (hostname.includes(key)) {
      return url;
    }
  }

  return DEFAULT_URL;
}
