import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { camelizeKeys, decamelizeKeys } from 'humps';

import api from '../api';
import apiCamelback from '../api/apiCamelback';

export const retrieveCo = createAsyncThunk('/company/retrieveCo', async ({ companyId }, thunkApi) => {
  const response = await apiCamelback.get(`/api/v1/company/${companyId}`);
  const camelized = camelizeKeys(response.data);
  if (response.data.errors) {
    return thunkApi.rejectWithValue(response.data.errors);
  }
  return camelized;
});

export const addCoUser = createAsyncThunk('/company/addCoUser', async (body, thunkApi) => {
  const decamelized = decamelizeKeys(body);
  const response = await api.post('api/v1/users', decamelized);
  if (response.data.errors) {
    return thunkApi.rejectWithValue(response.data.errors);
  }
  return camelizeKeys(response.data.user);
});

export const updateCoInfo = createAsyncThunk('/company/updateCoInfo', async ({ coId, updates }, thunkApi) => {
  const decamelized = decamelizeKeys(updates);
  try {
    const response = await apiCamelback.put(`/api/v1/company/${coId}`, decamelized);
    return camelizeKeys(response.data);
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const addSignUpReason = createAsyncThunk('/company/SignUpReason', async (reason, thunkApi) => {
  try {
    const response = await api.post('/api/v1/company/reason_to_join', { sign_up_reason: reason });
    if (response.data) {
      return camelizeKeys(response.data);
    }
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const getStripe = createAsyncThunk('company/GetStripe', async (_, thunkApi) => {
  try {
    const response = await api.get('/api/v1/stripe/public_key');
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const getStripeSecret = createAsyncThunk('company/GetStripeSecret', async (_, thunkApi) => {
  try {
    const response = await api.get('/api/v1/stripe/secret');
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const createIntent = createAsyncThunk('company/CreateIntent', async (_, thunkApi) => {
  try {
    const response = await api.post('api/v1/stripe/create_setup_intent');
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const getCompanyPaymentMethods = createAsyncThunk('company/getCompanyPaymentMethods', async (_, thunkApi) => {
  try {
    const response = await apiCamelback.get('api/v1/stripe/company_payment_methods?limit=100');
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
    return thunkApi.rejectWithValue(error);
  }
});

export const deletePaymentMethod = createAsyncThunk('company/deletePaymentMethod', async (payment_method, thunkApi) => {
  try {
    const response = await apiCamelback.delete(`api/v1/stripe/company_payment_methods/${payment_method}`);
    if (response.data.errors) {
      return thunkApi.rejectWithValue(response.data.errors);
    }
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const setDefaultPaymentMethod = createAsyncThunk('company/setDefaultPaymentMethod', async (payment_method, thunkApi) => {
  try {
    const response = await apiCamelback.put(`api/v1/stripe/company_payment_methods/${payment_method}/default`);
    if (response.data.errors) {
      return thunkApi.rejectWithValue(response.data.errors);
    }
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const attachPaymentMethod = createAsyncThunk('company/AttachMethod', async (payment_method, thunkApi) => {
  try {
    const response = await api.post('api/v1/stripe/attach_method', payment_method);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const chargeCardPub = createAsyncThunk('company/ChargeCardPub', async ({ payment_method, amount, reference_number }, thunkApi) => {
  try {
    const response = await api.post('api/v1/stripe/create_payment_intent_pub', { payment_method, amount, reference_number });
    if (response.data) return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const chargeCard = createAsyncThunk('company/ChargeCard', async ({ payment_method, amount }, thunkApi) => {
  try {
    const response = await api.post('api/v1/stripe/create_payment_intent', { payment_method, amount });
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const getJobInvoices = createAsyncThunk('company/getJobInvoices', async ({ jobId }, thunkApi) => {
  try {
    const response = await apiCamelback.get(`api/v1/stripe/invoices/${jobId}`);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
    return thunkApi.rejectWithValue(error);
  }
});

const initialState = {
  error: null,
  isLoading: false,
  company: {},
  userToAdd: {},
  stripe: {},
  stripeSecret: {},
  intent: {},
  attachMethod: {},
  paymentMethods: [],
  processedPayment: {},
  invoices: [],
};
export const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      //addCoUser
      .addCase(addCoUser.pending, (state, action) => {
        state.isLoading = true;
        state.userToAdd = action.payload;
      })
      .addCase(addCoUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(addCoUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.company.additionalUsers.push(action.payload);
        state.userToAdd = {};
      })
      //retrieveCo
      .addCase(retrieveCo.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(retrieveCo.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(retrieveCo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.company = action.payload;
      })
      //updateCoInfo
      .addCase(updateCoInfo.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateCoInfo.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(updateCoInfo.fulfilled, (state, action) => {
        state.company.company = action.payload;
        state.isLoading = false;
      })
      //addSignUpReason
      .addCase(addSignUpReason.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(addSignUpReason.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(addSignUpReason.fulfilled, (state, action) => {
        state.company.company = action.payload;
        state.isLoading = false;
      })
      //addgetstripe
      .addCase(getStripe.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getStripe.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(getStripe.fulfilled, (state, action) => {
        state.stripe = action.payload;
        state.isLoading = false;
      })
      //addgetstripe
      .addCase(getStripeSecret.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getStripeSecret.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(getStripeSecret.fulfilled, (state, action) => {
        state.stripeSecret = action.payload;
        state.isLoading = false;
      })
      //addcreateIntent
      .addCase(createIntent.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createIntent.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(createIntent.fulfilled, (state, action) => {
        state.intent = action.payload;
        state.isLoading = false;
      })
      //addattachMethod
      .addCase(attachPaymentMethod.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(attachPaymentMethod.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(attachPaymentMethod.fulfilled, (state, action) => {
        state.attachMethod = action.payload;
        state.isLoading = false;
      })
      //add get Methods
      .addCase(getCompanyPaymentMethods.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getCompanyPaymentMethods.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(getCompanyPaymentMethods.fulfilled, (state, action) => {
        state.paymentMethods = action.payload;
        state.isLoading = false;
      })
      // deletePaymentMethod
      .addCase(deletePaymentMethod.pending, (state, action) => {})
      .addCase(deletePaymentMethod.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(deletePaymentMethod.fulfilled, (state, action) => {
        state.paymentMethods = state.paymentMethods.filter(method => method.id !== action.payload.id);
      })
      // setDefaultPaymentMethod
      .addCase(setDefaultPaymentMethod.pending, (state, action) => {})
      .addCase(setDefaultPaymentMethod.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(setDefaultPaymentMethod.fulfilled, (state, action) => {
        state.paymentMethods = state.paymentMethods.map(method => {
          if (method.id === action.payload.invoice_settings?.default_payment_method) {
            return { ...method, is_default: true };
          }
          return { ...method, is_default: false };
        });
      })
      //add chargeCard
      .addCase(chargeCard.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(chargeCard.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(chargeCard.fulfilled, (state, action) => {
        state.processedPayment = action.payload;
        state.isLoading = false;
      })
      // add chargeCardPub
      .addCase(chargeCardPub.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(chargeCardPub.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(chargeCardPub.fulfilled, (state, action) => {
        state.processedPayment = action.payload;
        state.isLoading = false;
      })
      //add get Methods
      .addCase(getJobInvoices.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getJobInvoices.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(getJobInvoices.fulfilled, (state, action) => {
        state.invoices = action.payload;
        state.isLoading = false;
      });
  },
});
