import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Plus } from 'lucide-react';
import { Spinner } from 'react-bootstrap';
import { toast } from 'react-hot-toast';

import apiCamelback from '../api/apiCamelback';
import Button from '../components/Button';
import { STRIPE_PUBLISHABLE_KEY } from '../env';
import { useAppSelector } from '../index';
import classes from '../pages/SingleJob/SingleTaskModal/SingleTaskModal.module.scss';

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

export default function useStripeSetUpPaymentsForCompany({ successUrl, cancelUrl }: { successUrl: string; cancelUrl: string }) {
  const [loading, setLoading] = React.useState(false);
  const paymentMethods = useAppSelector(state => state.company.paymentMethods);

  const description = React.useMemo(() => {
    if (paymentMethods.length > 0) {
      return 'You can add more payment methods or set one as default. The default payment method will be used for all future payments.';
    } else {
      return 'You have not yet added a payment method. Please add one to continue.';
    }
  }, [paymentMethods]);

  const handleSetupPaymentMethod = React.useCallback(async () => {
    setLoading(true);
    try {
      const response = await apiCamelback.post('/api/v1/users/setup-company-payment-method', {
        successUrl: `${window.location.origin}${successUrl}`,
        cancelUrl: `${window.location.origin}${cancelUrl}`,
      });
      const { sessionId } = response.data;

      const stripe = await stripePromise;
      if (stripe) {
        stripe.redirectToCheckout({ sessionId });
      }
    } catch (error) {
      toast.error('Error adding payment method. Please try again.');
      console.error(error);
      setLoading(false);
    }
  }, [cancelUrl, successUrl]);

  const SetUpPaymentsForCompanyButton = React.useCallback(() => {
    return (
      <Button type='button' onClick={handleSetupPaymentMethod} className={classes.button} disabled={loading}>
        {loading ? <Spinner animation='border' size='sm' /> : <Plus />}
        Add Payment Method
      </Button>
    );
  }, [handleSetupPaymentMethod, loading]);

  return { description, SetUpPaymentsForCompanyButton };
}
