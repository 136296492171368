import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { camelizeKeys } from 'humps';

import api from '../api';
import apiCamelback from '../api/apiCamelback';

export const removeDocFromJob = createAsyncThunk('document/removeDocFromJob', async ({ id, jobId }, thunkApi) => {
  const response = await api.delete(`api/v1/cojobdoc/${id}/${jobId}`);
  if (response.data.errors) {
    return thunkApi.rejectWithValue(response.data.errors);
  }
  return camelizeKeys(response.data);
});

export const checkDocsForPro = createAsyncThunk('document/checkDocsForPro', async ({ jobId }, thunkApi) => {
  const response = await apiCamelback.get(`api/v1/codocuments/${jobId}`);
  if (response.data.errors) {
    return thunkApi.rejectWithValue(response.data.errors);
  }
  return camelizeKeys(response.data);
});

export const createCoJobDoc = createAsyncThunk('document/createCoJobDoc', async ({ docIds, jobId }, thunkApi) => {
  const response = await apiCamelback.post('api/v1/cojobdoc', { doc_ids: docIds, job_id: jobId });
  if (response.data.errors) {
    return thunkApi.rejectWithValue(response.data.errors);
  }
  return camelizeKeys(response.data);
});

export const coUploadDoc = createAsyncThunk('document/coUploadDoc', async ({ docs, jobId }, thunkApi) => {
  const response = await api.post('/api/v1/codocuments', { docs: docs, job_id: jobId });
  if (response.data.errors) {
    return thunkApi.rejectWithValue(response.data.errors);
  }
  return camelizeKeys(response.data);
});

export const coCheckForDocs = createAsyncThunk('document/coCheck', async (_, thunkApi) => {
  const response = await apiCamelback.get('/api/v1/codocuments');
  if (response.data.errors) {
    return thunkApi.rejectWithValue(response.data.errors);
  }
  return camelizeKeys(response.data);
});
export const uploadReport = createAsyncThunk('document/uploadReport', async ({ docs, jobId }, thunkApi) => {
  const response = await api.post('/api/v1/reports', { reports: docs, job_id: jobId });
  if (response.data.errors) {
    return thunkApi.rejectWithValue(response.data.errors);
  }
  return camelizeKeys(response.data);
});

export const getReports = createAsyncThunk('document/getReports', async ({ jobId }, thunkApi) => {
  const response = await api.get(`/api/v1/checkreports/${jobId}`);
  if (response.data.errors) {
    return thunkApi.rejectWithValue(response.data.errors);
  }
  return camelizeKeys(response.data);
});

export const getDocumentById = createAsyncThunk('document/getReports', async ({ workerId, documentId }, thunkApi) => {
  const response = await api.get(`/api/v1/checkreports/${workerId}`);
  if (response.data.errors) {
    return thunkApi.rejectWithValue(response.data.errors);
  }
  return camelizeKeys(response.data);
});

export const downloadReport = createAsyncThunk('document/downloadReport', async ({ jobId }, thunkApi) => {
  const response = await api.get(`/api/v1/reports/${jobId}`);
  if (response.data.errors) {
    return thunkApi.rejectWithValue(response.data.errors);
  }
  return camelizeKeys(response.data);
});

export const deleteReport = createAsyncThunk('document/deleteReport', async ({ reportId }, thunkApi) => {
  const response = await api.delete(`/api/v1/reports/${reportId}`);
  if (response.data.errors) {
    return thunkApi.rejectWithValue(response.data.errors);
  }
  return camelizeKeys(response.data);
});

export const uploadDocument = createAsyncThunk('document/uploadDocument', async ({ category, docs }, thunkApi) => {
  const response = await apiCamelback.post(`app/api/v1/documents/${category}`, docs);
  if (response.data.errors) {
    return thunkApi.rejectWithValue(response.data.errors);
  }
  return response.data;
});

export const getDocuments = createAsyncThunk('document/getDocuments', async (_, thunkApi) => {
  const response = await apiCamelback.get(`app/api/v1/documents/worker`);
  const camelized = camelizeKeys(response.data);
  if (response.data.errors) {
    return thunkApi.rejectWithValue(response.data.errors);
  }
  return camelized;
});

export const deleteSingleDocument = createAsyncThunk('documents/deleteSingleDocument', async ({ docId, hashToken }, thunkApi) => {
  try {
    const response = await apiCamelback.delete(`app/api/v1/documents/${docId}?hashToken=${hashToken}`);
    const camelized = camelizeKeys(response.data);
    if (response.data.errors) {
      return thunkApi.rejectWithValue(response.data.errors);
    }
    return camelized;
  } catch (error) {
    console.error(error);
  }
});

const initialState = {
  error: null,
  isLoading: false,
  newDoc: {},
  allDocuments: [],
  reports: [],
  newCoDocs: [],
  coDocs: [],
  coTemplates: [],
};

export const documentSlice = createSlice({
  name: 'document',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(removeDocFromJob.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(removeDocFromJob.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(removeDocFromJob.fulfilled, (state, action) => {
        state.isLoading = false;
        state.coTemplates = action.payload;
      })
      .addCase(checkDocsForPro.pending, (state, action) => {
        state.isLoading = true;
        state.coTemplates = [];
      })

      .addCase(checkDocsForPro.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(checkDocsForPro.fulfilled, (state, action) => {
        state.isLoading = false;
        state.coTemplates = action.payload;
      })
      .addCase(createCoJobDoc.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(createCoJobDoc.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(createCoJobDoc.fulfilled, (state, action) => {
        state.isLoading = false;
        state.coTemplates = action.payload;
      })
      .addCase(coCheckForDocs.pending, (state, action) => {
        state.coDocs = [];
        state.isLoading = true;
      })

      .addCase(coCheckForDocs.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(coCheckForDocs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.coDocs = action.payload;
      })
      .addCase(coUploadDoc.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(coUploadDoc.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(coUploadDoc.fulfilled, (state, action) => {
        state.isLoading = false;
        state.newCoDocs.push(action.payload);
      })
      .addCase(uploadReport.pending, (state, action) => {
        state.reports = [];
        state.isLoading = true;
      })

      .addCase(uploadReport.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(uploadReport.fulfilled, (state, action) => {
        state.isLoading = false;
        state.reports = action.payload;
      })
      .addCase(downloadReport.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(downloadReport.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(downloadReport.fulfilled, (state, action) => {
        state.isLoading = false;
        // state.reports.push(action.payload)
      })
      .addCase(getReports.pending, (state, action) => {
        state.reports = [];
        state.isLoading = true;
      })

      .addCase(getReports.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(getReports.fulfilled, (state, action) => {
        state.isLoading = false;
        state.reports = action.payload;
      })

      .addCase(deleteReport.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(deleteReport.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(deleteReport.fulfilled, (state, action) => {
        state.isLoading = false;
        // state.reports = action.payload
      })

      .addCase(uploadDocument.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(uploadDocument.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(uploadDocument.fulfilled, (state, action) => {
        state.isLoading = false;
      })

      .addCase(getDocuments.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(getDocuments.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })

      .addCase(getDocuments.fulfilled, (state, action) => {
        state.isLoading = false;
        state.allDocuments = action.payload;
      })

      .addCase(deleteSingleDocument.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(deleteSingleDocument.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(deleteSingleDocument.fulfilled, (state, action) => {
        const allDocumentsCopy = [...state.allDocuments].map(doc => ({ ...doc }));
        state.isLoading = false;
        state.allDocuments = allDocumentsCopy.filter(doc => doc.id !== String(action.payload.id));
      });
  },
});
