import React from 'react';
import { Banknote, FileText } from 'lucide-react';
import { Button } from 'primereact/button';

import SetUpPaymentsStatusModal from '../../../components/SetUpPaymentsForPro/SetUpPaymentsStatusModal';
import { useAppSelector } from '../../../index';
import { Todo } from '../../../types';
import SingleTaskModal from '../SingleTaskModal';
import classes from './JobTasks.module.scss';

export const tasksIcons = {
  SETUP_PAYOUT: Banknote,
  CUSTOM: FileText,
} as const;

export default function JobTasks() {
  const [openModal, setOpenModal] = React.useState(false);
  const [selectedTask, setSelectedTask] = React.useState(null);
  const job: Todo = useAppSelector(state => state.job.jobOrder);
  const user = useAppSelector(state => state.user.user);

  const tasks = React.useMemo(() => {
    let scopeConditions: string[] = [];
    if (user.role === 'worker') {
      scopeConditions = ['GLOBAL_WORKER', 'JOB_WORKER'];
    } else if (user.role === 'user') {
      scopeConditions = ['GLOBAL_COMPANY', 'JOB_COMPANY'];
    }
    return {
      completed:
        job.allTasks
          ?.slice()
          .filter((task: Todo) => {
            const isIncluded = scopeConditions.includes(task.scope);
            return isIncluded && task.stage === 'COMPLETED';
          })
          .sort((a: Todo, b: Todo) => (new Date(a.createdAt) as Todo) - (new Date(b.createdAt) as Todo)) || [],
      incomplete:
        job.allTasks
          ?.slice()
          .filter((task: Todo) => {
            const isIncluded = scopeConditions.includes(task.scope);
            return isIncluded && task.stage !== 'COMPLETED';
          })
          .sort((a: Todo, b: Todo) => (new Date(a.createdAt) as Todo) - (new Date(b.createdAt) as Todo)) || [],
    };
  }, [job.allTasks, user.role]);

  return (
    <ul className={classes.tasks_container}>
      {tasks.incomplete.length > 0 && (
        <>
          <h3 className={classes.task_title}>Incomplete</h3>
          {tasks.incomplete.map((task: Todo) => (
            <SingleTask key={task.sid} task={task} setSelectedTask={setSelectedTask} setOpenModal={setOpenModal} />
          ))}
        </>
      )}
      {tasks.completed.length > 0 && (
        <>
          <h3 className={`${classes.task_title} mt-4`}>Complete</h3>
          {tasks.completed.map((task: Todo) => (
            <SingleTask key={task.sid} task={task} setSelectedTask={setSelectedTask} setOpenModal={setOpenModal} />
          ))}
        </>
      )}
      {user.role === 'user' && (
        <div className='mt-5 d-flex gap-3'>
          <a
            href={`mailto:operations@goyellowbird.com?subject=Job%20Cancellation%20Request&body=Please%20cancel%20job%20with%20reference%20number%20${job.referenceNumber}`}
            target='_blank'
            rel='noopener noreferrer'
            className={classes.cancel_job_button}
          >
            Cancel this Job
          </a>
          <a
            href={`mailto:operations@goyellowbird.com?subject=Reschedule%20Job%20Request&body=Please%20reschedule%20job%20with%20reference%20number%20${job.referenceNumber}`}
            target='_blank'
            rel='noopener noreferrer'
            className={classes.cancel_job_button}
          >
            Reschedule this Job
          </a>
        </div>
      )}
      <SingleTaskModal show={openModal && !!selectedTask} onHide={() => setOpenModal(false)} task={selectedTask} />
      <SetUpPaymentsStatusModal />
    </ul>
  );
}

type SingleTaskProps = {
  task: Todo;
  setSelectedTask: React.Dispatch<React.SetStateAction<Todo>>;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
};

function SingleTask({ task, setSelectedTask, setOpenModal }: SingleTaskProps) {
  const Icon = tasksIcons[task.type as keyof typeof tasksIcons]; // TODO: Properly type this when we have the camelback API types
  const completed = task.stage === 'COMPLETED';

  const buttonTxt = React.useMemo(() => {
    if (task.type === 'SETUP_PAYOUT') {
      if (task.stage === 'PENDING') {
        return 'Pending Verification';
      } else {
        return completed ? 'Edit' : 'Start';
      }
    }
    return completed ? 'View' : 'Start';
  }, [completed, task.type, task.stage]);

  return (
    <li key={task.id} className={classes.task}>
      <div className='d-flex align-items-center gap-2'>
        <div className={classes.task_icon_wrapper}>
          <Icon size={26} className={classes.task_icon} />
        </div>
        <p className={classes.task_title}>{task.title}</p>
      </div>
      <button
        type='button'
        className={classes.task_button}
        onClick={() => {
          setSelectedTask(task);
          setOpenModal(true);
        }}
      >
        {buttonTxt}
      </button>
    </li>
  );
}
