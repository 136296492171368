import { configureStore } from '@reduxjs/toolkit';
import { PreloadedState } from 'redux';

import rootReducer, { RootState } from '../../reducers';
import logger from './logger';
import monitorReducerEnhancer from './monitorReducersEnhancer';

export default function configureAppStore(preloadedState?: PreloadedState<RootState>) {
  const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(logger),
    preloadedState,
    enhancers: [monitorReducerEnhancer],
    devTools: process.env.NODE_ENV !== 'production', // Enable Redux DevTools in development only
  });

  if (process.env.NODE_ENV !== 'production' && (module as any).hot) {
    // Enable Webpack hot module replacement for reducers
    (module as any).hot.accept('../../reducers', () => {
      const newRootReducer = require('../../reducers').default;
      store.replaceReducer(newRootReducer);
    });
  }

  return store;
}
