import React from 'react';
import { Modal as BootstrapModal } from 'react-bootstrap';

import './Modal.scss';

// To use this modal, all you need to do is pass in the children that will be inside of the modal, ex:
// <h5>, <p>, <a>, etc. This should all be wrapped with a fragment `<></>`

type ModalProps = {
  size?: 'sm' | 'lg' | 'xl';
  show: boolean;
  onHide: () => void;
  backdrop?: 'static' | boolean;
  keyboard?: boolean;
  scrollable?: boolean;
  centered?: boolean;
  maxWidth?: boolean;
  children: React.ReactNode;
  shouldHideClose?: boolean;
  closeButton?: boolean;
};

export default function Modal({
  size,
  show,
  onHide,
  backdrop = 'static',
  keyboard = false,
  scrollable = true,
  centered = false,
  maxWidth,
  children,
  shouldHideClose = false,
  closeButton = true,
}: ModalProps) {
  return (
    <BootstrapModal
      size={size}
      show={show}
      onHide={onHide}
      backdrop={backdrop}
      backdropClassName='backdropZIndex'
      keyboard={keyboard}
      style={{ zIndex: 60 }}
      dialogClassName={`custom-modal__container ${maxWidth ? 'max-whatever' : 'max-850'}`}
      scrollable={scrollable}
      centered={centered}
    >
      <BootstrapModal.Header style={shouldHideClose ? { visibility: 'hidden' } : {}} closeButton={closeButton}></BootstrapModal.Header>
      <BootstrapModal.Body className='py-1 px-sm-5'>
        <div className='px-3 px-sm-5'>{children}</div>
      </BootstrapModal.Body>
    </BootstrapModal>
  );
}
