import React, { useEffect, useMemo, useState } from 'react';
import { Lock } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import './job-invite.scss';

import carBreakingIcon from '../../assets/icons/car-breaking.svg';
import carIcon from '../../assets/icons/car.svg';
import close from '../../assets/icons/closeGray.svg';
import dateIcon from '../../assets/icons/date.svg';
import locationIcon from '../../assets/icons/location.svg';
import moneyIcon from '../../assets/icons/money.svg';
import Loading from '../../components/Loading';
import Modal from '../../components/Modal';
import { MAPS_KEY } from '../../env';
import { formatDateMMDDYYYY } from '../../helpers';
// import { getCanopyResponse } from '../../slices/canopy.slice';
import { respondToJobInvite, retrieveJobInvite } from '../../slices/job.slice';
import { updateJobInvite } from '../../slices/jobInvite.slice';
import ConditionalInfo from './Components/conditional-info';
import InfoBox from './Components/info-box';
import JobInviteReusableRadio from './Components/job-invite-reusable-radio';
import OperationsEmail from './Components/operations-email';

export default function JobInvite() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.user);
  const { jobInvite, isLoading } = useSelector(state => state.job);
  const { jobInfo } = jobInvite;
  const { referenceNumber } = useParams();
  const [isCompLoading, setIsCompLoading] = useState(true);
  const [isWorkerValid, setIsWorkerValid] = useState();
  const [jobAction, setJobAction] = useState({ declining: false, accepting: false });
  const { declining, accepting } = jobAction;
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isStandbyModalVisible, setIsStandbyModalVisible] = useState(false);
  const [isOptOutModalVisible, setIsOptOutModalVisible] = useState(false);
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(false);
  const alreadyMatched = useSelector(state => state.job.alreadyMatched);
  //CANOPY INTEGRATION STATE
  // const [canopyState, setCanopyState] = useState({ verified: false, canopyError: '' });
  // const { verified } = canopyState;

  const now = new Date().valueOf();
  const status = jobInvite?.jobInvite?.status || null;
  const expiration = jobInvite?.jobInvite?.expiration ? new Date(jobInvite.jobInvite.expiration).valueOf() : null;
  const checkedPay = jobInfo ? (jobInfo?.pay === '$0.00' ? 'To be determined...' : jobInfo?.payCents / 100) : 'To be determined';
  const jobInviteRefNum = jobInvite?.jobInvite?.referenceNumber;
  const jobName = jobInvite?.jobInfo?.name;
  const alreadyAccepted = jobInvite?.jobInvite?.status === 'accepted';

  useEffect(() => {
    if (alreadyMatched) {
      setJobAction(prevAction => ({ ...prevAction, accepting: false }));

      if (alreadyMatched) {
        setIsModalVisible(true);
      }
    }
  }, [alreadyMatched]);

  const inviteStatus = useMemo(() => {
    switch (status) {
      case 'pending':
      case 'accepted':
      case 'declined':
      case 'standby':
        return status;
      default:
        return '';
    }
  }, [status]);

  const standbyEligible = useMemo(() => {
    return jobInfo?.status === 'Matched' || alreadyMatched;
  }, [jobInfo, alreadyMatched]);

  const inProgressOrLater = useMemo(() => {
    if (jobInfo && jobInfo.status) {
      switch (jobInfo.status) {
        case 'In Progress':
        case 'Completed':
        case 'Closed-Paid':
        case 'Closed-Lost':
        case 'Closed-No Response':
          return true;
        default:
          return false;
      }
    }
    return false;
  }, [jobInfo]);

  const inviteResponded = inviteStatus === 'accepted' || inviteStatus === 'declined' || inviteStatus === 'standby';
  const standbyNotEligible = inProgressOrLater || inviteStatus === 'standby';
  const alreadyOnStandbyList = inviteStatus === 'standby';
  const noLongerAvailable = inProgressOrLater && (inviteStatus === 'pending' || expiration < now || inviteResponded);
  const jobIsClosed = ['InProgress', 'Completed', 'Closed-Paid', 'Closed-Lost', 'Closed-No Response'].includes(jobInfo?.status);

  const {
    register,
    formState: { isValid },
    handleSubmit,
    getValues,
    watch,
  } = useForm({
    defaultValues: {
      meansoftransportationRequired: '',
      insuranceVerified: '',
      //policyRequired: false,
      //manualPolicy: { show: false, validAge: false, driversLicense: null, insuranceID: null },
      otherTransportation: '',
      skillsMet: false,
      jobCommitment: false,
      declineReason: '',
      declineReasonDescription: '',
      timeWindowSid: null,
    },
  });

  const ownTransportation = watch('meansoftransportationRequired');
  //const policyRequired = watch('policyRequired');
  //const manualPolicy = watch('manualPolicy');
  const insuranceVerified = watch('insuranceVerified');
  const otherTransportation = watch('otherTransportation');
  const declineReason = watch('declineReason');
  const declineReasonDescription = watch('declineReasonDescription');
  const timeWindowSid = watch('timeWindowSid');

  useEffect(() => {
    const getJobInvite = async () => {
      try {
        const res = await dispatch(retrieveJobInvite(referenceNumber)).unwrap();
        setIsCompLoading(false);
        //To test locally, comment this out. Set setIsWorkerValid to true.
        if (res.jobInvite.workerId === user.workerId) {
          setIsWorkerValid(true);
        } else {
          setIsWorkerValid(false);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getJobInvite();
  }, [referenceNumber]);

  const handleCreateStandbyList = () => {
    const selectedTimeWindow = jobInfo.timeWindows?.find(window => window.sid.id === timeWindowSid);
    const jobDateSelection = selectedTimeWindow ? selectedTimeWindow.start : null;
    const jobInviteId = jobInvite.jobInvite.id;
    // JobInvite status enum value for standby is 3
    const standByListStatus = 3;

    if (!jobDateSelection) {
      console.error('No time window selected or found');
      return;
    }

    const standbyListData = {
      jobInviteId,
      preferredDate: jobDateSelection,
      standByListStatus,
    };

    setIsButtonClicked(true);
    setIsStandbyModalVisible(true);
    dispatch(updateJobInvite(standbyListData));
  };

  const handleOptOut = async () => {
    const jobInviteId = jobInvite.jobInvite.id;
    const standByListStatus = 0;

    const optOutData = {
      jobInviteId,
      standByListStatus,
    };

    await dispatch(updateJobInvite(optOutData));

    setIsOptOutModalVisible(false);
    setIsConfirmationModalVisible(true);

    dispatch(retrieveJobInvite(jobInviteRefNum));
  };

  const handleOptOutModal = () => {
    setIsOptOutModalVisible(true);
  };

  if (isCompLoading || (isLoading && !jobInfo)) {
    return <Loading />;
  }

  if (!isWorkerValid && !isCompLoading) {
    return (
      <div className='proacceptjob-no-access-div'>
        <Lock size={50} color='#FFCD03' strokeWidth={3} className='proacceptjob-lock-icon' />
        <h1 style={{ fontWeight: 700 }}>Oh sorry</h1>
        <p>You have no access to this page</p>
        <button className='proacceptjob-no-access-btn' onClick={() => navigate('/dashboard')}>
          Go back
        </button>
      </div>
    );
  }

  async function declineJob() {
    const formValues = getValues();

    toast.promise(
      dispatch(
        respondToJobInvite({
          response: 'declined',
          reasonForDecline: formValues.declineReasonDescription || formValues.declineReason,
          jobInviteRefNumber: referenceNumber,
        })
      ).unwrap(),
      {
        loading: 'Declining job...',
        success: () => {
          navigate('/dashboard/?action=show-confirmation-modal', { replace: true });
          return 'Job declined';
        },
        error: error => {
          console.error(error);
          return error?.message || 'Error declining job. Please try again later.';
        },
      }
    );
  }

  async function acceptJob(data) {
    const isProDriving = data.meansoftransportationRequired === 'Yes' ? true : false;

    toast.promise(
      dispatch(
        respondToJobInvite({
          response: 'accepted',
          reasonForDecline: null,
          jobInviteRefNumber: referenceNumber,
          isProDriving,
          timeWindowSid: data.timeWindowSid,
        })
      ).unwrap(),
      {
        loading: 'Accepting job...',
        success: () => {
          navigate('/my-jobs/current/?action=show-confirmation-modal', { replace: true });
          return 'Job accepted';
        },
        error: error => {
          console.error(error);
          return error?.message || 'Error accepting job. Please try again later.';
        },
      }
    );
  }

  //CANOPY CONNECT BUTTON AND MODAL LOGIC
  /*
  const CanopyConnectBtn = ({ publicAlias, metaData }) => {
    const [handler, setHandler] = React.useState(null);
    const dispatch = useDispatch();
    let pullId;

    const dispatchCanopyResponse = async pullId => {
      let errorMessage;
      try {
        const res = await dispatch(getCanopyResponse({ pullId })).unwrap();
        console.log(res);
        if (res.success === true) {
          setCanopyState({ ...canopyState, verified: true, canopyError: '' });
        } else {
          errorMessage = res.message;
          setCanopyState({ ...canopyState, canopyError: errorMessage });
        }
      } catch (error) {
        setCanopyState({ ...canopyState, canopyError: errorMessage || error.message });
      }
    };

    React.useEffect(() => {
      if (!publicAlias) {
        return;
      }

      const canopyHandler = window.CanopyConnect.create({
        publicAlias,
        pullMetaData: metaData,
      });

      //HAVE TO RUN AUTH SUCCESS FIRST TO STORE PULLID ON EXIT DOES NOT PROVIDE
      canopyHandler.on('authenticationSuccess', data => {
        pullId = data.pull.pull_id;
      });

      //ONCE THE CANOPY MODAL CLOSES CALLS AND PASSES THROUGH PULLID THROUGH CANOPY ENDPOINT
      canopyHandler.on('exit', data => {
        if (pullId) {
          dispatchCanopyResponse(pullId);
        }
      });

      setHandler(canopyHandler);

      return () => {
        setHandler(null);
        canopyHandler.destroy();
      };
    }, [publicAlias, metaData, dispatch]);

    if (!handler) {
      return (
        <button disabled className='job-invite-buttons job-invite-verify-insurance'>
          <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
        </button>
      );
    }

    return (
      <button
        disabled={policyRequired}
        className={`job-invite-buttons job-invite-verify-insurance ${verified && 'job-invite-verify-insurance-verified'}`}
        onClick={() => handler.open()}
      >
        {verified ? (
          <div style={{ display: 'flex', gap: '16px', alignItems: 'center', justifyContent: 'center' }}>
            <img style={{ maxWidth: '24px' }} src={checkIcon} />
            <span>Insurance Verified</span>
          </div>
        ) : (
          'Verify Insurance Coverage'
        )}
      </button>
    );
  };
*/

  return (
    <div className='job-invite-container'>
      {jobIsClosed ? (
        <div>
          <h2 className='job-invite-header'>This job is no longer active</h2>
          <p>
            Don't worry! New jobs are posted daily. Ensure your Profile details are up-to-date to improve your chance to receive additional job invitations.
          </p>
        </div>
      ) : alreadyOnStandbyList ? (
        <div>
          <h2 className='job-invite-header'>You're already on the Standby List for this job!</h2>
          <p>
            To increase your chances of being matched with new job opportunities, regularly update your YellowBird Profile with your latest skills, expertise,
            and experience. Our matching technology uses this information to connect you with the most relevant jobs. If you have any questions, feel free to
            reach out to <OperationsEmail />.
          </p>
          <p>
            <a href='#' onClick={handleOptOutModal} className='optOutText'>
              Withdraw from standby list
            </a>
          </p>
        </div>
      ) : alreadyAccepted ? (
        <div>
          <h2 className='job-invite-header'>You've already accepted this job!</h2>
          <p>
            You can find the details of this job here in 'Current Jobs' within your dashboard. Additional information will be provided to you via email and you
            will be connected via text message to the customer's point of contact.
          </p>
          <p>
            Need assistance? Please contact <OperationsEmail />
          </p>
        </div>
      ) : alreadyMatched ? (
        <div>
          <h2 className='job-invite-header'>Oh no! This job was just accepted by another YellowBird Pro</h2>
          <p>
            However, you can join the Standby List to be considered if the Pro ahead of you is unable to complete this job. Please review the job details
            thoroughly before joining the Standby List below.
          </p>
          <p>
            Questions? Contact <OperationsEmail />
          </p>
        </div>
      ) : standbyEligible ? (
        <div>
          <h2 className='job-invite-header'>This job has already been accepted by another YellowBird Pro</h2>
          <p>
            However, you can join the Standby List to be considered if the Pro ahead of you is unable to complete this job. Please review the job details
            thoroughly before joining the Standby List below.
          </p>
          <p>
            Questions? Contact <OperationsEmail />
          </p>
        </div>
      ) : (
        <h2 className='job-invite-header'>Congrats {user.firstName}! Please review the job below and 'Accept' to get started.</h2>
      )}
      <div className='job-invite-wrapper'>
        <div className='job-invite-sidebar'></div>
        <div className='job-invite-content'>
          <p className='labelText'>Job Title</p>
          <section className='job-invite-info-box'>
            <h4 className='descriptionText'>{jobInfo?.name}</h4>
            <p className='job-invite-reference-number'>#{jobInfo.referenceNumber}</p>
          </section>
          <section className='job-invite-section-styles'>
            {/* JOB DESCRIPTION */}
            <div className='job-invite-description'>
              {jobInfo.typeOfWork && (
                <div>
                  <h5 className='labelText'>Job Description</h5>
                  <p className='descriptionText'>{jobInfo.typeOfWork}</p>
                </div>
              )}
              <ConditionalInfo jobInfo={jobInfo.requiredSkills} label='Required Skills/and or Certifications' />
              <ConditionalInfo jobInfo={jobInfo.otherRequirements} label='Additional Requirements' />
              <ConditionalInfo jobInfo={jobInfo.languageRequired} label='Language Required' />
              <ConditionalInfo jobInfo={jobInfo.deliverablesWithDueDate} label='Reports or Deliverables Due Date:' />
              <ConditionalInfo jobInfo={jobInfo.ehsExperience} label='Preferred Years of Experience:' />
              <ConditionalInfo jobInfo={jobInfo.industryExperience} label='Preferred Industry Experience:' />
              <ConditionalInfo jobInfo={jobInfo.deliverableType} label='Deliverable Type Required:' />
              <ConditionalInfo jobInfo={jobInfo.additionalCompensation} label='Deliverable Type Required:' />
            </div>

            {/* LOCATION */}
            <InfoBox image={locationIcon}>
              <div className='job-invite-location-info'>
                <div className='location-details'>
                  <p className='labelText'>Location</p>
                  <p className='descriptionText'>
                    {jobInfo.commuteType.toLowerCase() === 'virtual' ? 'Virtual' : `${jobInfo.siteZip}, ${jobInfo?.siteCity}, ${jobInfo?.siteState}`}
                  </p>
                  {jobInfo?.multipleSites && <p className='multiple-locations'>(Multiple Locations)</p>}
                </div>
                <div className='job-invite-map-wrapper'>
                  {jobInfo.commuteType !== 'virtual' && (
                    <iframe
                      title='Job Location'
                      name='Job Location'
                      loading='lazy'
                      src={`https://www.google.com/maps/embed/v1/place?key=${MAPS_KEY}&q=${jobInfo.siteAddress},${jobInfo.siteCity}+${jobInfo.siteState}`}
                    ></iframe>
                  )}
                </div>
              </div>
            </InfoBox>

            {/* PAY AND HOURS */}
            <InfoBox image={moneyIcon}>
              <p className='labelText'>
                Estimated Hours: <span className='descriptionText'>{jobInfo.totalHours || '-'} Hours</span>
              </p>
              <p className='labelText'>
                Estimated Total: <span className='descriptionText'>${checkedPay}</span>
              </p>
            </InfoBox>

            {/* DATE, TIME WINDOWS AND SCHEDULE */}
            <InfoBox image={dateIcon}>
              {jobInfo.deadline ? (
                <div className='job-invite-date-info'>
                  <p className='labelText'>
                    Start Date: <span className='descriptionText'>{formatDateMMDDYYYY(new Date(jobInfo.startDate))}</span>
                  </p>
                  <p className='labelText'>
                    End Date: <span className='descriptionText'>{formatDateMMDDYYYY(new Date(jobInfo.deadline))}</span>
                  </p>
                </div>
              ) : (
                <div style={{ display: 'flex', gap: '1.5rem' }}>
                  <p className='labelText'>Start Date</p>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {jobInfo.timeWindows?.map(timeWindow => (
                      <div key={timeWindow.sid.id} style={{ display: 'flex', gap: '0.8rem', alignItems: 'center', marginBottom: '1rem' }}>
                        <input
                          type='radio'
                          name='timeWindowSid'
                          id={timeWindow.sid.id}
                          value={timeWindow.sid.id}
                          checked={timeWindowSid === timeWindow.sid.id}
                          disabled={jobIsClosed || standbyNotEligible || alreadyAccepted}
                          {...register('timeWindowSid', { required: Boolean(!jobInfo.deadline) })}
                        />
                        <label htmlFor={timeWindow.sid.id}>{formatDateMMDDYYYY(new Date(timeWindow.start))}</label>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <hr />
              {jobInfo.schedulingNotes && (
                <div style={{ marginTop: 20 }}>
                  <p className='job-invite-schedule'>Scheduling Notes</p>
                  <p className='mt-2'>{jobInfo.schedulingNotes}</p>
                </div>
              )}
            </InfoBox>

            {/* TRANSPORTATION */}
            {noLongerAvailable ? null : (
              <>
                {jobInfo.commuteType === 'HYBRID' || jobInfo.commuteType === 'ON_SITE' ? (
                  <>
                    <InfoBox image={carIcon}>
                      <p className='job-invite-transportation-info-box'>This job requires transportation to and from the site.</p>
                      <p className='job-invite-transportation-labelText'>Will you be driving your own vehicle?</p>
                      <fieldset className='job-invite-transportation-fieldset'>
                        <JobInviteReusableRadio value='Yes' register={register} formValue='meansoftransportationRequired' required={true} />
                        <JobInviteReusableRadio value='No' register={register} formValue='meansoftransportationRequired' required={true} />
                      </fieldset>
                      {ownTransportation === 'No' && (
                        <>
                          <hr />
                          <p className='job-invite-transportation-info-box alternate-info-p'>Do you have an alternate means of transportation?</p>
                          <fieldset className='job-invite-transportation-fieldset'>
                            <div className='job-invite-transportation-fieldset'>
                              <input
                                {...register('otherTransportation', { required: true, validate: value => value === 'yes' })}
                                value='yes'
                                id='yesOption'
                                type='radio'
                                className='job-invite-transportation-alternate-input'
                              />
                              <label htmlFor='yesOption'>Yes</label>
                            </div>
                            <div className='job-invite-transportation-fieldset'>
                              <input
                                {...register('otherTransportation', { required: true })}
                                value='no'
                                id='noOption'
                                type='radio'
                                className='job-invite-transportation-alternate-input'
                              />
                              <label htmlFor='noOption'>No</label>
                            </div>
                          </fieldset>
                          {otherTransportation === 'no' && (
                            <p className='job-invite-transportation-alternate-message'>
                              This is an on-site job and transportation is required. If you do not have transportation, you are not eligible for this job.
                            </p>
                          )}
                        </>
                      )}
                    </InfoBox>
                    {ownTransportation === 'Yes' && (
                      <InfoBox image={carBreakingIcon}>
                        <p className='job-invite-insurance-info-box'>
                          To accept this job, you must have a valid auto insurance policy for the vehicle you will be driving to the site.
                          <br />
                          <br />
                          YellowBird includes $1 million coverage over and above the standard auto insurance policy for YellowBird Pros who carry a combined
                          coverage of $300,000.
                        </p>
                        <p className='job-invite-insurance-descriptionText'>
                          See our&nbsp;
                          <a
                            href='https://goyellowbird.com/terms/'
                            target='_blank'
                            style={{ textDecoration: 'underline', color: '#006CA9' }}
                            rel='noreferrer noopener'
                          >
                            Terms and Conditions
                          </a>
                          &nbsp; for more information.
                        </p>
                        {
                          //CANOPY VERIFICATION INSURANCE BUTTON
                          /*} <CanopyConnectBtn publicAlias={'yellow-bird'} />
                        {canopyState.canopyError && (
                          <p style={{ color: '#dc2626', fontSize: '0.75rem', marginTop: '8px', lineHeight: '16px', marginBottom: '0px' }}>
                            {canopyState.canopyError}.
                            <br />
                            *If you're experiencing issues feel free to contact our Ops Team at (111)-111-1111.
                          </p>
                        )}
                        <div className='job-invite-insurance-padding'>
                          <fieldset className={`job-invite-insurance-fieldset ${verified && 'job-invite-insurance-fieldset-verified'}`}>
                            <input className='job-invite-checkbox checkBoxMultiStep' {...register('manualPolicy.show')} id='manuelPolicy' type='checkbox' />
                            <label className='descriptionText insuranceText' htmlFor='manuelPolicy'>
                              Or submit your documents manually
                            </label>
                          </fieldset>
                        </div>
                        */
                        }
                        {
                          //MANUALLY SUBMISSION OF DOCUMENTS - NOT FINISHED
                          /*{manualPolicy.show && (
                          <>
                            <fieldset className='job-invite-confirmation-fieldset'>
                              <div className='job-invite-confirmation-checkbox-group'>
                                <div>
                                  <input
                                    className='job-invite-confirmation-checkbox checkBoxMultiStep'
                                    {...register('manualPolicy.validAge', { validate: value => value == true })}
                                    id='validAge'
                                    type='checkbox'
                                  />
                                </div>
                                <label className='job-invite-confirmation-label labelText' htmlFor='validAge'>
                                  I confirm I meet the required age (23-70).
                                </label>
                              </div>
                              <div className='job-invite-confirmation-checkbox-group'>
                                <label className='job-invite-confirmation-label labelText' htmlFor='driversLicense'>
                                  Please upload your drivers licence.
                                </label>
                                <div>
                                  <input className='' {...register('manualPolicy.driversLicense')} id='driversLicense' type='file' />
                                </div>
                              </div>
                              <div className='job-invite-confirmation-checkbox-group'>
                                <label className='job-invite-confirmation-label labelText' htmlFor='insuranceID'>
                                  Please upload your insurance ID card for the car being driven.
                                </label>
                                <div>
                                  <input className='' {...register('manualPolicy.insuranceID')} id='insuranceID' type='file' />
                                </div>
                              </div>
                            </fieldset>
                            <button autoFocus disabled={!isValid} className='job-invite-buttons job-invite-buttons-accept'>
                              Submit Documents
                            </button>
                            <button autoFocus aria-disabled className='job-invite-hidden-button' style={{ border: 'none', background: 'none' }}></button>
                          </>
                        )}*/
                        }
                        {
                          //NO INSURANCE CHECKBOX
                          /*<div className='job-invite-insurance-padding'>
                          <fieldset className={`job-invite-insurance-fieldset ${verified && 'job-invite-insurance-fieldset-verified'}`}>
                            <input
                              className='job-invite-checkbox checkBoxMultiStep'
                              {...register('policyRequired', { validate: value => value == false })}
                              id='policyOption'
                              type='checkbox'
                            />
                            <label className='descriptionText insuranceText' htmlFor='policyOption'>
                              I do not have this auto coverage
                            </label>
                          </fieldset>
                          {policyRequired && (
                            <p className='job-invite-insurance-message'>
                              This is an on-site job and insurance policies with a combined coverage of $300,000 is required. If you do not have this coverage,
                              you are not eligible for this job.
                            </p>
                          )}
                          </div>*/
                        }
                        <p className='job-invite-transportation-labelText'>Do you hold this standard auto coverage?</p>
                        <fieldset className='job-invite-transportation-fieldset'>
                          <div className='job-invite-transportation-fieldset'>
                            <input
                              {...register('insuranceVerified', { required: true, validate: value => value === 'yes' })}
                              value='yes'
                              id='yesOption'
                              type='radio'
                              className='job-invite-transportation-alternate-input'
                            />
                            <label htmlFor='yesOption'>Yes</label>
                          </div>
                          <div className='job-invite-transportation-fieldset'>
                            <input
                              {...register('insuranceVerified', { required: true })}
                              value='no'
                              id='noOption'
                              type='radio'
                              className='job-invite-transportation-alternate-input'
                            />
                            <label htmlFor='noOption'>No</label>
                          </div>
                        </fieldset>
                        {insuranceVerified === 'no' && (
                          <p className='job-invite-transportation-alternate-message'>
                            This is an on-site job and insurance policies with a combined coverage of $300,000 is required. If you do not have this coverage,
                            you are not eligible for this job.
                          </p>
                        )}
                      </InfoBox>
                    )}
                  </>
                ) : null}
              </>
            )}
          </section>

          {/* ACCEPT AND DECLINE BUTTONS */}
          <div className='job-invite-buttons-container'>
            <button
              style={
                declining
                  ? {
                      backgroundColor: '#809BB6',
                      borderColor: '#809BB6',
                    }
                  : null
              }
              onClick={() => setJobAction({ ...jobAction, declining: true, accepting: false })}
              disabled={alreadyOnStandbyList}
              className='job-invite-buttons job-invite-buttons-decline'
            >
              Decline
            </button>
            {standbyEligible ? (
              <button
                style={
                  accepting
                    ? {
                        backgroundColor: '#D0A702',
                        borderColor: '#D0A702',
                        color: 'black',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                      }
                    : null
                }
                onClick={() => handleCreateStandbyList()}
                className={`job-invite-buttons job-invite-buttons-accept`}
                disabled={isButtonClicked || !isValid}
              >
                Join Standby List
              </button>
            ) : (
              <button
                style={
                  accepting
                    ? {
                        backgroundColor: '#D0A702',
                        borderColor: '#D0A702',
                        color: 'black',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                      }
                    : null
                }
                onClick={() => setJobAction({ ...jobAction, accepting: true, declining: false })}
                className={`job-invite-buttons job-invite-buttons-accept`}
                disabled={!isValid}
              >
                Accept
              </button>
            )}
          </div>
          {/* DECLINE CONFIRMATION */}
          {declining && (
            <div className='job-invite-decline-container'>
              <div className='job-invite-decline-header'>
                <p className='descriptionText'>Are you sure you want to decline the project?</p>
                <button onClick={() => setJobAction({ ...jobAction, declining: false })} className='job-invite-decline-close-button'>
                  <img className='closeIcon' src={close} alt='Go back' />
                </button>
              </div>
              <p style={{ marginTop: '12px' }} className='labelText'>
                Please specify your reasons to decline:
              </p>
              <fieldset className='job-invite-decline-reasons'>
                <JobInviteReusableRadio required={false} register={register} formValue='declineReason' value='Unavailable on dates provided' />
                <JobInviteReusableRadio required={false} register={register} formValue='declineReason' value='Hourly rate too low' />
                <JobInviteReusableRadio
                  required={false}
                  register={register}
                  formValue='declineReason'
                  value='Don’t have the required skills, expertise, certifications'
                />
                <JobInviteReusableRadio required={false} register={register} formValue='declineReason' value='Not interested in YellowBird Jobs' />
                <JobInviteReusableRadio required={false} register={register} formValue='declineReason' value='Other' />
                {declineReason === 'Other' && (
                  <div className='inputContainerForm'>
                    <input
                      style={{ marginTop: '0px' }}
                      className='inputMultiStep'
                      autoFocus
                      {...register('declineReasonDescription')}
                      type='text'
                      placeholder='Please Specify'
                    />
                  </div>
                )}
              </fieldset>
              <button
                autoFocus
                disabled={declineReason === 'Other' && !declineReasonDescription}
                onClick={declineJob}
                className='job-invite-buttons job-invite-verify-insurance'
              >
                Yes, I want to decline this job.
              </button>
            </div>
          )}

          {/* ACCEPT CONFIRMATION */}
          {accepting && (
            <>
              <fieldset className='job-invite-confirmation-fieldset'>
                <div className='job-invite-confirmation-checkbox-group'>
                  <div>
                    <input
                      className='job-invite-confirmation-checkbox checkBoxMultiStep'
                      {...register('skillsMet', { validate: value => value === true })}
                      id='skillsMet'
                      type='checkbox'
                    />
                  </div>
                  <label className='job-invite-confirmation-label labelText' htmlFor='skillsMet'>
                    I confirm that I meet all skills, experience and certifications established in the <br /> above job invitation.
                  </label>
                </div>
                <div className='job-invite-confirmation-checkbox-group'>
                  <div>
                    <input
                      className='job-invite-confirmation-checkbox checkBoxMultiStep'
                      {...register('jobCommitment', { validate: value => value === true })}
                      id='jobCommitment'
                      type='checkbox'
                    />
                  </div>
                  <label className='job-invite-confirmation-label labelText' htmlFor='jobCommitment'>
                    I commit to completing this job as defined in the above job invitation.
                  </label>
                </div>
              </fieldset>
              <button onClick={handleSubmit(acceptJob)} autoFocus disabled={!isValid} className='job-invite-buttons job-invite-buttons-accept'>
                Yes, I accept this job.
              </button>
              <button autoFocus aria-disabled className='job-invite-hidden-button' style={{ border: 'none', background: 'none' }}></button>
            </>
          )}
        </div>
      </div>
      <Modal id='acceptedModal' show={isModalVisible} onHide={() => setIsModalVisible(false)} className='standby-custom-modal-container'>
        <div className='standby-modal-header standby-title'>
          <h2>Oh no!</h2>
        </div>
        <div className='standby-list-footer'>
          <div className='standby-modal-content standby-text'>
            <p>This job was just accepted by another YellowBird Pro</p>
            <p>
              However, you can join the Standby List to be considered if the Pro ahead of you is unable to complete this job. Please review the job details
              thoroughly before joining the Standby List. Click close to proceed to join.
            </p>
            <p>Questions? Contact operations@goyellowbird.com</p>
          </div>
          <div className='standby-button-container'>
            <button
              id='modalBtn'
              onClick={() => {
                setIsModalVisible(false);
                window.location.reload();
              }}
              className='standby-modalBtn'
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
      <Modal id='standbyModal' show={isStandbyModalVisible} onHide={() => setIsModalVisible(false)} className='standby-custom-modal-container'>
        <div className='standby-modal-header standby-title'>
          <h2>Thank you for being part of our Standby List!</h2>
        </div>
        <div className='standby-list-footer'>
          <div className='standby-modal-content standby-text'>
            Although the YellowBird Pro ahead of you is expected to fulfill the job successfully, your readiness to step in if needed is highly valued. In the
            rare event that the assigned Pro is unavailable, our customer success team will reach out to you. If you have any questions, please don't hesitate
            to contact us at <OperationsEmail />.
          </div>
          <div className='standby-button-container'>
            <button
              id='modalBtn'
              onClick={() => {
                setIsModalVisible(false);
                window.location.reload();
              }}
              className='standby-modalBtn'
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
      <Modal id='optOutModal' show={isOptOutModalVisible} onHide={() => setIsModalVisible(false)} className='standby-custom-modal-container'>
        <div className='standby-list-footer'>
          <div className='standby-modal-content standby-text'>Are you sure you would like to withdraw from the standby list for {jobName}?</div>
          <div className='standby-button-container'>
            <button
              id='modalBtn'
              onClick={() => {
                handleOptOut();
                setIsOptOutModalVisible(false);
              }}
              className='secondary-standby-modalBtn'
            >
              Yes
            </button>
            <button onClick={() => setIsOptOutModalVisible(false)} className='standby-modalBtn'>
              Cancel
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        id='confirmationModal'
        show={isConfirmationModalVisible}
        onHide={() => setIsConfirmationModalVisible(false)}
        className='standby-custom-modal-container'
      >
        <div className='confirmation-modal-content'>
          <div className='standby-modal-content standby-text'>You have successfully withdrawn from the standby list for {jobName}.</div>
          <div className='standby-button-container'>
            <button onClick={() => setIsConfirmationModalVisible(false)} className='standby-modalBtn'>
              Close
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
