import React from 'react';
import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom/client';
import { Toaster } from 'react-hot-toast';
import { Provider, TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

import './global.scss';
import './App.scss';
import 'bootstrap/dist/css/bootstrap.css';
import './index.scss';

import App from './App';
import { DNS_SENTRY } from './env';
import { RootState } from './reducers';
import configureAppStore from './utils/redux/configureStore';

// import reportWebVitals from './reportWebVitals';

const toasterOptions = {
  style: {
    padding: '18px',
    fontSize: '17px',
    minWidth: '250px',
  },
  loading: {
    duration: Infinity,
    style: {
      color: 'white',
      background: '#007bff',
    },
  },
  success: {
    duration: 4000,
    style: {
      color: 'white',
      background: '#28a745',
    },
  },
  error: {
    duration: 5000,
    style: {
      color: 'white',
      background: '#ff6a30',
    },
  },
};

Sentry.init({
  dsn: DNS_SENTRY,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: 0.05, // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', 'https://app.goyellowbird.com/', 'https://dev.goyellowbird.com/'],
  replaysSessionSampleRate: 0.1, // Capture session replays based on your preference.
  replaysOnErrorSampleRate: 1, // Capture session replays for all sessions that encounter an error.
});

const store = configureAppStore();

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
      <Toaster position='top-center' toastOptions={toasterOptions} />
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
