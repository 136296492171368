import { useMemo } from 'react';

type List = {
  id: number;
  label?: string;
  nameOfIndustries?: string;
  name?: string;
  cardType?: string;
};

type Props = {
  category: number[];
  list: List[];
  label: string;
};

export default function EditProfileItemsSelected({ category, list, label }: Props) {
  const items = useMemo(() => {
    return category?.map(id => {
      const listItem = list?.find(it => it.id === id);
      return listItem ? listItem.label || listItem.name || listItem.nameOfIndustries || listItem.cardType : null;
    });
  }, [category, list]);

  return (
    <div>
      <h1 className='text-base text-slate-500 font-semibold'>{label}</h1>
      <div className='flex flex-wrap gap-2'>
        {items?.map(it => {
          return <span className='border-1 border-gray-300 bg-gray-100 text-start rounded-xl px-3 text-black'>{it}</span>;
        })}
      </div>
    </div>
  );
}
