import React, { useEffect, useRef, useState } from 'react';

import './CircularProgressBar.scss';

const CircularProgressBar = ({ size = 180, progress = 0, strokeWidth = 2, circleOneStroke = '#90acbc', circleTwoStroke = '#ffce07' }) => {
  progress = progress < 0 ? 0 : progress > 100 ? 100 : progress;

  const [offset, setOffset] = useState(0);

  const circleRef = useRef(null);

  // progress circle
  const progressRadius = size / 2;
  const progressCircumference = 2 * Math.PI * progressRadius;
  const progressShadowSpread = 3;
  const progressStrokeWidth = size / 12.86;

  // common variables
  const center = (size + progressStrokeWidth / 2 + progressShadowSpread) / 2;

  // background circle
  const radius = size / 2 - strokeWidth / 2;

  useEffect(() => {
    const progressOffset = ((100 - progress) / 100) * progressCircumference - size / 20;
    setOffset(progressOffset);

    circleRef.current.style = 'transition: stroke-dashoffset 850ms ease-in-out';
  }, [setOffset, progress, progressCircumference, offset, size]);

  return (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        className='circular-progress__svg'
        width={size}
        height={size / 1.1}
        viewBox={`0 0 ${size + progressStrokeWidth / 2 + progressShadowSpread} ${size + progressStrokeWidth / 2 + progressShadowSpread}`}
      >
        <ellipse
          className='circular-progress__circle-bg'
          stroke={circleOneStroke}
          cx={center}
          cy={center}
          rx={radius}
          style={{ fill: 'white' }}
          ry={radius / 1.1}
          strokeWidth={strokeWidth}
        />
        <ellipse
          className='circular-progress__circle'
          ref={circleRef}
          stroke={circleTwoStroke}
          cx={center}
          cy={center}
          ry={progressRadius}
          rx={progressRadius / 1.1}
          strokeWidth={progressStrokeWidth}
          strokeDasharray={2 * Math.PI * (size / 2 / 1.04)}
          strokeDashoffset={offset}
          strokeLinecap='round'
          transform={`rotate(270, ${center}, ${center})`}
        />
        <text x={`${center}`} y={`${center - size / 45}`} fontSize={`${size / 82.57}rem`} className='circular-progress__progress-text'>
          {progress}%
        </text>
        <text x={`${center}`} y={`${center + size / 7.5}`} fontSize={`${size / 169.81}rem`} className='circular-progress__completed-text'>
          Completed
        </text>
        <filter id='circle-bg__shadow' colorInterpolationFilters='sRGB'>
          <feDropShadow dx='0' dy='2' stdDeviation='2' floodOpacity='0.2' />
        </filter>
        <filter id='circle__shadow' colorInterpolationFilters='sRGB'>
          <feDropShadow dx='0' dy='0' stdDeviation={progressShadowSpread} floodOpacity='0.15' />
        </filter>
      </svg>
    </>
  );
};

export default CircularProgressBar;
