import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import {  useSelector } from 'react-redux';

import Modal from '../../components/Modal';
import OnboardingContent from './OnboardingContent';
import OnboardingHeading from './OnboardingHeading';

import './OnboardingWidget.scss';

const OnboardingWidget = ({ show, handleClose }) => {
  const state = useSelector(state => state);
  const [onboardingProgress, setOnboardingProgress] = React.useState(0);
  const { data: onboardingData } = state.onboarding;
  const onboarding = onboardingData.filter(
    step => step.name === 'Sign up for Orientation' || step.name === 'Complete Background Check' 
  );

  React.useEffect(() => {
    if (onboarding && onboarding.length > 0) {
      const progressValues = [0, 25, 50, 20, 30];
      const calcProgress = progressValues[onboarding[0].status] + progressValues[onboarding[1].status]
      setOnboardingProgress(calcProgress);
    }
  }, [onboarding]);

  const containerVariants = {
    hidden: {
      opacity: 0,
      x: '20vw',
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: { type: 'spring', delay: 0.2 },
    },
    exit: {
      x: '-100vh',
      transition: { type: 'spring', delay: 0.5, ease: 'easeInOut' },
    },
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        handleClose();
      }}
      centered
    >
      <AnimatePresence>
          <motion.div variants={containerVariants} initial='hidden' animate='visible' exit='exit'>
            <OnboardingHeading onboardingProgress={onboardingProgress} />
            <hr className='mx-1 mx-sm-4 onboarding-widget__horizontal' />
            <OnboardingContent onboarding={onboarding} />
          </motion.div>
      </AnimatePresence>
    </Modal>
  );
};

export default OnboardingWidget;
