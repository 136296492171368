import React, { useState } from 'react';
import { Plus, X } from 'lucide-react';
import { Col, Row } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import TextareaAutosize from 'react-textarea-autosize';

import PDF from '../../../assets/Yellowbird-Job-Order-Agreement.pdf';
import Button from '../../../components/Button';
import Dropdown from '../../../components/Dropdown';
import Loading from '../../../components/Loading';
import Modal from '../../../components/Modal';
import NewButton from '../../../components/NewButton';
import PaymentMethod from '../../../components/OldPaymentMethod';
import ShadowCard from '../../../components/ShadowCard';
import Spacer from '../../../components/Spacer';
import Text from '../../../components/Text';
import TextInput from '../../../components/TextInput';
import { c } from '../../../constants/colors';
import { CONTACT_US_1800_NUMBER } from '../../../env';
import { formatToISODate, getTodayISODate, roundDateToMidnight } from '../../../helpers';
import { stateOptions } from '../../../lib/options';
import { updateJobDetails } from '../../../slices/job.slice';

const MAX_TIME_WINDOW_OPTIONS = 15;

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  topRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  row: {
    display: 'flex',
    borderBottomStyle: 'solid',
  },
  fullRow: {
    display: 'flex',
    borderBottomStyle: 'solid',
    justifyContent: 'space-between',
    borderBottomColor: c.lightGrey,
    borderBottomWidth: 1,
    marginBottom: 4,
  },
  footnoteContainer: {
    borderStyle: 'solid',
    borderWidth: 2,
    borderColor: c.lightGrey,
    borderRadius: 30,
    padding: 16,
  },
};

export default function JobOrder({ responseToJobOrder, paymentMethods, paymentLoading }) {
  const dispatch = useDispatch();
  const job = useSelector(state => state.job.jobOrder);
  const [loading, setLoading] = useState(false);
  const [showReason, setShowReason] = useState(false);
  const [reasonToDecline, setReasonToDecline] = useState('');
  const [initialValues] = useState({
    jobId: job.id,
    startDate: job.startDate,
    deadline: job.deadline,
    siteAddress: job.siteAddress,
    siteCity: job.siteCity,
    siteState: job.siteState,
    siteZip: job.siteZip,
    timeWindows: job.timeWindows || [],
    totalHours: job.totalHours,
    schedulingNotes: job.schedulingNotes || '',
  });
  const [values, setValues] = useState(initialValues);
  const [changesMade, setChangesMade] = useState(false);

  async function handleResponseToJobOrder(response, reason) {
    setLoading(true);
    toast.promise(responseToJobOrder(job.id, response, reason), {
      loading: 'Submitting...',
      success: () => {
        setLoading(false);
        return `Job ${response === 'accepted' ? 'Approved' : 'Declined'}!`;
      },
      error: () => {
        setLoading(false);
        return 'Something went wrong. Please try again later';
      },
    });
  }

  async function handleUpdateJobDetails() {
    if (!values.startDate) return toast.error('Start Date is required');
    if (!values.totalHours) return toast.error('Total Hours is required');
    if (!values.deadline && values.timeWindows.some(timeWindow => !timeWindow.start)) return toast.error('All Time Windows must have a date');

    toast.promise(dispatch(updateJobDetails(values)).unwrap(), {
      loading: 'Saving Changes...',
      success: () => {
        setLoading(false);
        setChangesMade(false);
        return 'Changes Saved!';
      },
      error: () => {
        setLoading(false);
        return 'Something went wrong. Please try again later';
      },
    });
  }

  async function handleSaveOrApprove() {
    if (changesMade) {
      await handleUpdateJobDetails();
    } else {
      await handleResponseToJobOrder('accepted');
    }
  }

  function handleUndoChangesOrShowReason() {
    if (changesMade) {
      handleUndoChangesJobDetails();
    } else {
      setShowReason(true);
    }
  }

  const handleUndoChangesJobDetails = () => {
    setValues(initialValues);
    setChangesMade(false);
    toast.success('Changes Undone');
  };

  const DetailRow = ({ title, detail }) => {
    const styles = {
      container: {
        display: 'flex',
        flexDirection: 'column',
        borderBottomStyle: 'solid',
        borderBottomColor: c.lightGrey,
        maxWidth: 480,
        marginBottom: 24,
      },
    };

    return (
      <div style={styles.container}>
        <Text t='mediumBoldBlack'>{title}</Text>
        <Text>{detail}</Text>
      </div>
    );
  };

  const DetailMultiRow = ({ title, details }) => {
    const styles = {
      container: {
        display: 'flex',
        flexDirection: 'column',
        borderBottomStyle: 'solid',
        borderBottomColor: c.lightGrey,
        maxWidth: 480,
        marginBottom: 24,
      },
    };

    return (
      <div style={styles.container}>
        <Text t='mediumBoldBlack'>{title}</Text>
        {details.map(detail => {
          return <Text key={detail}>{detail}</Text>;
        })}
      </div>
    );
  };

  // Function to handle adding new date inputs, up to a maximum of 5
  function addDateInput() {
    if (values.timeWindows.length < MAX_TIME_WINDOW_OPTIONS) {
      setChangesMade(true);
      setValues(prev => ({ ...prev, timeWindows: [...prev.timeWindows, { id: new Date().getTime(), start: '' }] }));
    } else {
      toast.error(`You can add up to ${MAX_TIME_WINDOW_OPTIONS} Time Windows`);
    }
  }

  // Function to remove a time window
  function removeDateInput(timeWindowId) {
    setChangesMade(true);
    setValues(prev => ({ ...prev, timeWindows: prev.timeWindows.filter(timeWindow => timeWindow.id !== timeWindowId) }));
  }

  function handleTimeWindowChange(e, id) {
    const normalizedStartDate = roundDateToMidnight(values.startDate);

    const existingDate = values.timeWindows.some(timeWindow => {
      const normalizedTimeWindowStart = roundDateToMidnight(timeWindow.start);
      const normalizedValue = roundDateToMidnight(e.target.value);

      // Check if the time window start is the same as new value or startDate
      return normalizedTimeWindowStart.getTime() === normalizedValue.getTime() || normalizedValue.getTime() === normalizedStartDate.getTime();
    });

    if (existingDate) {
      return toast.error('Time window with this date already exists! Please choose another date');
    }
    setChangesMade(true);
    setValues({
      ...values,
      timeWindows: values.timeWindows.map(timeWindow => (timeWindow.id === id ? { ...timeWindow, start: e.target.value } : timeWindow)),
    });
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setChangesMade(true);
    if (name === 'deadline' || name === 'startDate') {
      setValues({ ...values, [name]: new Date(value) });
    } else {
      setValues({ ...values, [name]: value });
    }
  }

  if (!job || !values.startDate) return <Loading />;

  return (
    <div style={styles.container}>
      <div className='mb-4' style={styles.topRow}>
        <Text t='bigBlack'>Job Order / {`${job.service}${job.typeOfService ? '/' + job.typeOfService : ''}`}</Text>
        <Text blueHeading>{'#' + job.referenceNumber}</Text>
      </div>
      <Text t='boldHeading'>Details</Text>
      <Row>
        <Col md={6} style={{ marginBottom: '2rem' }}>
          <div style={{ ...styles.fullRow, flexDirection: 'column', width: 'fit-content' }}>
            <TextInput
              name='startDate'
              className='date-picker'
              style={{ color: '#586F7C' }}
              value={formatToISODate(values.startDate)}
              label='Start Date'
              type='date'
              min={getTodayISODate()}
              onChange={handleChange}
              overrideContainerStyle={{ marginTop: 0 }}
            />
          </div>
          {values.deadline ? (
            <div style={{ ...styles.fullRow, flexDirection: 'column', width: 'fit-content' }}>
              <TextInput
                name='deadline'
                className='date-picker'
                style={{ color: '#586F7C' }}
                value={formatToISODate(values.deadline)}
                label='End Date'
                type='date'
                min={formatToISODate(values.startDate) || getTodayISODate()}
                onChange={handleChange}
                overrideContainerStyle={{ marginTop: '1em' }}
              />
            </div>
          ) : null}
          {!values.deadline ? (
            <div>
              {values.timeWindows.length > 0 && (
                <>
                  <Spacer h={24} />
                  <label style={{ marginBottom: '0' }} className='text-input__label'>
                    Time Windows (up to {MAX_TIME_WINDOW_OPTIONS})
                  </label>
                  {values.timeWindows.map((timeWindow, index) => (
                    <div key={timeWindow.id} style={{ marginBottom: '0' }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <TextInput
                          className='date-picker'
                          style={{ color: '#586F7C', marginRight: '1em' }}
                          value={formatToISODate(timeWindow.start)}
                          type='date'
                          min={getTodayISODate()}
                          onChange={e => handleTimeWindowChange(e, timeWindow.id)}
                          overrideContainerStyle={{ marginTop: '1em' }}
                          disabled={loading}
                        />
                        <Button
                          type='button'
                          className='btn transparent-btn'
                          onClick={() => removeDateInput(timeWindow.id)}
                          disabled={loading}
                          style={{ marginTop: '0.1em' }}
                        >
                          <X size={18} />
                        </Button>
                      </div>
                    </div>
                  ))}
                </>
              )}
              <Button
                type='button'
                className='btn primary-btn mt-4'
                style={{ fontWeight: 600, display: 'flex', alignItems: 'center' }}
                onClick={addDateInput}
                disabled={loading || values.timeWindows.length >= MAX_TIME_WINDOW_OPTIONS}
              >
                <Plus size={18} style={{ marginRight: '8px' }} />
                Add Time Window
              </Button>
            </div>
          ) : null}
        </Col>
        <Col md={6}>
          <div>
            <label htmlFor='totalHours' className='text-input__label'>
              Total Hours
            </label>
            <TextInput
              id='totalHours'
              value={values.totalHours}
              name='totalHours'
              placeholder='Enter Job Total Hours'
              onChange={handleChange}
              overrideContainerStyle={{ marginBottom: '1.4em', marginTop: 0 }}
            />
          </div>
          <div style={{ width: '100%' }}>
            <label htmlFor='schedulingNotes' className='text-input__label'>
              Scheduling Notes
            </label>
            <TextareaAutosize
              id='schedulingNotes'
              name='schedulingNotes'
              onChange={handleChange}
              value={values.schedulingNotes}
              className='form-control'
              minRows={8}
              style={{ marginBottom: '2rem' }}
            />
          </div>
        </Col>
      </Row>
      <Spacer h={30} />
      <div style={styles.fullRow}>
        <Text>Job Type/Service</Text>
        <Text>{`${job.service}${job.typeOfService ? '/' + job.typeOfService : ''}`}</Text>
      </div>
      <Spacer h={48} />

      {job.orderStatus === 'pending' ? (
        paymentLoading ? (
          <Loading />
        ) : (
          <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
            <NewButton onClick={handleUndoChangesOrShowReason} h={48} white>
              <Text>{changesMade ? 'Undo Changes' : 'Reject Job'}</Text>
            </NewButton>
            <Spacer w={48} />
            <NewButton onClick={handleSaveOrApprove} h={48} main>
              <Text>{changesMade ? 'Save Changes' : 'Approve Job'}</Text>
            </NewButton>
          </div>
        )
      ) : (
        <div style={{ textAlign: 'center' }}>
          <Text>You've {job.orderStatus} this job order</Text>
        </div>
      )}

      <Spacer h={48} />
      <div
        style={{
          display: 'grid',
          gap: '2rem',
          gridTemplateColumns: 'repeat(auto-fill, minmax(400px, 1fr))',
        }}
      >
        {/* LeftSide */}
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex' }}>
            <ShadowCard title={`${job.priceType} Rate`} detail={`$${(job.coHourlyRateCents / 100).toLocaleString('en-us')}`} />
            <Spacer w={16} />
            <ShadowCard title='Total Hours' detail={job.totalHours} />
          </div>
          <div>
            <ShadowCard title='The Total Amount Is' detail={`$${(job.estimatedTotalCents / 100).toLocaleString('en-us')}`} />
          </div>
          <Spacer h={24} />
          <div style={styles.footnoteContainer}>
            <Text t='red'>Note!</Text>
            <Text>
              This is the base price for this job, This price does not include additional expenses for per diem or mileage reimbursement, or any other
              additional compensation required to fill this job.
            </Text>
            <Spacer h={8} />
            <Text>
              Premium Pay will be paid out at 1.5x the hourly rate for any worked hours exceeding 8 in a day and 40 in a week. Per diem will be based on the GSA
              set rates by state.
              <a href='https://www.gsa.gov/travel/plan-book/per-diem-rates?gsaredirect=perdiem' target='_blank' rel='noreferrer noopener'>
                <Text>Per Diem Rates</Text>
              </a>
            </Text>
            <Spacer h={8} />
            <Text>
              We provide 48 hours from the time of Pro marking the job as complete, to allow for review of deliverables and work. Please accept or reject within
              48 hours. If no response is recieved, the Pro will be paid 48 hours from the time of submission.
            </Text>
          </div>
          <div style={{ textAlign: 'center', marginTop: 24 }}>
            {job.gigStaffing ? (
              <a style={{ textDecoration: 'none' }} href={PDF} target='_blank' rel='noreferrer'>
                <Text>View YellowBird Job Order Agreement</Text>
              </a>
            ) : null}
          </div>
          <Spacer h={24} />
        </div>

        {/* Right Side */}
        <div>
          <DetailRow title='Job Details' detail={job.typeOfWork} />
          {job.commuteType === 'on_site' ? (
            <>
              <DetailRow title='Site Address (This is an On Site Job)' />
              <TextInput
                value={values.siteAddress}
                name='siteAddress'
                placeholder='Enter Your Street Address'
                onChange={handleChange}
                overrideContainerStyle={{ marginBottom: '1.4em' }}
              />
              <TextInput
                value={values.siteCity}
                name='siteCity'
                placeholder='Enter Your City'
                onChange={handleChange}
                overrideContainerStyle={{ marginBottom: '1.4em' }}
              />
              <Dropdown
                id='siteState'
                name='siteState'
                options={stateOptions}
                value={values.siteState}
                onChange={handleChange}
                overrideContainerStyle={{ marginTop: '30px', marginBottom: '1.7em' }}
              />
              <TextInput
                value={values.siteZip}
                name='siteZip'
                placeholder='Enter Your Postal Code'
                onChange={handleChange}
                overrideContainerStyle={{ marginBottom: '1.4em' }}
              />
            </>
          ) : job.commuteType === 'hybrid' ? (
            <>
              <DetailRow
                title='Site Address (This is a Hybrid Job)'
                detail={`${job.siteAddress} ${job.siteCity.charAt(0).toUpperCase() + job.siteCity.substr(1)}, ${job.siteState} ${job.siteZip}`}
              />
              <DetailRow title='Virtual Job Details' detail={job.commuteDetails} />
            </>
          ) : (
            <DetailRow title='Virtual Job Details (This is a Virtual Job)' detail={job.commuteDetails} />
          )}
          {job.schedule && <DetailRow title='Schedule' detail={job.schedule} />}
          {job.reportingDetails && <DetailRow title='Reports/Deliverables' detail={job.reportingDetails} />}
          {job.requiredSkills && <DetailRow title='Required Skills' detail={job.requiredSkills} />}
          {job.ehsExperience && <DetailRow title='Preferred Years of Experience' detail={job.ehsExperience} />}
          {job.industryExperience && <DetailRow title='Preferred Industry Experience' detail={job.industryExperience} />}
          {job.languageRequired && <DetailRow title='Language Required' detail={job.languageRequired} />}
          {job.deliverableType && <DetailRow title='Deliverable Type Required' detail={job.deliverableType} />}
          {job.otherRequirements && <DetailRow title='Additional Requirements' detail={job.otherRequirements} />}

          <DetailMultiRow
            title='Point of Contact'
            details={[
              `${job.pocFirstName} ${job.pocLastName}`,
              `${job.pocEmail}`,
              `${job.pocPhone}`,
              `Preferred Contact Method: ${job?.pocContactMethod?.charAt(0).toUpperCase() + job.pocContactMethod?.substr(1)}`,
            ]}
          />
          {job.overtime && <DetailRow title='Premium Pay' detail={job.overtime} />}
          {job.perDiem && <DetailRow title='Per Diem' detail={job.perDiem} />}
          {job.mileageReimbursement && <DetailRow title='Mileage Reimbursement' detail={job.mileageReimbursement} />}
          {job.promo && <DetailRow title='Promo' detail={job.promo} />}
          {job.paymentMethodSelection && <DetailRow title='Payment Method' detail={job.paymentMethodSelection} />}
          {job.paymentMethodSelection && paymentMethods ? (
            job.paymentMethodSelection === 'Card' ? (
              <PaymentMethod data={paymentMethods.find(x => x.id === job.stripePaymentMethod)} onClick={() => console.log('clicked')} />
            ) : null
          ) : (
            job.paymentMethodSelection === 'Card' && 'Login to view credit card details'
          )}
          <div style={{ textAlign: 'center' }}>
            <Spacer h={24} />
            <Text>Still Have Questions?</Text>
            <Text>Email YellowBird at sales@goyellowbird.com or call {CONTACT_US_1800_NUMBER}</Text>
          </div>
          <Spacer h={24} />
        </div>
      </div>
      <Modal onHide={() => setShowReason(false)} show={showReason}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Text heading>Reason to decline?</Text>
          <Spacer h={24} />
          <div style={{ width: '100%' }}>
            <TextInput placeholder={'Enter your reason'} onChange={e => setReasonToDecline(e.target.value)} value={reasonToDecline}></TextInput>
          </div>
          <Spacer h={24} />
          <NewButton onClick={() => handleResponseToJobOrder('declined', reasonToDecline)} main>
            Submit
          </NewButton>
          <Spacer h={24} />
        </div>
      </Modal>
    </div>
  );
}
