import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';

import api from '../api';
import apiCamelback from '../api/apiCamelback';
import { Todo } from '../types';

export const createWorker = createAsyncThunk<
  Todo, // The type of the return value of the payload creator
  Todo, // The type of the first argument to the payload creator
  {
    rejectValue: Todo; // The type for `thunkApi.rejectWithValue`, if error handling is customized
  }
>('worker/createWorker', async ({ basicDetails, personalInformation, professionalInformation }, thunkApi) => {
  try {
    const { otherEhsCredentials, ehsExperience, professionalCertifications, workedIndustries } = professionalInformation;
    let body = { ...basicDetails, ...personalInformation, ...otherEhsCredentials, ...professionalCertifications, ehsExperience, workedIndustries };
    let decamelized = decamelizeKeys(body);
    const response = await api.post('/api/v1/workers', decamelized);
    if (response.data.errors) {
      return thunkApi.rejectWithValue(response.data.errors);
    }
    return response.data;
  } catch (error: unknown) {
    // Handle errors in axios request (includes HTTP status code errors)
    if (axios.isAxiosError(error) && error.response) {
      // If the error response is available and contains the response data
      return thunkApi.rejectWithValue(error.response.data?.message || 'Unknown server error');
    } else {
      // If the error is not from axios (not an AxiosError)
      console.error('An unknown error occurred', error);
      return thunkApi.rejectWithValue('An unknown error occurred');
    }
  }
});

export const getResume = createAsyncThunk<
  Todo, // The type of the return value of the payload creator
  Todo, // The type of the first argument to the payload creator
  {
    rejectValue: Todo; // The type for `thunkApi.rejectWithValue`, if error handling is customized
  }
>('worker/uploadResume', async ({ documentId }, thunkApi) => {
  try {
    const response = await apiCamelback.get(`/api/v1/workers/autofill-for-document/${documentId}`);
    if (response.data.errors) {
      return thunkApi.rejectWithValue(response.data.errors);
    }
    return response.data;
  } catch (error: unknown) {
    // Handle errors in axios request (includes HTTP status code errors)
    if (axios.isAxiosError(error) && error.response) {
      // If the error response is available and contains the response data
      return thunkApi.rejectWithValue(error.response.data?.message || 'Unknown server error');
    } else {
      // If the error is not from axios (not an AxiosError)
      console.error('An unknown error occurred', error);
      return thunkApi.rejectWithValue('An unknown error occurred');
    }
  }
});

export const fetchWorkerById = createAsyncThunk<
  Todo, // The type of the return value of the payload creator
  Todo, // The type of the first argument to the payload creator
  {
    rejectValue: Todo; // The type for `thunkApi.rejectWithValue`, if error handling is customized
  }
>('worker/fetchWorkerById', async (workerId, thunkApi) => {
  try {
    const response = await apiCamelback.get(`/api/v1/workers/${workerId}`);
    const camelized = camelizeKeys(response.data);
    if (response.data?.errors) {
      return thunkApi.rejectWithValue(response.data.errors);
    }
    return camelized;
  } catch (error: unknown) {
    // Handle errors in axios request (includes HTTP status code errors)
    if (axios.isAxiosError(error) && error.response) {
      // If the error response is available and contains the response data
      return thunkApi.rejectWithValue(error.response.data?.message || 'Unknown server error');
    } else {
      // If the error is not from axios (not an AxiosError)
      console.error('An unknown error occurred', error);
      return thunkApi.rejectWithValue('An unknown error occurred');
    }
  }
});

// const originalEducationLength = localWorker.education.length;

export const updateWorkerById = createAsyncThunk<
  Todo, // The type of the return value of the payload creator
  Todo, // The type of the first argument to the payload creator
  {
    rejectValue: Todo; // The type for `thunkApi.rejectWithValue`, if error handling is customized
  }
>('worker/updateWorkerById', async ({ workerId, updates }, thunkApi) => {
  let updateUserResponse: AxiosResponse<Todo>;
  try {
    const decamelized = decamelizeKeys(updates);
    updateUserResponse = await apiCamelback.put(`/api/v1/workers/${workerId}`, decamelized);

    if (updateUserResponse.data.errors) {
      return thunkApi.rejectWithValue(updateUserResponse.data.errors);
    }
  } catch (error: unknown) {
    // Handle errors in axios request (includes HTTP status code errors)
    if (axios.isAxiosError(error) && error.response) {
      // If the error response is available and contains the response data
      return thunkApi.rejectWithValue(error.response.data?.message || 'Unknown server error');
    } else {
      // If the error is not from axios (not an AxiosError)
      console.error('An unknown error occurred', error);
      return thunkApi.rejectWithValue('An unknown error occurred');
    }
  }

  try {
    // Calling Camelback API to index new job in Elastic Search
    await apiCamelback.post(`api/v1/elasticsearch/worker/${updateUserResponse.data.id}/update`);
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error.response) {
      console.error({ message: error?.response?.data?.message || error.message, error });
    } else {
      console.error('An unknown error occurred', error);
    }
  }
  let camelized = camelizeKeys(updateUserResponse.data);
  return camelized;
});

export const signOutUser = createAsyncThunk('auth/signout', async (_, thunkApi) => {
  return {};
});

export const getWorkerPaymentMethods = createAsyncThunk<
  Todo, // The type of the return value of the payload creator
  void, // The type of the first argument to the payload creator
  {
    rejectValue: Todo; // The type for `thunkApi.rejectWithValue`, if error handling is customized
  }
>('company/getWorkerPaymentMethods', async (_, thunkApi) => {
  try {
    const response = await apiCamelback.get('api/v1/stripe/worker_payment_methods?limit=100');
    if (response.data.errors) {
      return thunkApi.rejectWithValue(response.data.errors);
    }
    return response.data;
  } catch (error: unknown) {
    // Handle errors in axios request (includes HTTP status code errors)
    if (axios.isAxiosError(error) && error.response) {
      // If the error response is available and contains the response data
      return thunkApi.rejectWithValue(error.response.data?.message || 'Unknown server error');
    } else {
      // If the error is not from axios (not an AxiosError)
      console.error('An unknown error occurred', error);
      return thunkApi.rejectWithValue('An unknown error occurred');
    }
  }
});

interface WorkerState {
  error: Todo;
  isLoading: boolean;
  worker: Todo;
  originalEducationLength: number;
  paymentMethods: Todo[];
}

const initialState: WorkerState = {
  error: null,
  isLoading: false,
  worker: {},
  originalEducationLength: 0,
  paymentMethods: [],
};

export const workerSlice = createSlice({
  name: 'worker',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchWorkerById.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchWorkerById.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(fetchWorkerById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.worker = action.payload;
        const originalEducation = state.worker?.education || [];
        state.originalEducationLength = originalEducation.length;
      })
      .addCase(updateWorkerById.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateWorkerById.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(updateWorkerById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.worker = action.payload;
      })
      .addCase(createWorker.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createWorker.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(createWorker.fulfilled, (state, action) => {
        state.isLoading = false;
        state.worker = action.payload;
      })
      .addCase(signOutUser.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(signOutUser.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(signOutUser.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getWorkerPaymentMethods.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getWorkerPaymentMethods.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getWorkerPaymentMethods.fulfilled, (state, action) => {
        state.isLoading = false;
        state.paymentMethods = action.payload?.data || [];
      });
  },
});
