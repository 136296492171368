export const defaultValues = {
  accountType: '',
  firstName: '',
  lastName: '',
  emailAddress: '',
  password: '',
  jobTitle: '',
  workNumber: '',
  mobileNumber: '',
  organizationName: '',
  organizationSize: '',
  industry: '',
  otherIndustry: '',
  otp: '',
  referral: {
    value: '',
    code: '',
    by: '',
  },
  types: {
    trainings: false,
    assesmentsOrSurveys: false,
    inspections: false,
    safetyManagamentOrConsulty: false,
    environmentalServices: false,
    policyDevelopment: false,
    buildASafetyProgram: false,
    offerOrSaleSafetyServicesToMyClients: false,
    other: false,
    otherDescription: '',
  },
};
