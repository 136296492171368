import { useContext } from 'react';
import ProgressBar from '@ramonak/react-progress-bar';

import birdLogoGif from '../../../assets/icons/yellobirdLogo.gif';
import { MethodsContext } from '../context';

import './header.css';

const Header = () => {
  const { percentage } = useContext(MethodsContext);

  return (
    <div className='headerContainer'>
      <div className='progressDiv'>
        <ProgressBar
          completed={percentage}
          isLabelVisible={false}
          transitionDuration='0.2s'
          bgColor='#FFCD03'
          height='0.4rem'
          baseBgColor='#66798C'
          borderRadius='0px'
        />
      </div>
      <div className='circleDivLogo'>
        <img className='circlebirdlogo' src={birdLogoGif} alt='' />
      </div>
    </div>
  );
};

export default Header;
