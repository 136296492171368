import React from 'react';

import { c } from '../../constants/colors';

const HorizontalRule = () => {
  const style = {
    backgroundColor: c.darkGrey,
    height: 0.5,
  };
  return <hr style={style} />;
};

export default HorizontalRule;
