import React from 'react';
import { ChevronRight } from 'lucide-react';
import { Link } from 'react-router-dom';

import './CoAvailableServicesList.scss';

const services = [
  { to: '/co-available-service/1', text: 'Safety Manager' },
  { to: '/co-available-service/6', text: 'OSHA Mock Audit' },
  { to: '/co-available-service/7', text: 'Loss Control Audit' },
  { to: '/co-available-service/3', text: 'Policy Development' },
  { to: '/co-available-service/10', text: 'Forklift Training' },
];

const CoAvailableServicesList = () => {
  return (
    <>
      <div className='available-services-list-container'>
        <div className='available-services-list-div-title'>
          <p className='available-services-list-title'>Choose from these top services to jumpstart your job post:</p>
        </div>
        <div className='available-services-list-list'>
          {services.map((service, index) => (
            <div key={index} className='flex dashboard-component-animation'>
              <ChevronRight color='#FFCD00' />
              <Link to={service.to}>{service.text}</Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default CoAvailableServicesList;
