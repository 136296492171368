// use it like this to get the main button design
//<Button className={`button primary-btn mt-3 py-2 justify-content-center ${width >= 768 ? 'main-button-desktop mx-5' : 'main-button-mobile mx-2'}`}>Save Changes</Button>
import React from 'react';

import './button.scss';

const Button = ({ type = 'button', children, ...props }) => {
  return (
    <button type={type} {...props}>
      {children}
    </button>
  );
};

export default Button;
