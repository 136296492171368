export const languages = [
  { id: 1, label: 'Spanish' },
  { id: 2, label: 'French' },
  { id: 3, label: 'Mandarin' },
  { id: 4, label: 'Korean' },
  { id: 5, label: 'Japanese' },
  { id: 6, label: 'Arabic' },
  { id: 7, label: 'Hindi' },
];

export const numOfEmployeesRanges = [
  { id: 0, label: '1-50', value: '1-50' },
  { id: 1, label: '51-100', value: '51-100' },
  { id: 2, label: '101-1000', value: '101-1000' },
];

export const contactMethodOptions = [
  { id: 0, label: 'Phone Call', value: 'phone' },
  { id: 1, label: 'Text Message', value: 'txt' },
  { id: 2, label: 'Email', value: 'email' },
];

export const referralSourceOptions = [
  {
    label: 'I have a Referral Code',
    value: 'referralCode',
  },
  {
    label: 'Name of Referrer',
    value: 'referralPerson',
  },
  {
    label: 'Google Search',
    value: 'google',
  },
  {
    label: 'Facebook',
    value: 'facebook',
  },
  {
    label: 'LinkedIn',
    value: 'linkedin',
  },
  {
    label: 'YellowBird Article or Blog',
    value: 'articleOrBlog',
  },
  {
    label: 'Event or Webinar',
    value: 'eventOrWebinar',
  },
  {
    label: 'Heard about YellowBird on a Podcast',
    value: 'podcast',
  },
  {
    label: 'Clicked an Ad or Sponsored Link',
    value: 'adOrLink',
  },
];

export const deliverableTypeOptions = [
  {
    label: 'Report',
    value: 'Report',
  },
  {
    label: 'Training Content',
    value: 'Training Content',
  },
  {
    label: 'Program/Policy',
    value: 'Program/Policy',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

export const stateOptions = [
  { value: 'AL', label: 'AL', name: 'ALABAMA' },
  { value: 'AK', label: 'AK', name: 'ALASKA' },
  { value: 'AZ', label: 'AZ', name: 'ARIZONA' },
  { value: 'AR', label: 'AR', name: 'ARKANSAS' },
  { value: 'CA', label: 'CA', name: 'CALIFORNIA' },
  { value: 'CO', label: 'CO', name: 'COLORADO' },
  { value: 'CT', label: 'CT', name: 'CONNECTICUT' },
  { value: 'DE', label: 'DE', name: 'DELAWARE' },
  { value: 'DC', label: 'DC', name: 'DISTRICT OF COLUMBIA' },
  { value: 'FL', label: 'FL', name: 'FLORIDA' },
  { value: 'GA', label: 'GA', name: 'GEORGIA' },
  { value: 'HI', label: 'HI', name: 'HAWAII' },
  { value: 'ID', label: 'ID', name: 'IDAHO' },
  { value: 'IL', label: 'IL', name: 'ILLINOIS' },
  { value: 'IN', label: 'IN', name: 'INDIANA' },
  { value: 'IA', label: 'IA', name: 'IOWA' },
  { value: 'KS', label: 'KS', name: 'KANSAS' },
  { value: 'KY', label: 'KY', name: 'KENTUCKY' },
  { value: 'LA', label: 'LA', name: 'LOUISIANA' },
  { value: 'ME', label: 'ME', name: 'MAINE' },
  { value: 'MD', label: 'MD', name: 'MARYLAND' },
  { value: 'MA', label: 'MA', name: 'MASSACHUSETTS' },
  { value: 'MI', label: 'MI', name: 'MICHIGAN' },
  { value: 'MN', label: 'MN', name: 'MINNESOTA' },
  { value: 'MS', label: 'MS', name: 'MISSISSIPPI' },
  { value: 'MO', label: 'MO', name: 'MISSOURI' },
  { value: 'MT', label: 'MT', name: 'MONTANA' },
  { value: 'NE', label: 'NE', name: 'NEBRASKA' },
  { value: 'NV', label: 'NV', name: 'NEVADA' },
  { value: 'NH', label: 'NH', name: 'NEW HAMPSHIRE' },
  { value: 'NJ', label: 'NJ', name: 'NEW JERSEY' },
  { value: 'NM', label: 'NM', name: 'NEW MEXICO' },
  { value: 'NY', label: 'NY', name: 'NEW YORK' },
  { value: 'NC', label: 'NC', name: 'NORTH CAROLINA' },
  { value: 'ND', label: 'ND', name: 'NORTH DAKOTA' },
  { value: 'OH', label: 'OH', name: 'OHIO' },
  { value: 'OK', label: 'OK', name: 'OKLAHOMA' },
  { value: 'OR', label: 'OR', name: 'OREGON' },
  { value: 'PA', label: 'PA', name: 'PENNSYLVANIA' },
  { value: 'PR', label: 'PR', name: 'PUERTO RICO' },
  { value: 'RI', label: 'RI', name: 'RHODE ISLAND' },
  { value: 'SC', label: 'SC', name: 'SOUTH CAROLINA' },
  { value: 'SD', label: 'SD', name: 'SOUTH DAKOTA' },
  { value: 'TN', label: 'TN', name: 'TENNESSEE' },
  { value: 'TX', label: 'TX', name: 'TEXAS' },
  { value: 'UT', label: 'UT', name: 'UTAH' },
  { value: 'VT', label: 'VT', name: 'VERMONT' },
  { value: 'VA', label: 'VA', name: 'VIRGINIA' },
  { value: 'WA', label: 'WA', name: 'WASHINGTON' },
  { value: 'WV', label: 'WV', name: 'WEST VIRGINIA' },
  { value: 'WI', label: 'WI', name: 'WISCONSIN' },
  { value: 'WY', label: 'WY', name: 'WYOMING' },
];

export const stateList = stateOptions.map((state, i) => {
  // dynamically add id's to prevent having to change all when list gets changed
  state['id'] = i;
  return {
    id: state.id,
    label: state.label,
    value: state.value,
    disabled: false,
  };
});

export const yesNoOptions = [
  { id: 0, name: 'YES' },
  { id: 1, name: 'NO' },
];

export const professionalTypeOptions = [
  { id: 0, name: 'Environmental' },
  { id: 1, name: 'Health' },
  { id: 2, name: 'Safety' },
];

export const professionalCertificationOptions = [
  { id: 0, name: 'Associate Safety Professional (ASP)' },
  { id: 1, name: 'Certified Instructional Trainer (CIT)' },
  { id: 2, name: 'Certified Safety Professional (CSP)' },
  { id: 3, name: 'Construction Health and Safety Technician (CHST)' },
  { id: 4, name: 'Graduate Safety Practitioner (GSP)' },
  { id: 5, name: 'Occupational Hygiene and Safety Technician (OHST)' },
  { id: 6, name: 'Safety Management Specialist (SMS)' },
  { id: 7, name: 'Safety Trained Supervisor (STS)' },
  { id: 8, name: 'Safety Trained Supervisor Construction (STSC)' },
  { id: 9, name: 'Transitional Safety Practitioner (TSP)' },
  {
    id: 10,
    name: 'Certified Environmental and Safety Compliance Officer (CESCO)',
  },
];

export const oshaCertificates = [
  {
    id: 0,
    name: 'OSHA 510 – Occupational Safety & Health Standards for the Construction Industry (30-Hour)',
  },
  {
    id: 1,
    name: 'OSHA 500 – Authorized Outreach Instructor for Construction Industry',
  },
  {
    id: 2,
    name: 'OSHA 511 – Occupational Safety & Health Standards for General Industry (30-Hour)',
  },
  {
    id: 3,
    name: 'OSHA 501 – Authorized Outreach Instructor for General Industry',
  },
  { id: 4, name: 'OSHA 5109 – Cal/OSHA Standards for Construction Industry' },
  { id: 5, name: 'OSHA 5119 – Cal/OSHA Standards for General Industry' },
  {
    id: 6,
    name: 'OSHA 5410 – Occupational Safety & Health Standards for the Maritime Industry',
  },
  {
    id: 7,
    name: 'OSHA 5400 – Trainer Course in Occupational Safety & Health Standards for the Maritime Industry',
  },
  { id: 8, name: 'OSHA 5600 – Disaster Site Worker Trainer Course' },
  { id: 9, name: 'Other' },
];

export const oshaCourseCertificates = [
  { id: 0, name: 'OSHA 2015 – Hazardous Materials' },
  { id: 1, name: 'OSHA 2055 – Cranes in Construction' },
  { id: 2, name: 'OSHA 2045 – Machinery and Machine Guarding Standards' },
  { id: 3, name: 'OSHA 2225 – Respiratory Protection' },
  { id: 4, name: 'OSHA 2255 – Principles of Ergonomics' },
  { id: 5, name: 'OSHA 2264 – Permit-Required Confined Space Entry' },
  {
    id: 6,
    name: 'OSHA 3015 – Excavation / Trenching and Soil Mechanics',
    altName: 'Excavation, Trenching and Soil Mechanics',
  },
  { id: 7, name: 'OSHA 3085 – Principles of Scaffolding' },
  { id: 8, name: 'OSHA 3095 – Electrical Standards (Low Voltage – Federal)' },
  { id: 9, name: 'OSHA 3115 – Fall Protection' },
  {
    id: 10,
    name: 'OSHA 7000 – OSHA Training Guidelines for Safe Patient Handling',
  },
  { id: 11, name: 'OSHA 7005 – Public Warehousing and Storage' },
  {
    id: 12,
    name: 'OSHA 7105 – Introduction to Evacuation and Emergency Planning',
  },
  { id: 13, name: 'OSHA 7115 – Lockout/Tagout' },
  { id: 14, name: 'OSHA 7120 – Introduction to Combustible Dust Hazards' },
  {
    id: 15,
    name: 'OSHA 7200 – Bloodborne Pathogens Exposure Control for Healthcare Facilities',
  },
  { id: 16, name: 'OSHA 7205 – Health Hazard Awareness' },
  {
    id: 17,
    name: 'OSHA 7215 – Silica in Construction / Maritime / General Industries',
    altName: 'Silica in Construction / Maritime /General Industries',
  },
  { id: 18, name: '8 Hour HAZWOPER' },
  { id: 19, name: '24 Hour HAZWOPER' },
  { id: 20, name: '40 Hour HAZWOPER' },
  { id: 21, name: 'Other' },
];

export const mshaBlueCardOptions = [
  { id: 0, name: 'Underground Instructor' },
  { id: 1, name: 'Surface Instructor' },
  { id: 2, name: 'Unlimited' },
  { id: 3, name: 'Instructor' },
  { id: 4, name: 'None' },
  { id: 5, name: 'Other' },
];

export const emergencyResponseCertificationOptions = [
  { id: 0, name: 'Basic Life Support for Healthcare Providers' },
  { id: 1, name: 'Bloodborne Pathogens Training' },
  { id: 2, name: 'First Aid and CPR/AED' },
  { id: 3, name: 'Responding to Emergencies' },
  { id: 4, name: 'Water Safety Instruction' },
  { id: 5, name: 'None' },
];

export const environmentalCertificationOptions = [
  {
    id: 0,
    name: 'Certified Industrial Hygienist',
    altName: 'Certified Industrial Hygienist (CIH)',
  },
  { id: 1, name: 'HazWoper Instructor' },
  { id: 2, name: 'Hazardous Material Mannager' },
  {
    id: 3,
    name: 'Certified Environmental and Safety Compliance Officer',
    altName: 'Certified Environmental and Safety Compliance Officer (CESCO)',
  },
  { id: 4, name: 'None' },
  { id: 5, name: 'Other' },
];

export const dotCertificationOptions = [
  { id: 0, name: 'The Highway Traffic Safety Professional (HTSP)' },
  { id: 1, name: 'None' },
];

export const industriesOptions = [
  { id: 0, name: 'Construction' },
  { id: 1, name: 'Manufacturing' },
  { id: 2, name: 'Healthcare' },
  { id: 3, name: 'Mining' },
  { id: 4, name: 'General Industry' },
  { id: 5, name: 'Oil and Gas', altName: 'Oil & Gas' },
  { id: 6, name: 'Other' },
];

export const yearsOfExperienceOptions = [
  { id: 0, name: '0-2 Years' },
  { id: 1, name: '2-5 Years' },
  { id: 2, name: '5-10 Years' },
  { id: 3, name: '10-19 Years' },
  { id: 4, name: '20+ Years' },
];

export const typeOfWorkOptions = [
  { id: 0, name: 'Training', altName: '' },
  { id: 1, name: 'Inspection', altName: '' },
  { id: 2, name: 'Inspection Prep', altName: '' },
  { id: 3, name: 'Auditing', altName: 'Audit' },
  { id: 4, name: 'Program Buildout', altName: '' },
  { id: 5, name: 'OHSA Representative', altName: '' },
  { id: 6, name: 'General Consulting', altName: '' },
  { id: 7, name: 'Other', altName: '' },
];

export const preferredEngagementOptions = [
  { id: 0, name: 'Single-day', altName: '4-8 hours' },
  { id: 1, name: 'Multi Day', altName: '1-3 days' },
  { id: 2, name: '30+ Days' },
  {
    id: 3,
    name: 'No preference',
    altName: 'Flexible, no preference',
    altName2: 'flexible',
  },
  { id: 4, name: 'Week or mutli-week assignments' },
];

export const farWillingTravelOptions = [
  { id: 0, name: '5-20 Miles' },
  { id: 1, name: '20-50 Miles' },
  { id: 2, name: '50-100 Miles' },
  { id: 3, name: '100-200 Miles' },
  { id: 4, name: 'No limitations' },
];

export const timeAvailabilityOptions = [
  { id: 0, name: '8 AM - Noon' },
  { id: 1, name: '10 AM - 2 PM' },
  { id: 2, name: 'Noon - 4 PM' },
  { id: 3, name: 'Weekends' },
  { id: 4, name: 'Evenings' },
  { id: 5, name: 'Other' },
];

export const startWorkingOptions = [
  { id: 0, name: 'Immediately' },
  { id: 1, name: '2 Weeks', altName: '2-weeks' },
  { id: 2, name: '1 Month' },
  { id: 3, name: 'Other' },
];

export const initialOnboardingTaskList = [
  {
    key: 'signup_for_orientation',
    name: 'Sign up for Orientation',
    status: 0,
    order: 1,
  },
  {
    key: 'complete_background_check',
    name: 'Complete Background Check',
    status: 0,
    order: 2,
  },
];

export const schedule15MinutesCallLink = 'https://calendly.com/yb-intro-call/15min';

export const backgroundCheckLink = 'https://yellowbird.quickapp.pro/apply/applicant/new/16117';

export const orientationVideoLink = 'https://6459781.hs-sites.com/en/knowledge/a-quick-update-from-your-yellowbird-team';

export const signUpForOrientationLink = 'https://calendar.google.com/calendar/embed?src=orientation%40goyellowbird.com&ctz=America%2FPhoenix';

export const shippingAddressOptions = [
  { id: 0, name: 'Send to the same address' },
  { id: 1, name: 'Send to another address' },
];

export const contractorOptions = [
  { id: 0, name: 'Individual' },
  { id: 1, name: 'Business' },
];

export const bankingAccountOptions = [
  { value: 0, label: 'Checking' },
  { value: 1, label: 'Money Market' },
  { value: 2, label: 'Rents held in trust' },
  { value: 3, label: 'Savings' },
  { value: 4, label: 'Trust account' },
];

export const tShirtSizeList = [
  { value: 'S', label: 'S' },
  { value: 'M', label: 'M' },
  { value: 'L', label: 'L' },
  { value: 'XL', label: 'XL' },
  { value: 'XXL', label: 'XXL' },
  { value: 'XXXL', label: 'XXXL' },
];

export const taskStatus = ['Not Started', 'Pending Admin Approval', 'Completed', 'Action Required', 'Scheduled'];

export const stringPrefix = {
  farWillingTravelOptions: 'farWillingTravelIds-',
  startWorkingOptions: 'startWorkingIds-',
  yearsOfExperienceOptions: 'yearsOfExperienceIds-',
  temp: 'interestedInVolunteeringIds-',
};

export const optionsForDropForCommute = [
  { label: 'On-Site', value: 'ON_SITE' },
  { label: 'Virtual', value: 'VIRTUAL' },
  { label: 'Hybrid', value: 'HYBRID' },
];

export const extraOptionsKeys = {
  industriesOptions: 'industryOther',
  typeOfWorkOptions: 'typeOfWorkOther',
  industriesOptions: 'industryToWorkInOther',
  startWorkingOptions: 'startWorkingOther',
  otherCertifications: 'otherCertifications',
  mshaBlueCardOptions: 'MASHBlueCardOther',
  oshaCertificates: 'OSHACertificatesOther',
  oshaCourseCertificates: 'OSHACoursesOther',
  environmentalCertificationOptions: 'environmentalCertificationsOther',
  timeAvailabilityOptions: 'timeAvailabilityOther',
};

export const reasonsForDeclineJob = [
  {
    id: 'Not available at the scheduled time',
    name: 'Not available at the scheduled time',
  },
  {
    id: 'Hourly rate too low',
    name: 'Hourly rate too low',
  },
  {
    id: 'Don’t have the required skills/experience/certs',
    name: 'Don’t have the required skills/experience/certs',
  },
  {
    id: 'Not interested in YellowBird Jobs',
    name: 'Not interested in YellowBird Jobs',
  },
];

export const sortedJobsOptions = [
  {
    id: 0,
    value: 'name',
    label: 'Name',
  },
  {
    id: 1,
    value: 'service',
    label: 'Service',
  },
  {
    id: 2,
    value: 'referenceNumber',
    label: 'Reference #',
  },
  {
    id: 3,
    value: 'startDate',
    label: 'Start Day',
  },
  {
    id: 4,
    value: 'endDate',
    label: 'End Day',
  },
  {
    id: 5,
    value: 'status',
    label: 'Status',
  },
];

export const mapFieldsAndCatalogs = [
  {
    type: 'select',
    propertyName: 'matchReady',
    catalogName: 'yesNoOptions',
    textFieldName: 'name',
    idName: 'matchReadyIds',
  },
  {
    type: 'multiselect',
    propertyName: 'certifications',
    catalogName: 'certifications',
    otherOption: 'certificationsOther',
    textFieldName: 'name',
  },
  {
    type: 'multiselect',
    propertyName: 'desiredIndustries',
    catalogName: 'industries',
    otherOption: 'industriesWouldYouLikeOther',
    textFieldName: 'nameOfIndustries',
  },
  {
    type: 'multiselect',
    propertyName: 'desiredWork',
    catalogName: 'desiredWork',
    otherOption: 'typeOfWorkOther',
    textFieldName: 'name',
  },
  {
    type: 'multiselect',
    propertyName: 'dotCertifications',
    catalogName: 'dotCertifications',
    textFieldName: 'name',
  },
  {
    type: 'multiselect',
    propertyName: 'emergencyResponseCertifications',
    catalogName: 'emergencyResponseCertifications',
    textFieldName: 'name',
  },
  {
    type: 'multiselect',
    propertyName: 'environmentalCertifications',
    catalogName: 'environmentalCertifications',
    otherOption: 'environmentalCertificationsOther',
    textFieldName: 'name',
  },
  {
    type: 'multiselect',
    propertyName: 'mshaBlueCards',
    catalogName: 'mshaBlueCards',
    otherOption: 'MASHBlueCardOther',
    textFieldName: 'cardType',
  },
  {
    type: 'multiselect',
    propertyName: 'oshaCertificates',
    catalogName: 'oshaCertificates',
    otherOption: 'OSHACertificatesOther',
    textFieldName: 'name',
  },
  {
    type: 'multiselect',
    propertyName: 'oshaCourseCertificates',
    catalogName: 'oshaCourseCertificates',
    otherOption: 'OSHACoursesOther',
    textFieldName: 'name',
  },
  {
    type: 'multiselect',
    propertyName: 'preferredEngagement',
    catalogName: 'preferredEngagement',
    textFieldName: 'daysOfEngagement',
  },
  {
    type: 'multiselect',
    propertyName: 'proType',
    catalogName: 'proType',
    textFieldName: 'name',
  },
  {
    type: 'multiselect',
    propertyName: 'timeAvailability',
    catalogName: 'timeAvailability',
    otherOption: 'timeAvailabilityOther',
    textFieldName: 'availability',
  },
  {
    type: 'multiselect',
    propertyName: 'workedIndustries',
    catalogName: 'industries',
    otherOption: 'industriesWorkedOther',
    textFieldName: 'nameOfIndustries',
  },
  {
    type: 'select',
    propertyName: 'ehsExperience',
    catalogName: 'ehsExperience',
    textFieldName: 'experience',
    idName: 'yearsOfExperienceIds',
  },
  {
    type: 'select',
    propertyName: 'travelAvailability',
    catalogName: 'travelAvailability',
    textFieldName: 'distance',
    idName: 'farWillingTravelIds',
  },
  {
    type: 'select',
    propertyName: 'workAvailability',
    catalogName: 'workAvailability',
    otherOption: 'startWorkingOther',
    textFieldName: 'startToWork',
    idName: 'startWorkingIds',
  },
  {
    type: 'select',
    propertyName: 'volunteering',
    catalogName: 'yesNoOptions',
    textFieldName: 'name',
    idName: 'interestedInVolunteeringIds',
  },
  {
    type: 'select',
    propertyName: 'contractorExperience',
    catalogName: 'yesNoOptions',
    textFieldName: 'name',
    idName: 'contractorExperienceIds',
  },
  {
    type: 'select',
    propertyName: 'isnetworldExperience',
    catalogName: 'yesNoOptions',
    textFieldName: 'name',
    idName: 'isnetworldExperienceIds',
  },
  {
    type: 'select',
    propertyName: 'veteran',
    catalogName: 'yesNoOptions',
    textFieldName: 'name',
    idName: 'veteranIds',
  },
];

export const jobMatchingDefaultColumns = [
  { field: 'city' },
  { field: 'state' },
  { field: 'certificationsText' },
  { field: 'travelAvailabilityText' },
  { field: 'ehsExperienceText' },
];

export const jobMatchingTableColumns = [
  {
    field: 'city',
    header: 'City',
    filterPlaceholder: 'Search by city',
    filterElement: '',
    filterField: 'city',
    sortable: true,
    filter: true,
    filterMatchMode: 'contains',
  },
  {
    field: 'state',
    header: 'State',
    filterElement: 'stateFilter',
    filterField: 'state',
    sortable: true,
    filter: true,
  },
  {
    field: 'certificationsText',
    header: 'Professional Certs',
    filterElement: 'certificationsFilter',
    filterField: 'certificationsText',
    filterFunction: 'customFunction',
    filterMatchMode: 'custom',
    sortable: true,
    filter: true,
  },
  {
    field: 'travelAvailabilityText',
    header: 'Travel Availability',
    filterElement: 'travelAvailabilityFilter',
    filterField: 'travelAvailabilityText',
    sortable: true,
    filter: true,
  },
  {
    field: 'ehsExperienceText',
    header: 'Years of Experience',
    filterElement: 'ehsExperienceFilter',
    filterField: 'ehsExperienceText',
    sortable: true,
    filter: true,
  },
  {
    field: 'proTypeText',
    header: 'Type of Professional',
    filterElement: 'proTypeFilter',
    filterField: 'proTypeText',
    filterFunction: 'customFunction',
    filterMatchMode: 'custom',
    sortable: true,
    filter: true,
  },
  {
    field: 'workedIndustriesText',
    header: 'Industries Worked In',
    filterElement: 'workedIndustriesFilter',
    filterField: 'workedIndustriesText',
    filterFunction: 'customFunction',
    filterMatchMode: 'custom',
    sortable: true,
    filter: true,
  },
  {
    field: 'desiredWorkText',
    header: 'Type of Work Looking for',
    filterElement: 'desiredWorkFilter',
    filterField: 'desiredWorkText',
    filterFunction: 'customFunction',
    filterMatchMode: 'custom',
    sortable: true,
    filter: true,
  },
  {
    field: 'desiredIndustriesText',
    header: 'Desired Indistries',
    filterElement: 'desiredIndustriesFilter',
    filterField: 'desiredIndustriesText',
    filterFunction: 'customFunction',
    filterMatchMode: 'custom',
    sortable: true,
    filter: true,
  },
  {
    field: 'preferredEngagementText',
    header: 'Preferred Engagement',
    filterElement: 'preferredEngagementFilter',
    filterField: 'preferredEngagementText',
    filterFunction: 'customFunction',
    filterMatchMode: 'custom',
    sortable: true,
    filter: true,
  },
  {
    field: 'timeAvailabilityText',
    header: 'Time Availability',
    filterElement: 'timeAvailabilityFilter',
    filterField: 'timeAvailabilityText',
    filterFunction: 'customFunction',
    filterMatchMode: 'custom',
    sortable: true,
    filter: true,
  },
  {
    field: 'workAvailabilityText',
    header: 'Worker Availability',
    filterElement: 'workAvailabilityFilter',
    filterField: 'workAvailabilityText',
    sortable: true,
    filter: true,
  },
  {
    field: 'oshaCertificatesText',
    header: 'OSHA Certificates',
    filterElement: 'oshaCertificatesFilter',
    filterField: 'oshaCertificatesText',
    filterFunction: 'customFunction',
    filterMatchMode: 'custom',
    sortable: true,
    filter: true,
  },
  {
    field: 'oshaCourseCertificatesText',
    header: 'OSHA Course Certificates',
    filterElement: 'oshaCourseCertificatesFilter',
    filterField: 'oshaCourseCertificatesText',
    filterFunction: 'customFunction',
    filterMatchMode: 'custom',
    sortable: true,
    filter: true,
  },
  {
    field: 'mshaBlueCardsText',
    header: 'MSHA Blue Card',
    filterElement: 'mshaBlueCardsFilter',
    filterField: 'mshaBlueCardsText',
    filterFunction: 'customFunction',
    filterMatchMode: 'custom',
    sortable: true,
    filter: true,
  },
  {
    field: 'dotCertificationsText',
    header: 'DOT Certifications',
    filterElement: 'dotCertificationsFilter',
    filterField: 'dotCertificationsText',
    filterFunction: 'customFunction',
    filterMatchMode: 'custom',
    sortable: true,
    filter: true,
  },
  {
    field: 'emergencyResponseCertificationsText',
    header: 'Emergency Response Certifications',
    filterElement: 'emergencyResponseCertificationsFilter',
    filterField: 'emergencyResponseCertificationsText',
    filterFunction: 'customFunction',
    filterMatchMode: 'custom',
    sortable: true,
    filter: true,
  },
  {
    field: 'environmentalCertificationsText',
    header: 'Environmental Certs',
    filterElement: 'environmentalCertificationsFilter',
    filterField: 'environmentalCertificationsText',
    filterFunction: 'customFunction',
    filterMatchMode: 'custom',
    sortable: true,
    filter: true,
  },
  {
    field: 'otherCertifications',
    header: 'Other Certs',
    filterPlaceholder: 'Search by other certs',
    filterElement: '',
    filterField: 'otherCertifications',
    sortable: true,
    filter: true,
    filterMatchMode: 'contains',
  },
  {
    field: 'notes',
    header: 'Notes',
    filterPlaceholder: 'Search by notes',
    filterElement: '',
    filterField: 'notes',
    sortable: true,
    filter: true,
    filterMatchMode: 'contains',
  },
  {
    field: 'volunteeringText',
    header: 'Volunteering',
    filterElement: 'volunteeringFilter',
    filterField: 'volunteeringText',
    sortable: true,
    filter: true,
  },
  {
    field: 'contractorExperienceText',
    header: 'Contractor Validation Experience',
    filterElement: 'contractorExperienceFilter',
    filterField: 'contractorExperienceText',
    sortable: true,
    filter: true,
  },
  {
    field: 'isnetworldExperienceText',
    header: 'ISNetworld',
    filterElement: 'isnetworldExperienceFilter',
    filterField: 'isnetworldExperienceText',
    sortable: true,
    filter: true,
  },
  {
    field: 'veteranText',
    header: 'Veteran',
    filterElement: 'veteranFilter',
    filterField: 'veteranText',
    sortable: true,
    filter: true,
  },
];

export const airTrafficControlTableColumns = [
  {
    field: 'status',
    header: 'Job Status',
    editor: '{(props) => statusEditor(props)}',
    sortable: true,
    filter: true,
    filterElement: 'jobStatusFilter',
  },
  {
    field: 'company.name',
    header: 'Company Name',
    filterPlaceholder: 'Search by company name',
    sortable: true,
    filter: true,
    filterMatchMode: 'contains',
  },
  {
    field: 'name',
    header: 'Name',
    filterPlaceholder: 'Search by job name',
    sortable: true,
    filter: true,
    filterMatchMode: 'contains',
  },
  {
    field: 'jobType',
    header: 'Job Type',
    filterPlaceholder: 'Search by job type',
    sortable: true,
    filter: true,
    filterMatchMode: 'contains',
  },
  {
    field: 'createdAt',
    header: 'Created At',
    filterPlaceholder: 'Search by created at',
    sortable: true,
    filter: true,
    filterMatchMode: 'contains',
  },
  {
    field: 'startDate',
    header: 'Start Date',
    sortable: true,
    filter: true,
    filterMatchMode: 'contains',
  },
  {
    field: 'deadline',
    header: 'Deadline',
    sortable: true,
    filter: true,
    filterMatchMode: 'contains',
  },
  {
    field: 'totalHoursPerWeek',
    header: 'Total Hours Per Week',
    filterPlaceholder: 'Search by total hours per week',
    sortable: true,
    filter: true,
    filterMatchMode: 'contains',
  },
  {
    field: 'referenceNumber',
    header: 'Reference Number',
    filterPlaceholder: 'Search by job reference',
    sortable: true,
    filter: true,
    filterMatchMode: 'contains',
  },
  {
    field: 'accepted',
    header: 'Job Matched',
    sortable: true,
    filter: true,
    body: "(e) => trueFalseBodyTemplate(e, 'accepted')",
    filterElement: 'jobAcceptedFilter',
  },
  {
    field: 'pocFullName',
    header: 'POC Name',
    filterPlaceholder: 'Search by poc name',
    sortable: true,
    filter: true,
    filterMatchMode: 'contains',
  },
  {
    field: 'pocEmail',
    header: 'POC Email',
    filterPlaceholder: 'Search by poc email',
    sortable: true,
    filter: true,
    filterMatchMode: 'contains',
  },
  {
    field: 'pocJobTitle',
    header: 'POC Job Title',
    filterPlaceholder: 'Search by poc job title',
    sortable: true,
    filter: true,
    filterMatchMode: 'contains',
  },
  {
    field: 'pocPhone',
    header: 'POC Phone',
    filterPlaceholder: 'Search by poc phone',
    sortable: true,
    filter: true,
    filterMatchMode: 'contains',
  },
  {
    field: 'pocContactMethod',
    header: 'POC Contact Method',
    filterPlaceholder: 'Search by poc contact method',
    sortable: true,
    filter: true,
    filterMatchMode: 'contains',
  },
  {
    field: 'siteAddress',
    header: 'Site Address',
    filterPlaceholder: 'Search by site address',
    sortable: true,
    filter: true,
    filterMatchMode: 'contains',
  },
  {
    field: 'siteState',
    header: 'Site State',
    filterPlaceholder: 'Search by site state',
    sortable: true,
    filter: true,
    filterMatchMode: 'contains',
  },
  {
    field: 'siteZip',
    header: 'Site Zip',
    filterPlaceholder: 'Search by site zip',
    sortable: true,
    filter: true,
    filterMatchMode: 'contains',
  },
  {
    field: 'multipleSites',
    header: 'Multiple Sites',
    filterPlaceholder: 'Search by multiple sites',
    sortable: true,
    filter: true,
    body: "(e) => trueFalseBodyTemplate(e, 'multipleSites')",
    filterElement: 'multipleSitesFilter',
  },
  {
    field: 'typeOfWork',
    header: 'Type Of Work',
    filterPlaceholder: 'Search by type of work',
    sortable: true,
    filter: true,
    filterMatchMode: 'contains',
  },
  {
    field: 'requestProName',
    header: 'Request Pro Name',
    filterPlaceholder: 'Search by request pro name',
    sortable: true,
    filter: true,
    filterMatchMode: 'contains',
  },
  {
    field: 'pay',
    header: 'Pay',
    filterPlaceholder: 'Search by pay',
    sortable: true,
    filter: true,
    filterMatchMode: 'contains',
  },
  {
    field: 'coHourlyRate',
    header: 'Co Hourly Rate',
    filterPlaceholder: 'Search by co hourly rate',
    sortable: true,
    filter: true,
    filterMatchMode: 'contains',
  },
  {
    field: 'proHourlyRate',
    header: 'Pro Hourly Rate',
    filterPlaceholder: 'Search by pro hourly rate',
    sortable: true,
    filter: true,
    filterMatchMode: 'contains',
  },
  {
    field: 'numberOfDays',
    header: 'Number Of Days',
    filterPlaceholder: 'Search by number of days',
    sortable: true,
    filter: true,
    filterMatchMode: 'contains',
  },
  {
    field: 'numberOfJobs',
    header: '# Of Jobs',
    filterPlaceholder: 'Search by # of jobs',
    sortable: true,
    filter: true,
    filterMatchMode: 'contains',
  },
  {
    field: 'numInvitesSent',
    header: '# Invites Sent',
    filterPlaceholder: 'Search by # invites sent',
    sortable: true,
    filter: true,
    filterMatchMode: 'contains',
  },
  {
    field: 'percentageDeclined',
    header: '% Declined',
    filterPlaceholder: 'Search by % declined',
    sortable: true,
    filter: true,
    filterMatchMode: 'contains',
  },
  {
    field: 'percentageNoResponse',
    header: '% No Response',
    filterPlaceholder: 'Search by % no response',
    sortable: true,
    filter: true,
    filterMatchMode: 'contains',
  },
  {
    field: 'notes',
    header: 'Notes',
    filterPlaceholder: 'Search by notes',
    sortable: true,
    filter: true,
    filterMatchMode: 'contains',
  },
  {
    field: 'completedFromPro',
    header: 'Completed From Pro',
    filterPlaceholder: 'Search by poc name',
    sortable: true,
    filter: true,
    body: "(e) => trueFalseBodyTemplate(e, 'completedFromPro')",
    filterElement: 'completedFromProFilter',
  },
  {
    field: 'completedFromCo',
    header: 'Completed From Co',
    filterPlaceholder: 'Search by poc name',
    sortable: true,
    filter: true,
    body: "(e) => trueFalseBodyTemplate(e, 'completedFromCo')",
    filterElement: 'completedFromCoFilter',
  },
  {
    field: 'requiredSales',
    header: 'Required Sales',
    filterPlaceholder: 'Search by required sales',
    sortable: true,
    filter: true,
    filterMatchMode: 'contains',
  },
  {
    field: 'findings',
    header: 'Findings',
    filterPlaceholder: 'Search by reason findings',
    sortable: true,
    filter: true,
    filterMatchMode: 'contains',
  },
  {
    field: 'reasonForNotStarting',
    header: 'Reason For Not Starting',
    filterPlaceholder: 'Search by reason for not starting',
    sortable: true,
    filter: true,
    filterMatchMode: 'contains',
  },
  {
    field: 'northStarMetric',
    header: 'North Star Metric',
    filterPlaceholder: 'Search by reason to decline completition',
    sortable: true,
    filter: true,
    body: "(e) => trueFalseBodyTemplate(e, 'northStarMetric')",
    filterElement: 'northStarMetricFilter',
  },
  {
    field: 'reasonToDeclineCompletition',
    header: 'Reason To Decline Completition',
    filterPlaceholder: 'Search by reason to decline completition',
    sortable: true,
    filter: true,
    filterMatchMode: 'contains',
  },
  {
    field: 'orderStatus',
    header: 'Order Status',
    sortable: true,
    filter: true,
    filterElement: 'jobOrderStatusFilter',
  },
  {
    field: 'deliverables',
    header: 'Deliverables',
    filterPlaceholder: 'Search by deliverables',
    sortable: true,
    filter: true,
    filterMatchMode: 'contains',
  },
  {
    field: 'schedule',
    header: 'Schedule',
    filterPlaceholder: 'Search by schedule',
    sortable: true,
    filter: true,
    filterMatchMode: 'contains',
  },
  {
    field: 'estimatedOnSiteCompletitionDate',
    header: 'Estimated On Site Completition Date',
    filterPlaceholder: 'Search by estimated on site completition date',
    sortable: true,
    filter: true,
    filterMatchMode: 'contains',
  },
  {
    field: 'estimatedDeliverableCompletitionDate',
    header: 'Estimated Deliverable Completition Date',
    filterPlaceholder: 'Search by deliverables',
    sortable: true,
    filter: true,
    filterMatchMode: 'contains',
  },
  {
    field: 'promo',
    header: 'Promo',
    filterPlaceholder: 'Search by promo',
    sortable: true,
    filter: true,
    filterMatchMode: 'contains',
  },
  {
    field: 'perDiem',
    header: 'Per Diem',
    filterPlaceholder: 'Search by per diem',
    sortable: true,
    filter: true,
    filterMatchMode: 'contains',
  },
  {
    field: 'specificRequirements',
    header: 'Specific Requirements',
    filterPlaceholder: 'Search by specific requirements',
    sortable: true,
    filter: true,
    filterMatchMode: 'contains',
  },
  {
    field: 'otherRequirements',
    header: 'Other Requirements',
    filterPlaceholder: 'Search by other requirements',
    sortable: true,
    filter: true,
    filterMatchMode: 'contains',
  },
  {
    field: 'requiredSkills',
    header: 'Required Skills',
    filterPlaceholder: 'Search by required skills',
    sortable: true,
    filter: true,
    filterMatchMode: 'contains',
  },
  {
    field: 'company.businessPhone',
    header: 'Company Business Phone',
    filterPlaceholder: 'Search by company business phone',
    sortable: true,
    filter: true,
    filterMatchMode: 'contains',
  },
  {
    field: 'company.website',
    header: 'Company Website',
    filterPlaceholder: 'Search by company website',
    sortable: true,
    filter: true,
    filterMatchMode: 'contains',
  },
  {
    field: 'company.address',
    header: 'Company Address',
    filterPlaceholder: 'Search by company address',
    sortable: true,
    filter: true,
    filterMatchMode: 'contains',
  },
  {
    field: 'company.city',
    header: 'Company City',
    filterPlaceholder: 'Search by company city',
    sortable: true,
    filter: true,
    filterMatchMode: 'contains',
  },
  {
    field: 'company.state',
    header: 'Company State',
    filterPlaceholder: 'Search by poc company state',
    sortable: true,
    filter: true,
    filterMatchMode: 'contains',
  },
  {
    field: 'company.zip',
    header: 'Company Zip',
    filterPlaceholder: 'Search by company zip',
    sortable: true,
    filter: true,
    filterMatchMode: 'contains',
  },
  {
    field: 'company.createdAt',
    header: 'Company Register Date',
    filterPlaceholder: 'Search by company register date',
    sortable: true,
    filter: true,
    filterMatchMode: 'contains',
  },
];

export const airTrafficControlDefaultColumns = [
  { field: 'status' },
  { field: 'company.name' },
  { field: 'name' },
  { field: 'jobType' },
  { field: 'createdAt' },
  { field: 'startDate' },
  { field: 'deadline' },
  { field: 'totalHoursPerWeek' },
];

export const jobStatuses = [
  { name: 'Posted' },
  { name: 'Negotiating' },
  { name: 'Approved' },
  { name: 'Matched' },
  { name: 'In Progress' },
  { name: 'Completed' },
  { name: 'Closed-Paid' },
  { name: 'Closed-Lost' },
  { name: 'Closed-No Response' },
];

export const jobOrderStatuses = [{ name: 'pending' }, { name: 'accepted' }, { name: 'declined' }];

export const yearsOptions = [
  { id: 0, label: 'Less than 1 year', value: '0' },
  { id: 1, label: '1 Year', value: '1' },
  { id: 2, label: '2 Years', value: '2' },
  { id: 3, label: '3 Years', value: '3' },
  { id: 4, label: '4 Years', value: '4' },
  { id: 5, label: '5 Years', value: '5' },
  { id: 6, label: '6 Years', value: '6' },
  { id: 7, label: '7 Years', value: '7' },
  { id: 8, label: '8 Years', value: '8' },
  { id: 9, label: '9 Years', value: '9' },
  { id: 10, label: '10 Years', value: '10' },
  { id: 11, label: '11 Years', value: '11' },
  { id: 12, label: '12 Years', value: '12' },
  { id: 13, label: '13 Years', value: '13' },
  { id: 14, label: '14 Years', value: '14' },
  { id: 15, label: '15 Years', value: '15' },
  { id: 16, label: '16 Years', value: '16' },
  { id: 17, label: '17 Years', value: '17' },
  { id: 18, label: '18 Years', value: '18' },
  { id: 19, label: '19 Years', value: '19' },
  { id: 20, label: '20 Years', value: '20' },
  { id: 21, label: '21 Years', value: '21' },
  { id: 22, label: '22 Years', value: '22' },
  { id: 23, label: '23 Years', value: '23' },
  { id: 24, label: '24 Years', value: '24' },
  { id: 25, label: '25 Years', value: '25' },
  { id: 26, label: '26 Years', value: '26' },
  { id: 27, label: '27 Years', value: '27' },
  { id: 28, label: '28 Years', value: '28' },
  { id: 29, label: '29 Years', value: '29' },
  { id: 30, label: '30 Years', value: '30' },
  { id: 31, label: '31 Years', value: '31' },
  { id: 32, label: '32 Years', value: '32' },
  { id: 33, label: '33 Years', value: '33' },
  { id: 34, label: '34 Years', value: '34' },
  { id: 35, label: '35 Years', value: '35' },
  { id: 36, label: '36 Years', value: '36' },
  { id: 37, label: '37 Years', value: '37' },
  { id: 38, label: '38 Years', value: '38' },
  { id: 39, label: '39 Years', value: '39' },
  { id: 40, label: '40 Years', value: '40' },
  { id: 41, label: '41 Years', value: '41' },
  { id: 42, label: '42 Years', value: '42' },
  { id: 43, label: '43 Years', value: '43' },
  { id: 44, label: '44 Years', value: '44' },
  { id: 45, label: '45 Years', value: '45' },
  { id: 46, label: '46 Years', value: '46' },
  { id: 47, label: '47 Years', value: '47' },
  { id: 48, label: '48 Years', value: '48' },
];

export const howCanYellowbirdHelpOptions = [
  {
    label: 'I have a role or service I need to fill now',
    value: 'fillNow',
  },
  {
    label: 'I regularly hire Risk or EHS Pros',
    value: 'regularNeed',
  },
  {
    label: 'I want to learn more about how YellowBird can help me',
    value: 'learnMore',
  },
];
