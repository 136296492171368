import React, { Fragment, ReactNode } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { X } from 'lucide-react';
import { useFormContext } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useAppDispatch, useAppSelector } from 'src';
import { updateWorkerById } from 'src/slices/worker.slice';

import { Updates } from '.';

type Props = {
  title: string;
  updates: Updates;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  children: ReactNode;
};

export default function EditProfileModal({ showModal, setShowModal, title, children, updates }: Props) {
  const dispatch = useAppDispatch();
  const { worker } = useAppSelector(state => state.worker);
  const {
    getValues,
    formState: { errors },
  } = useFormContext();

  async function onSubmit() {
    if (Object.keys(errors).length > 0) {
      return;
    }
    const data = getValues();
    toast.promise(dispatch(updateWorkerById({ workerId: worker.id, updates: { ...updates, ...data } })), {
      loading: 'Saving Changes...',
      success: () => {
        setShowModal(false);
        return 'Profile updated successfully.';
      },
      error: () => {
        return 'Could not update profile. Please try again later';
      },
    });
  }

  return (
    <Transition appear show={showModal} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={() => null}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-black/50' />
        </Transition.Child>
        <div className='fixed inset-0 overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center p-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <Dialog.Panel className='w-full max-w-md transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                <div className='flex justify-between items-center'>
                  <Dialog.Title className='text-xl font-semibold text-slate-700'>
                    {title === 'Education' ? 'Add' : 'Edit'} {title}
                  </Dialog.Title>
                  <div>
                    <X className='pointer-cursor' onClick={() => setShowModal(false)} />
                  </div>
                </div>
                <div className='mt-2 grid gap-3'>{children}</div>
                <div className='flex justify-center gap-3 mt-4'>
                  <button
                    type='button'
                    className='bg-slate-500 text-white py-2.5 px-4 font-bold rounded hover:scale-105 duration-200'
                    onClick={() => setShowModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type='submit'
                    className={`py-2.5 px-4 border-2 font-bold rounded hover:scale-105 duration-200 min-w-44 bg-yellow-400 border-yellow-400`}
                    onClick={onSubmit}
                  >
                    Save Changes
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
