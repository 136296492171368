import React, { useEffect } from 'react';
import { Col, Container, Row, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import Loading from '../../components/Loading';
import { getReferralsById } from '../../slices/referrals.slice';
import Invites from './Invites';

import '../MyJobsScreen/myJobsScreen.scss';
import './referrals.scss';

import { Copy } from 'lucide-react';
import toast, { Toaster } from 'react-hot-toast';

import { fetchWorkerById } from '../../slices/worker.slice';

const Referrals = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state);
  const { user } = state.user;
  const { referrals, isLoading } = state.referrals;

  useEffect(() => {
    (async () => {
      const loggedInUser = state.user?.user;
      if (loggedInUser.role === 'worker') {
        dispatch(fetchWorkerById(loggedInUser.workerId));
      }
    })();
  }, []);

  const copyInviteToClipboard = (code, isUrl, isPro) => {
    const text = isUrl ? `app.goyellowbird.com/register${isPro ? '-pro' : ''}/invite?code=${code}&id=${user.id}` : code;
    navigator.clipboard.writeText(text);
  };

  const copyToClipboard = () =>
    toast('Link copied', {
      icon: '🎉',
    });

  useEffect(() => {
    dispatch(getReferralsById({ userId: user.id }));
  }, []);

  return (
    <Container fluid className='referrals-main-container'>
      <Row>
        <Col>
          <h1 className='referrals-title-page'>Refer and Earn</h1>
          <p className='referrals-text-page my-2'>
            YellowBird offers the chance to earn referral rewards by inviting businesses or professionals to join the platform.
          </p>
        </Col>
      </Row>
      <Row className='referrals-main-row'>
        <Col className='referrals-form-container'>
          <div className='referrals-side-div-org'></div>
          <div className='referrals-form-component'>
            <h1 className='referrals-form-title'>Refer an Organization to YellowBird & receive $250</h1>
            <span className='my-2' style={{ fontSize: '12px', fontWeight: '600' }}>
              *Organizations must input your referral code or the email associated with your YellowBird account in order for your referral to be paid.
            </span>
            <div className='referrals-subtitle-div'>
              <p className='referrals-form-subtitle'>Copy the link below and send it to your recipient:</p>
              <div className='referrals-div-copy'>
                <Copy
                  onClick={() => {
                    copyInviteToClipboard(user.referralCode, true, false);
                    copyToClipboard();
                  }}
                />
                <Toaster />
                <p>Copy</p>
              </div>
            </div>
            <div className='referrals-form-div-link'>
              {user.id ? (
                <p
                  onClick={() => {
                    copyInviteToClipboard(user.referralCode, true, false);
                    copyToClipboard();
                  }}
                >
                  app.goyellowbird.com/register/invite?code={user.referralCode}&id={user.id}
                </p>
              ) : (
                <a href='https://app.goyellowbird.com/register/invite' target='_blank' rel='noreferrer'>
                  app.goyellowbird.com/register/invite
                </a>
              )}
            </div>
            <p className='referrals-form-subtitle-form'>Or write your own Message and send the Referral Invitation email directly from here:</p>
            <Invites inviteeType='Company' />
          </div>
        </Col>
        <Col className='referrals-form-container'>
          <div className='referrals-side-div-pro'></div>
          <div className='referrals-form-component'>
            <h1 className='referrals-form-title'>Refer an EHS or Risk Professional & receive $20</h1>
            <div className='referrals-subtitle-div'>
              <p className='referrals-form-subtitle'>Copy the link below and send it to your recipient:</p>
              <div className='referrals-div-copy'>
                <Copy
                  onClick={() => {
                    copyInviteToClipboard(user.referralCode, true, true);
                    copyToClipboard();
                  }}
                />
                <Toaster />
                <p>Copy</p>
              </div>
            </div>
            <div className='referrals-form-div-link'>
              {user.id ? (
                <p
                  onClick={() => {
                    copyInviteToClipboard(user.referralCode, true, true);
                    copyToClipboard();
                  }}
                >
                  app.goyellowbird.com/register-pro/invite?code={user.referralCode}&id={user.id}
                </p>
              ) : (
                <a href='https://app.goyellowbird.com/register-pro/invite' target='_blank' rel='noreferrer'>
                  app.goyellowbird.com/register-pro/invite
                </a>
              )}
            </div>
            <p className='referrals-form-subtitle-form'>Or write your own Message and send the Referral Invitation email directly from here:</p>
            <Invites inviteeType='Professional' />
          </div>
        </Col>
      </Row>
      {referrals.length > 0 && (
        <Row>
          {!isLoading ? (
            <div className='mt-3 mt-md-0 px-5 flex-row align-items-center'>
              <h5 className='my-4 d-flex justify-content-center' style={{ color: '#586f7c', fontWeight: 600 }}>
                Invited Companies and Professionals
              </h5>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Invited Email</th>
                    <th>Name</th>
                    <th>Date Invited</th>
                    <th>Status</th>
                    <th>Payment</th>
                  </tr>
                </thead>
                <tbody>
                  {referrals.map(item => (
                    <tr key={item.id}>
                      <td>{item.refereeEmail}</td>
                      <td>{item.refereeName}</td>
                      <td>{new Date(item.createdAt).toLocaleDateString()}</td>
                      <td>{typeof item.refereeUserId === 'number' ? 'Signed Up on Platform' : 'No Action'}</td>
                      <td>{item.isReferralFeeEarned === false ? 'Not Paid' : 'Paid'}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ) : (
            <Loading />
          )}
        </Row>
      )}
    </Container>
  );
};

export default Referrals;
