import React, { Fragment, useState } from 'react';

import './splash-modal.scss';
import './loading-state.scss';

import { Dialog, Transition } from '@headlessui/react';

const SplashModal = ({ workerName }) => {
  const [open, setOpen] = useState(true);

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog style={{ position: 'relative', zIndex: '10' }} as='div' onClose={() => setOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter='transition-opacity duration-75'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='transition-opacity duration-150'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div style={{ position: 'fixed', inset: '0', backgroundColor: 'rgba(0, 0, 0, 0.6)' }} />
        </Transition.Child>
        <div style={{ position: 'fixed', inset: '0', overflowY: 'auto', padding: '12px' }}>
          <div style={{ display: 'flex', minHeight: '100%', alignItems: 'center', justifyContent: 'center' }}>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <Dialog.Panel className='dialogPanelSplashScreen'>
                <Dialog.Title as='h3' style={{ fontSize: '36px', fontWeight: '700', color: '#49525E' }}>
                  Thanks for joining YellowBird, {workerName}
                </Dialog.Title>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                  <p style={{ fontSize: '24px', fontWeight: '700', color: '#49525E' }}>Before you can accept jobs, you must complete onboarding:</p>
                  <ol style={{ display: 'flex', flexDirection: 'column', gap: '12px', fontSize: '16px' }}>
                    <li style={{ fontWeight: '700' }}>Upload your most up-to-date resume</li>
                    <li>Fill out your Professional safety and risk profile</li>
                    <li>Verify your personal details and contact information</li>
                  </ol>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <button autoFocus={false} onClick={() => setOpen(false)} className='profileBuildingUploadBtn'>
                      Continue
                    </button>
                  </div>
                  <i style={{ color: '#66798C', fontWeight: '500' }}>
                    YellowBird is designed with a singular vision: to bridge the gap between skilled professionals like you and a wide variety of short- and
                    long-term job opportunities. Together, we're not just checking boxes; we're creating safer, more resilient workplaces and communities across
                    the country.
                  </i>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default SplashModal;
