import { ReactNode, useState } from 'react';
import { Pencil } from 'lucide-react';
import { useFormContext } from 'react-hook-form';

import EditProfileModal from './edit-profile-modal';

type Props = {
  title: string;
  updates: {};
  children: ReactNode;
  editProfileModalChildren: ReactNode;
  icon: any;
};

export default function ProfileWidget({ icon, title, children, editProfileModalChildren, updates }: Props) {
  const [showModal, setShowModal] = useState(false);
  const { reset } = useFormContext();

  return (
    <div className='p-3 md:p-4 bg-white drop-shadow-lg rounded-md'>
      <div className='flex justify-between items-center text-slate-700'>
        <div className='flex gap-2 items-center'>
          <div>{icon}</div>
          <h3 className='text-xl m-0 font-semibold'>{title}</h3>
        </div>
        <div>
          <Pencil
            className='pointer-cursor'
            onClick={() => {
              reset();
              setShowModal(true);
            }}
          />
        </div>
      </div>
      <div className='mt-2 flex grid gap-6'>{children}</div>
      <EditProfileModal updates={updates} title={title} showModal={showModal} setShowModal={setShowModal}>
        {editProfileModalChildren}
      </EditProfileModal>
    </div>
  );
}
