import React from 'react';

import PostAJob from '../../components/PostAJob';

const PostAJobButton = () => {
  return (
    <div className='postajob-container-allservices'>
      <p style={{ fontSize: '14px', fontWeight: '700', maxWidth: '500px' }}>
        If your desired service is not listed, you can also create a unique service request when you post a job.
      </p>
      <PostAJob isButton={true} />
      {/* <button className='postajob-button-allservices dashboard-component-animation' onClick={() => navigate('/dashboard/?action=post-a-job')}>
        Post a Job
      </button> */}
    </div>
  );
};

export default PostAJobButton;
