import React from 'react';
import { useNavigate } from 'react-router-dom';

import './servicesSection.css';

import LeftArrow from '../../assets/images/left-icon.svg';
import PostAJobButton from './postajobbutton';
import { assesments, extendedContractWork, trainingAndClasses, workPolicyDevelopment } from './services';
import Section from './serviceSection';

const AllServices = () => {
  const navigate = useNavigate();
  return (
    <>
      <section style={{ padding: '40px' }}>
        <div style={{ marginBottom: '50px' }}>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
            <button style={{ background: 'none', border: 'none' }} onClick={() => navigate('/dashboard')}>
              <img style={{ cursor: 'pointer' }} className='pe-2' width='18' height='30' src={LeftArrow} alt='' />
            </button>
            <h2 style={{ fontSize: '20px', fontWeight: '700', marginBottom: '0px' }}>Explore Services</h2>
          </div>
          <p style={{ fontSize: '14px' }}>
            Browse the services that YellowBird’s quality-assured <br /> Professionals can provide for you at the click of a button!
          </p>
        </div>
        <div className='services-section-container'>
          <Section heading='Assessments' services={assesments} />
          <Section heading='Workplace Policy Development' services={workPolicyDevelopment} />
          <Section heading='Training' services={trainingAndClasses} />
          <Section heading='Extended Pro Placement' services={extendedContractWork} />
        </div>
      </section>
      <PostAJobButton />
    </>
  );
};

export default AllServices;
