import moment from 'moment';

export const clipText = (txt, length = 23) => {
  if (txt.length > length) {
    return txt.slice(0, length - 3) + '...';
  } else {
    return txt;
  }
};

export const arrToText = selections => {
  return selections.join(', ');
};

/**
 * Format date to US locale
 *
 * @param {Date} value - Date object
 * @returns {string}   - Formatted date string
 *
 * @example
 * formatDate(new Date('2020-01-01')) // January 1, 2020
 * formatDate(new Date('2020-01-01T00:00:00.000Z')) // January 1, 2020
 * formatDate(new Date('2020-01-01T00:00:00.000')) // January 1, 2020
 * formatDate(new Date('2020-01-01T00:00:00')) // January 1, 2020
 * formatDate(new Date('2020-01-01T00:00')) // January 1, 2020
 * formatDate(new Date('2020-01-01T00')) // January 1, 2020
 * formatDate(new Date('2020-01-01T')) // January 1, 2020
 * formatDate(new Date('2020-01-01T00:00:00.000-05:00')) // January 1, 2020
 * formatDate(new Date('2020-01-01T00:00:00.000+05:00')) // January 1, 2020
 *
 */
export function formatDate(value) {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return value ? value.toLocaleDateString('en-US', options) : 'None Given';
}

/**
 * Rounds the given date to midnight (00:00:00).
 *
 * @param {Date} date - The date to be rounded.
 * @returns {Date} - The rounded date with time set to midnight.
 *
 * @example
 * roundDateToMidnight(new Date('2020-01-01T12:34:56.789Z')) // 2020-01-01T00:00:00.000Z
 */
export function roundDateToMidnight(date) {
  const d = new Date(date);
  d.setHours(0, 0, 0, 0); // Time set to midnight
  return d;
}

/**
 * Converts a date object or timestamp to an ISO formatted date string (YYYY-MM-DD).
 * If no date is provided, returns an empty string.
 *
 * @param {Date|string|null} date The date to convert. Can be a Date object or a string that can be parsed into a Date.
 * @return {string} The ISO formatted date string or an empty string if input is falsy.
 * @example
 * // returns "2023-01-01"
 * formatToISODate(new Date('2023-01-01T10:00:00Z'));
 *
 * @example
 * // returns "2023-01-02"
 * formatToISODate('2023-01-02');
 *
 * @example
 * // returns ""
 * formatToISODate(null);
 */
export const formatToISODate = date => {
  return date ? new Date(date).toISOString().slice(0, 10) : '';
};

/**
 * Gets today's date as an ISO formatted date string (YYYY-MM-DD).
 *
 * @return {string} Today's date in ISO format.
 * @example
 * // Example output if today is January 1, 2023
 * // returns "2023-01-01"
 * getTodayISODate();
 */
export const getTodayISODate = () => {
  return new Date().toISOString().slice(0, 10);
};

/**
 * Formats a date to MM/DD/YYYY.
 * If no date is provided, returns "None Given".
 * @param {Date|string|null} date The date to format. Can be a Date object or a string that can be parsed into a Date.
 * @return {string} The formatted date or "None Given" if input is falsy.
 * @example
 * // returns "01/01/2023"
 * formatDateMMDDYYYY(new Date('2023-01-01T10:00:00Z'));
 * @example
 * // returns "01/02/2023"
 * formatDateMMDDYYYY('2023-01-02');
 */
export const formatDateMMDDYYYY = date => {
  return date ? moment(date).format('MM/DD/YYYY') : 'None Given';
};

/**
 * Formats a date to MMM D, YYYY.
 * If no date is provided, returns "None Given".
 * @param {Date|string|null} date The date to format. Can be a Date object or a string that can be parsed into a Date.
 * @return {string} The formatted date or "None Given" if input is falsy.
 * @example
 * // returns "Jan 1, 2023"
 * formatDateFromUnix(new Date('2023-01-01T10:00:00Z'));
 */
export const formatDateFromUnix = date => {
  return date ? moment.unix(date).format('MMM D, YYYY') : 'None Given';
};

// every first letter of a word is capitalized
export function capitalize(str) {
  return str
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export function formatMoneyFromCents(amountInCents) {
  const amount = amountInCents / 100;

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(amount);
}
