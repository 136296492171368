import React, { useState } from 'react';
import { CheckCircle2, Trash2 } from 'lucide-react';
import { Badge, Button } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';

import useBaseURL from '../../hooks/useBaseURL';
import { deleteSingleDocument } from '../../slices/document.slice';

export default function UploadedDoc({ doc, index, length }) {
  const dispatch = useDispatch();
  const [deleting, setDeleting] = useState(false);
  const baseURL = useBaseURL();

  async function handleDeleteDocument() {
    setDeleting(true);
    try {
      await toast.promise(dispatch(deleteSingleDocument({ docId: doc.id, hashToken: doc.hashToken })), {
        loading: 'Deleting document',
        success: 'Document deleted successfully',
        error: 'Failed to delete document. Please try again later',
      });
    } catch (error) {
      // console.log({ error });
    } finally {
      setDeleting(false);
    }
  }

  const styles = {
    container: {
      display: 'flex',
      alignItems: 'center',
      margin: 0,
      padding: index === 0 ? '0 0 16px 0' : '16px 0',
      width: '100%',
      justifyContent: 'space-between',
      borderBottom: index === length - 1 ? 'none' : '1px solid lightgray',
    },
  };

  return (
    <div style={styles.container}>
      <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
        <CheckCircle2 size={26} color='#007bff' />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Button
            variant='link'
            title={doc.fileName}
            href={`${baseURL}/api/v1/workers/${doc?.worker?.id}/documents/${doc?.id}?hashToken=${doc?.hashToken}`}
            target='_blank'
            data-disabled={deleting}
            rel='noreferrer noopener'
            className='p-0 text-dark'
            style={{ textAlign: 'left' }}
            size='sm'
          >
            <strong>{doc.fileName || doc.name}</strong>
          </Button>
          {doc.category && (
            <Badge pill bg='warning' text='dark' style={{ width: 'fit-content' }}>
              {doc.category}
            </Badge>
          )}
        </div>
      </div>
      <Button
        type='button'
        onClick={handleDeleteDocument}
        disabled={deleting}
        size='sm'
        variant={deleting ? 'outline-secondary' : 'outline-danger'}
        className='rounded-full p-1.5'
        title='Delete Document'
      >
        {deleting ? (
          <div className='spinner-border spinner-border-sm' role='status'>
            <span className='visually-hidden'>Loading...</span>
          </div>
        ) : (
          <Trash2 size={16} />
        )}
      </Button>
    </div>
  );
}
