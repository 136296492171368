import { useFormContext } from 'react-hook-form';

import './referral.css';

import Navigation from '../../layout/navigation';
import ConditionedReferralInput from './conditionedReferralInput';
import RadioOption from './radioOption';

const ReferralStep = () => {
  const {
    watch,
    formState: { isValid, errors },
  } = useFormContext();
  const referralValue = watch('referral.value');

  return (
    <div className='multiStepContainer'>
      <div className='scrollAreaMulstiStep'>
        <h1 className='h1MultiStepForm'>
          How did you <br /> hear about us?
        </h1>
        <fieldset className='radioFieldsetReferral'>
          <RadioOption label='I have a Referral code' htmlForId='referralCode' value='referralCode' />
          {referralValue === 'referralCode' && (
            <ConditionedReferralInput fieldValue='referral.code' placeholder='Referral Code*' error={errors.referral?.code?.message?.toString()} />
          )}
          <RadioOption label='Referred by Someone' htmlForId=' referredSomeone' value='referralPerson' />
          {referralValue == 'referralPerson' && (
            <ConditionedReferralInput fieldValue='referral.by' placeholder='Email of Referrer*' error={errors.referral?.by?.message?.toString()} />
          )}
          <RadioOption value='google' htmlForId='googleSearchRadio' label='Google Search' />
          <RadioOption value='facebook' htmlForId='facebookRadio' label='Facebook' />
          <RadioOption value='linkedin' htmlForId='LinkedinRadio' label='Linkedin' />
          <RadioOption value='articleOrBlog' htmlForId='articleOrBlog' label='Article or Blog' />
          <RadioOption value='eventOrWebinar' htmlForId='eventOrWebinar' label='Event or Webinar' />
          <RadioOption value='podcast' htmlForId='podcast' label='Podcast' />
          <RadioOption value='adOrLink' htmlForId='adSponsoredLink' label='Ad or Sponsored Link' />
        </fieldset>
      </div>
      <Navigation disabled={isValid} />
    </div>
  );
};

export default ReferralStep;
