import React from 'react';
import { SetupIntent } from '@stripe/stripe-js';
import { AlertTriangle, CheckCircle } from 'lucide-react';
import { Button, Spinner } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { useLocation } from 'react-router-dom';

import apiCamelback from '../../api/apiCamelback';
import { useAppDispatch, useAppSelector } from '../../index';
import { markTaskAsCompleted } from '../../slices/job.slice';
import { updateGlobalSetupPayoutTaskForCompany, updateGlobalSetupPayoutTaskForPro } from '../../slices/task.slice';
import Modal from '../Modal';
import classes from './SetUpPaymentsStatusModal.module.scss';

export default function SetUpPaymentsStatusModal() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [loading, setLoading] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [stripeConnectAccount, setStripeConnectAccount] = React.useState<{ details_submitted: boolean; charges_enabled: boolean } | null>(null);
  const [stripeSetupIntentStatus, setStripeSetupIntentStatus] = React.useState<SetupIntent['status'] | null>(null);
  const user = useAppSelector(state => state.user.user);

  const searchParams = new URLSearchParams(location.search);
  const setupPaymentStatus = searchParams.get('setup-payment');
  const taskSid = searchParams.get('taskSid');

  React.useEffect(() => {
    // Fetch Stripe Connect Account for worker
    async function fetchStripeConnectAccountForWorker() {
      try {
        setLoading(true);
        const res = await apiCamelback.get(`/api/v1/workers/stripe-connect/account`);
        const account = res.data;
        if (account) {
          // This part is responsible for updating the related task status to completed
          // the taskSid is the task that started the payment setup process in the tasks tab in the job page
          const isAllSuccessful = account.details_submitted && account.charges_enabled;
          const onlyDetailsSubmitted = account.details_submitted && !account.charges_enabled;
          if (isAllSuccessful) {
            await dispatch(updateGlobalSetupPayoutTaskForPro({ globalTaskType: 'SETUP_PAYOUT', stage: 'complete' })).unwrap();
            if (taskSid) {
              // TODO: Remove ts-ignore when job.slice.js file is updated to typescript
              // @ts-ignore-next-line
              dispatch(markTaskAsCompleted({ taskSid, stage: 'COMPLETED' }));
            }
          } else if (onlyDetailsSubmitted) {
            await dispatch(updateGlobalSetupPayoutTaskForPro({ globalTaskType: 'SETUP_PAYOUT', stage: 'restart' })).unwrap();
            if (taskSid) {
              // TODO: Remove ts-ignore when job.slice.js file is updated to typescript
              // @ts-ignore-next-line
              dispatch(markTaskAsCompleted({ taskSid, stage: 'PENDING' }));
            }
          }
          if (isAllSuccessful) {
            toast.success('Payment setup completed successfully.', {
              position: 'top-right',
            });
          } else {
            setStripeConnectAccount(account);
            setOpenModal(true);
          }
        }
      } catch (error) {
        console.error('SetUpPaymentsStatusModal - fetchStripeConnectAccountForWorker: ', error);
        toast.error('An error occurred. Please try again later.');
      } finally {
        setLoading(false);
      }
    }
    async function fetchStripeSetupIntentStatusForCompany() {
      try {
        setLoading(true);
        const res = await apiCamelback.get(`/api/v1/company/stripe/setup-intent-status`);
        const setupIntentStatus = res.data as SetupIntent['status'];
        setStripeSetupIntentStatus(setupIntentStatus);
        if (setupIntentStatus === 'succeeded') {
          await dispatch(updateGlobalSetupPayoutTaskForCompany({ globalTaskType: 'SETUP_PAYOUT', stage: 'complete' })).unwrap();
          if (taskSid) {
            // TODO: Remove ts-ignore when job.slice.js file is updated to typescript
            // @ts-ignore-next-line
            dispatch(markTaskAsCompleted({ taskSid, stage: 'COMPLETED' }));
          }
          toast.success('Payment method added successfully.', {
            position: 'top-right',
          });
        } else if (setupIntentStatus === 'requires_action') {
          setOpenModal(true);
        }
      } catch (error) {
        console.error('SetUpPaymentsStatusModal - fetchStripeSetupIntentStatusForCompany: ', error);
        toast.error('An error occurred. Please try again later.');
      } finally {
        setLoading(false);
      }
    }

    if (setupPaymentStatus === 'success') {
      if (user.role === 'worker') {
        fetchStripeConnectAccountForWorker();
      } else if (user.role === 'user') {
        fetchStripeSetupIntentStatusForCompany();
      }
    }
    if (setupPaymentStatus === 'error') {
      toast.error('Error setting up payments. Please try again.');
      setLoading(false);
    }
  }, [setupPaymentStatus, setLoading, dispatch, taskSid, user.role]);

  if (loading) {
    return (
      <div className={classes.loading_container}>
        <Spinner animation='border' role='status'>
          <span className='visually-hidden'>Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <Modal show={openModal} centered onHide={() => setOpenModal(false)}>
      <div className='pb-5'>
        {stripeConnectAccount && setupPaymentStatus === 'success' && (
          <div className='d-flex flex-column text-center align-items-center gap-3 mx-auto' style={{ maxWidth: '500px' }}>
            {/* Banking Details Not Submitted */}
            {!stripeConnectAccount.details_submitted && (
              <>
                <AlertTriangle size={104} color='red' />
                <h4>You have not submitted your payment details. Please submit your details to receive payments.</h4>
              </>
            )}
            {/* Details Submitted but Charges Disabled */}
            {stripeConnectAccount.details_submitted && !stripeConnectAccount.charges_enabled && (
              <>
                <CheckCircle size={104} color='green' />
                <h4>
                  You have successfully submitted your payment details. We are currently reviewing your account. You will be notified once your account is
                  approved.
                </h4>
              </>
            )}
          </div>
        )}
        {stripeSetupIntentStatus === 'requires_action' && (
          <div className='d-flex flex-column text-center align-items-center gap-3 mx-auto' style={{ maxWidth: '500px' }}>
            <AlertTriangle size={104} color='orange' />
            <h4>Your payment method has been added but requires additional verification.</h4>
            <p>You will see your payment method added to your account once the verification is complete. This process can take between 1-2 business days.</p>
          </div>
        )}
        <div className='d-flex align-items-center justify-content-center mt-4'>
          <Button variant='primary' onClick={() => setOpenModal(false)} className='px-5'>
            Got it
          </Button>
        </div>
      </div>
    </Modal>
  );
}
