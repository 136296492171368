import { useContext } from 'react';
import { ChevronRight } from 'lucide-react';
import { useFormContext } from 'react-hook-form';

import { MethodsContext } from '../context';

import './step-0.css';

import { Link } from 'react-router-dom';

const Step0 = () => {
  const { setValue } = useFormContext();
  const { next } = useContext(MethodsContext);

  return (
    <div className='step0Container'>
      <h1 className='h1MultiStepForm'>Welcome!</h1>
      <div>
        <p className='already0'>Already have an account?</p>
        <Link className='signIn' to='/'>
          Sign in here.
        </Link>
      </div>
      <div className='boxContainerForm'>
        <div
          className='box0'
          onClick={() => {
            setValue('accountType', 'Organzation Account');
            next();
          }}
        >
          <div className='bar0 steel'></div>
          <div className='mainBoxDiv'>
            <div className='textBoxDiv'>
              <h2 className='h20'>
                Create an <br /> Organization Account
              </h2>
              <p className='ptext0'>I want to schedule EHS or Risk Services for my organization.</p>
            </div>
            <div className='iconDiv0'>
              <ChevronRight className='chevrownRight0' />
            </div>
          </div>
        </div>
        <Link className='box0' to='/register-pro'>
          <div className='bar0 canary'></div>
          <div className='mainBoxDiv'>
            <div className='textBoxDiv'>
              <h2 className='h20'>
                Set up a YellowBird <br />
                Pro Profile
              </h2>
              <p className='ptext0'>I'm an EHS or Risk Services professional who is available for flexible or full-time work.</p>
            </div>
            <div className='iconDiv0'>
              <ChevronRight className='chevrownRight0' />
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Step0;
