import React, { useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';

import './coDocUploader.scss';

import AddDoc from '../../../assets/images/add-docs-icon.svg';
import DragAndDropIcon from '../../../assets/images/drag-drop-icon.svg';
import Button from '../../../components/Button';
import Loading from '../../../components/Loading';
import Modal from '../../../components/Modal';
import { coCheckForDocs, coUploadDoc, createCoJobDoc, removeDocFromJob } from '../../../slices/document.slice';

const baseStyle = {
  flex: 1,
  height: '11rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '35px',
  borderWidth: 2,
  borderRadius: '1rem',
  borderColor: '#b4b4b4',
  borderStyle: 'dashed',
  backgroundColor: '#ffffff',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

const CoDocUploader = ({ docsToUse, setDocsToUse, detailView, coTemplates, jobId }) => {
  const state = useSelector(state => state.document);
  const { coDocs, isLoading } = state;
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  const [changeToSave, setChangeToSave] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  // const genericFileImg = require('../../assets/images/paper-icon.png').default;

  const uploadDocuments = doc => {
    if (doc.length > 0) {
      const docs = doc.map(doc => {
        return {
          data: doc.dataURL,
          type: doc.type,
          name: doc.name,
        };
      });
      dispatch(coUploadDoc({ docs, jobId: null }))
        .unwrap()
        .then(res => {
          setDocsToUse([...docsToUse, res]);
        });
    } else {
      return;
    }
  };

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: '.pdf, image/*, .docx, .doc, .txt',
    onDrop: acceptedFiles => {
      acceptedFiles.forEach(file => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          file.dataURL = reader.result.split(',')[1];
        };
      });

      const allFiles = [
        ...files,
        ...acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ];
      setTimeout(() => {
        uploadDocuments(allFiles);
      }, 500);
      setFiles(allFiles);
    },
  });
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach(file => {
        URL.revokeObjectURL(file.preview);
      });
    },
    [files]
  );
  useEffect(() => {
    if (docsToUse.length > 0) {
      setChangeToSave(true);
    } else {
      setChangeToSave(false);
    }
  }, [docsToUse]);
  useEffect(() => {
    dispatch(coCheckForDocs());
  }, []);

  // useEffect(() => {
  //   uploadDocuments(files);
  //   // setFiles([]);
  //   setDocsToUse(docsToUse);
  // }, [...files]);

  return (
    <div className='uploader-container' style={{ whiteSpace: 'nowrap' }}>
      <p className='mb-0' style={{ fontWeight: 700, fontSize: '15px', whiteSpace: 'nowrap' }}>
        Upload Required Report or Deliverable for Professional
      </p>
      {!isLoading ? (
        <div>
          <div>
            <div className='d-flex flex-row justify-content-between'>
              <input {...getInputProps()} />
              <label className='mb-0' htmlFor='uploader' style={{ color: '#b4b4b4', marginTop: '31.5px' }}>
                <p className='text-wrap'>Upload any documents the Pro is required to use for this job ( i.e.report template, training content, etc.)</p>
              </label>
            </div>
            <div>
              <div className='container' style={{ marginTop: '31.5px' }}>
                <div {...getRootProps({ style })}>
                  <input {...getInputProps()} />
                  <img src={DragAndDropIcon} style={{ cursor: 'pointer' }} alt='drag drop icon' />
                </div>
              </div>
            </div>
            {detailView && changeToSave ? (
              <div
                className='d-flex button primary-btn mx-5 mt-5 py-2 pointer-cursor justify-content-center'
                style={{ fontWeight: 600, whiteSpace: 'nowrap' }}
                onClick={() => {
                  let docIds = docsToUse.map(doc => doc.id);
                  dispatch(createCoJobDoc({ docIds: docIds, jobId: jobId }))
                    .unwrap()
                    .then(res => {
                      setDocsToUse([]);
                      setChangeToSave(false);
                    });
                }}
              >
                Save Changes
              </div>
            ) : null}
            <div className='d-flex justify-content-center' style={{ ...styles.headerText, marginTop: '31.5px', fontWeight: 500 }}>
              or
            </div>
            <div className='d-flex justify-content-center'>
              <Button
                style={{
                  ...styles.headerText,
                  border: 'none',
                  backgroundColor: 'transparent',
                  textDecoration: 'underline',
                  fontSize: '14px',
                  paddingBottom: 0,
                }}
                onClick={() => setDisplayModal(true)}
              >
                Choose from your previously Uploaded Documents
              </Button>
            </div>
            <div>
              {docsToUse.length > 0
                ? docsToUse.map(doc => {
                    return (
                      <div className='thumb-row-div' key={doc.id}>
                        <div
                          className='pointer-cursor'
                          onClick={() => {
                            let tempArr = docsToUse.filter(d => d.id !== doc.id);
                            setDocsToUse(tempArr);
                          }}
                        >
                          {/* <MdRemoveCircleOutline size={24} /> */}
                          <img src={AddDoc} alt='' />
                        </div>
                        <span style={{ whiteSpace: 'nowrap' }}>{doc.fileName}</span>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
        </div>
      ) : null}
      <div style={{ marginTop: '24px' }}>
        {files.length >= 1 && (
          <>
            <label className='labelText'>Files Uploaded</label>
            {files.map(file => (
              <div key={file.name}>{file.name}</div>
            ))}
          </>
        )}
      </div>
      <br />
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {detailView && coTemplates?.length > 0 ? (
            <div>
              {/* <h6 style={{ marginLeft: -35 }}>The following documents have been included in this job:</h6> */}
              {coTemplates.map(temp => {
                return (
                  <div className='thumb-row-div' style={{ marginLeft: -35 }} key={temp.id}>
                    <div
                      className='pointer-cursor'
                      onClick={() => {
                        dispatch(removeDocFromJob({ jobId: jobId, id: temp.id }));
                      }}
                    >
                      <img src={AddDoc} alt='' />
                    </div>
                    <span style={{ marginLeft: '12px', color: '#b4b4b4' }}>{temp.fileName}</span>
                  </div>
                );
              })}
              <br />
            </div>
          ) : null}
          <Modal size='lg' dialogClassName backdrop show={displayModal} centered onHide={() => setDisplayModal(!displayModal)}>
            <>
              {coDocs.length > 0 ? (
                <div style={{ minHeight: '650px' }}>
                  <h5 style={styles.headerText}>Choose from your previously Uploaded Documents</h5>
                  {coDocs.map(coDoc => {
                    if (!docsToUse.includes(coDoc)) {
                      return (
                        <div className='thumb-row-div' key={coDoc.id}>
                          <div
                            className='pointer-cursor'
                            onClick={() => {
                              setDocsToUse([...docsToUse, coDoc]);
                            }}
                          >
                            {/* <img src={AddIcon} alt='add icon' /> */}
                            <span className='file-label-div' style={{ textDecoration: 'underline' }}>
                              {coDoc.fileName}
                            </span>
                          </div>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
              ) : (
                <h5 style={styles.headerText}>No Documents have been Uploaded yet</h5>
              )}
            </>
          </Modal>
        </>
      )}
    </div>
  );
};

const styles = {
  headerText: {
    display: 'flex',
    justifyContent: 'center',
    color: '#586f7c',
    fontWeight: 700,
    paddingBottom: 20,
  },
};

export default CoDocUploader;
