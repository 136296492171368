import React from 'react';
import clsx from 'clsx';
import { Badge } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { Link, useParams } from 'react-router-dom';

import './singleJob.scss';

import LeftArrow from '../../assets/images/left-icon.svg';
import Loading from '../../components/Loading';
import { useAppDispatch, useAppSelector } from '../../index';
import { getCompanyPaymentMethods } from '../../slices/company.slice';
import { checkDocsForPro } from '../../slices/document.slice';
import { retrieveSingleCompanyJob, retrieveSingleWorkerJob } from '../../slices/job.slice';
import { getWorkerPaymentMethods } from '../../slices/worker.slice';
import { Todo } from '../../types';
import InvoicesPage from '../Invoices';
import CompanyJob from './CompanyJob';
import JobTasks from './JobTasks/index';
import ProJob from './ProJob';
import classes from './SingleJobPage.module.scss';

const allTabs = ['Overview', 'Tasks', 'Invoices'];

export default function SingleJobPage() {
  const { referenceNumber } = useParams();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = React.useState(false);
  const user = useAppSelector(state => state.user.user);
  const job: Todo = useAppSelector(state => state.job.jobOrder);

  const searchParams = new URLSearchParams(window.location.search);
  const paramTab = searchParams.get('tab') || '';
  const tab = allTabs.includes(paramTab) ? paramTab : allTabs[0];

  React.useEffect(() => {
    async function fetchSingleJob() {
      setLoading(true);
      try {
        if (user.role === 'worker') {
          dispatch(getWorkerPaymentMethods());
          // TODO: Remove ts-ignore when job.slice.js file is updated to typescript
          // @ts-ignore-next-line
          await dispatch(retrieveSingleWorkerJob({ referenceNumber })).unwrap();
        }
        if (user.role === 'user') {
          dispatch(getCompanyPaymentMethods());
          // TODO: Remove ts-ignore when job.slice.js file is updated to typescript
          // @ts-ignore-next-line
          await dispatch(retrieveSingleCompanyJob({ referenceNumber })).unwrap();
        }
      } catch (err) {
        console.error(err);
        toast.error('Error retrieving job. Please try again later.');
      } finally {
        setLoading(false);
      }
    }
    if (referenceNumber && Object.keys(user).length > 0) {
      fetchSingleJob();
    }
  }, [referenceNumber, dispatch, user]);

  React.useEffect(() => {
    if (job.id) {
      // TODO: Remove ts-ignore when document.slice.js file is updated to typescript
      // @ts-ignore-next-line
      dispatch(checkDocsForPro({ jobId: job.id }));
    }
  }, [dispatch, job.id]);

  if (loading || !job.id) {
    return <Loading />;
  }
  return (
    <div className={classes.single_job_container}>
      <div className={classes.top}>
        <div className='d-flex align-items-center gap-2'>
          <Link to='/my-jobs/current' style={{ background: 'none', border: 'none', cursor: 'pointer', paddingRight: '1rem' }}>
            <img width='16' height='16' src={LeftArrow} alt='' />
          </Link>
          <h2 className={classes.job_name}>{job.name}</h2>
        </div>
        <h3 className={classes.job_reference_number}>Ref: {referenceNumber}</h3>
      </div>
      {/* TABS */}
      <Tabs tab={tab} referenceNumber={referenceNumber!} />

      {/* JOB OVERVIEW TAB */}
      {tab === 'Overview' && (
        <>
          {user.role === 'user' && <CompanyJob />}
          {user.role === 'worker' && <ProJob />}
        </>
      )}
      {/* JOB TASKS TAB */}
      {tab === 'Tasks' && <JobTasks />}
      {/* JOB INVOICES TAB */}
      {tab === 'Invoices' && <InvoicesPage jobId={job.id} />}
    </div>
  );
}

type TabsProps = {
  tab: string;
  referenceNumber: string;
};

function Tabs({ tab, referenceNumber }: TabsProps) {
  const job: Todo = useAppSelector(state => state.job.jobOrder);
  const user = useAppSelector(state => state.user.user);

  const incompleteTasksCount = React.useMemo(() => {
    return job.allTasks?.filter((task: Todo) => task.stage !== 'COMPLETED').length || 0;
  }, [job]);

  const tabs = React.useMemo(() => {
    if (user.role === 'user') {
      return allTabs;
    }
    return allTabs.filter(t => t !== 'Invoices');
  }, [user.role]);

  return (
    <div className={classes.tabs_container}>
      {tabs.map(t => (
        <Link to={`/job/${referenceNumber}?tab=${t}`} key={t} className={clsx(classes.tab, tab === t && classes.tab_active)}>
          {t}
          {t === 'Tasks' && incompleteTasksCount > 0 && (
            <Badge pill bg='danger'>
              {incompleteTasksCount}
            </Badge>
          )}
        </Link>
      ))}
    </div>
  );
}
