import { v4 as uuid4 } from 'uuid';

type Props = {
  itemsChecked: (string | null | undefined)[];
  value: string | undefined;
  handleCheckboxChange: (value: string | undefined) => void;
};

export default function EditProfileCheckbox({ value, itemsChecked, handleCheckboxChange }: Props) {
  const id = uuid4();

  return (
    <div className='flex gap-2' onClick={e => e.stopPropagation()}>
      <div>
        <input id={id} checked={itemsChecked.includes(value)} onChange={() => handleCheckboxChange(value)} value={value} type='checkbox' />
      </div>
      <label htmlFor={id}>{value}</label>
    </div>
  );
}
