import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import Button from '../../../components/Button';
import InputError from '../../../components/InputError';
import TextInput from '../../../components/TextInput';
import { addCoUser } from '../../../slices/company.slice';

AdditionalUsersForm.propTypes = {
  values: PropTypes.object,
  setShowAddUserModal: PropTypes.func.isRequired,
};

export default function AdditionalUsersForm({ values: initialValues, setShowAddUserModal }) {
  const dispatch = useDispatch();
  const { handleSubmit, handleChange, handleBlur, values, errors, touched } = useFormik({
    initialValues: {
      firstName: initialValues.firstName,
      lastName: initialValues.lastName,
      email: initialValues.email,
      password: initialValues.password,
      companyId: initialValues.companyId,
      role: initialValues.role,
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required('Required'),
      lastName: Yup.string().required('Required'),
      email: Yup.string().email('Invalid email address').required('Required'),
      password: Yup.string().min(6).required('Required'),
    }),
    onSubmit: values => {
      dispatch(addCoUser(values));
      setShowAddUserModal(false);
    },
  });
  return (
    <Container fluid className='px-0'>
      <Row>
        <Col>
          <div style={{ fontWeight: 600, fontSize: '1.25rem' }}>Add an Additional User</div>
        </Col>
      </Row>
      <form className='mt-3' onSubmit={handleSubmit}>
        <Row>
          <Col className='col-6'>
            <TextInput
              id='firstName'
              name='firstName'
              label='First Name'
              placeholder='Enter Their First Name'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.firstName}
            />
            {touched.firstName && errors.firstName ? <InputError>{errors.firstName}</InputError> : null}
          </Col>
          <Col className='col-6'>
            <TextInput
              id='lastName'
              name='lastName'
              label='Last Name'
              placeholder='Enter Their Last Name'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.lastName}
            />
            {touched.lastName && errors.lastName ? <InputError>{errors.lastName}</InputError> : null}
          </Col>
        </Row>
        <Row className='mt-3'>
          <Col className='col-6'>
            <TextInput
              id='email'
              name='email'
              label='Email'
              placeholder='Enter Their Email Address'
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.email}
            />
            {touched.email && errors.email ? <InputError>{errors.email}</InputError> : null}
          </Col>
          <Col className='col-6'>
            <TextInput
              id='password'
              name='password'
              type='password'
              label='Password'
              placeholder='Enter Their Password'
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.password}
            />
            {touched.password && errors.password ? <InputError>{errors.password}</InputError> : null}
          </Col>
        </Row>
        <Row className='my-3'>
          <Col sm={12} className='d-flex justify-content-center mt-2'>
            <Button className='button primary-btn change-info-submit' type='submit' style={{ fontWeight: 700, fontSize: '16px' }}>
              Confirm
            </Button>
          </Col>
        </Row>
      </form>
    </Container>
  );
}
