import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { camelizeKeys } from 'humps';

import api from '../api';
import apiCamelback from "../api/apiCamelback";

// Thunks
export const retrieveOnboardingTasks = createAsyncThunk('onboarding/retrieveTasks', async (_, thunkApi) => {
  try {
    const response = await apiCamelback.get('/api/v1/onboardings');
    if (response.data.errors) return thunkApi.rejectWithValue(response.data.errors);
    return camelizeKeys(response.data);
  } catch (err) {
    return thunkApi.rejectWithValue(err);
  }
});

export const createOnboardingTasks = createAsyncThunk('onboarding/createTasks', async ({ taskList }, thunkApi) => {
  try {
    const response = await apiCamelback.post('/api/v1/onboardings', { task_list: taskList });
    if (response.data.errors) return thunkApi.rejectWithValue(response.data.errors);
    return camelizeKeys(response.data);
  } catch (err) {
    return thunkApi.rejectWithValue(err);
  }
});

export const updateTask = createAsyncThunk('onboarding/updateTask', async ({ taskId, status }, thunkApi) => {
  try {
    const response = await apiCamelback.put(`/api/v1/onboardings/${taskId}`, { status });
    if (response.data.errors) return thunkApi.rejectWithValue(response.data.errors);
    return camelizeKeys(response.data);
  } catch (err) {
    return thunkApi.rejectWithValue(err);
  }
});

export const retrieveOnboardingList = createAsyncThunk('onboarding/retrieveOnboardingList', async (_, thunkApi) => {
  try {
    const response = await api.get('/api/v1/onboardings/all');
    if (response.data.errors) return thunkApi.rejectWithValue(response.data.errors);
    return camelizeKeys(response.data);
  } catch (err) {
    return thunkApi.rejectWithValue(err);
  }
});

const initialState = {
  error: null,
  isLoading: false,
  data: [],
};

export const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      // retrieveOnboardingTasks
      .addCase(retrieveOnboardingTasks.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(retrieveOnboardingTasks.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(retrieveOnboardingTasks.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      // updateTask
      .addCase(updateTask.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateTask.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(updateTask.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      // retrieveOnboardingList
      .addCase(retrieveOnboardingList.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(retrieveOnboardingList.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(retrieveOnboardingList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      });
  },
});
