import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import FullStarSVG from '../../assets/images/star-filled.svg';
import EmptyStarSVG from '../../assets/images/star-outline.svg';
import ShadowedBox from '../../components/Containers/ShadowedBox';
import Header from '../../components/Layout/Header';
import Loading from '../../components/Loading';
import NewButton from '../../components/NewButton';
import Spacer from '../../components/Spacer';
import Text from '../../components/Text';
import TextInput from '../../components/TextInput';
import useWindowDimensions from '../../hooks/useWindowDimensions';

const CompletedReview = ({ review }) => {
  const { width } = useWindowDimensions();
  const isMobile = width < 760;
  const [iL, setIL] = useState(true);
  const [payloadArr, setPayloadArr] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    let payload = [];
    for (const property in review?.payload) {
      payload.push(review.payload[property]);
    }
    setPayloadArr([...payload]);
    setIL(false);
  }, [review]);
  let emptyStars = [1, 2, 3, 4, 5];

  const QAndAs = () => {
    const styles = {
      starRow: {
        display: 'flex',
        margin: 8,
        marginTop: 12,
      },
      starContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // marginBottom: 12,
        // borderStyle: 'dashed',
      },
      starDiv: {
        marginLeft: 8,
        marginRight: 8,
      },
      questionContainer: {
        margin: 12,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        textAlign: 'center',
        width: 280,
      },
    };
    return payloadArr.map((item, i) => {
      if (!isNaN(parseInt(item.a))) {
        let fullStars = [];
        for (let i = 0; i < parseInt(item.a); i++) {
          fullStars.push(0);
        }
        const stars = fullStars.concat(emptyStars);
        return (
          <div style={styles.starContainer}>
            <Text smallHeading>{item.q}</Text>
            <div style={styles.starRow}>
              {stars.map((star, i) => {
                if (i < 5) {
                  return (
                    <div style={styles.starDiv}>
                      <img style={{ width: 40 }} src={star === 0 ? FullStarSVG : EmptyStarSVG} alt='star' />
                    </div>
                  );
                } else {
                  return null;
                }
              })}
            </div>
            <div style={{ width: isMobile ? 360 : 600 }}>
              <TextInput style={{ textAlign: 'center' }} value={item.more || 'No Comments'} onChange={e => console.log(e)} disabled={true} />
            </div>
          </div>
        );
      } else {
        return (
          <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 12 }}>
            <div>
              <Text smallHeading>{item.q + ': '}</Text>
            </div>
            <div>
              <Text smallHeading>{item.a}</Text>
            </div>
          </div>
        );
      }
    });
  };
  const styles = {
    container: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  };
  if (iL) return <Loading />;
  return (
    <div style={styles.container}>
      <Header />
      {isMobile ? <Spacer h={76} /> : null}
      <ShadowedBox>
        <div style={{ width: isMobile ? 300 : 600, textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Text heading>Your Review</Text>
          <QAndAs />
          <Spacer h={24} />
          <NewButton main label='Back' onClick={() => navigate('/my-jobs')} />
        </div>
      </ShadowedBox>
    </div>
  );
};

export default CompletedReview;
