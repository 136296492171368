import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import { decamelizeKeys } from 'humps';

import api from '../api';
import apiCamelback from '../api/apiCamelback';
import { type Data as SignUpProData } from '../pages/ProRegistration/ProRegContext';
import { Todo } from '../types';

// TODO: This was based from github link https://github.com/YellowBird-Holdings/camelback/blob/develop/src/application/response/auth/legacy-auth-user-response.dto.ts
type User = {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  role: string;
  status: string;
  created_at: Date;
  updated_at: Date;
  company_id: number | null;
  referral: string | null;
  worker_id: number | null;
  referral_code: string;
  referral_source: string;
  hubspot_vid: string | null;
  phone_number: string | null;
  created_by: string | null;
  updated_by: string | null;
};

interface LoginResponse {
  token: string;
  user: User;
}

interface LoginRequest {
  email: string;
  password: string;
}

export const loginUser = createAsyncThunk<
  LoginResponse,
  LoginRequest,
  { rejectValue: string } // The type for `thunkApi.rejectWithValue`, if error handling is customized
>('/auth/loginUser', async (body, thunkApi) => {
  try {
    const response = await apiCamelback.post('/api/v1/auth', body);
    const data = response.data;

    if (data.errors) {
      return thunkApi.rejectWithValue(data.errors);
    }
    localStorage.setItem('token', data.token);
    return data;
  } catch (error: unknown) {
    // Handle errors in axios request (includes HTTP status code errors)
    if (axios.isAxiosError(error) && error.response) {
      // If the error response is available and contains the response data
      return thunkApi.rejectWithValue(error.response.data?.message || 'Unknown server error');
    } else {
      // If the error is not from axios (not an AxiosError)
      console.error('An unknown error occurred', error);
      return thunkApi.rejectWithValue('An unknown error occurred');
    }
  }
});

export const signUpPro = createAsyncThunk<
  Todo, // The type of the return value of the payload creator
  SignUpProData, // The type of the first argument to the payload creator
  {
    rejectValue: Todo; // The type for `thunkApi.rejectWithValue`, if error handling is customized
  }
>('/auth/signUpPro', async (body, thunkApi) => {
  let decamelized = decamelizeKeys(body);

  const basicDetails = {
    firstName: body.firstName,
    lastName: body.lastName,
    email: body.email,
    password: body.password,
    referral: body.referral,
    referralSource: body.referralSource,
  };
  let decamelizedBasic: Todo = decamelizeKeys(basicDetails);
  decamelizedBasic.referral_code = basicDetails.firstName.split(' ')[0].toUpperCase() + Math.floor(1000 + Math.random() * 9000);
  let signUpProResponse: AxiosResponse<Todo>;
  let workerResponse: AxiosResponse<Todo>;
  decamelizedBasic.role = 'worker';

  try {
    signUpProResponse = await apiCamelback.post('api/v1/users', decamelizedBasic);
    const data = signUpProResponse.data;
    if (data.errors) {
      return thunkApi.rejectWithValue(data.errors);
    }

    localStorage.setItem('token', data.token);
    workerResponse = await apiCamelback.post('api/v1/workers', decamelized);
    const workerData = workerResponse.data;

    if (workerData.errors) {
      return thunkApi.rejectWithValue(workerData.errors);
    }
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error.response) {
      return thunkApi.rejectWithValue(error.response.data?.message || 'Unknown server error');
    } else {
      console.error('An unknown error occurred', error);
      return thunkApi.rejectWithValue('An unknown error occurred');
    }
  }

  try {
    // Calling Camelback API to index new worker in Elastic Search
    await apiCamelback.post(`api/v1/elasticsearch/worker/${workerResponse.data.id}/create`);
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error.response) {
      console.error({ message: error?.response?.data?.message || error.message, error });
    } else {
      console.error('An unknown error occurred', error);
    }
  }

  const newResponse = {
    user: signUpProResponse.data,
    token: signUpProResponse.data.token,
    worker: workerResponse.data,
  };

  return newResponse;
});

/**
 * @deprecated
 * @see checkEmailCo
 */
export const checkEmail = createAsyncThunk<
  Todo, // The type of the return value of the payload creator
  Todo, // The type of the first argument to the payload creator
  {
    rejectValue: Todo; // The type for `thunkApi.rejectWithValue`, if error handling is customized
  }
>('auth/checkEmail', async (email, thunkApi) => {
  try {
    const response = await api.post('/api/v1/users/check_email', { email });
    const data = response.data;
    if (data.errors) {
      return thunkApi.rejectWithValue(data.errors.message);
    }
    return data.message;
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error.response) {
      return thunkApi.rejectWithValue(error.response.data?.message || 'Unknown server error');
    } else {
      console.error('An unknown error occurred', error);
      return thunkApi.rejectWithValue('An unknown error occurred');
    }
  }
});
/**
 * Uses api camelback to check email - used in onboardings pro/co
 * @todo unfiy across all usages as checkEmail
 */
export const checkEmailCo = createAsyncThunk<
  Todo, // The type of the return value of the payload creator
  Todo, // The type of the first argument to the payload creator
  {
    rejectValue: Todo; // The type for `thunkApi.rejectWithValue`, if error handling is customized
  }
>('auth/checkEmail', async (body, thunkApi) => {
  try {
    const response = await apiCamelback.post('/api/v1/users/check_email', body);
    const data = response.data;
    if (data.errors) {
      return thunkApi.rejectWithValue(data.errors.message);
    }
    return data.message;
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error.response) {
      return thunkApi.rejectWithValue(error.response.data?.message || 'Unknown server error');
    } else {
      console.error('An unknown error occurred', error);
      return thunkApi.rejectWithValue('An unknown error occurred');
    }
  }
});

export const signUpCo = createAsyncThunk<
  Todo, // The type of the return value of the payload creator
  Todo, // The type of the first argument to the payload creator
  {
    rejectValue: Todo; // The type for `thunkApi.rejectWithValue`, if error handling is customized
  }
>('/auth/signUpCo', async (body, thunkApi) => {
  const bodyWithCode = { ...body, code: body.firstName.split(' ')[0].toUpperCase() + Math.floor(1000 + Math.random() * 9000) };
  try {
    const response = await apiCamelback.post('api/v1/users/sign_up', bodyWithCode);
    const data = response.data;

    if (data.errors) {
      return thunkApi.rejectWithValue(data.errors);
    }

    localStorage.setItem('token', response.data.access_token);
    return data.message;
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error.response) {
      return thunkApi.rejectWithValue(error.response.data?.message || 'Unknown server error');
    } else {
      console.error('An unknown error occurred', error);
      return thunkApi.rejectWithValue('An unknown error occurred');
    }
  }
});

export const sendOtpCode = createAsyncThunk<
  Todo, // The type of the return value of the payload creator
  Todo, // The type of the first argument to the payload creator
  {
    rejectValue: Todo; // The type for `thunkApi.rejectWithValue`, if error handling is customized
  }
>('', async (body, thunkApi) => {
  const decamelized = decamelizeKeys(body);

  try {
    const response = await apiCamelback.post('api/v1/users/sign_up/send_otp_code', decamelized);
    const data = response.data;

    if (data.errors) {
      return thunkApi.rejectWithValue(data.errors.message);
    }

    return data.message;
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error.response) {
      return thunkApi.rejectWithValue(error.response.data?.message || 'Unknown server error');
    } else {
      console.error('An unknown error occurred', error);
      return thunkApi.rejectWithValue('An unknown error occurred');
    }
  }
});

// export const updateUser = createAsyncThunk();

// export const deleteAccount = createAsyncThunk();

export const updatePassword = createAsyncThunk<
  Todo, // The type of the return value of the payload creator
  Todo, // The type of the first argument to the payload creator
  {
    rejectValue: Todo; // The type for `thunkApi.rejectWithValue`, if error handling is customized
  }
>('auth/updatePassword', async (values, thunkApi) => {
  let decamelized = decamelizeKeys(values);

  try {
    const response = await apiCamelback.put('/api/v1/users/me', decamelized);
    const data = response.data;

    if (data.errors) {
      return thunkApi.rejectWithValue(data.errors);
    }
    return data;
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error.response) {
      return thunkApi.rejectWithValue(error.response.data?.message || 'Error updating user');
    } else {
      console.error('An unknown error occurred', error);
      return thunkApi.rejectWithValue('An unknown error occurred');
    }
  }
});

export const resetPassword = createAsyncThunk<
  Todo, // The type of the return value of the payload creator
  Todo, // The type of the first argument to the payload creator
  {
    rejectValue: Todo; // The type for `thunkApi.rejectWithValue`, if error handling is customized
  }
>('auth/resetPassword', async (user, thunkApi) => {
  try {
    const response = await apiCamelback.post('/api/v1/users/password', { user: user });
    const data = response.data;

    if (data.errors) {
      return thunkApi.rejectWithValue(data.errors);
    }

    return 'success';
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error.response) {
      return thunkApi.rejectWithValue(error?.response?.data?.message || 'Error resetting user');
    } else {
      console.error('An unknown error occurred', error);
      return thunkApi.rejectWithValue('An unknown error occurred');
    }
  }
});

export const changePassword = createAsyncThunk<
  Todo, // The type of the return value of the payload creator
  Todo, // The type of the first argument to the payload creator
  {
    rejectValue: Todo; // The type for `thunkApi.rejectWithValue`, if error handling is customized
  }
>('auth/changePassword', async (user, thunkApi) => {
  try {
    const response = await apiCamelback.put('/api/v1/users/password', { user });
    const data = response.data;
    if (data.errors) {
      return thunkApi.rejectWithValue(data.errors);
    }
    return 'success';
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error.response) {
      return thunkApi.rejectWithValue(error?.response?.data?.message || 'Error changing password');
    } else {
      console.error('An unknown error occurred', error);
      return thunkApi.rejectWithValue('An unknown error occurred');
    }
  }
});

interface AuthState {
  error: Todo;
  isLoading: boolean;
  auth: User | {};
  token?: string;
  worker: Todo;
  availabilityMessage: string;
  redirectToDeepLink: string | null;
}

const initialState: AuthState = {
  error: null,
  isLoading: false,
  auth: {},
  token: undefined,
  worker: {},
  availabilityMessage: '',
  redirectToDeepLink: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    saveRedirectToDeepLink: (state, action) => {
      state.redirectToDeepLink = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loginUser.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(loginUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(loginUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.auth = action.payload.user;
        state.token = action.payload.token;
      })

      .addCase(signUpCo.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(signUpCo.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(signUpCo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.auth = action.payload;
      })

      .addCase(signUpPro.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(signUpPro.rejected, (state, action) => {
        state.isLoading = false;
      })

      .addCase(signUpPro.fulfilled, (state, action) => {
        state.isLoading = false;
        state.auth = action.payload.user;
        state.token = action.payload.token;
        state.worker = action.payload.worker;
      })

      .addCase(updatePassword.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(updatePassword.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(updatePassword.fulfilled, (state, action) => {
        state.isLoading = false;
      })

      .addCase(resetPassword.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.auth = action.payload;
      })

      .addCase(changePassword.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.auth = action.payload;
      })

      .addCase(checkEmail.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(checkEmail.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(checkEmail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.availabilityMessage = action.payload;
      });
  },
});
export const { saveRedirectToDeepLink } = authSlice.actions;
