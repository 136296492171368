import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useDispatch, useSelector } from 'react-redux';

import Modal from '../../../components/Modal';
import NewButton from '../../../components/NewButton';
import PaymentMethod from '../../../components/OldPaymentMethod';
import Radio from '../../../components/Radio';
import Spacer from '../../../components/Spacer';
import Text from '../../../components/Text';
import { STRIPE_PUBLISHABLE_KEY } from '../../../env';
import { createIntent, getCompanyPaymentMethods } from '../../../slices/company.slice';
import { updateJobPaymentMethod } from '../../../slices/job.slice';
import CheckoutForm from '../../OldCreateJob/CheckoutForm';

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const styles = {
  paymentContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  radioRow: {
    display: 'flex',
    alignItems: 'center',
  },
};

export default function DeclineModal({ showModal, setShowModal, jobId }) {
  const state = useSelector(state => state);
  const dispatch = useDispatch();
  const { paymentMethods, intent } = state.company;
  const [addNewCard, setAddNewCard] = React.useState(false);
  const [paymentMethodSelection, setPaymentMethodSelection] = React.useState('');
  const [cardToUse, setCardToUse] = React.useState('');

  React.useEffect(() => {
    dispatch(getCompanyPaymentMethods()).then(response => {
      setPaymentMethodSelection('Card');
      if (response.payload.length === 0) {
      }
    });
  }, [cardToUse]);

  const handleRadio = e => {
    if (e.target.value === 'NewCard') {
      setAddNewCard(true);
      dispatch(createIntent());
    }
    setPaymentMethodSelection(e.target.value);
  };

  const handleButtonClick = () => {
    dispatch(updateJobPaymentMethod({ job_id: jobId, payment_method_selection: paymentMethodSelection, stripe_payment_method: cardToUse })).then(() => {
      setShowModal(false);
    });
  };

  return (
    <Modal show={showModal} onHide={() => setShowModal(false)}>
      <Spacer h={16} />
      <div>
        <Text t='red'>Your card payment was DECLINED select another saved card, add a new card, or select pay by invoice.</Text>
      </div>
      <Spacer h={24} />

      <div style={styles.radioRow}>
        <Radio
          id='cardRadio'
          name='payRadio'
          value='Card'
          onChange={handleRadio}
          checked={paymentMethodSelection === 'Card'}
          overrideContainerStyle={{ display: 'inline-block' }}
        />
        <Text t='greyInline'>Select a previously saved Card</Text>
      </div>
      {paymentMethodSelection === 'Card' ? (
        paymentMethods.length > 0 ? (
          <div style={styles.paymentContainer}>
            {paymentMethods.map(pm => {
              return <PaymentMethod key={pm.id} data={pm} onClick={() => setCardToUse(pm.id)} selected={cardToUse === pm.id} />;
            })}
          </div>
        ) : null
      ) : null}
      <Spacer h={24} />

      <div style={styles.radioRow}>
        <Radio
          id='newCardRadio'
          name='payRadio'
          value='NewCard'
          onChange={handleRadio}
          checked={paymentMethodSelection === 'NewCard'}
          overrideContainerStyle={{ display: 'inline-block' }}
        />
        <Text t='greyInline'>Add a new Card</Text>
      </div>
      {paymentMethodSelection === 'NewCard' ? (
        <div>
          {intent ? (
            <Elements stripe={stripePromise} options={{ clientSecret: intent.client_secret }}>
              <CheckoutForm intentObj={intent} setCardToUse={setCardToUse} setAddNewCard={setAddNewCard} />
            </Elements>
          ) : null}
        </div>
      ) : null}
      <Spacer h={24} />

      <div style={styles.radioRow}>
        <Radio
          id='invoiceRadio'
          name='payRadio'
          value='Invoice'
          onChange={handleRadio}
          checked={paymentMethodSelection === 'Invoice'}
          overrideContainerStyle={{ display: 'inline-block' }}
        />
        <Text t='greyInline'>Pay by Invoice</Text>
      </div>
      {paymentMethodSelection === 'Invoice' ? (
        <Text>
          We invoice 100% of the total upfront due Net 14. Typically all gigs are completed within this 14 day period. Please click “Accept Order” which will
          then initiate an invoice for 100% of the Total Order. Once the invoice has been paid the matching process can begin.
        </Text>
      ) : null}
      <Spacer h={24} />
      <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
        <Spacer h={24} />
        <NewButton onClick={handleButtonClick} main label='Update Payment Method' />
      </div>
      <Spacer h={24} />
    </Modal>
  );
}
