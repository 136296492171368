import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { backgroundCheckLink, orientationVideoLink } from '../../../../lib/options';
import { updateTask } from '../../../../slices/onboarding.slice';
import StatusIcon from '../StatusIcon';

import './OnboardingStep.scss';

const OnboardingStep = ({ order, onboarding, title, details, status, prevStepComplete }) => {
  const dispatch = useDispatch();

  let statusTitle;
  switch (status) {
    case 0:
      statusTitle = 'Not Started';
      break;
    case 1:
      statusTitle = 'Pending Amin Approval';
      break;
    case 2:
      statusTitle = 'Completed';
      break;
    case 3:
      statusTitle = 'Action Required';
      break;
    case 4:
      statusTitle = 'Processing';
      break;
    default:
      statusTitle = 'Not Started';
  }

  let targetPage;
  switch (title) {
    case 'Sign up for Orientation':
      targetPage = 'orientation';
      break;
    case 'Complete Background Check':
      targetPage = 'background';
      break;
    default:
      targetPage = 'home';
  }

  const handleClick = () => {
    if (targetPage === 'orientation') {
      window.open(orientationVideoLink, '_blank');
      const taskId = onboarding[0].id;
      dispatch(updateTask({ taskId, status: 4 }));
    }
    if (status !== 2 && prevStepComplete) {
      if (targetPage === 'background') {
        window.open(backgroundCheckLink, '_blank');
        const taskId = onboarding[1].id;
        dispatch(updateTask({ taskId, status: 4 }));
      }
    }
  };

  return (
    <Row className='mb-4'>
      <Col className='d-flex flex-column justify-content-center'>
        <span
          onClick={handleClick}
          className={`onboarding-step onboarding__step-title ${status === 2 ? 'onboarding__step__complete' : ''} ${
            !prevStepComplete ? 'onboarding__step__prev-incomplete' : ''
          } ${status === 4 && targetPage === 'meeting' ? 'onboarding__disable-cursor' : ''}`}
        >
          {order}. {title === 'Sign up for Orientation' ? 'Watch Orientation and Complete Quiz' : title}
        </span>
        <br />
        <span className={`onboarding-step__details`}>{status === 2 || status === 0 ? '' : details}</span>
      </Col>
      <Col style={{ maxWidth: 172 }} className='d-flex align-items-center justify-content-between'>
        <StatusIcon status={status} />
        <span className={`onboarding-step onboarding__step-progress ${status === 2 ? 'onboarding__step__complete' : ''}`}>{statusTitle}</span>
      </Col>
    </Row>
  );
};

export default OnboardingStep;
