import React from 'react';

import { Todo } from '../../types';
import DeletePaymentMethodForCompanyModal from './DeletePaymentMethodForCompanyModal';
import PaymentMethod from './PaymentMethod';
import classes from './paymentMethodList.module.scss';
import SetPaymentMethodAsDefaultForCompanyModal from './SetPaymentMethodAsDefaultForCompanyModal';

export default function PaymentMethodList({ paymentMethods }: Todo) {
  const [showDeletePaymentMethodModal, setShowDeletePaymentMethodModal] = React.useState(false);
  const [paymentMethodToDelete, setPaymentMethodToDelete] = React.useState(null);
  const [showSetPaymentMethodAsDefaultModal, setShowSetPaymentMethodAsDefaultModal] = React.useState(false);
  const [paymentMethodToDefault, setPaymentMethodToDefault] = React.useState(null);
  return paymentMethods && paymentMethods.length > 0 ? (
    <>
      <ul className={classes.payment_methods}>
        {paymentMethods.map((paymentMethod: Todo) => (
          <PaymentMethod
            key={paymentMethod.id}
            paymentMethod={paymentMethod}
            setShowDeletePaymentMethodModal={setShowDeletePaymentMethodModal}
            setPaymentMethodToDelete={setPaymentMethodToDelete}
            setShowSetPaymentMethodAsDefaultModal={setShowSetPaymentMethodAsDefaultModal}
            setPaymentMethodToDefault={setPaymentMethodToDefault}
          />
        ))}
      </ul>
      <DeletePaymentMethodForCompanyModal
        paymentMethod={paymentMethodToDelete}
        show={showDeletePaymentMethodModal && !!paymentMethodToDelete}
        onHide={() => {
          setShowDeletePaymentMethodModal(false);
          setPaymentMethodToDelete(null);
        }}
      />
      <SetPaymentMethodAsDefaultForCompanyModal
        paymentMethod={paymentMethodToDefault}
        show={showSetPaymentMethodAsDefaultModal && !!paymentMethodToDefault}
        onHide={() => {
          setShowSetPaymentMethodAsDefaultModal(false);
          setPaymentMethodToDefault(null);
        }}
      />
    </>
  ) : null;
}
