import { useEffect, useMemo, useState } from 'react';
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate, useParams } from 'react-router-dom';

import LeftArrow from '../../assets/images/left-icon.svg';
import Dropdown from '../../components/Dropdown';
import JobRow from '../../components/JobRow';
import JobRowMobile from '../../components/JobRowMobile';
import Loading from '../../components/Loading';
import { sortedJobsOptions } from '../../lib/options';
import { getDocuments } from '../../slices/document.slice';
import { coRetrieveJobs, getAllDraftJobs, getMatrixData, proRetrieveJobs } from '../../slices/job.slice';
import { getCoReviews, getReviews } from '../../slices/proReviewByCo.slice';
import { fetchWorkerById } from '../../slices/worker.slice';
import ConfirmationModal from '../ProAcceptJob/ConfirmationModal';
import JobConfirmationModal from '../ProAcceptJob/JobConfirmationModal';
import DraftJobRow from './draft-job-row';
import DraftJobRowMobile from './draft-job-row-mobile';

import './myJobsScreen.scss';

const CURRENT_JOB_STATUSES = ['InProgress', 'Posted', 'Approved', 'Negotiating', 'Matched'];
const PAST_JOB_STATUSES = ['Completed', 'ClosedPaid', 'ClosedLost', 'ClosedNoResponse', 'ClosedDidntComplete'];

export default function MyJobsScreen() {
  const { category } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedJobCategory, setSelectedJobCategory] = useState(category);
  const [selectedJobs, setSelectedJobs] = useState([]);
  const [sortedJobs, setSortedJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sortByProperty, setSortByProperty] = useState('');
  const [jobToOpen, setJobToOpen] = useState(null);
  const user = useSelector(state => state.user.user);
  const { allJobs, jobInvite, allDraftJobs: draftJobs } = useSelector(state => state.job);

  function getQueryParameter(name) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(name);
  }

  const action = getQueryParameter('action');
  const jobId = getQueryParameter('jobId');
  const showModalService = action === 'show-confirmation-modal';
  const job = jobInvite?.jobInfo?.name || null;

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      if (user.role === 'worker') {
        await Promise.all([
          dispatch(fetchWorkerById(user.workerId)),
          dispatch(getDocuments()),
          dispatch(proRetrieveJobs()),
          dispatch(getMatrixData()),
          dispatch(getCoReviews()),
        ]);
      } else {
        await Promise.all([dispatch(coRetrieveJobs()), dispatch(getReviews()), dispatch(getAllDraftJobs())]);
      }
      setLoading(false);
    }
    if (Object.keys(user).length > 0) {
      fetchData();
    }
  }, [user, dispatch]);

  const otherJobs = useMemo(() => {
    if (user.role === 'worker') {
      if (allJobs.length > 0) {
        return allJobs.map(jobInvite => {
          return { ...jobInvite.job, uncompletedTasks: jobInvite.uncompletedTasks };
        });
      } else {
        return allJobs;
      }
    } else {
      return allJobs;
    }
  }, [user.role, allJobs]);

  useEffect(() => {
    // Function to filter jobs based on their status
    const filterJobsByStatus = jobStatuses => otherJobs.filter(job => jobStatuses.includes(job.status));

    let tempArr = [];

    switch (selectedJobCategory) {
      case 'current':
        tempArr = filterJobsByStatus(CURRENT_JOB_STATUSES);
        break;
      case 'past':
        tempArr = filterJobsByStatus(PAST_JOB_STATUSES);
        break;
      default:
        tempArr = [];
    }

    setSelectedJobs(tempArr);
  }, [selectedJobCategory, sortByProperty, allJobs, otherJobs]);

  useEffect(() => {
    const dynamicSort = property => {
      return function (job1, job2) {
        if (property === 'name') {
          return job1[property].toLowerCase() < job2[property].toLowerCase() ? -1 : job1[property].toLowerCase() > job2[property].toLowerCase() ? 1 : 0;
        }
        let result = job1[property] < job2[property] ? -1 : job1[property] > job2[property] ? 1 : 0;
        return result;
      };
    };
    const tempArr = selectedJobs.sort(dynamicSort(sortByProperty));
    setSortedJobs([...tempArr]);
  }, [sortByProperty, selectedJobCategory, selectedJobs]);

  const handleChange = e => {
    setSortByProperty(e.target.value);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Container fluid style={{ padding: '40px', position: 'relative' }} className='my-jobs-screen__desktop-container'>
        {job && showModalService ? <JobConfirmationModal /> : null}
        <div className='profile-tabs'>
          <Row>
            <Col style={{ width: '100%' }}>
              <Tabs activeKey={selectedJobCategory} onSelect={category => setSelectedJobCategory(category)} className='mb-5 d-flex justify-content-center'>
                <Tab eventKey='current' title='Current Jobs' tabClassName='profile-tabitem'>
                  <div>
                    <Row style={{ fontWeight: 600 }} className='text-nowrap'>
                      <Col>Job Name</Col>
                      <Col>Service</Col>
                      <Col>Reference # </Col>
                      <Col>Start Date</Col>
                      <Col>Status</Col>
                      <Col>Tasks</Col>
                    </Row>
                    <hr />
                    {selectedJobs?.map((job, index) => (
                      <JobRow job={job} key={`${job.id}-${index}`} jobCategory='current' />
                    ))}
                  </div>
                </Tab>
                <Tab eventKey='past' title='Past Jobs' tabClassName='profile-tabitem'>
                  <div>
                    <Row style={{ fontWeight: 600 }} className='text-nowrap'>
                      <Col>Job Name</Col>
                      <Col>Service</Col>
                      <Col>Reference # </Col>
                      <Col>Start Date</Col>
                      <Col>Status</Col>
                    </Row>
                    <hr />
                    {selectedJobs?.map((job, index) => (
                      <JobRow job={job} key={`${job.id}-${index}`} jobCategory='past' />
                    ))}
                  </div>
                </Tab>
                {user.role === 'user' && (
                  <Tab eventKey='draft' title='Draft Jobs' tabClassName='profile-tabitem'>
                    <div>
                      <Row style={{ fontWeight: 600 }} className='text-nowrap'>
                        <Col>Job Name</Col>
                        <Col>Service</Col>
                        <Col>State</Col>
                        <Col>Commute</Col>
                        <Col>Status</Col>
                      </Row>
                      <hr />
                      {draftJobs?.map(job => (
                        <NavLink style={{ textDecoration: 'none' }} to={`/job/edit-job/${job.sid}`} key={job.sid}>
                          <DraftJobRow job={job.json} />
                        </NavLink>
                      ))}
                    </div>
                  </Tab>
                )}
              </Tabs>
            </Col>
          </Row>
        </div>
        <div>
          <Dropdown
            style={{ borderBottom: 'none', width: '6rem', position: 'absolute', top: 50, right: 24 }}
            role='button'
            oA
            name='sortBy'
            options={sortedJobsOptions}
            onChange={handleChange}
            value={sortByProperty}
            disabledLabel='Sort By'
          />
          <button style={{ position: 'absolute', top: 50, left: 24, background: 'none', border: 'none' }} onClick={() => navigate('/dashboard')}>
            <img style={{ cursor: 'pointer' }} className='pe-2' width='18' height='30' src={LeftArrow} alt='' />
          </button>
        </div>
      </Container>
      <Container fluid style={{ padding: '20px', position: 'relative', marginTop: '20px' }} className='my-jobs-screen__mobile-container'>
        {job && showModalService ? <ConfirmationModal /> : null}
        <div className='profile-tabs'>
          <Row>
            <Col>
              <Tabs className='pb-3 d-flex justify-content-center' activeKey={selectedJobCategory} onSelect={category => setSelectedJobCategory(category)}>
                <Tab eventKey='current' title='Current Jobs' tabClassName='profile-tabitem'>
                  {selectedJobs?.map((job, index) => (
                    <JobRowMobile job={job} key={`${job.id}-${index}`} jobCategory='current' />
                  ))}
                </Tab>
                <Tab eventKey='past' title='Past Jobs'>
                  {selectedJobs.map((job, index) => (
                    <JobRowMobile job={job} key={`${job.id}-${index}`} jobCategory='past' />
                  ))}
                </Tab>
                {user.role === 'user' && (
                  <Tab eventKey='draft' title='Draft Jobs'>
                    <div>
                      <hr />
                      {draftJobs?.map(job => (
                        <NavLink style={{ textDecoration: 'none' }} to={`/job/edit-job/${job.sid}`} key={job.sid}>
                          <DraftJobRowMobile job={job.json} />
                        </NavLink>
                      ))}
                    </div>
                  </Tab>
                )}
              </Tabs>
            </Col>
          </Row>
        </div>
        <div>
          <Dropdown
            style={{ borderBottom: 'none', width: '6rem', position: 'absolute', top: -12, right: 24 }}
            role='button'
            oA
            name='sortBy'
            options={sortedJobsOptions}
            onChange={handleChange}
            value={sortByProperty}
            disabledLabel='Sort By'
          />
        </div>
        <button style={{ position: 'absolute', top: 50, left: 24, background: 'none', border: 'none' }} onClick={() => navigate('/dashboard')}>
          <img style={{ cursor: 'pointer' }} className='pe-2' width='18' height='30' src={LeftArrow} alt='' />
        </button>
      </Container>
    </>
  );
}
