import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';

import { MethodsContext } from '../context';
import Navigation from '../layout/navigation';

import './step-2.css';

const Step2 = () => {
  const {
    register,
    formState: { errors, isValid },
    trigger,
    setValue,
  } = useFormContext();

  const handleChange = (e, filedValue) => {
    const numericValue = e.target.value.replace(/\D/g, '');
    let formattedNumber = '';
    if (numericValue.length >= 1) {
      formattedNumber = '(' + numericValue.slice(0, 3);
    }
    if (numericValue.length >= 4) {
      formattedNumber += ')-' + numericValue.slice(3, 6);
    }
    if (numericValue.length >= 7) {
      formattedNumber += '-' + numericValue.slice(6, 10);
    }
    setValue(filedValue, formattedNumber);
    trigger(filedValue);
  };
  const { handleInputChange } = useContext(MethodsContext);

  return (
    <div className='multiStepContainer'>
      <h1 className='h1MultiStepForm'>
        Tell us a little <br />
        bit more about yourself.
      </h1>
      <div className='mainContainer1'>
        <div className='inputContainerForm'>
          <input
            className='inputMultiStep'
            {...register('jobTitle', { required: 'Required field.' })}
            type='text'
            placeholder='Job Title*'
            onChange={e => handleInputChange(e, 'jobTitle')}
          />
          <p className='errorMessageInputForm'> {errors.jobTitle?.message?.toString()}</p>
        </div>
        <div className='inputContainerForm'>
          <input
            className='inputMultiStep'
            {...register('workNumber', {
              required: 'Required field.',
              minLength: {
                value: 14,
                message: 'Please enter a valid phone number.',
              },
            })}
            type='text'
            placeholder='Work Phone Number*'
            onChange={e => handleChange(e, 'workNumber')}
          />
          <p className='errorMessageInputForm'> {errors.workNumber?.message?.toString()}</p>
        </div>
        <div className='inputContainerForm'>
          <input
            className='inputMultiStep'
            {...register('mobileNumber', {
              minLength: {
                value: 14,
                message: 'Please enter a valid phone number.',
              },
            })}
            type='text'
            placeholder='Mobile Phone Number'
            onChange={e => handleChange(e, 'mobileNumber')}
          />
          <p className='errorMessageInputForm'> {errors.mobileNumber?.message?.toString()}</p>
        </div>
      </div>
      <Navigation disabled={isValid} />
    </div>
  );
};

export default Step2;
