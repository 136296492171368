import React, { useContext } from 'react';
import { useFormContext } from 'react-hook-form';

import { MethodsContext } from '../../context';

const ConditionedReferralInput = ({ fieldValue, error, placeholder }) => {
  const { register, getValues } = useFormContext();
  const { handleInputChange } = useContext(MethodsContext);
  const code = getValues('code');

  return (
    <div className='inputContainerReferral'>
      <input
        value={code}
        className='inputMultistepReferral'
        autoFocus
        {...register(fieldValue, { required: 'Required field.' })}
        type='text'
        placeholder={placeholder}
        onChange={e => handleInputChange(e, fieldValue)}
      />
      <p className='errorMessageReferral'>{error}</p>
    </div>
  );
};

export default ConditionedReferralInput;
