import React from 'react';
import { ChevronRight } from 'lucide-react';
import { Link } from 'react-router-dom';

//Import SASS
import './coBrowseServicesWeOffer.scss';

import IndustriesImg from '../../assets/images/Industry-Graphic.png';

const CoBrowseServicesWeOffer = () => {
  return (
    <>
      <Link className='services-we-offer-link' to='/co-available-services'>
        <div className='services-we-offer-div-body'>
          <div className='services-we-offer-body'>
            <div>
              <h1 className='services-we-offer-title'>Browse Services We Offer</h1>
              <p className='services-we-offer-text-body'>Don’t see what you’re looking for? View our complete service list by clicking the arrow below.</p>
            </div>

            <div className='services-we-offer-div-img'>
              <img className='services-we-offer-img' src={IndustriesImg} alt='' />
            </div>
          </div>
        </div>

        <div className='services-we-offer-bottom-div'>
          <h4 className='services-we-offer-h4'>View YellowBird Services List</h4>
          <ChevronRight color='#FFCD03' strokeWidth={3} />
        </div>
      </Link>
    </>
  );
};

export default CoBrowseServicesWeOffer;
