import React from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import toast from 'react-hot-toast';

import { useAppDispatch } from '../../index';
import { setDefaultPaymentMethod } from '../../slices/company.slice';
import { Todo } from '../../types';
import PaymentMethod from './PaymentMethod';
import classes from './paymentMethodList.module.scss';

export default function SetPaymentMethodAsDefaultForCompanyModal({ show, onHide, paymentMethod }: { show: boolean; onHide: () => void; paymentMethod: Todo }) {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = React.useState(false);

  async function handleSetPaymentMethodAsDefault() {
    setLoading(true);
    // TODO: Remove ts-ignore when job.slice.js file is updated to typescript
    // @ts-ignore-next-line
    toast.promise(dispatch(setDefaultPaymentMethod(paymentMethod.id)).unwrap(), {
      loading: 'Setting payment method as default...',
      success: () => {
        setLoading(false);
        onHide();
        return 'Default payment method set successfully.';
      },
      error: (error: any) => {
        console.error('<SetPaymentMethodAsDefaultForCompanyModal />: ', error);
        setLoading(false);
        return `Error setting default payment method`;
      },
    });
  }

  if (!paymentMethod) {
    return null;
  }
  return (
    <Modal show={show} onHide={onHide} size='lg' centered dialogClassName={classes.custom_modal_dialog} backdropClassName={classes.custom_modal_backdrop}>
      <Modal.Header closeButton>
        <Modal.Title>Set Payment Method As Default</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>Are you sure you want to set this payment method as default?</p>
        <PaymentMethod paymentMethod={paymentMethod} isModal />
      </Modal.Body>

      <Modal.Footer>
        <Button type='button' variant='secondary' onClick={onHide}>
          Cancel
        </Button>

        <Button
          variant='primary'
          type='button'
          onClick={handleSetPaymentMethodAsDefault}
          disabled={loading}
          className='d-flex align-items-center gap-1 justify-content-center'
        >
          {loading && <Spinner animation='border' size='sm' />}
          Set as Default
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
