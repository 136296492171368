import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useInterval } from 'usehooks-ts';

import chooseFile from '../../assets/icons/Choose file white.svg';
import logOutSvg from '../../assets/icons/logout.svg';
import yellowBirdLogoPng from '../../assets/icons/yellowbirdlogo.png';
import useValidateUser from '../../hooks/useValidateUser';
import { uploadDocument } from '../../slices/document.slice';
import { getResume } from '../../slices/worker.slice';
import LoadingState from './loading-state';
import ProfileBuildingFields from './profile-building-fields';
import SplashModal from './splash-moda';

import './index.scss';

import useSignOut from 'src/hooks/useSignOut';
import { hasZeroEhsExperience } from 'src/utils/profile-building-check';

const validMimeTypes = [
  'application/pdf',
  'image/png',
  'image/jpeg',
  'image/tiff',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword',
];

export default function ProfileBuilding() {
  const { validateUserState } = useValidateUser();
  const { isLoading: userLoading } = useSelector(state => state.user);
  const { worker, isLoading: workerLoading } = useSelector(state => state.worker);
  const staticData = useSelector(state => state.staticData.staticData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const signOut = useSignOut();
  const [documentId, setDocumentId] = useState(null);
  const [resumeState, setResumeState] = useState({ resumeValuesAvailable: false, resumeProcessed: false });
  const { resumeValuesAvailable, resumeProcessed } = resumeState;
  const [resumeLoading, setResumeLoading] = useState(false);

  const {
    register,
    resetField,
    watch,
    clearErrors,
    handleSubmit,
    setValue,
    setError,
    formState: { errors, isValid },
  } = useForm({ defaultValues: { file: null, checkBox: false } });

  const checkBox = watch('checkBox');
  const file = watch('file');

  const clearIntervalAndShowForm = log => {
    resetField('file');
    setDocumentId(null);
    setError('file', { message: 'Unfortunately, we were unable to process your uploaded resume. To continue, please fill out the form below.' });
    setValue('checkBox', true);
    setResumeLoading(false);
  };

  const fetchWorker = async () => {
    try {
      const processedResume = await dispatch(getResume({ documentId: documentId })).unwrap();
      if (processedResume.processingStatus === 'processed') {
        setDocumentId(null);
        clearErrors('file');
        setResumeState({ ...resumeState, resumeValuesAvailable: processedResume, resumeProcessed: true });
        setResumeLoading(false);
        return;
      } else if (processedResume.processingStatus === 'error') {
        clearIntervalAndShowForm('Error status in fetchWorker');
        return;
      } else {
        return;
      }
    } catch {
      clearIntervalAndShowForm('fetchWorker caught it');
    }
  };

  useInterval(fetchWorker, !documentId ? null : 10000);

  const onSubmit = async data => {
    try {
      setResumeLoading(true);
      const file = data.file[0];
      if (!validMimeTypes.includes(file.type)) {
        toast.error('Please upload only PDF, PNG, JPEG, DOC or DOCX, or TIFF files');
        return;
      }

      if (file.size >= 50000000) {
        toast.error('Please upload a file less than 50MB');
        return;
      }

      const formData = new FormData();
      formData.append('file', file);

      const res = await dispatch(uploadDocument({ workerId: worker.id, category: 'resume', docs: formData })).unwrap();
      setDocumentId(res);
    } catch {
      clearIntervalAndShowForm('onSubmit called it');
    }
  };

  if (validateUserState === 'initializing' || userLoading || workerLoading) {
    return (
      <div className='loadingLayout'>
        <img className='yellowbird-logo-loadingstate' src={yellowBirdLogoPng} />
      </div>
    );
  }

  if (!hasZeroEhsExperience(worker)) {
    return (
      <div className='profileBuildingIndexWrapper'>
        <div style={{ marginRight: 'auto', marginLeft: 'auto', maxWidth: '1100px', marginBottom: '40px' }}>
          <img className='profileBuildingYellowbirdLogo' src={yellowBirdLogoPng} />
        </div>
        <div className='profilrBuildingFormWrapper'>
          <h4 style={{ textAlign: 'center' }}>You have already completed this step!</h4>
          <button style={{ marginTop: '16px' }} className='profileBuildingUploadBtn' onClick={() => navigate('/dashboard', { replace: true })}>
            Go to Dashboard
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className='profileBuildingIndexWrapper'>
      <SplashModal workerName={worker.firstName} />
      <LoadingState isOpen={resumeLoading}>
        <label
          style={{ cursor: 'pointer' }}
          className='clearSelectionCategoryFieldset'
          onClick={() => {
            setResumeLoading(false);
            setDocumentId(null);
            setValue('checkBox', true);
          }}
        >
          Fill out manually instead
        </label>
      </LoadingState>
      <div className='imageWrapperProfileBuilding'>
        <div className='imageWrapperContainerProfileBuilding'>
          <img className='profileBuildingYellowbirdLogo' src={yellowBirdLogoPng} />
        </div>
      </div>
      <div className='profilrBuildingFormWrapper'>
        <div className='titleAndButtonWrapper'>
          <h2 className='resumeUploadTitle'>{resumeProcessed ? 'Edit your Profile' : 'Upload your Resume'}</h2>
          <button style={{ display: 'flex', alignItems: 'center', gap: '8px' }} className='profileBuildingLogoOutBtn' onClick={signOut}>
            <span className='logOutProfileBuilding'>Log Out</span>
            <img src={logOutSvg} />
          </button>
        </div>
        {resumeProcessed ? (
          <>
            <p>Please take a moment to review the extracted information for accuracy and consider enhancing your profile with any additional details.</p>
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
              <button
                className='clearSelectionCategoryFieldset'
                onClick={() => {
                  setResumeState({ ...resumeState, resumeProcessed: false });
                  setValue('checkBox', true);
                  setValue('file', null);
                }}
                type='button'
              >
                Upload a different file
              </button>
            </div>
          </>
        ) : (
          <>
            <p>YellowBird's matching technology uses keywords from your resume to align your skills and expertise to jobs posted on the platform.</p>
            <div className='profileBuildingUploadWrapper'>
              <form className='profileBuildingUploadSection' onSubmit={handleSubmit(onSubmit)}>
                <div className='profileBuildingFileLabel'>
                  <input style={{ display: 'none' }} id='file' type='file' {...register('file', { required: true })} />
                  <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <label
                      style={{ marginBottom: '0px', cursor: 'pointer', display: 'flex', gap: '8px', alignItems: 'center' }}
                      className='clearAllSection uploadSectonBtn'
                      htmlFor='file'
                    >
                      <img className='chooseFileIconProfileBuilding' src={chooseFile} />
                      Choose a File
                    </label>
                    <span style={{ fontWeight: '400', fontSize: '14px' }}>{file ? file[0]?.name ?? '' : null}</span>
                  </div>
                  {file && (
                    <label
                      style={{ marginBottom: '0px', background: '#F44336', cursor: 'pointer' }}
                      className='clearAllSection uploadSectonBtn'
                      onClick={() => resetField('file')}
                    >
                      Remove File
                    </label>
                  )}
                </div>
                <button className='profileBuildingUploadBtn' disabled={!isValid} type='submit'>
                  Upload
                </button>
                <p style={{ fontSize: '14px', marginBottom: '0px', marginTop: '20px', color: '#66798C' }}>
                  *You can export your LinkedIn profile or Website as a PDF and upload it. Here is the&nbsp;
                  <a href='https://www.linkedin.com/help/linkedin/answer/a541960' target='_blank'>
                    guide!
                  </a>
                </p>
                <label
                  style={{ cursor: 'pointer', alignSelf: 'center' }}
                  className='clearSelectionCategoryFieldset'
                  onClick={() => {
                    setResumeLoading(false);
                    setDocumentId(null);
                    setValue('checkBox', true);
                  }}
                >
                  Fill out manually instead
                </label>
              </form>
            </div>
          </>
        )}
        {errors.file && <p className='errorMessageProfileBuilding'>{errors.file?.message?.toString()}</p>}
        {(checkBox || resumeValuesAvailable) && <ProfileBuildingFields staticData={staticData} worker={worker} workerResume={resumeValuesAvailable} />}
      </div>
    </div>
  );
}
