import React from 'react';

import Text from '../Text';

const ShadowCard = ({ title, detail }) => {
  const styles = {
    box: {
      borderRadius: 30,
      width: '100%',
      boxShadow: 'inset 0 3px 6px rgba(0, 0, 0, 0.16), 0 30px 40px rgba(138, 149, 158, 0.2)',
      padding: 16,
      height: 180,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 8,
    },
    textDiv: {
      display: 'flex',
    },
  };
  return (
    <div style={styles.box}>
      <div style={styles.textDiv}>
        <Text t='smallBold'>{title}</Text>
      </div>
      <div style={styles.textDiv}>
        <Text t='orangeBold'>{detail}</Text>
      </div>
    </div>
  );
};

export default ShadowCard;
