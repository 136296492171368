import React, { Fragment, useEffect, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDown } from 'lucide-react';
import { Spinner } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { stateOptions } from '../../lib/options';
import { updateWorkerById } from '../../slices/worker.slice';
import CategoryFieldsetWrapper from './category-fieldset-wrapper';
import ProfileBuildingInput from './profile-building-input';

import './profile-building-fields.scss';

const ProfileBuildingFields = ({ worker, workerResume, staticData }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const city = workerResume?.city ?? '';
  const state = workerResume?.state ?? '';
  const address = workerResume?.full_address ?? '';
  const getDefaultValues = field => field?.map(it => it.db_id) ?? [];
  const industries_worked_in = getDefaultValues(workerResume?.industries_worked_in);
  const certification = getDefaultValues(workerResume?.certifications);
  const osha_certificates = getDefaultValues(workerResume?.osha_certificates);
  const osha_courses_certificates = getDefaultValues(workerResume?.osha_courses_certificates);
  const msha_blue_cards = getDefaultValues(workerResume?.msha_blue_cards);
  const dot_certification = getDefaultValues(workerResume?.dot_certification);
  const emergency_response_certifications = getDefaultValues(workerResume?.emergency_response_certifications);
  const environmental_certifications = getDefaultValues(workerResume?.environmental_certifications);
  const environmental_services = getDefaultValues(workerResume?.environmental_services);
  const trainings = getDefaultValues(workerResume?.trainings);
  const general_skills = getDefaultValues(workerResume?.general_skills);
  const other_languages = getDefaultValues(workerResume?.other_languages);

  const {
    register,
    handleSubmit,
    resetField,
    getValues,
    control,
    trigger,
    setValue,
    formState: { isValid, errors },
  } = useForm({
    defaultValues: {
      firstName: worker?.firstName ?? '',
      lastName: worker?.lastName ?? '',
      email: worker?.email ?? '',
      mobilePhone: worker?.mobilePhone ?? '',
      zipCode: worker?.zipCode ?? '',
      address: address,
      state: state,
      city: city,
      ehsExperience: 0,
      workedIndustries: industries_worked_in,
      certifications: certification,
      oshaCertificates: osha_certificates,
      oshaCourseCertificates: osha_courses_certificates,
      mshaBlueCards: msha_blue_cards,
      dotCertifications: dot_certification,
      emergencyResponseCertifications: emergency_response_certifications,
      environmentalCertifications: environmental_certifications,
      environmentalServices: environmental_services,
      training: trainings,
      generalSkills: general_skills,
      education: [],
      otherLanguages: other_languages,
    },
  });

  const handleInputChange = (e, fieldValue) => {
    const currentValue = fieldValue == 'email' ? e.target.value.toLowerCase() : e.target.value;
    setValue(fieldValue, currentValue);
    trigger(fieldValue);
  };

  const onSubmit = async data => {
    try {
      // Update empty string values to empty arrays for specific fields
      // todo refactor CategoryFieldsetWrapper to get proper defaultValue as [] not as empty string
      const arrayFields = [
        'workedIndustries',
        'certifications',
        'oshaCertificates',
        'oshaCourseCertificates',
        'mshaBlueCards',
        'dotCertifications',
        'emergencyResponseCertifications',
        'environmentalCertifications',
        'environmentalServices',
        'training',
        'generalSkills',
        'education',
        'otherLanguages',
      ];

      arrayFields.forEach(field => {
        if (data[field] === '') {
          data[field] = [];
        }
      });

      setSubmitting(true);
      await dispatch(updateWorkerById({ workerId: worker.id, updates: data })).unwrap();
      navigate('/dashboard', { replace: true });
    } catch (e) {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    setValue('firstName', `${worker?.firstName ?? ''}`);
    setValue('lastName', `${worker?.lastName ?? ''}`);
    setValue('email', `${worker?.email ?? ''}`);
    setValue('mobilePhone', `${worker?.mobilePhone ?? ''}`);
    setValue('zipCode', `${worker?.zipCode ?? ''}`);
    setValue('address', `${address}`);
    setValue('state', `${state}`);
    setValue('city', `${city}`);
    setValue('ehsExperience', null);
    setValue('workedIndustries', `${industries_worked_in}`);
    setValue('certifications', `${certification}`);
    setValue('oshaCertificates', `${osha_certificates}`);
    setValue('oshaCourseCertificates', `${osha_courses_certificates}`);
    setValue('mshaBlueCards', `${msha_blue_cards}`);
    setValue('dotCertifications', `${dot_certification}`);
    setValue('emergencyResponseCertifications', `${emergency_response_certifications}`);
    setValue('environmentalCertifications', `${environmental_certifications}`);
    setValue('environmentalServices', `${environmental_services}`);
    setValue('training', `${trainings}`);
    setValue('generalSkills', `${general_skills}`);
    setValue('education', []);
    setValue('otherLanguages', `${other_languages}`);
  }, [workerResume]);

  return (
    <form className='profileBuildingForm' onSubmit={handleSubmit(onSubmit)}>
      <h6 style={{ borderBottom: '1px solid #b4b4b4', paddingBottom: '6px' }} className='categoryFieldsetH6'>
        Personal Information*
      </h6>
      <div className='profileBuildingAndAddresWrapperMain'>
        <div className='profileBuildingInputWrapper'>
          <ProfileBuildingInput onChange={handleInputChange} placeholder='FirstName*' errors={errors} register={register} field='firstName'>
            <p className='errorMessageInputProfilebuilding'>{errors?.firstName?.message.toString()}</p>
          </ProfileBuildingInput>
          <ProfileBuildingInput onChange={handleInputChange} placeholder='LastName*' errors={errors} register={register} field='lastName'>
            <p className='errorMessageInputProfilebuilding'>{errors?.lastName?.message.toString()}</p>
          </ProfileBuildingInput>
          <ProfileBuildingInput onChange={handleInputChange} placeholder='Email Address*' errors={errors} register={register} field='email'>
            <p className='errorMessageInputProfilebuilding'>{errors?.email?.message.toString()}</p>
          </ProfileBuildingInput>
          <ProfileBuildingInput onChange={handleInputChange} placeholder='Phone Number*' errors={errors} register={register} field='mobilePhone'>
            <p className='errorMessageInputProfilebuilding'>{errors?.mobilePhone?.message.toString()}</p>
          </ProfileBuildingInput>
          <div className='relative'>
            <input
              type='number'
              {...register('ehsExperience', {
                required: 'Required Field',
                min: { message: 'Must be a positive number', value: 1 },
                valueAsNumber: true,
                onChange: e => handleInputChange(e, 'ehsExperience'),
              })}
              placeholder='EHS Years of Experience*'
              field='ehsExperience'
              className='profileBuildingInput'
            />
            <p className='errorMessageInputProfilebuilding'>{errors?.ehsExperience?.message.toString()}</p>
          </div>
        </div>
        <div className='addressSectionProfileBuilding'>
          <ProfileBuildingInput onChange={handleInputChange} placeholder='Street Address*' errors={errors} register={register} field='address'>
            <p className='errorMessageInputProfilebuilding'>{errors?.address?.message.toString()}</p>
          </ProfileBuildingInput>
          <div className='stateAndCityDivProfileBuilding'>
            <ProfileBuildingInput onChange={handleInputChange} placeholder='City*' errors={errors} register={register} field='city'>
              <p className='errorMessageInputProfilebuilding'>{errors?.city?.message.toString()}</p>
            </ProfileBuildingInput>
            <Controller
              name='state'
              control={control}
              defaultValue={''}
              rules={{ required: 'Required field.' }}
              render={({ field }) => {
                return (
                  <Listbox {...field}>
                    <div className='dropDownForm'>
                      <Listbox.Button style={{ borderBottomColor: '#cdc8c8', marginTop: '1.5px' }} className='dropDownButton'>
                        {field.value ? (
                          <span className={`activeField insideContainer resizeStep3`}>{field.value}</span>
                        ) : (
                          <span style={{ color: '#9f9898' }} className='insideContainer resizeStep3'>
                            State*
                          </span>
                        )}
                        <ChevronDown style={{ width: '24px', height: '24px' }} className='chevrowDownForm' aria-hidden='true' />
                      </Listbox.Button>
                      <p className='errorMessageInputProfilebuilding'>{errors.state?.message?.toString()}</p>
                      <Transition
                        as={Fragment}
                        enter='transitio ease-in duration-150'
                        leave='transition ease-in duration-100'
                        enterFrom='opacity-0'
                        leaveFrom='opacity-0'
                        leaveTo='opacity-0'
                      >
                        <Listbox.Options className='optionsDropDown' onBlur={() => trigger('state')}>
                          {stateOptions.map(option => (
                            <Listbox.Option className={({ active }) => `optionField ${active ? 'activeField' : 'unactiveField'}`} value={option.value}>
                              {({ selected }) => (
                                <>
                                  {selected ? (
                                    <span className='checkIconStep3'>
                                      <CheckIcon className='checkIconInside' aria-hidden='true' />
                                    </span>
                                  ) : null}
                                  <span className={`valueDropDown ${selected ? 'font-medium' : 'font-normal'}`}>{option.name}</span>
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </Listbox>
                );
              }}
            />
          </div>
          <div style={{ maxWidth: '140px' }}>
            <ProfileBuildingInput onChange={handleInputChange} placeholder='Zip Code*' errors={errors} register={register} field='zipCode'>
              <p className='errorMessageInputProfilebuilding'>{errors?.zipCode?.message.toString()}</p>
            </ProfileBuildingInput>
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '60px', marginTop: '32px' }}>
        <CategoryFieldsetWrapper
          setValue={setValue}
          workerResume={workerResume}
          resetField={resetField}
          register={register}
          staticData={staticData}
          getValues={getValues}
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <button className='profileBuildingUploadBtn' disabled={!isValid || submitting} style={{ marginTop: '60px', minWidth: '280px' }} type='submit'>
          {submitting ? <Spinner size='sm' /> : 'Save and Continue'}
        </button>
      </div>
    </form>
  );
};

export default ProfileBuildingFields;
