import React from 'react';
import { Container } from 'react-bootstrap';

import OnboardingStep from './OnboardingStep';

import './OnboardingContent.scss';

const OnboardingContent = ({ onboarding }) => {
  const getDetails = stepName => {
    switch (stepName) {
      case 'Sign up for Orientation':
        return 'Our CS team will be notified and will update your status within our system, please be patient during this process.';
      case 'Complete Background Check':
        return 'May take up to 5+ business days to clear depending on your State and County.';
      default:
        return '';
    }
  };

  return (
    <Container fluid className='px-3 pt-4 pb-4 px-sm-4'>
      {onboarding.map((step, index) => (
        <OnboardingStep
          key={index}
          prevStepComplete={index === 0 || onboarding[index - 1]?.status === 2}
          order={index + 1}
          title={step.name}
          status={step.status}
          onboarding={onboarding}
          details={getDetails(step.name)}
        />
      ))}
    </Container>
  );
};

export default OnboardingContent;
