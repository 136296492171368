import { Badge, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import LeftArrow from '../../assets/images/left-icon.svg';
import { formatDateMMDDYYYY } from '../../helpers';
import { useAppSelector } from '../../index';
import { Todo } from '../../types';
import { friendlyJobStatusLabel } from '../JobRow';
import classes from '../JobRow/JobRow.module.scss';

export default function JobRowMobile({ job, jobCategory }: { job: Todo; jobCategory: 'current' | 'past' | 'draft' }) {
  const user = useAppSelector(state => state.user.user);
  return (
    <div className='border mt-5 p-3 rounded'>
      <Row>
        <Link to={`/job/${job.referenceNumber}`} className={`${classes.a} justify-content-between d-flex align-items-center`}>
          <span>{job.name}</span>
          <img className='pe-2' width='18' height='30' src={LeftArrow} alt='' style={{ rotate: '180deg' }} />
        </Link>
      </Row>
      <Row className='border-bottom py-3'>
        <Col>Service</Col>
        <Col>{job.service || 'None Provided'}</Col>
      </Row>
      <Row className='border-bottom py-3'>
        <Col>Reference #</Col>
        <Col>{job.referenceNumber}</Col>
      </Row>
      <Row className='border-bottom py-3'>
        <Col>Start Day</Col>
        <Col>{formatDateMMDDYYYY(job.startDate)}</Col>
      </Row>
      <Row className={jobCategory === 'current' ? 'border-bottom py-3' : 'pt-3'}>
        <Col>Status</Col>
        <Col>{friendlyJobStatusLabel(job.status, user.role)}</Col>
      </Row>
      {jobCategory === 'current' && (
        <Row className='pt-3'>
          <Col>Tasks</Col>
          <Col>
            {job.uncompletedTasks > 0 ? (
              <Link to={`/job/${job.referenceNumber}?tab=Tasks`} className={classes.tasks_link}>
                View tasks
                <Badge pill bg='danger'>
                  {job.uncompletedTasks}
                </Badge>
                <span className='visually-hidden'>incomplete tasks</span>
              </Link>
            ) : (
              <span>-</span>
            )}
          </Col>
        </Row>
      )}
    </div>
  );
}
