import React from 'react';

import './profile-building-input.scss';

const ProfileBuildingInput = ({ register, field, placeholder, onChange, children, customValidation, ...props }) => {
  return (
    <div style={{ position: 'relative' }}>
      <input
        {...props}
        readOnly={field === 'email'}
        placeholder={placeholder}
        className='profileBuildingInput'
        {...register(field, { required: 'Required field', onChange: e => onChange(e, field), ...customValidation })}
      />
      {children}
    </div>
  );
};

export default ProfileBuildingInput;
