import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FaChevronRight } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../components/Button';
import CoAvailableServicesList from '../../components/CoAvailableServicesList';
import CoBrowseServicesWeOffer from '../../components/CoBrowseServicesWeOffer';
import MyJobs from '../../components/MyJobs';
import MyProfile from '../../components/MyProfile';
import OnboardingWidget from '../../components/OnboardingWidget2';
import OnboardingStatusSummary from '../../components/OnboardingWidget2/OnboardingStatusSummary';
import PostAJob from '../../components/PostAJob';
import UserDashboardSkeleton from '../../components/SkeletonLoading/dashboardLoading';
import { retrieveCo } from '../../slices/company.slice';
import { getDocuments } from '../../slices/document.slice';
import { getAllDraftJobs, getMatrixData } from '../../slices/job.slice';
import { retrieveOnboardingTasks } from '../../slices/onboarding.slice';
import { getCoReviews, getReviews } from '../../slices/proReviewByCo.slice';
import { updateReferralCode } from '../../slices/user.slice';
import { fetchWorkerById } from '../../slices/worker.slice';
import JobConfirmationModal from '../ProAcceptJob/JobConfirmationModal';

import './dashboard.scss';
import '../../components/CoBrowseServicesWeOffer/coBrowseServicesWeOffer.scss';
import '../../components/MyJobs/myJobs.scss';
import '../../components/HowItWorks/howItWorks.scss';
import '../../components/ProAvailableServices/availableServices.scss';

export default function Dashboard() {
  const dispatch = useDispatch();
  const { user, isLoading: userLoading } = useSelector(state => state.user);
  const { data: onboardingData, isLoading: onboardingLoading } = useSelector(state => state.onboarding);
  const { jobInvite } = useSelector(state => state.job);
  const companyName = useSelector(state => state.company?.company?.company?.[0]?.name);
  const [onboardingProgress, setOnboardingProgress] = React.useState(0);
  const [onboardingShow, setOnboardingShow] = React.useState(false);
  const [company, setCompany] = React.useState(null);
  const job = jobInvite?.jobInfo ? jobInvite.jobInfo.name : null;
  const onboarding = onboardingData.slice(0, 3);
  const urlParams = new URLSearchParams(window.location.search);
  const action = urlParams.get('action');
  const parentId = urlParams.get('parentId') ? parseInt(urlParams.get('parentId')) : null;
  const childId = urlParams.get('childId') ? parseInt(urlParams.get('childId')) : null;
  const copy = urlParams.get('copy') === 'true';
  const prepaid = urlParams.get('prepaid') === 'true';
  const jobName = urlParams.get('jobName') || null;
  const fromService = action === 'post-a-job';
  const showModalService = action === 'show-confirmation-modal';

  // const handleCreateStandbyList = () => {
  //   console.log("THIS IS THE USER ID", user.id)
  //   const userId = user?.id;
  //   const jobId = 973;
  //   const jobDateSelection = new Date().toISOString();

  //   const standbyListData = {
  //     userId,
  //     jobId,
  //     jobDateSelection,
  //   };

  //   dispatch(createStandbyList(standbyListData));
  // };

  useEffect(() => {
    if (companyName) {
      setCompany(companyName);
    }
  }, [companyName]);

  useEffect(() => {
    async function fetchData() {
      if (user.role === 'worker') {
        await Promise.all([
          dispatch(retrieveOnboardingTasks()),
          dispatch(fetchWorkerById(user.workerId)),
          dispatch(getDocuments()),
          dispatch(getMatrixData()),
          user.companyId && dispatch(getCoReviews(user.workerId)),
        ]);
      } else {
        await Promise.all([
          dispatch(getMatrixData()),
          dispatch(getAllDraftJobs()),
          dispatch(getReviews(user.companyId)),
          dispatch(retrieveCo({ companyId: user.companyId })),
        ]);
      }
    }

    if (Object.keys(user).length > 0) {
      fetchData();
    }
  }, [user, dispatch]);

  useEffect(() => {
    if (action === 'onboarding') {
      setOnboardingShow(true);
    }
  }, [action]);

  useEffect(() => {
    if (!user.referralCode) {
      dispatch(updateReferralCode(user.firstName));
    }
  }, []);

  React.useEffect(() => {
    if (onboarding && onboarding.length > 0) {
      const progressValues = [0, 25, 50, 20, 30];
      const calcProgress = progressValues[onboarding[0].status] + progressValues[onboarding[1].status]
      setOnboardingProgress(calcProgress);
    }
  }, [onboarding]);

  if (userLoading) {
    return <UserDashboardSkeleton />;
  }

  // CoDashboard
  if (user?.role === 'user') {
    return (
      <div style={{ padding: '40px' }} className='dashboard-container'>
        {company !== null ? (
          <div>
            <h3 className='dashboard-company-name'>Welcome, {company}</h3>
          </div>
        ) : (
          <div>
            <div className='skeleton-header' />
          </div>
        )}
        <Row className='d-flex flex-column'>
          <PostAJob copy={copy} fromService={fromService} parentId={parentId} childId={childId} jobName={jobName} prepaid={prepaid} />
        </Row>
        <Row className='d-flex flex-column flex-md-row gap-2'>
          <Col className='my-jobs-container d-flex justify-content-center dashboard-component-animation'>
            <MyJobs />
          </Col>
          <Col className='choose-services-div d-flex justify-content-center'>
            <CoAvailableServicesList />
          </Col>
        </Row>
        <Row className='justify-content-between dashboard-component-animation'>
          <Col className='services-we-offer-link-div d-flex justify-content-center'>
            <CoBrowseServicesWeOffer />
          </Col>
        </Row>
      </div>
    );
  }

  //pro dashboard
  if (user?.role === 'worker') {
    return (
      <Container id='workerDashboard' style={{ padding: '40px', position: 'relative' }} fluid>
        {job && showModalService ? <JobConfirmationModal /> : null}
        <Row className='mb-4'>
          <Col
            xs={12}
            md={12}
            lg={12}
            className='px-md-5 py-4 mb-3 mb-md-0 shadow-md'
            style={{
              color: '#fff',
              backgroundColor: '#4b5a63',
              borderRadius: '12px',
              fontWeight: 400,
              border: 'transparent',
              boxShadow: 'inset 0 3px 6px rgba(0, 0, 0, .16), 0 30px 60px rgba(138, 149, 158, .2)',
            }}
          >
            {onboardingLoading || !onboarding ? (
              <></>
            ) : onboarding && onboarding[onboarding.length - 1]?.status !== 2 ? (
              <>
                <h3 className='ps-3 pt-2 mb-4' style={{ fontWeight: 700 }}>
                  Welcome!
                </h3>
                <p style={{ fontSize: '13px' }} className='ps-3 mb-0'>
                  Thank you for registering as a Professional with YellowBird.
                </p>
                <p style={{ fontSize: '13px' }} className='ps-3'>
                  There are <strong>a few more steps</strong> to become Match Ready! Please click Onboarding to view your next task.
                </p>
                <Button style={{ backgroundColor: '#4b5a63', border: 'transparent', fontSize: '13px' }} onClick={() => setOnboardingShow(true)}>
                  <h4 className='onboarding-button d-flex text-nowrap ps-2 py-2 pt-md-3' style={{ color: '#ffcd03', fontSize: 17, fontWeight: 700 }}>
                    Onboarding Process
                    <FaChevronRight className='ms-3' />
                  </h4>
                </Button>
              </>
            ) : (
              <>
                <h3 className='ps-3 text-2xl pt-2 mb-4' style={{ fontWeight: 700 }}>
                  Congratulations, you are now Match Ready!
                </h3>
                <p className='ps-3' style={{ fontSize: '13px' }}>
                  When your skills, certifications, experience, and other criteria match with a posted Job, you will receive{' '}
                  <span style={{ fontWeight: 'bold' }}>a Job Invite via text message.</span>
                  <br />
                  <br />
                  Click the link in the invite and if the Job looks like a good match to your skillset, click "
                  <span style={{ fontWeight: 'bold' }}>Accept.</span>"
                  <br />
                  Once you've accepted, click <span style={{ fontWeight: 'bold', color: '#ffcd00' }}>"My Jobs"</span> below to manage the Job.
                </p>
              </>
            )}
          </Col>
        </Row>
        <Row className='mb-3 mb-md-0 d-flex flex-column flex-sm-row gap-2'>
          <Col className='my-jobs-container d-flex justify-content-center me-sm-1 mb-3 dashboard-component-animation'>
            {onboardingLoading || !onboarding ? (
              <div className='d-flex justify-content-center align-items-center' style={{ height: '100%' }}>
                <div className='spinner-border' role='status' />
              </div>
            ) : onboarding && onboarding[1]?.status !== 2 ? (
              <OnboardingStatusSummary onClick={() => setOnboardingShow(true)} progress={onboardingProgress} />
            ) : (
              <MyJobs />
            )}
          </Col>
          <Col className='my-jobs-container d-flex justify-content-center me-sm-1 mb-3 dashboard-component-animation'>
            <MyProfile />
          </Col>
        </Row>
        <OnboardingWidget show={onboardingShow} handleClose={() => setOnboardingShow(false)} />
      </Container>
    );
  }
  return <UserDashboardSkeleton />;
}
