import React, { useEffect, useState } from 'react';

import FullStarSVG from '../../assets/images/star-filled.svg';
import EmptyStarSVG from '../../assets/images/star-outline.svg';
import Spacer from '../../components/Spacer';
import Text from '../../components/Text';
import TextInput from '../../components/TextInput';
import useWindowDimensions from '../../hooks/useWindowDimensions';

const StarButtons = ({
  showNext,
  setShowNext,
  answers,
  setAnswers,
  question,
  questions,
  setPage,
  page,
  questionLength,
  selected,
  setSelected,
  onFinish,
  scores,
  setScores,
}) => {
  const { width } = useWindowDimensions();
  const isMobile = width < 760;
  let numButtons = [];
  let textButtons = [];
  let emptyStars = [1, 2, 3, 4, 5];
  const starQuestions = questions.filter(item => item.answerType === 'buttons');

  if (question.answerType === 'buttons') {
    for (let i = 0; i < question.quantity; i++) {
      let value = i + 1;
      let text = i + 1;
      numButtons.push({ value, text });
    }
  } else if (question.answerType === 'textButtons') {
    for (let i = 0; i < question.quantity; i++) {
      let { value, text } = question.options[i];
      textButtons.push({ value, text });
    }
  } else {
    console.log('in else');
  }

  const handleText = e => {
    const questionText = question.question;
    const p = page + 1;
    setAnswers({ ...answers, [p]: { q: questionText, a: e.target.value } });
    setShowNext(true);
  };

  const onPress = e => {
    const questionText = question.question;
    const value = e.target.getAttribute('value');
    const p = page + 1;
    setSelected(value);
    setAnswers({ ...answers, [p]: { q: questionText, a: value } });
    setShowNext(true);
  };

  const handleMore = (e, p) => {
    let property = { ...answers[p] };
    property.more = e.target.value;
    setAnswers({ ...answers, [p]: property });
  };

  const handleStarPress = (e, p, q, v) => {
    const questionText = q;
    const value = v;
    const page = p;
    setSelected(value);
    setAnswers({ ...answers, [page]: { q: questionText, a: value } });
  };

  const styles = {
    starRow: {
      display: 'flex',
      margin: 8,
      marginTop: 12,
    },
    starContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    starDiv: {
      cursor: 'pointer',
      marginLeft: 8,
      marginRight: 8,
    },
    questionContainer: {
      margin: 12,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      textAlign: 'center',
      width: !isMobile ? '60%' : '80%',
    },
  };

  useEffect(() => {
    if (page < starQuestions.length) {
      let next = false;
      for (let i = 0; i < starQuestions.length; i++) {
        let page = i + 1;
        if (answers[page]?.a) {
          next = true;
          setPage(i);
        } else {
          next = false;
        }
      }
      setShowNext(next);
    }
  }, [answers]);

  const StarQuestions = () => {
    return (
      <div style={styles.starContainer}>
        {starQuestions.map(q => {
          let numFullStars = answers[q.id]?.a || 0;
          return (
            <div key={q.id} style={styles.questionContainer}>
              <Text normalBold>{q.question}</Text>
              <div style={styles.starRow}>
                {emptyStars.map((eS, i) => {
                  return (
                    <div style={styles.starDiv} onClick={e => handleStarPress(e, q.id, q.question, i + 1)}>
                      <img style={{ width: 40 }} src={numFullStars > i ? FullStarSVG : EmptyStarSVG} />
                    </div>
                  );
                })}
              </div>
              <div style={{ width: isMobile ? '140%' : '100%' }}>
                <TextInput
                  style={{ textAlign: 'center', width: '100%' }}
                  value={answers[q.id]?.more || ''}
                  onChange={e => handleMore(e, q.id)}
                  placeholder='Tell us more about your answer'
                />
              </div>
            </div>
          );
        })}
        <Spacer h={128} />
      </div>
    );
  };

  const OtherQuestions = () => {
    const textButton = {
      margin: 6,
      borderRadius: 6,
      padding: 12,
      cursor: 'pointer',
      boxShadow: '0px 10px 10px #8a959e33',
      color: '#4b5a63',
      fontWeight: 600,
      paddingRight: 48,
      paddingLeft: 48,
      textAlign: 'center',
    };
    const selectedTextButton = {
      margin: 6,
      borderRadius: 12,
      padding: 12,
      cursor: 'pointer',
      boxShadow: '0px 10px 10px #8a959e33',
      backgroundColor: '#C7D4DA',
      color: '#4b5a63',
      fontWeight: 600,
      paddingRight: 48,
      paddingLeft: 48,
      textAlign: 'center',
    };
    const buttonCol = {
      display: 'flex',
      flexDirection: 'column',
    };

    if (question.answerType === 'textButtons' && textButtons.length === question.quantity) {
      return (
        <div style={{ display: 'flex' }}>
          <Spacer w={24} />
          <div>
            <div style={{ textAlign: 'center' }}>
              <Text subHeading>{question.question}</Text>
            </div>
            <Spacer h={12} />
            <div style={buttonCol}>
              {textButtons.map(button => {
                return (
                  <div style={answers[page + 1]?.a != button.value ? textButton : selectedTextButton} value={button.value} onClick={e => onPress(e)}>
                    {button.text}
                  </div>
                );
              })}
            </div>
          </div>
          <Spacer w={24} />
        </div>
      );
    }
    if (question.answerType === 'text') {
      const answerNumber = page + 1;
      return (
        <div>
          <div>
            <Text subHeading>{question.question}</Text>
            <Spacer h={12} />
            <TextInput value={answers[answerNumber]?.a || ''} onChange={e => handleText(e)} />
          </div>
        </div>
      );
    }
  };

  return <div>{page < starQuestions.length ? StarQuestions() : OtherQuestions()}</div>;
};

export default StarButtons;
