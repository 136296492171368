const AMPLITUDE_API_KEY = process.env.REACT_APP_AMPLITUDE_API_KEY;

const isProduction = process.env.NODE_ENV === 'production';

export const Events = {
  // Sign-in page events
  SIGN_IN_PAGE: 'SIGN_IN_PAGE',
  SIGN_IN_COMPLETE: 'SIGN_IN_COMPLETE',
  // Company registration events
  CO_REGISTRATION_STARTED: 'CO_REGISTRATION_STARTED',
  CO_REGISTRATION_COMPLETED_BASIC_INFO: 'CO_REGISTRATION_COMPLETED_BASIC_INFO',
  CO_REGISTRATION_COMPLETED_REFERRAL: 'CO_REGISTRATION_COMPLETED_REFERRAL',
  CO_REGISTRATION_COMPLETED_INDUSTRY_SEGMENT: 'CO_REGISTRATION_COMPLETED_INDUSTRY_SEGMENT',
  CO_REGISTRATION_COMPLETED_COMPANY_INFO: 'CO_REGISTRATION_COMPLETED_COMPANY_INFO',
  CO_REGISTRATION_COMPLETED: 'CO_REGISTRATION_COMPLETED',
  // Company actions
  CO_STARTED_NEW_JOB: 'CO_STARTED_NEW_JOB',
  CO_ADDED_JOB_SITES: 'CO_ADDED_JOB_SITES',
  CO_ADDED_JOB_TIMELINE: 'CO_ADDED_JOB_TIMELINE',
  CO_ADDED_JOB_CONTACT: 'CO_ADDED_JOB_CONTACT',
  CO_CREATED_NEW_JOB: 'CO_CREATED_NEW_JOB',
  // Pro registration events
  PRO_REGISTRATION_STARTED: 'PRO_REGISTRATION_STARTED',
  PRO_REGISTRATION_COMPLETED_BASIC_INFO: 'PRO_REGISTRATION_COMPLETED_BASIC_INFO',
  PRO_REGISTRATION_COMPLETED_PERSONAL_INFO: 'PRO_REGISTRATION_COMPLETED_PERSONAL_INFO',
  PRO_REGISTRATION_COMPLETED_PRO_INFO: 'PRO_REGISTRATION_COMPLETED_PRO_INFO',
  PRO_REGISTRATION_COMPLETED_TYPE_OF_WORK: 'PRO_REGISTRATION_COMPLETED_TYPE_OF_WORK',
  PRO_REGISTRATION_COMPLETED: 'PRO_REGISTRATION_COMPLETED',
  // Pro onboarding clicks
  PRO_ONBOARD_CLICKED_COMPLETE_PROFILE: 'PRO_ONBOARD_CLICKED_COMPLETE_PROFILE',
  PRO_ONBOARD_CLICKED_UPLOAD_DOCS: 'PRO_ONBOARD_CLICKED_UPLOAD_DOCS',
  PRO_ONBOARD_CLICKED_SCHEDULE_CALL: 'PRO_ONBOARD_CLICKED_SCHEDULE_CALL',
  PRO_ONBOARD_CLICKED_ORIENTATION_SIGN_UP: 'PRO_ONBOARD_CLICKED_ORIENTATION_SIGN_UP',
  PRO_ONBOARD_CLICKED_VERIFY_ADDRESS: 'PRO_ONBOARD_CLICKED_VERIFY_ADDRESS',
  PRO_ONBOARD_CLICKED_BACKGROUND_CHECK: 'PRO_ONBOARD_CLICKED_BACKGROUND_CHECK',
  PRO_ONBOARD_CLICKED_BANKING_INFO: 'PRO_ONBOARD_CLICKED_BANKING_INFO',
  // Pro onboarding actions
  PRO_ONBOARD_ORIENTATION_SIGNED_UP: 'PRO_ONBOARD_ORIENTATION_SIGNED_UP',
  PRO_ONBOARD_ADDRESS_VERIFIED: 'PRO_ONBOARD_ADDRESS_VERIFIED',
  PRO_ONBOARD_BANKING_COMPLETED: 'PRO_ONBOARD_BANKING_COMPLETED',
  // Pro actions
  PRO_UPLOADED_DOCUMENTS: 'PRO_UPLOADED_DOCUMENTS',
  PRO_UPDATED_CERTS: 'PRO_UPDATED_CERTS',
  PRO_UPDATED_SKILL_EXPERIENCE: 'PRO_UPDATED_SKILL_EXPERIENCE',
  PRO_UPDATED_TYPE_OF_WORK: 'PRO_UPDATED_TYPE_OF_WORK',
  PRO_UPDATED_PRO_DETAILS: 'PRO_UPDATED_PRO_DETAILS',
};

const initializeAmplitude = () => {
  if (!isProduction) {
    return null;
  }
  const amplitude = require('amplitude-js');
  amplitude.getInstance().init(AMPLITUDE_API_KEY);
  return amplitude.getInstance();
};

export const setUserDevice = installationToken => {
  const amplitude = initializeAmplitude();
  if (!amplitude) return;

  try {
    amplitude.setDeviceId(installationToken);
  } catch (error) {
    console.error(`Amplitude failed to set user device:`, error);
  }
};

export const setUserId = userId => {
  const amplitude = initializeAmplitude();
  if (!amplitude) return;

  try {
    amplitude.setUserId(userId);
  } catch (error) {
    console.error(`Amplitude failed to set userId:`, error);
  }
};

export const setUserProperties = properties => {
  const amplitude = initializeAmplitude();
  if (!amplitude) return;

  try {
    amplitude.setUserProperties(properties);
  } catch (error) {
    console.error(`Amplitude failed to set user properties:`, error);
  }
};

export const sendEvent = (type, properties = {}) => {
  const amplitude = initializeAmplitude();
  if (!amplitude) return;

  try {
    amplitude.logEventWithTimestamp(type, properties, Date.now());
  } catch (error) {
    console.error(`Amplitude failed to send event:`, error);
  }
};

export const getNewJobStepEvent = stepNum => {
  switch (stepNum) {
    case 0:
      return Events.CO_STARTED_NEW_JOB;
    case 1:
      return Events.CO_ADDED_JOB_SITES;
    case 2:
      return Events.CO_ADDED_JOB_TIMELINE;
    case 3:
      return Events.CO_ADDED_JOB_CONTACT;
    case 4:
      return Events.CO_CREATED_NEW_JOB;
    default:
      return 'UNKNOWN_NEW_JOB_EVENT';
  }
};

export const getRegistrationStepEvent = (stepNum, isPro) => {
  switch (stepNum) {
    case 0:
      return isPro ? Events.PRO_REGISTRATION_COMPLETED_BASIC_INFO : Events.CO_REGISTRATION_COMPLETED_BASIC_INFO;
    case 1:
      return isPro ? Events.PRO_REGISTRATION_COMPLETED_PERSONAL_INFO : Events.CO_REGISTRATION_COMPLETED_REFERRAL;
    case 2:
      return isPro ? Events.PRO_REGISTRATION_COMPLETED_PRO_INFO : Events.CO_REGISTRATION_COMPLETED_INDUSTRY_SEGMENT;
    case 3:
      return isPro ? Events.PRO_REGISTRATION_COMPLETED_TYPE_OF_WORK : Events.CO_REGISTRATION_COMPLETED_COMPANY_INFO;
    default:
      return 'UNKNOWN_REGISTRATION_STEP';
  }
};
