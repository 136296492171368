import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import './job-confirmation-modal.scss';

import acceptedIcon from '../../../assets/icons/accepted.svg';
import blogIcon from '../../../assets/icons/blog.svg';
import canaryIcon from '../../../assets/icons/canary.svg';
import closeIcon from '../../../assets/icons/closeWhite.svg';
import OperationsEmail from '../Components/operations-email';

const JobConfirmationModal = () => {
  const job = useSelector(state => state.job);
  const location = useLocation();
  const { jobInfo } = job.jobInvite;
  const [isOpening, setIsOpening] = useState(true);
  const navigate = useNavigate();
  const isDashboardPage = location.pathname === '/dashboard/';

  const closeModal = () => {
    setIsOpening(false);
    if (isDashboardPage) {
      setTimeout(() => navigate('/dashboard', { replace: true }), 250);
    } else setTimeout(() => navigate('/my-jobs/current', { replace: true }), 250);
  };

  if (isDashboardPage) {
    return (
      <div className={`confirmationModalBackground ${isOpening ? 'opening' : 'closing'}`}>
        <div className={`declineModalMain`}>
          <div className='confirmationModaLHeaderWrapper'>
            <h3 className='confirmationModalH3'>We are sorry you declined {jobInfo.name}.</h3>
            <button onClick={closeModal} className='confirmationModalCloseButton'>
              <img alt='closeIcon' style={{ maxWidth: '24px' }} src={closeIcon} />
            </button>
          </div>
          <p style={{ marginBottom: '34px' }} className='confirmationModalP'>
            Be on the lookout for additional jobs in the future. Make sure to check out the Canary in a Coal Mine Podcast or find useful content on our Blog in
            the meantime.
          </p>
          <div className='declineModalImageWrapper'>
            <img alt='declineModalImage' className='declineModalImages' src={canaryIcon} />
            <div className='declineModalDiv'></div>
            <img alt='declineModalImage' className='declineModalImages' src={blogIcon} />
          </div>
          <p className='confirmationModalH3'>
            Need assistance? Please contact&nbsp;
            <OperationsEmail />
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className={`confirmationModalBackground ${isOpening ? 'opening' : 'closing'}`}>
      <div className='confirmationModalMain'>
        <div className='confirmationModaLHeaderWrapper'>
          <h3 className='confirmationModalH3'>Thank you for accepting {jobInfo.name}.</h3>
          <button onClick={closeModal} className='confirmationModalCloseButton acceptedCloseButton'>
            <img alt='closeIcon' style={{ maxWidth: '24px' }} src={closeIcon} />
          </button>
        </div>
        <section className='confirmationModalSecondaryDiv'>
          <div className='confirmationModalParagraphDiv'>
            <p className='confirmationModalH3'>You can find the details of this job here in ‘Current Jobs’ within your Dashboard. </p>
            <p className='confirmationModalP'>
              Additional job details will be provided by the customer success team, and you will be connected via text message to the customer’s point of
              contact.
            </p>
            <p className='confirmationModalH3'>
              Need assistance? Please contact&nbsp;
              <OperationsEmail />
            </p>
          </div>
          <div className='confirmationModalIconWrapper'>
            <img alt='acceptedIcon' className='acceptedIcon' src={acceptedIcon} />
          </div>
        </section>
      </div>
    </div>
  );
};

export default JobConfirmationModal;
