import React from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { useParams } from 'react-router-dom';

import ExternalAccountList from '../../../components/ExternalAccount';
import PaymentMethodList from '../../../components/PaymentMethodList';
import useStripeSetUpPaymentsForCompany from '../../../hooks/useStripeSetUpPaymentsForCompany';
import useStripeSetUpPayoutsForPro from '../../../hooks/useStripeSetUpPayoutsForPro';
import { useAppDispatch, useAppSelector } from '../../../index';
import { markTaskAsCompleted } from '../../../slices/job.slice';
import { updateCustomTaskForCompany, updateCustomTaskForPro } from '../../../slices/task.slice';
import { Todo } from '../../../types';
import classes from './SingleTaskModal.module.scss';

type SingleTaskModalProps = {
  show: boolean;
  onHide: () => void;
  task: Todo;
};

export default function SingleTaskModal({ show, onHide, task }: SingleTaskModalProps) {
  const { referenceNumber } = useParams();
  const dispatch = useAppDispatch();
  const user = useAppSelector(state => state.user.user);
  const companyPaymentMethods = useAppSelector(state => state.company.paymentMethods);
  const workerPaymentMethods = useAppSelector(state => state.worker.paymentMethods);

  const [loading, setLoading] = React.useState(false);
  const urls = React.useMemo(
    () => ({
      success: `/job/${referenceNumber}?tab=Tasks&setup-payment=success&taskSid=${task?.sid}`,
      error: `/job/${referenceNumber}?tab=Tasks&setup-payment=error&taskSid=${task?.sid}`,
    }),
    [referenceNumber, task]
  );
  const { description: setUpPayoutsDescriptionForPro, SetUpPayoutsForProButton } = useStripeSetUpPayoutsForPro({
    refreshUrl: urls.error,
    returnUrl: urls.success,
  });
  const { description: setUpPaymentsDescriptionForCompany, SetUpPaymentsForCompanyButton } = useStripeSetUpPaymentsForCompany({
    cancelUrl: urls.error,
    successUrl: urls.success,
  });

  async function handleUpdateCustomTaskCompletion() {
    setLoading(true);
    const completed = task.stage === 'COMPLETED';
    try {
      if (!referenceNumber) {
        toast.error('Error updating task. Please try again later.');
        return;
      }
      if (user.role === 'worker') {
        await dispatch(updateCustomTaskForPro({ taskSid: task.sid, stage: completed ? 'restart' : 'complete' })).unwrap();
      } else {
        await dispatch(updateCustomTaskForCompany({ taskSid: task.sid, stage: completed ? 'restart' : 'complete' })).unwrap();
      }
      // TODO: Remove ts-ignore when job.slice.js file is updated to typescript
      // @ts-ignore-next-line
      dispatch(markTaskAsCompleted({ taskSid: task.sid, stage: completed ? 'NOT_STARTED' : 'COMPLETED' }));
      toast.success(`Task marked as ${completed ? 'incomplete' : 'complete'}`);
      onHide();
    } catch (error) {
      toast.error('Error updating task. Please try again later.');
      console.error('<SingleTaskModal /> - handleUpdateCustomTaskCompletion: ', error);
    } finally {
      setLoading(false);
    }
  }

  if (!task) {
    return null;
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      dialogClassName={classes.modal_dialog}
      backdropClassName={classes.modal_backdrop}
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>{task.title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {task.type === 'SETUP_PAYOUT' && task.scope === 'GLOBAL_WORKER' ? (
          <>
            <p>{setUpPayoutsDescriptionForPro}</p>
            <ExternalAccountList externalAccounts={workerPaymentMethods} />
          </>
        ) : task.type === 'SETUP_PAYOUT' && task.scope === 'GLOBAL_COMPANY' ? (
          <>
            <p>{setUpPaymentsDescriptionForCompany}</p>
            <PaymentMethodList paymentMethods={companyPaymentMethods} />
          </>
        ) : (
          <p>{task.descriptionMd}</p>
        )}
      </Modal.Body>

      <Modal.Footer className={classes.modal_footer}>
        <Button variant='secondary' onClick={onHide} className={classes.responsive_button}>
          Close
        </Button>
        {task.type === 'SETUP_PAYOUT' && task.scope === 'GLOBAL_WORKER' ? (
          <SetUpPayoutsForProButton />
        ) : task.type === 'SETUP_PAYOUT' && task.scope === 'GLOBAL_COMPANY' ? (
          <SetUpPaymentsForCompanyButton />
        ) : (
          <Button
            variant='primary'
            onClick={handleUpdateCustomTaskCompletion}
            className={`${classes.button} d-flex align-items-center gap-1 justify-content-center`}
            disabled={loading}
          >
            {loading && <Spinner animation='border' size='sm' />}
            <span>{task.stage === 'COMPLETED' ? 'Mark as incomplete' : 'Mark as complete'}</span>
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}
