import NewButton from '../../../components/NewButton';
import TextInput from '../../../components/TextInput';

import './confirmModal.scss';

export default function ConfirmModal({ message, onClickYes, reasonToDecline, setReasonToDecline }) {
  const decline = message === 'Are you sure you want to reject the Pro “Marked as Complete” status?' ? true : false;
  return (
    <div className='modal-div' style={{ color: '#4b5a63' }}>
      <h5 className='d-flex justify-content-center'>{message}</h5>
      {decline ? (
        <>
          <br />
          <p>What is the reason for declining Completion of this job?</p>
          <TextInput
            type='text'
            placeholder='Enter Your Reason to Decline'
            style={{ borderLeft: 'none', borderTop: 'none', borderRight: 'none' }}
            value={reasonToDecline}
            onChange={e => setReasonToDecline(e.target.value)}
          />
        </>
      ) : null}
      <div className='d-flex justify-content-center mt-5' style={{ marginBottom: '-10px' }}>
        <NewButton className='pointer-cursor' main onClick={onClickYes} style={{ fontWeight: 700 }}>
          Submit
        </NewButton>
      </div>
    </div>
  );
}
