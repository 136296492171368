import React, { createContext, Dispatch, ReactNode, SetStateAction, useState } from 'react';

export type Data = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  terms: boolean;
  referral: string;
  referralSource: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  mobilePhone: string;
  authorizedToWork: string;
  workedIndustries: string[];
  ehsExperience: number;
  otherLanguages: string[];
  certifications: string[];
  environmentalCertifications: string[];
  dotCertifications: string[];
  oshaCertificates: string[];
  oshaCourseCertificates: string[];
  emergencyResponseCertifications: string[];
  environmentalServices: string[];
  generalSkills: string[];
  training: string[];
  mshaBlueCards: string[];
};

// Define the types for your context state
interface ProRegContextType {
  step: number;
  setStep: Dispatch<SetStateAction<number>>;
  data: Data;
  setData: Dispatch<SetStateAction<Data>>;
  errors: Record<string, any>;
  setErrors: Dispatch<SetStateAction<Record<string, any>>>;
}

// Define the initial state
const initialState: ProRegContextType = {
  step: 1,
  setStep: () => {},
  data: {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    terms: false,
    referral: '',
    referralSource: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    mobilePhone: '',
    authorizedToWork: '',
    workedIndustries: [],
    ehsExperience: 0,
    otherLanguages: [],
    certifications: [],
    environmentalCertifications: [],
    dotCertifications: [],
    oshaCertificates: [],
    oshaCourseCertificates: [],
    emergencyResponseCertifications: [],
    environmentalServices: [],
    generalSkills: [],
    training: [],
    mshaBlueCards: [],
  },
  setData: () => {},
  errors: {},
  setErrors: () => {},
};

// Create the context with the initial state
const ProRegContext = createContext<ProRegContextType>(initialState);

// Define the type for the provider props
interface ProRegProviderProps {
  children: ReactNode;
}

// Create the provider component
const ProRegProvider: React.FC<ProRegProviderProps> = ({ children }) => {
  const [step, setStep] = useState<number>(1);
  const [data, setData] = useState<Data>(initialState.data);
  const [errors, setErrors] = useState<Record<string, any>>({});

  return <ProRegContext.Provider value={{ step, setStep, data, setData, errors, setErrors }}>{children}</ProRegContext.Provider>;
};

export { ProRegContext, ProRegProvider };
