import React, { useEffect, useState } from 'react';
import { deleteFromStorage, useLocalStorage } from '@rehooks/local-storage';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ChevronRight } from 'lucide-react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import './postAJob.scss';
import '../Layout/Sidebar/sidebar.scss';

import iconPostJob from '../../assets/images/icons-post-job.svg';
import LeftArrow from '../../assets/images/left-icon.svg';
import Button from '../../components/Button';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { sendCoJobIncompleteEmail } from '../../slices/job.slice';
import Modal from '../Modal';
import ProgressBar from '../ProgressBar';
import Spacer from '../Spacer';
// import { Events } from '../../lib/amplitude';
import Form from './Form';

const PageTitles = [
  'What Role or Service Do You Need?',
  'Job Sites & Timeline',
  'Additional Details',
  'Job Point of Contact',
  'Payment Method',
  'Review Your New Job',
];

const styles = {
  jobButton: {
    boxShadow: '-1px 1px 9px 0px rgba(0, 0, 0, 0.25)',
    color: '#66798c',
    backgroundColor: '#FFCD03',
  },
  promoButton: {
    boxShadow: '-1px 1px 9px 0px rgba(0, 0, 0, 0.25)',
    color: '#66798c',
    backgroundColor: '#fff',
    marginTop: '20px',
  },
};

export default function PostAJob({ fromService, parentId, childId, copy, isSideBar, icon, name, setOpen, isButton, prepaid, jobName }) {
  const { width } = useWindowDimensions();
  const user = useSelector(state => state.user.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { promoBanner: promoBannerFlag } = useFlags();

  const [displayModal, setDisplayModal] = useState(false);
  const [parentIdState, setParentIdState] = useState(parentId);
  const [childIdState, setChildIdState] = useState(childId);
  const [page, setPage] = useState(1);

  const [jobPosted] = useLocalStorage('job-posted');
  const [jobValues] = useLocalStorage('job-values');

  useEffect(() => {
    if (fromService) {
      console.log('fromService', fromService);
      setDisplayModal(true);
    }
  }, [fromService]);

  const handlePrev = () => {
    setPage(page - 1);
  };

  const handleHideModal = async () => {
    if (!jobPosted) {
      await dispatch(sendCoJobIncompleteEmail(jobValues)).unwrap();

      deleteFromStorage('job-posted');
      deleteFromStorage('job-values');
    }
  };

  // useEffect(() => {
  //   const amplitude = require('../../lib/amplitude');
  //   if (page === 1) {
  //     amplitude.sendEvent(Events.CO_STARTED_NEW_JOB);
  //   } else if (page === 2) {
  //     amplitude.sendEvent(Events.CO_ADDED_JOB_SITES);
  //   } else if (page === 3) {
  //     amplitude.sendEvent(Events.CO_ADDED_JOB_TIMELINE);
  //   } else if (page === 5) {
  //     amplitude.sendEvent(Events.CO_ADDED_JOB_CONTACT);
  //   }
  // }, [page]);

  // const amplitude = require('../../lib/amplitude');
  // amplitude.sendEvent(Events.CO_CREATED_NEW_JOB);
  // setTimeout(() => {
  //   navigate('/dashboard');
  // }, 500);

  if (isSideBar) {
    return (
      <div
        role='button'
        onClick={() => {
          navigate('/dashboard');
          setDisplayModal(true);
          setOpen(false);
        }}
        className={`${user.role === 'worker' ? 'sidebar-navlink-worker' : 'sidebar-navlink'}`}
      >
        {icon}
        {name}
      </div>
    );
  }

  if (isButton) {
    return (
      <div>
        <button
          className='postajob-button-allservices dashboard-component-animation'
          onClick={() => {
            navigate('/dashboard');
            setDisplayModal(true);
          }}
        >
          Post a Job
        </button>
      </div>
    );
  }
  return (
    <Col className='d-flex justify-content-end p-0 shadow-md'>
      <div className='d-flex flex-column'>
        <button
          style={styles.jobButton}
          onClick={() => {
            setParentIdState(null);
            setChildIdState(null);
            navigate('/dashboard');
            setDisplayModal(true);
          }}
          className='btn-text-white flex-column post-job-button dashboard-component-animation'
        >
          <div className='post-job-white-div'>
            <div>
              <p style={{ whiteSpace: 'nowrap', fontWeight: 700, fontSize: '20px', color: '#49525E', margin: '0px', textAlign: 'left' }}>Post a Job Now</p>
              <p style={{ fontWeight: 500, fontSize: '13px', color: '#49525E', margin: '0px', textAlign: 'left' }}>
                Get started today by posting your first EHS or Risk Management job and quickly connect with a qualified YellowBird Pro.
              </p>
            </div>
            <div className='post-job-div-img'>
              <img className='post-job-img' src={iconPostJob} alt='' />
            </div>
          </div>
          <div className='post-job-begin-job'>
            <p style={{ whiteSpace: 'nowrap', fontWeight: 700, fontSize: '20px', color: '#000', margin: '0px' }}>Begin Job Posting</p>
            <ChevronRight color='#000' strokeWidth={3} />
          </div>
        </button>

        {/* Promo Button */}
        {promoBannerFlag?.enabled && (
          <Button
            style={styles.promoButton}
            onClick={() => {
              setParentIdState(promoBannerFlag.promo_parent_id);
              setChildIdState(promoBannerFlag.promo_children_id);
              navigate(`/dashboard?action=post-a-job&parentId=${promoBannerFlag.promo_parent_id}&childId=${promoBannerFlag.promo_children_id}`);
              setDisplayModal(true);
            }}
            className='button btn-text-white flex-column d-flex mini-q1-promo-button dashboard-component-animation'
          >
            <div className='mini-q1-promo-button__content'>
              <div className='mini-q1-promo-button__content-text'>
                <p className='mini-q1-promo-button__first'>
                  Limited <span>Time March Offer</span>
                </p>
                <p className='mini-q1-promo-button__percentage'>
                  20%<span className='off'>OFF</span>
                </p>
                <p className='mini-q1-promo-button__last'>Loss Control Audits*</p>
              </div>
              <p className='mini-q1-promo-button__terms_and_conditions'>*Terms and Conditions Apply.</p>
            </div>

            <div className='mini-q1-promo-button__bottom'>
              <p style={{ whiteSpace: 'nowrap', fontWeight: 700, fontSize: '20px', color: '#000', margin: '0px' }}>Get Started & Save</p>
              <ChevronRight color='#000' strokeWidth={3} />
            </div>
          </Button>
        )}
      </div>
      <Modal
        shouldHideClose={true}
        leftArrow={LeftArrow}
        scrollable
        dialogClassName
        show={displayModal}
        centered
        onHide={() => {
          setPage(1);
          handleHideModal();
          setDisplayModal(!displayModal);
        }}
      >
        <>
          <div className='relative'>
            <Col className='absolute -left-5 cursor-pointer md:-left-10'>
              {page !== 1 ? (
                <div onClick={handlePrev}>
                  <img
                    width='12'
                    height='20'
                    src={LeftArrow}
                    alt=''
                    className={`image-width ${width === 320 ? 'small-screen' : width === 768 ? 'medium-screen' : 'large-screen'}`}
                  />
                </div>
              ) : null}
            </Col>
            {page >= 1 && page <= 6 && <Col style={styles.headerText}>{PageTitles[page - 1]}</Col>}
          </div>
          <Spacer h={24} />
          <ProgressBar totalSteps={6} currentStep={page} />
          <Row>
            <Form
              copy={copy}
              fromService={fromService}
              parentId={parentIdState}
              childId={childIdState}
              handlePrev={handlePrev}
              setDisplayModal={setDisplayModal}
              page={page}
              setPage={setPage}
              prepaid={prepaid}
              jobName={jobName}
            />
          </Row>
        </>
      </Modal>
    </Col>
  );
}
