import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { FaLock } from 'react-icons/fa';
import ExternalAccountList from 'src/components/ExternalAccount';

import AddUserIcon from '../../assets/images/add-users-icon.svg';
import LogoutIcon from '../../assets/images/logout-icon.svg';
import Loading from '../../components/Loading';
import Modal from '../../components/Modal';
import PaymentMethodList from '../../components/PaymentMethodList';
import SetUpPaymentsStatusModal from '../../components/SetUpPaymentsForPro/SetUpPaymentsStatusModal';
import TextInput from '../../components/TextInput';
import useSignOut from '../../hooks/useSignOut';
import useStripeSetUpPaymentsForCompany from '../../hooks/useStripeSetUpPaymentsForCompany';
import useStripeSetUpPayoutsForPro from '../../hooks/useStripeSetUpPayoutsForPro';
import { useAppDispatch, useAppSelector } from '../../index';
import { getCompanyPaymentMethods, retrieveCo } from '../../slices/company.slice';
import { updateUser } from '../../slices/user.slice';
import { fetchWorkerById, getWorkerPaymentMethods } from '../../slices/worker.slice';
import { Todo } from '../../types';
import AdditionalUsers from './AdditionalUsers';
import classes from './myAccount.module.scss';
import UpdateInfo from './UpdateInfo';
import UpdatePassword from './UpdatePassword';

import '../../components/TextInput/textInput.scss';
import './UpdatePassword/updatePassword.scss';

const urls = {
  success: '/myaccount?setup-payment=success',
  error: '/myaccount?setup-payment=error',
};

export default function MyAccount() {
  const dispatch = useAppDispatch();
  const signOut = useSignOut();
  const { user, isLoading: userLoading } = useAppSelector(state => state.user);
  const { isLoading, paymentMethods } = useAppSelector(state => state.company);
  const { additionalUsers, company }: Todo = useAppSelector(state => state.company.company);
  const worker = useAppSelector(state => state.worker.worker);
  const workerPaymentMethods = useAppSelector(state => state.worker.paymentMethods);
  const [showPasswordModal, setShowPasswordModal] = React.useState(false);
  const [showAddUserModal, setShowAddUserModal] = React.useState(false);
  const [editModePro, setEditModePro] = React.useState(false);
  const [editModeCoCompanyInfo, setEditModeCoCompanyInfo] = React.useState(false);

  const { description: setUpPayoutsDescriptionForPro, SetUpPayoutsForProButton } = useStripeSetUpPayoutsForPro({
    refreshUrl: urls.error,
    returnUrl: urls.success,
  });

  const { description: setUpPaymentsDescription, SetUpPaymentsForCompanyButton } = useStripeSetUpPaymentsForCompany({
    successUrl: urls.success,
    cancelUrl: urls.error,
  });

  const addUserValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    companyId: user.companyId,
    role: 'user',
  };
  const infoValues = {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    mobilePhone: worker?.mobilePhone || '',
    address: worker?.address || '',
    city: worker?.city || '',
    state: worker?.state || '',
    zipCode: worker?.zipCode || '',
    workerId: user.workerId,
  };
  const infoValuesCo = {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
  };
  let coValues;
  if (company && company[0]) {
    coValues = {
      id: company[0].id,
      name: company[0].name || '',
      numOfEmployees: company[0].numOfEmployees || '',
      businessPhone: company[0].businessPhone || '',
      website: company[0].website || '',
      address: company[0].address || '',
      city: company[0].city || '',
      state: company[0].state || '',
      zip: company[0].zip || '',
    };
  }

  React.useEffect(() => {
    (async () => {
      if (user.role === 'user') {
        dispatch(getCompanyPaymentMethods());
        // TODO: Remove ts-ignore when company.slice.js file is updated to typescript
        // @ts-ignore-next-line
        await dispatch(retrieveCo({ companyId: user.companyId })).unwrap();
      } else if (user.role === 'worker') {
        dispatch(getWorkerPaymentMethods());
        dispatch(fetchWorkerById(user.workerId));
      }
    })();
  }, [dispatch, user]);

  async function handleReactivateDeactivateAccount() {
    const newStatus = user.status === 'INACTIVE' ? 'ACTIVE' : 'INACTIVE';
    try {
      await dispatch(updateUser({ updates: { status: newStatus } }));
      if (newStatus === 'INACTIVE') {
        signOut();
      }
    } catch (error) {
      toast.error('An error occurred. Please try again later.');
    }
  }

  if (isLoading || userLoading) {
    return <Loading />;
  }

  //worker account info
  if (user.workerId) {
    return (
      <Container fluid style={{ padding: '40px' }}>
        <UpdateInfo values={infoValues} editModePro={editModePro} setEditModePro={setEditModePro} />
        <hr className='my-0' />
        {/* Payment Info */}
        <Row className='pb-4'>
          <Col className='mt-4'>
            <h6 className={classes.header_text}>Payment Methods</h6>
            <p>{setUpPayoutsDescriptionForPro}</p>
            <SetUpPayoutsForProButton />
            <ExternalAccountList externalAccounts={workerPaymentMethods} />
            <SetUpPaymentsStatusModal />
          </Col>
        </Row>
        <hr className='my-0' />
        <Row className='d-flex flex-column'>
          <Col className='mt-5' style={{ color: '#586f7c' }}>
            <h6 className={classes.header_text}>Settings</h6>
          </Col>
        </Row>
        <hr className='my-0' />
        <Row className='d-flex flex-column'>
          <Col>
            <button
              id='Change Password Button'
              onClick={() => setShowPasswordModal(true)}
              className='ps-0 my-4'
              style={{ backgroundColor: 'transparent', border: 'none', fontSize: '14px', fontWeight: 700 }}
            >
              Change Password
            </button>
          </Col>
          <Col className='pb-4'>
            <button
              onClick={handleReactivateDeactivateAccount}
              style={{ backgroundColor: 'transparent', border: 'none', fontSize: '14px', fontWeight: 700, padding: 0, display: 'flex', alignItems: 'center' }}
            >
              <FaLock size={20} style={{ marginRight: '14px' }} />
              {user.status === 'INACTIVE' ? 'Reactivate Account' : 'Deactivate Account'}
            </button>
          </Col>
          <Row>
            <Col className='pb-4'>
              <button
                id='Logout Button'
                onClick={signOut}
                style={{ backgroundColor: 'transparent', border: 'none', fontSize: '14px', fontWeight: 700, padding: 0, display: 'flex', alignItems: 'center' }}
              >
                <img className='px-0' src={LogoutIcon} alt='' style={{ marginRight: '10px' }} />
                Log Out
              </button>
            </Col>
          </Row>
        </Row>
        <Modal show={showPasswordModal} centered onHide={() => setShowPasswordModal(!showPasswordModal)}>
          <UpdatePassword onCancel={() => setShowPasswordModal(false)} />
        </Modal>
      </Container>
    );
  }

  // company user
  return (
    <Container fluid style={{ padding: '40px' }}>
      {company && (
        <>
          <Modal size='lg' show={showAddUserModal} centered onHide={() => setShowAddUserModal(!showAddUserModal)}>
            <AdditionalUsers setShowAddUserModal={setShowAddUserModal} values={addUserValues} />
          </Modal>
          <UpdateInfo
            values={infoValuesCo}
            coValues={coValues}
            editModePro={editModePro}
            setEditModePro={setEditModePro}
            editModeCoCompanyInfo={editModeCoCompanyInfo}
            setEditModeCoCompanyInfo={setEditModeCoCompanyInfo}
          />

          <Row className='ps-0 mt-3 pb-4'>
            <Col className='mt-4' style={{ color: '#586f7c' }}>
              <h6 className={classes.header_text}>Additional Company Users</h6>
            </Col>
            <Col className='d-flex justify-content-end mt-4'>
              <div>
                <button type='button' style={{ backgroundColor: 'transparent', border: 'none', fontSize: '14px' }} onClick={() => setShowAddUserModal(true)}>
                  <img style={{ fontSize: '14px' }} src={AddUserIcon} alt='' />
                </button>
              </div>
            </Col>
          </Row>
          <hr className='my-0' />
          <Row>
            <Col>
              {additionalUsers && additionalUsers.length > 0
                ? additionalUsers.map((item: Todo) => {
                    if (item.id !== user.id) {
                      return (
                        <>
                          <Row key={item.id}>
                            <Col md={5}>
                              <TextInput
                                label='Name'
                                value={`${item.firstName} ${item.lastName}`}
                                style={{ color: '#5a5a5a', fontSize: '14px', backgroundColor: 'transparent' }}
                                readOnly={true}
                              />
                            </Col>
                            <Col md={2}></Col>
                            <Col md={5}>
                              <TextInput
                                label='Email'
                                value={item.email}
                                style={{ color: '#5a5a5a', fontSize: '14px', backgroundColor: 'transparent' }}
                                readOnly={true}
                              />
                            </Col>
                          </Row>
                        </>
                      );
                    } else {
                      return null;
                    }
                  })
                : null}
            </Col>
          </Row>

          <hr className='my-0' />
          {/* Payment Info */}
          <Row className='pb-4'>
            <Col className='mt-4'>
              <h6 className={classes.header_text}>Payment Methods</h6>
              <p>{setUpPaymentsDescription}</p>
              <SetUpPaymentsForCompanyButton />
              <PaymentMethodList paymentMethods={paymentMethods} />
              <SetUpPaymentsStatusModal />
            </Col>
          </Row>
          <hr className='my-0' />
          <Row className='d-flex flex-column mt-4'>
            <Col className='mt-4' style={{ color: '#586f7c' }}>
              <h6 className={classes.header_text}>Settings</h6>
            </Col>
          </Row>
          <Row className='d-flex flex-column'>
            <Col>
              <button
                onClick={() => setShowPasswordModal(!showPasswordModal)}
                className='ps-0 my-4'
                style={{ backgroundColor: 'transparent', border: 'none', fontSize: '14px', fontWeight: 700 }}
              >
                Change Password
              </button>
            </Col>
            <Col className='pb-4'>
              <button
                onClick={handleReactivateDeactivateAccount}
                style={{ backgroundColor: 'transparent', border: 'none', fontSize: '14px', fontWeight: 700, padding: 0, display: 'flex', alignItems: 'center' }}
              >
                <FaLock size={20} style={{ marginRight: '14px' }} />
                {user.status === 'INACTIVE' ? 'Reactivate Account' : 'Deactivate Account'}
              </button>
            </Col>
            <Col className='pb-4'>
              <button
                onClick={signOut}
                style={{ backgroundColor: 'transparent', border: 'none', fontSize: '14px', fontWeight: 700, padding: 0, display: 'flex', alignItems: 'center' }}
              >
                <img className='px-0' src={LogoutIcon} alt='' style={{ marginRight: '10px' }} />
                Log Out
              </button>
            </Col>
          </Row>

          {/* Update Password Modal */}
          <Modal show={showPasswordModal} centered onHide={() => setShowPasswordModal(false)}>
            <UpdatePassword onCancel={() => setShowPasswordModal(false)} />
          </Modal>
        </>
      )}
    </Container>
  );
}
