import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { camelizeKeys } from 'humps';

import api from '../api';
import reviewApi from '../api/serverless';
import apiCamelback from "../api/apiCamelback";

export const selectProToReview = createAsyncThunk('review/setPro', async (jobId, thunkApi) => {
  let reviewObj = {};
  try {
    const response = await apiCamelback.get(`/api/v1/jobs/worker_for_job/${jobId}`);
    if (response.data) {
      reviewObj.worker = response.data.worker;
      reviewObj.company = response.data.company;
      reviewObj.job = response.data.job;
      return camelizeKeys(reviewObj);
    }
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const selectCoToReview = createAsyncThunk('review/setCo', async (jobId, thunkApi) => {
  let reviewObj = {};
  try {
    const response = await apiCamelback.get(`/api/v1/jobs/co_for_job/${jobId}`);
    if (response.data) {
      reviewObj.worker = response.data.worker;
      reviewObj.company = response.data.company;
      reviewObj.job = response.data.job;
      return camelizeKeys(reviewObj);
    }
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const getReviews = createAsyncThunk('review/getAllByCompanyId', async (coId, thunkApi) => {
  try {
    const response = await reviewApi.get(`/pros/pro-reviews-by-co-id/${coId}`);
    if (response.data) {
      return camelizeKeys(response.data);
    }
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const getCoReviews = createAsyncThunk('review/getAllCoReviews', async (proId, thunkApi) => {
  try {
    const response = await reviewApi.get(`/cos/co-reviews-by-pro-id/${proId}`);
    if (response.data) {
      return camelizeKeys(response.data);
    }
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const submitReview = createAsyncThunk('review/submit', async (review, thunkApi) => {
  try {
    await reviewApi.post(`/pros/pro-reviews`, review);
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const submitCoReview = createAsyncThunk('coReview/submit', async (review, thunkApi) => {
  // const decamelized = decamelizeKeys(review)
  try {
    await reviewApi.post(`/cos/co-reviews`, review);
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

const initialState = {
  error: null,
  isLoading: false,
  reviewObj: {},
};

export const proReviewByCoSlice = createSlice({
  name: 'proReviewByCo',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(selectProToReview.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(selectProToReview.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(selectProToReview.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.reviewObj = action.payload;
      })
      .addCase(selectCoToReview.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(selectCoToReview.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(selectCoToReview.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.reviewObj = action.payload;
      })
      .addCase(submitReview.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(submitReview.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(submitReview.fulfilled, (state, action) => {
        state.error = null;
        state.isLoading = false;
      })
      .addCase(submitCoReview.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(submitCoReview.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(submitCoReview.fulfilled, (state, action) => {
        state.error = null;
        state.isLoading = false;
      })
      .addCase(getCoReviews.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getCoReviews.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(getCoReviews.fulfilled, (state, action) => {
        state.error = null;
        state.isLoading = false;
        state.reviews = action.payload;
      })
      .addCase(getReviews.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getReviews.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(getReviews.fulfilled, (state, action) => {
        state.error = null;
        state.isLoading = false;
        state.reviews = action.payload;
      });
  },
});
