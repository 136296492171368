import { c } from '../../constants/colors';

const styles = {
  checkTangle: {
    color: '#586f7c',
    fontSize: '1.125rem',
    fontWeight: 'bold',
  },
  blueHeading: {
    fontSize: 22,
    fontWeight: 700,
    color: '#586F7C',
  },
  subHeading: {
    fontSize: 20,
    fontWeight: 600,
    color: '#4B5A63',
  },
  mediumHeading: {
    fontSize: 17.5,
    fontWeight: 600,
    color: '#4B5A63',
  },
  smallHeading: {
    fontSize: 16,
    fontWeight: 600,
    color: '#4B5A63',
    marginBottom: 0,
  },
  greyedOut: {
    fontSize: 16,
    color: '#B4B4B4',
  },
  normal: {
    color: '#323031',
    marginBottom: 0,
    fontSize: 14,
    margin: 0,
  },
  smallText: {
    color: '#323031',
    marginBottom: 0,
    fontSize: 13.5,
  },
  greySmallText: {
    color: c.grey,
    marginBottom: 0,
    fontSize: 13.5,
  },
  buttonText: {
    color: c.black,
    fontSize: 15,
    fontWeight: 610,
    margin: 0,
  },
  whiteButtonText: {
    color: c.white,
    fontSize: 15,
    fontWeight: 610,
    margin: 0,
  },
  heading: {
    color: c.darkBlue,
    fontWeight: 700,
    fontSize: 26,
  },
  notBoldHeading: {
    color: c.darkBlue,
    fontSize: 20,
  },
  notBoldSmallHeading: {
    fontSize: 16,
    color: c.darkBlue,
    marginBottom: 0,
  },
  normalBold: {
    fontWeight: 700,
    fontSize: 14,
    color: c.mediumBlue,
    margin: 0,
  },
  smallBold: {
    fontWeight: 700,
    fontSize: '1.125rem',
    color: c.darkBlue,
    margin: 0,
  },
  smallBoldBlack: {
    fontWeight: 700,
    fontSize: '0.844rem',
    color: c.black,
    margin: 0,
    marginBottom: 0,
    display: 'inline-block',
  },
  mediumBoldBlack: {
    fontWeight: 700,
    fontSize: 15,
    color: c.black,
    margin: 0,
  },
  bigBlue: {
    fontWeight: 650,
    fontSize: '1.359rem',
    color: c.lightBlue,
    margin: 0,
  },
  bigBlack: {
    fontWeight: 600,
    fontSize: '1.359rem',
    color: c.black,
    margin: 0,
  },
  boldHeading: {
    fontWeight: 800,
    fontSize: '1.406rem',
  },
  orangeBold: {
    fontWeight: 800,
    fontSize: '1.875rem',
    color: '#ffcd03',
    margin: 0,
  },
  red: {
    color: c.red,
    margin: 0,
    fontSize: '0.843rem',
    fontWeight: 700,
  },
  greyInline: {
    color: c.mediumBlue,
    display: 'inline-block',
  },
};

const Text = ({
  t,
  children,
  mediumHeading,
  whiteButtonText,
  smallText,
  blueHeading,
  subHeading,
  smallHeading,
  normal,
  greyedOut,
  buttonText,
  heading,
  normalBold,
  notBoldHeading,
  className,
}) => {
  return (
    <p
      className={className}
      style={
        t === 'smallHeading'
          ? styles.smallHeading
          : t === 'smallText'
            ? styles.smallText
            : t === 'greySmall'
              ? styles.greySmallText
              : t === 'notBoldSmallHeading'
                ? styles.notBoldSmallHeading
                : t === 'buttonText'
                  ? styles.buttonText
                  : t === 'whiteButtonText'
                    ? styles.whiteButtonText
                    : t === 'smallBold'
                      ? styles.smallBold
                      : t === 'boldHeading'
                        ? styles.boldHeading
                        : t === 'orangeBold'
                          ? styles.orangeBold
                          : t === 'smallBoldBlack'
                            ? styles.smallBoldBlack
                            : t === 'red'
                              ? styles.red
                              : t === 'mediumBoldBlack'
                                ? styles.mediumBoldBlack
                                : t === 'bigBlack'
                                  ? styles.bigBlack
                                  : t === 'greyInline'
                                    ? styles.greyInline
                                    : t === 'checkTangle'
                                      ? styles.checkTangle
                                      : whiteButtonText
                                        ? styles.whiteButtonText
                                        : blueHeading
                                          ? styles.heading
                                          : subHeading
                                            ? styles.subHeading
                                            : smallHeading
                                              ? styles.smallHeading
                                              : greyedOut
                                                ? styles.greyedOut
                                                : normal
                                                  ? styles.normal
                                                  : buttonText
                                                    ? styles.buttonText
                                                    : heading
                                                      ? styles.heading
                                                      : notBoldHeading
                                                        ? styles.notBoldHeading
                                                        : normalBold
                                                          ? styles.normalBold
                                                          : smallText
                                                            ? styles.smallText
                                                            : mediumHeading
                                                              ? styles.mediumHeading
                                                              : styles.normal
      }
    >
      {children}
    </p>
  );
};

export default Text;
