import React from 'react';
import { Download, File } from 'lucide-react';
import { OverlayPanel } from 'primereact/overlaypanel';
import { FaEllipsisV } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { Todo } from 'src/types';

import Loading from '../../components/Loading';
import { capitalize, formatDateFromUnix, formatMoneyFromCents } from '../../helpers';
import { useAppDispatch, useAppSelector } from '../../index';
import { getJobInvoices } from '../../slices/company.slice';
import classes from './Invoices.module.scss';

export default function Invoices({ jobId }: { jobId: string }) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user, isLoading: userLoading } = useAppSelector(state => state.user);
  const { isLoading, invoices }: { isLoading: boolean; invoices: Todo } = useAppSelector(state => state.company);

  React.useEffect(() => {
    (async () => {
      if (user.role === 'user') {
        // TODO: Remove ts-ignore when company.slice.js file is updated to typescript
        // @ts-ignore-next-line
        dispatch(getJobInvoices({ jobId }));
      }
    })();
  }, [dispatch, user, navigate, jobId]);

  if (isLoading || userLoading) {
    return <Loading />;
  }

  return invoices.length === 0 ? (
    <div className={classes.no_invoices}>
      <div className=''>
        <p>
          <strong>No invoices</strong>
        </p>
        <p>You currently don't have any invoices.</p>
      </div>
    </div>
  ) : (
    <ul className={classes.invoices}>
      {invoices.map((invoice: Todo) => (
        <Invoice key={invoice.id} invoice={invoice} />
      ))}
    </ul>
  );
}

function Invoice({ invoice }: { invoice: Todo }) {
  const op = React.useRef<OverlayPanel>(null);

  return (
    <li>
      <div className={classes.left}>
        <File size={24} />
        <h3>
          {formatDateFromUnix(new Date(invoice.period_start))} <span className={classes.invoice_status}>{capitalize(invoice.status)}</span>
        </h3>
      </div>
      <div className={classes.right}>
        <h3>{formatMoneyFromCents(invoice.amount_due)} USD</h3>
        <button type='button' className={classes.ellipsis_button} onClick={e => op.current?.toggle(e)}>
          <FaEllipsisV size={20} />
        </button>
        <OverlayPanel ref={op}>
          <a href={invoice.invoice_pdf} download className={classes.download}>
            <Download size={20} />
            Download PDF
          </a>
        </OverlayPanel>
      </div>
    </li>
  );
}
