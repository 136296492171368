import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

const CustomNavlink = ({ icon, path, name, setOpen }) => {
  const user = useSelector(state => state.user.user);

  const linkClassName = user.role === 'worker' ? 'sidebar-navlink-worker' : 'sidebar-navlink';
  const activeLinkClassName = user.role === 'worker' ? 'active-link-worker' : 'active-link';
  return (
    <NavLink
      onClick={() => setOpen(false)}
      className={({ isActive }) => `sidebar-container-navlink ${linkClassName} ${isActive ? activeLinkClassName : ''}`}
      to={path}
    >
      {icon ? icon : null}
      {name}
    </NavLink>
  );
};

export default CustomNavlink;
