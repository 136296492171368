import React from 'react';

import ForgotPasswordContainer from './ForgotPasswordContainer';

import './forgotPassword.scss';

const ForgotPassword = () => {
  return <ForgotPasswordContainer />;
};

export default ForgotPassword;
