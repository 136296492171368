import React from 'react';

export default function Loading({ removeText = false }) {
  return (
    <div style={{ height: '100%', width: '100%' }} className={`text-center d-flex justify-content-center align-items-center${!removeText && ' mt-5'}`}>
      <div className='d-block'>
        <div className={`spinner-border ${!removeText && 'mb-3'}`} role='status' />
        {!removeText && <h1 className='mb-5'>Loading</h1>}
      </div>
    </div>
  );
}
