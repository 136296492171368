import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { FaPhoneAlt } from 'react-icons/fa';

import YellowbirdHeaderLogo from '../../../assets/images/yellowbird-header-logo.svg';

import './footer.scss';

import { CONTACT_US_1800_NUMBER } from '../../../env';

export default function Footer() {
  return (
    <footer className='mt-auto pt-3 pt-md-2 pb-5 pb-md-0 d-none d-md-block'>
      <Container className='pb-2' fluid style={{ maxWidth: '1280px', position: 'sticky', bottom: 0 }}>
        <Row>
          <Col xs={12} md={4} lg={3} className=''>
            <Brand />
          </Col>
          <Col></Col>
          <Col xs={12} md={4} lg={3} className=''>
            <ContactUs />
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

const Brand = () => (
  <div className='footer-brand text-center ps-4 ps-sm-3'>
    <img className='d-flex mb-2 ps-3 ps-sm-4 ms-sm-2 w-50 justify-content-center' src={YellowbirdHeaderLogo} alt='YellowBird Logo' />
    <span className='d-flex'>
      <p>
        YellowBird connects EHS Professionals
        <br />
        with Companies On-Demand
      </p>
    </span>
  </div>
);

const ContactUs = () => (
  <div className='footer-contact text-center justify-content-sm-end me-4 ps-4 pt-sm-2'>
    <p className='d-flex justify-content-sm-end mb-1 me-3 ps-4'>CONTACT US</p>
    <span className='d-flex justify-content-sm-end'>
      <p>
        <FaPhoneAlt size={18} color='#ffce07' className='me-sm-2' />
        <a className='phone-number' href={`tel:${CONTACT_US_1800_NUMBER}`}>
          {CONTACT_US_1800_NUMBER}
        </a>
      </p>
    </span>
  </div>
);
