import { useFormContext } from 'react-hook-form';

import ConditionedInput from '../../conditionedInput';
import Navigation from '../../layout/navigation';
import CheckboxOption from './checkboxOption';

import './step-4.css';

const Step4 = () => {
  const {
    formState: { isValid, errors },
    watch,
  } = useFormContext();
  const otherChecked = watch('types.other');

  return (
    <div className='multiStepContainer'>
      <div className='scrollAreaMulstiStep'>
        <h1 className='h1MultiStepForm'>
          What type of <br />
          EHS or Risk Management project(s) do you need completed?
        </h1>
        <fieldset className='checkboxesContainer'>
          <CheckboxOption value='trainings' text='Trainings' />
          <CheckboxOption value='assesmentsOrSurveys' text='Assessments or Surveys' />
          <CheckboxOption value='inspections' text='Inspections' />
          <CheckboxOption value='safetyManagamentOrConsulty' text='Safety Management or Consulting' />
          <CheckboxOption value='environmental' text='Environmental Services' />
          <CheckboxOption value='policy' text='Content or Policy Development' />
          <CheckboxOption value='buildASafetyProgram' text='Build a Safety Program' />
          <CheckboxOption value='offerOrSale' text='Offer or Sell Safety Services to my Clients' />
          <CheckboxOption value='other' text='Other' />
          {otherChecked && (
            <ConditionedInput fieldValue='types.otherDescription' error={errors.types?.otherDescription?.message?.toString()} placeholder='Please Indicate*' />
          )}
        </fieldset>
      </div>
      <Navigation disabled={isValid} />
    </div>
  );
};

export default Step4;
