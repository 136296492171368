import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { camelizeKeys, decamelizeKeys } from 'humps';

import api from '../api';
import apiCamelback from "../api/apiCamelback";

export const getReferralsById = createAsyncThunk('/referrals/getReferralsById', async ({ userId }, thunkApi) => {
  const response = await apiCamelback.get(`/api/v1/referrals/${userId}`);
  const camelized = camelizeKeys(response.data);
  if (response.data.errors) {
    return thunkApi.rejectWithValue(response.data.errors);
  }
  return camelized;
});

export const sendEmailInvites = createAsyncThunk('/referrals/sendEmailInvites', async (body, thunkApi) => {
  let decamelized = decamelizeKeys(body);
  const response = await apiCamelback.post('/api/v1/referrals/send_email_invites', { referrals: decamelized });
  if (response.data.errors) {
    return thunkApi.rejectWithValue(response.data.errors);
  }
});

export const createReferral = createAsyncThunk('/referrals/createReferral', async (body, thunkApi) => {
  console.log('createReferral called');
  let decamelized = decamelizeKeys(body);
  const response = await apiCamelback.post('/api/v1/referrals', decamelized);
  if (response.data.errors) {
    return thunkApi.rejectWithValue(response.data.errors);
  }
});

export const retrieveReferrerId = createAsyncThunk('/referrals/retrieveReferrerId', async (referralCode, thunkApi) => {
  console.log('retrieveReferral called');
  const response = await apiCamelback.get(`api/v1/referrals/referrer/${referralCode}`);
  if (response.data === null) {
    return thunkApi.rejectWithValue('Invalid referral code');
  }
  return camelizeKeys(response.data);
});

export const updateReferral = createAsyncThunk('/referrals/updateReferral', async (body, thunkApi) => {
  const decamelized = decamelizeKeys(body);
  const { referee_user_id, is_referral_fee_earned } = decamelized;
  const response = await apiCamelback.put(`api/v1/referrals/fee-earned`, { referee_user_id, is_referral_fee_earned });
  if (response.data.errors) {
    return thunkApi.rejectWithValue(`error ${response.data.errors}`);
  }
  return response.data;
});

const initialState = {
  error: null,
  isLoading: false,
  referralToAdd: {},
  referrals: [],
};

export const referralsSlice = createSlice({
  name: 'referrals',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      //get referrals for the signed in user
      .addCase(getReferralsById.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getReferralsById.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getReferralsById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.referrals = action.payload;
      })
      //send email invites
      .addCase(sendEmailInvites.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(sendEmailInvites.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(sendEmailInvites.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      //create referral
      .addCase(createReferral.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createReferral.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(createReferral.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      //get referrer's id by code
      .addCase(retrieveReferrerId.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(retrieveReferrerId.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(retrieveReferrerId.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      //updating the referral based on the professionals onboarding
      .addCase(updateReferral.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateReferral.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(updateReferral.fulfilled, (state, action) => {
        state.isLoading = false;
      });
  },
});
