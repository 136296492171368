export const assesments = [
  'Loss Control/Risk Assessment',
  'Safety Walkthrough',
  'Site Survey',
  'Environmental Site Assessment',
  'Mock OSHA Inspection',
  'Environmental Compliance Assessment',
  'Incident Investigation',
  'Job Safety Analysis',
  'Fire Safety Assessment',
  'Industrial Hygiene Assessment',
  'Noise Dosimetry Assessment',
  'Ergonomics Assessment',
  'Air Quality Assessment',
  'Hazard Assessment (Chemical, Biological, Machine Safety)',
];

export const trainingAndClasses = [
  'OSHA 10 Training',
  'OSHA 30 Training',
  'OSHA 300 Training',
  'First Aid & CPR/AED Training',
  'Fire Protection Training',
  'Defensive Driving Training',
  'Emergency Preparedness Training',
  'MSHA Mining Training',
  'MSHA Part 46 Training Refresher',
  'HAZWOPER 8 Training',
  'HAZWOPER 24 Training',
  'HAZWOPER 40 Training',
  'Hazard Communication Training',
  'Bloodborne Pathogens Training',
  'Respiratory Protection Training',
  'Electrical Safety Training (LockOut TagOut, Arc Flash)',
  'Fall Protection Training',
  'Confined Space Training',
  'Forklift Training',
  'Boom Lift Training',
  'Scissor Lift Training',
  'Overhead Crane Training',
  'Stormwater Management Training',
  'Spill Prevention Training',
  'Electric Pallet Jack Training',
  'DOT Advanced Training',
  'Scaffolding Training',
  'Machine Guarding Training',
  'Excavation/Trenching Training',
  'Rigging/Signaling Training',
  'Personal Protective Equipment (PPE) Training',
];

export const workPolicyDevelopment = [
  'Training Manuals',
  'DOT/CMV Policy',
  'Incident Reporting and Investigation Plan',
  'Weather-Related Hazard Program',
  'Industrial Hygiene Program',
  'Environmental Compliance Policy',
  'Fire Safety Policy',
  'Hazard Communication Policy',
  'Cannabis Policy',
  'Emergency Response Policy',
  'Storm/Wastewater Management Policy',
  'Injury/Illness Prevention Program',
  'OSHA Citation Response',
  'Vendor Certification',
];

export const extendedContractWork = [
  'Safety Manager',
  'Safety Professional',
  'Site Manager',
  'EHS Consultant',
  'Ergonomics Specialist',
  'Project Manager',
  'Environmental Compliance Manager',
  'Industrial Hygienist',
];
