import { XIcon } from 'lucide-react';

const CloseModalButton = ({ setIsExitDialogOpen }) => {
  return (
    <div style={{ display: 'flex', position: 'absolute', right: '24px', top: '0', gap: '12px', alignItems: 'center' }}>
      <XIcon
        style={{ cursor: 'pointer' }}
        onClick={() => {
          setIsExitDialogOpen(true);
        }}
      />
    </div>
  );
};

export default CloseModalButton;
