import React from 'react';

const Section = ({ heading, services }) => {
  return (
    <section style={{ fontSize: '14px', marginBottom: '20px' }}>
      <p className='mb-3' style={{ fontWeight: '700' }}>
        {heading}
      </p>
      {services.map(s => {
        return (
          <div style={{ display: 'flex', gap: '8px', marginLeft: '10px' }} key={s}>
            <span>•</span>
            <span>{s}</span>
          </div>
        );
      })}
    </section>
  );
};

export default Section;
