import React, { useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from 'lucide-react';

import EditProfileCheckbox from './edit-profile-checkbox';

type List = {
  id: number;
  label?: string;
  nameOfIndustries?: string;
  name?: string;
  cardType?: string;
};

type Props = {
  setUpdates: React.Dispatch<React.SetStateAction<{}>>;
  updates: {};
  label: string;
  category: number[];
  fieldName: string;
  list: List[];
};

export default function EditProfileDropdown({ label, category, list, setUpdates, updates, fieldName }: Props) {
  const selectedItems = category
    .map(id => {
      const listItem = list?.find(it => it.id === id);
      return listItem ? listItem.label || listItem.name || listItem.nameOfIndustries || listItem.cardType : null;
    })
    .filter(Boolean);

  const [itemsChecked, setItemsChecked] = useState<(string | null | undefined)[]>([...selectedItems]);

  const handleCheckboxChange = (value: string | undefined) => {
    setItemsChecked(prev => {
      const updatedItemsChecked = prev.includes(value) ? prev.filter(item => item !== value) : [...prev, value];
      const newUpdates = findIdsByLabels(updatedItemsChecked, list);
      setUpdates({ ...updates, [fieldName]: newUpdates });
      return updatedItemsChecked;
    });
  };

  const findIdsByLabels = (itemsChecked: (string | null | undefined)[], list: List[]) => {
    return itemsChecked
      .map(item => {
        const listItem = list?.find(it => it.label === item || it.name === item || it.nameOfIndustries === item || it.cardType === item);
        return listItem ? listItem.id : null;
      })
      .filter(Boolean);
  };

  return (
    <Menu as='div' className='relative'>
      <label className='text-slate-500 font-semibold'>{label}</label>
      <Menu.Button className='border-1 border-gray-300 rounded-md gap-4 flex w-full p-2.5 items-center justify-between'>
        <label className='flex flex-wrap gap-2'>
          {itemsChecked.map(it => {
            return <span className='border-1 border-gray-300 bg-gray-100 text-start rounded-xl px-3 text-black line-clamp-1'>{it}</span>;
          })}
        </label>
        <div>
          <ChevronDownIcon className='text-black' aria-hidden='true' />
        </div>
      </Menu.Button>
      <Transition>
        <Menu.Items className='w-full max-h-80 p-2 overflow-y-auto absolute z-20 mt-2 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none'>
          <div className='p-1'>
            {list.map(it => {
              const value = it.label || it.name || it.cardType || it.nameOfIndustries;
              return (
                <Menu.Item key={value} as='div'>
                  <EditProfileCheckbox handleCheckboxChange={handleCheckboxChange} value={value} itemsChecked={itemsChecked} />
                </Menu.Item>
              );
            })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
