import React from 'react';

const StatusIcon = ({ size = 45, strokeWidth = 1, className, status = 0 }) => {
  const viewBox = size + strokeWidth;

  const radius = size / 2 - strokeWidth / 2;
  const center = viewBox / 2;

  let strokeColor,
    fillColor = '#ffffff';
  switch (status) {
    case 0:
      strokeColor = '#b4b4b4';
      break;
    case 1:
    case 4:
      strokeColor = '#ffce07';
      fillColor = '#ffce07';
      break;
    case 2:
      strokeColor = '#586f7c';
      fillColor = '#586f7c';
      break;
    case 3:
      strokeColor = '#ffa630';
      fillColor = '#ffa630';
      break;
    default:
  }

  const checkmarkWidth = 18.798;
  const checkmarkHeight = 15.765;

  const scheduledWidth = 32.118;
  const scheduledHeight = 32.115;

  const processingWidth = 29.359;
  const processingHeight = 32.371;

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} fill='none' viewBox={`0 0 ${viewBox} ${viewBox}`}>
      <circle
        className={`status-icon__circle ${className}`}
        cx={center}
        cy={center}
        strokeWidth={strokeWidth}
        r={radius}
        stroke={strokeColor}
        fill={fillColor}
      />
      {status === 1 && (
        <path
          id='Path_10203'
          name='Path 10203'
          d='M225.518,284.79a9.206,9.206,0,0,1-.487,1.605,23.952,23.952,0,0,1-1.739,3.033,9.075,9.075,0,0,1-6.177,3.79c-.3.048-.6.077-.894.1-.686.042-.844.315-.54.942a9,9,0,0,1,.3,7.739,19.976,19.976,0,0,1-2.029,3.55,3.649,3.649,0,0,1-4.92.884q-4.983-2.865-9.957-5.745c-.418-.242-.844-.473-1.244-.742a3.528,3.528,0,0,1-1.414-4.261,13.925,13.925,0,0,1,3.1-4.851,9.314,9.314,0,0,1,5.9-2.513c.643-.041.814-.333.525-.922a8.88,8.88,0,0,1-.405-7.5,19.449,19.449,0,0,1,2.179-3.861,3.645,3.645,0,0,1,4.938-.794q3.814,2.19,7.619,4.4c1.1.633,2.2,1.255,3.287,1.9A4.006,4.006,0,0,1,225.518,284.79Zm-26.209,12.144a3.993,3.993,0,0,0,.612.543Q204.957,300.4,210,303.3c1.208.7,1.215.7,1.9-.512a14.259,14.259,0,0,0,1.261-2.375,6.247,6.247,0,0,0-.453-4.944,3.663,3.663,0,0,1,2.628-5.272c.49-.089,1-.088,1.484-.19a5.936,5.936,0,0,0,3.783-2.328,28.415,28.415,0,0,0,1.462-2.5c.256-.452.2-.692-.249-.949q-5.41-3.126-10.825-6.242c-.466-.268-.672-.208-.946.253-.366.616-.7,1.254-1.079,1.859a5.735,5.735,0,0,0-.589,4.715,10.607,10.607,0,0,0,.628,1.572,3.722,3.722,0,0,1-3.239,5.128,5.93,5.93,0,0,0-5.188,3.124c-.328.6-.683,1.183-1.022,1.776C199.489,296.543,199.432,296.68,199.309,296.934Z'
          transform={`translate(${-196.159 + (center - processingWidth / 2)} ${-275.631 + (center - processingHeight / 2)})`}
          fill='#586f7c'
        />
      )}
      {status === 2 && (
        <path
          d='M16.796 1.5a.249.249 0 0 0 .039.023.614.614 0 0 1 .3 1q-.658.912-1.321 1.82L9.396 13.18a2.431 2.431 0 0 1-3.778.33q-1.952-1.906-3.89-3.826a1.944 1.944 0 0 1-.231-.356v-.247a1.648 1.648 0 0 1 .166-.291.609.609 0 0 1 .914 0q.806.786 1.606 1.576l2.275 2.236a1.209 1.209 0 0 0 1.945-.161l7.675-10.572a.917.917 0 0 1 .468-.378Z'
          fill='#ffffff'
          stroke='#ffffff'
          strokeWidth={3}
          transform={`translate(${center - checkmarkWidth / 2} ${center - checkmarkHeight / 2})`}
        />
      )}
      {status === 4 && (
        <g id='Group_6404' name='Group 6404' transform={`translate(${-1166.439 + (center - scheduledWidth / 2)} ${-661.07 + (center - scheduledHeight / 2)})`}>
          <g id='Group_6344' name='Group 6344' transform='translate(1065.939 469.729)'>
            <path
              id='Path_10205'
              name='Path 10205'
              d='M101,206.486c.079-.589.137-1.181.239-1.766a15.232,15.232,0,0,1,5.077-9.026,15,15,0,0,1,8.106-3.7,15.6,15.6,0,0,1,15.077,6.778c.043.064.092.123.187.248v-.364c0-.962,0-1.924,0-2.887a1.218,1.218,0,1,1,2.429,0c0,1.145.009,2.289,0,3.434a3.886,3.886,0,0,1-3.928,3.937c-1.2.013-2.39.006-3.585,0a1.217,1.217,0,1,1,0-2.428c1.063-.005,2.126,0,3.259,0-.181-.281-.324-.53-.492-.761a12.93,12.93,0,0,0-11.379-5.656,12.477,12.477,0,0,0-8.173,3.339,13.065,13.065,0,0,0-1.86,17.463,12.847,12.847,0,0,0,7.906,5.144,12.641,12.641,0,0,0,3.5.257,2.883,2.883,0,0,1,.753.027,1.186,1.186,0,0,1,.927,1.253,1.219,1.219,0,0,1-1.1,1.118l-.6.061h-1.7c-.411-.049-.822-.091-1.231-.148a15.569,15.569,0,0,1-13.177-12.733c-.1-.585-.161-1.177-.239-1.766Z'
              transform='translate(0 0)'
              fill='#586f7c'
              stoke='#586f7c'
              strokeWidth='1'
            />
            <path
              id='Path_10206'
              name='Path 10206'
              d='M233.513,258.581c0,1.539-.009,3.079.005,4.618a1.6,1.6,0,0,1-.5,1.219q-2.287,2.268-4.556,4.553a1.257,1.257,0,0,1-1.4.388,1.2,1.2,0,0,1-.546-1.854,2.97,2.97,0,0,1,.285-.315q2.018-2.021,4.042-4.036a.738.738,0,0,0,.245-.579q-.013-4.375-.005-8.75a1.247,1.247,0,0,1,.792-1.275,1.212,1.212,0,0,1,1.636,1.17c.012,1.185,0,2.37,0,3.555Q233.513,257.928,233.513,258.581Z'
              transform='translate(-115 -56.015)'
              fill='#586f7c'
              stroke='#586f7c'
              strokeWidth='1'
            />
            <path
              id='Path_10207'
              name='Path 10207'
              d='M368.921,547.939a1.269,1.269,0,0,1-1.175-.955,1.176,1.176,0,0,1,.574-1.32c.387-.217.784-.421,1.19-.6a1.207,1.207,0,0,1,1.1,2.15c-.386.22-.788.413-1.191.6A2.772,2.772,0,0,1,368.921,547.939Z'
              transform='translate(-246.407 -326.23)'
              fill='#586f7c'
              stroke='#586f7c'
              strokeWidth='1'
            />
            <path
              id='Path_10208'
              name='Path 10208'
              d='M470.45,422.719a13.943,13.943,0,0,1,.463-1.541,1.146,1.146,0,0,1,1.417-.578,1.158,1.158,0,0,1,.822,1.29,9.777,9.777,0,0,1-.385,1.494,1.171,1.171,0,0,1-1.406.715A1.266,1.266,0,0,1,470.45,422.719Z'
              transform='translate(-341.327 -211.29)'
              fill='#586f7c'
              stroke='#586f7c'
              strokeWidth='1'
            />
            <path
              id='Path_10209'
              name='Path 10209'
              d='M434.748,493.841a5.717,5.717,0,0,1-.369.771,10.339,10.339,0,0,1-.7.876,1.216,1.216,0,0,1-1.7.231,1.2,1.2,0,0,1-.2-1.726c.262-.346.537-.685.828-1.007a1.181,1.181,0,0,1,1.335-.3A1.4,1.4,0,0,1,434.748,493.841Z'
              transform='translate(-305.339 -277.863)'
              fill='#586f7c'
              stroke='#586f7c'
              strokeWidth='1'
            />
          </g>
        </g>
      )}
    </svg>
  );
};

export default StatusIcon;
