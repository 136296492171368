import { X } from 'lucide-react';
import { Modal as BootstrapModal } from 'react-bootstrap';

import './bannerModal.scss';

export default function BannerModal({ show, onHide, onAccept, onDecline, maxWidth, ...props }) {
  return (
    <BootstrapModal
      show={show}
      onHide={onHide}
      backdrop
      keyboard={false}
      dialogClassName='banner-modal__container'
      scrollable
      centered
      style={{ borderRadius: 15 }}
      {...props}
    >
      <BootstrapModal.Header>
        <h3>Thank you for your business!</h3>
        <button type='button' onClick={onHide}>
          <X size={24} />
        </button>
      </BootstrapModal.Header>
      <BootstrapModal.Body>
        <div style={{ padding: 20 }}>
          <p className='banner-modal__first-text'>
            Limited <span>Time March Offer</span>
          </p>
          <p className='banner-modal__percentage'>
            20%<span>OFF</span>
          </p>
          <p className='banner-modal__last-text'>Loss Control Audits*</p>
        </div>
      </BootstrapModal.Body>
      <BootstrapModal.Footer>
        <div className='banner-modal__buttons'>
          <button
            type='button'
            onClick={() => {
              onHide();
              onDecline();
            }}
            className='banner-modal__button-cancel'
          >
            No, Thanks
          </button>
          <button type='button' onClick={onAccept} className='banner-modal__button-next'>
            Get Started & Save
          </button>
        </div>
        <p>*Terms and Conditions Apply.</p>
      </BootstrapModal.Footer>
    </BootstrapModal>
  );
}
