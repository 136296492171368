import Stripe from '@stripe/stripe-js';
import { Info, Landmark } from 'lucide-react';
import { Tooltip } from 'primereact/tooltip';

import { Todo } from '../../types';
import classes from './ExternalAccount.module.scss';

function getFriendlyStatus(status: Stripe.BankAccount['status']): { friendlyName: string; explanation: string } {
  switch (status) {
    case 'new':
      return {
        friendlyName: 'New - Awaiting Validation',
        explanation: 'The bank account is newly added and has not had any activity or validation performed yet.',
      };
    case 'validated':
      return {
        friendlyName: 'Validated - Bank Account Exists',
        explanation: 'Stripe has determined that the bank account exists. However, full verification may not be performed.',
      };
    case 'verified':
      return {
        friendlyName: 'Verified - Bank Account Verified',
        explanation: 'The bank account has been successfully verified through customer verification methods.',
      };
    case 'verification_failed':
      return {
        friendlyName: 'Verification Failed - Update Required',
        explanation: 'Verification of the bank account failed. Please update the account details.',
      };
    case 'errored':
      return {
        friendlyName: 'Errored - Update Required',
        explanation: 'A payout to this bank account failed. Please update the account details to resume payouts.',
      };
    default:
      return {
        friendlyName: 'Unknown Status',
        explanation: 'The status of this bank account is unknown. Please contact support for more information.',
      };
  }
}

export default function ExternalAccountList({ externalAccounts }: { externalAccounts: Todo[] }) {
  return (
    <ul className={classes.account_list}>
      {externalAccounts.map((account, index) => {
        const status = getFriendlyStatus(account.status);
        return (
          <li key={index} className={classes.account}>
            <Landmark className={classes.account_icon} />
            <div>
              <p className={classes.account_text}>
                Bank Account ({account.bank_name}) ending in {account.last4}
              </p>
              <p className={classes.account_status}>
                {status.friendlyName}
                <Info className='status_info' data-pr-tooltip={status.explanation} data-pr-position='top' size={16} />
                <Tooltip target='.status_info' />
              </p>
            </div>
          </li>
        );
      })}
    </ul>
  );
}
