import React from 'react';
import { writeStorage } from '@rehooks/local-storage';
import { useFormik } from 'formik';
import { Info, Plus, X, XIcon } from 'lucide-react';
import { Col, Container, Row } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TextareaAutosize from 'react-textarea-autosize';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import * as Yup from 'yup';

import EditIcon from '../../assets/images/edit-icon.svg';
import ToolTipIcon from '../../assets/images/tooltip-icon.svg';
import Button from '../../components/Button';
import Dropdown from '../../components/Dropdown';
import HorizontalRule from '../../components/HorizontalRule';
import InputError from '../../components/InputError';
import PaymentMethodList from '../../components/PaymentMethodList';
import PaymentMethod from '../../components/PaymentMethodList/PaymentMethod';
import Radio from '../../components/Radio';
import Spacer from '../../components/Spacer';
import Text from '../../components/Text';
import TextInput from '../../components/TextInput';
import useStripeSetUpPaymentsForCompany from '../../hooks/useStripeSetUpPaymentsForCompany';
import { Events } from '../../lib/amplitude';
import { deliverableTypeOptions, stateOptions } from '../../lib/options';
import { getCompanyPaymentMethods, retrieveCo } from '../../slices/company.slice';
import { createCoJobDoc } from '../../slices/document.slice';
import { coRetrieveJobs, createDraftJob, publishDraftJob, updateDraftJob } from '../../slices/job.slice';
import CloseModalButton from './close-button';
import CoDocUploader from './CoDocUploader';
import ExitDialog from './exit-dialog';

import './oldCreateJob.scss';

export const StyledReactTooltip = styled(ReactTooltip)`
  background-color: white !important;
  color: black !important;
  box-shadow: 0px 2px 20px #586f7c;
  max-width: 300px;
  opacity: 1;
  &:after {
    border-top-color: #586f7c !important;
  }
`;

const CARD_BANK_FOOTNOTE =
  'YellowBird will match your job with a qualified EHS Professional once posted. Your selected payment method will be charged for the total amount upon job completion.';

const extraOption = { label: 'Enter My Own' };

const optionsForDrop = [
  { label: 'Phone Call', value: 'phone' },
  { label: 'Text Message', value: 'txt' },
  { label: 'Email', value: 'email' },
];

const optionsForDropForCommute = [
  { label: 'On-Site', value: 'ON_SITE' },
  { label: 'Virtual', value: 'VIRTUAL' },
  { label: 'Hybrid', value: 'HYBRID' },
];

const styles = {
  headerText: {
    color: '#586f7c',
    fontWeight: 700,
  },
  labelText: {
    fontWeight: 700,
    fontSize: '15px',
  },
  buttonStyle: {
    borderRadius: '6px',
    marginTop: '31.5px',
    marginBottom: '31.5px',
  },
  labelTextNoInput: {
    fontSize: '15px',
    fontWeight: 700,
    marginTop: '5px',
  },
  editButtonSpacing: {
    display: 'flex',
    justifyContent: 'end',
  },
  tooltip: {
    backgroundColor: '#fff !important',
    color: '#000 !important',
    boxShadow: '0px 2px 20px lightgray !important',
  },
  dropdownStyle: {
    fontSize: 'inherit',
    color: '#586F7C',
  },
  radioLabelText: {
    marginBottom: '15px',
    fontSize: '18px',
  },
  reviewTextInput: {
    marginTop: 0,
  },
};

const MAX_TIME_WINDOW_OPTIONS = 15;

export default function OldCreateJob({ copy = false, page, setPage, handlePrev, fromService, parentId, childId, setDisplayModal, prepaid, jobName }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.user);
  const company = useSelector(state => state.company.company.company);
  const paymentMethods = useSelector(state => state.company.paymentMethods);
  const { jobParents, jobChildren, jobToCopy } = useSelector(state => state.job);
  const coTemplates = useSelector(state => state.document.coTemplates);
  const [jobParent, setJobParent] = React.useState('Select');
  const [jobChild, setJobChild] = React.useState('Select');
  const [childOptions, setChildOptions] = React.useState([]);
  const [selectedJob, setSelectedJob] = React.useState(null);
  const [enterMyOwn, setEnterMyOwn] = React.useState(copy && jobToCopy.service === 'Enter My Own' ? true : false);

  const [docsToUse, setDocsToUse] = React.useState([]);
  const [showExitDialog, setShowExitDialog] = React.useState(false);
  const [gigStaffing, setGigStaffing] = React.useState(false);
  const disableJobSelection = !!prepaid;
  const [values, setValues] = React.useState({
    name: jobName || '',
    footnote:
      'We invoice 100% of the total upon Job completion and require Net 30 day terms, unless you have previously arranged other terms with YellowBird accounting.',
    pocContactMethod: copy ? jobToCopy.pocContactMethod : '',
    pocEmail: copy ? jobToCopy.pocEmail : user.email || '',
    pocFirstName: copy ? jobToCopy.pocFirstName : user.firstName || '',
    pocLastName: copy ? jobToCopy.pocLastName : user.lastName || '',
    pocJobTitle: copy ? jobToCopy.pocJobTitle : '',
    pocPhone: copy ? jobToCopy.pocPhone : '',
    requestProName: copy ? jobToCopy.requestProName : '',
    siteAddress: copy ? jobToCopy.siteAddress : '',
    siteCity: copy ? jobToCopy.siteCity : '',
    siteState: copy ? jobToCopy.siteState : '',
    siteZip: copy ? jobToCopy.siteZip : '',
    timeWindows: [
      { id: 1, start: '', startDateString: '' },
      { id: 2, start: '', startDateString: '' },
      { id: 3, start: '', startDateString: '' },
    ],
    deliverableRequired: copy && jobToCopy.deliverableRequired ? 'yes' : '',
    templateRequired: copy && jobToCopy.templateRequired ? 'yes' : '',
    coAdditionalContent: copy && jobToCopy.coAdditionalContent ? 'yes' : '',
    additionalRequirements: copy && jobToCopy.additionalRequirements ? 'yes' : '',
    languageRequired: copy ? jobToCopy.languageRequired : '',
    ehsExperience: copy ? jobToCopy.languageRequired : '',
    industryExperience: copy ? jobToCopy.languageRequired : '',
    requiredSkills: copy ? jobToCopy.requiredSkills : '',
    deliverableType: copy ? jobToCopy.deliverableType : '',
    typeOfWork: copy ? jobToCopy.typeOfWork : '',
    multipleSites: false,
    roleOrService: copy ? jobToCopy.jobType : '',
    service: copy && jobToCopy.service ? jobToCopy.service : '',
    typeOfService: copy && jobToCopy.typeOfService ? jobToCopy.typeOfService : '',
    customService: copy && jobToCopy.service === 'Enter My Own' ? jobToCopy.jobType : '',
    estimatedRate: copy ? jobToCopy.coHourlyRateCents / 100 : '',
    priceType: copy !== false ? jobToCopy.priceType : '',
    enteredRate: copy ? jobToCopy.coHourlyRateCents / 100 : '',
    jobType: copy ? jobToCopy.jobType : '',
    perDiemOffered: copy && jobToCopy.perDiem ? true : false,
    perDiem: copy ? jobToCopy.perDiem : '',
    totalHours: copy ? jobToCopy.totalHours : '',
    numberOfDays: copy ? jobToCopy.numberOfDays : null,
    schedulingNotes: copy ? jobToCopy.schedulingNotes : '',
    isOtherReqs: copy && jobToCopy.otherRequirements ? true : false,
    otherRequirements: copy ? jobToCopy.otherRequirements : '',
    isSupplierManagementProcess: copy && jobToCopy.supplierManagementDetails ? true : false,
    supplierManagementDetails: copy ? jobToCopy.supplierManagementDetails : '',
    reportRequired: copy && jobToCopy.reportingDetails ? true : false,
    reportingDetails: copy ? jobToCopy.reportingDetails : '',
    commuteType: '',
    commuteDetails: copy ? jobToCopy.commuteDetails : '',
    paymentMethodSelection: '',
    totalHoursPerWeek: '',
    numberOfDaysPerWeek: '',
  });
  const [rateError, setRateError] = React.useState('');
  const [formErrors, setFormErrors] = React.useState({});
  const [addScheduleNotes, setAddScheduleNotes] = React.useState(false);
  const [draftSid, setDraftSid] = React.useState(null);

  const { description: setUpPaymentsDescription, SetUpPaymentsForCompanyButton } = useStripeSetUpPaymentsForCompany({
    successUrl: `/job/edit-job/${draftSid}?setup-payment=success`,
    cancelUrl: `/job/edit-job/${draftSid}`,
  });

  const schema = React.useMemo(() => {
    switch (page) {
      case 1:
        return Yup.object().shape({
          name: Yup.string().required('Job Name is Required'),
          typeOfWork: Yup.string().required('Required'),
          service: Yup.string().required('Required'),
          typeOfService: childOptions && !enterMyOwn ? Yup.string().required('Required') : null,
          roleOrService: enterMyOwn ? Yup.string().required('Required') : null,
          enteredRate: enterMyOwn ? Yup.number().required('Required') : null,
        });
      case 2:
        return Yup.object().shape({
          commuteType: Yup.string().required('Required'),
          siteAddress: values.commuteType !== 'VIRTUAL' ? Yup.string().required('Required') : null,
          siteCity: values.commuteType !== 'VIRTUAL' ? Yup.string().required('Required') : null,
          siteState: values.commuteType !== 'VIRTUAL' ? Yup.string().required('Required') : null,
          siteZip: values.commuteType !== 'VIRTUAL' ? Yup.string().required('Required') : null,
          timeWindows: Yup.array()
            .of(
              Yup.object().shape({
                start: Yup.date().nullable().required('Start Date is required'),
                startDateString: Yup.string().required('Potential Start Date is required'),
              })
            )
            .min(1, 'At least one date is required')
            .max(MAX_TIME_WINDOW_OPTIONS, `No more than ${MAX_TIME_WINDOW_OPTIONS} dates can be added`),
          totalHours: selectedJob?.hours ? null : Yup.number().required('Required'),
        });
      case 3:
        return Yup.object().shape({
          additionalRequirements: Yup.string().required('Required'),
          deliverableRequired: Yup.string().required('Required'),
          deliverableType: values.deliverableRequired === 'yes' ? Yup.string().required('Required') : null,
          templateRequired: values.deliverableRequired === 'yes' ? Yup.string().required('Required') : null,
          coAdditionalContent: Yup.string().required('Required'),
        });
      case 4:
        return Yup.object().shape({
          pocFirstName: Yup.string().required('Required'),
          pocLastName: Yup.string().required('Required'),
          pocJobTitle: Yup.string().required('Required'),
          pocEmail: Yup.string().required('Required'),
          pocPhone: Yup.string().required('Required'),
          pocContactMethod: Yup.string().required('Required'),
        });
      default:
        return Yup.object().shape({
          paymentMethodSelection: Yup.string().required('Required'),
        });
    }
  }, [page, enterMyOwn, childOptions, selectedJob, values]);

  const handleNext = () => {
    if (page !== 5) {
      schema
        .validate(values, { abortEarly: false })
        .then(valid => {
          setFormErrors({});
          setPage(page + 1);
        })
        .catch(e => {
          let tempObject = {};
          e.inner.forEach(e => {
            tempObject[e.path] = e.message;
          });
          setFormErrors({ ...tempObject });
        });
    } else {
      setPage(page + 1);
    }
  };

  React.useEffect(() => {
    writeStorage('job-values', values);
  }, [values]);

  React.useEffect(() => {
    writeStorage('job-posted', false);

    (async () => {
      if (user.role === 'worker') {
        navigate('/dashboard');
      }
    })();
  }, [user.role, navigate]);

  React.useEffect(() => {
    const regex = /\D/;
    const match = regex.test(values.enteredRate);
    if (match && values.enteredRate !== '') {
      setRateError('Enter Only Numeric Values');
    } else {
      setRateError('');
    }
  }, [values.enteredRate]);

  React.useEffect(() => {
    const amplitude = require('../../lib/amplitude');
    if (page === 1) {
      amplitude.sendEvent(Events.CO_STARTED_NEW_JOB);
    } else if (page === 2) {
      amplitude.sendEvent(Events.CO_ADDED_JOB_SITES);
    } else if (page === 3) {
      amplitude.sendEvent(Events.CO_ADDED_JOB_TIMELINE);
    } else if (page === 5) {
      amplitude.sendEvent(Events.CO_ADDED_JOB_CONTACT);
    }
  }, [page]);

  React.useEffect(() => {
    if (!copy && !gigStaffing) {
      setValues(prevValues => ({
        ...prevValues,
        jobType: selectedJob?.label,
        typeOfWork: selectedJob?.description,
        estimatedRate: selectedJob?.price || '',
        priceType: selectedJob?.priceType || '',
        totalHours: selectedJob?.hours || '',
      }));
    } else if (!copy) {
      setValues(prevValues => ({
        ...prevValues,
        jobType: selectedJob?.label,
        typeOfWork: selectedJob?.description,
        estimatedRate: selectedJob?.price || '',
        priceType: selectedJob?.priceType || '',
      }));
    }
  }, [selectedJob, copy, gigStaffing]);

  React.useEffect(() => {
    dispatch(retrieveCo({ companyId: user.companyId }));
    dispatch(getCompanyPaymentMethods());
  }, [dispatch, user.companyId]);

  React.useEffect(() => {
    if (copy && jobToCopy.service && jobToCopy.typeOfService) {
      const parent = jobParents.find(item => item.label === jobToCopy.service);
      setJobParent(parent);
      if (parent?.hasChildren) {
        const options = jobChildren.filter(item => item.parentJobId === parent.id);
        setChildOptions(options);
        setValues(prevValues => ({ ...prevValues, typeOfWork: jobToCopy.typeOfWork }));
      }
    }
  }, [copy, jobToCopy, jobParents, jobChildren]);

  const handleCustom = change => {
    setSelectedJob(null);
    setEnterMyOwn(change);
    setValues({
      ...values,
      roleOrService: '',
      jobType: '',
      typeOfWork: '',
      estimatedRate: '',
      priceType: '',
      service: '',
      typeOfService: '',
    });
    if (change) {
      setValues({ ...values, roleOrService: '', typeOfWork: '', service: 'Enter My Own' });
      setChildOptions([]);
    }
  };

  const handleCustomJobText = e => {
    setValues({ ...values, roleOrService: e.target.value, jobType: e.target.value });
  };
  const handleCustomCommuteText = e => {
    setValues({ ...values, commuteDetails: e.target.value });
  };

  const handleDrop = e => {
    setValues({ ...values, pocContactMethod: e.target.value });
  };
  const handleDropForCommute = e => {
    setValues({ ...values, commuteType: e.target.value });
  };

  const handleDropForDeliverableType = e => {
    setValues({ ...values, deliverableType: e.target.value });
  };

  const handleRoleSelect = e => {
    const typeOfService = e.target.value;
    setValues({ ...values, typeOfService });
    const job = jobChildren.find(item => item.label === typeOfService);
    setJobChild(job);
    setSelectedJob(job);
  };

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    if (name === 'siteCity') {
      setValues({ ...values, siteCity: value.charAt(0).toUpperCase() + value.substr(1) });
    } else {
      setValues({ ...values, [name]: value });
    }
  };

  React.useEffect(() => {
    if (gigStaffing) {
      const totalHours = values.totalHoursPerWeek * 2;
      setValues({
        ...values,
        numberOfDaysPerWeek: '5',
        totalHoursPerWeek: '40',
        totalHours: totalHours || '80', // Fallback if totalHoursPerWeek is undefined
      });
    }
  }, [gigStaffing, values.totalHoursPerWeek]);
  

  React.useEffect(() => {
    if (fromService && parentId) {
      const parent = jobParents.find(item => item.id === parentId);
      const service = jobParents.find(item => item.id === parentId)?.label;
      setJobParent(parent);
      setValues(prevValues => ({ ...prevValues, service }));
      if (parent?.hasChildren) {
        if (parent.id === 10) {
          const forkliftOption = jobChildren.filter(item => item.id === 24);
          setChildOptions(forkliftOption);
        } else {
          const options = jobChildren.filter(item => item.parentJobId === parent.id);
          setChildOptions(options);
        }
        if (childId) {
          const child = jobChildren.find(item => item.id === childId);
          setJobChild(child);
          setSelectedJob(child);
          setValues(prevValues => ({ ...prevValues, service, typeOfService: child.label }));
        }
      }
    }
  }, [parentId, childId, fromService, jobParents, jobChildren]);

  const handleParent = e => {
    const service = e.target.value;
    if (service === 'Safety Manager') {
      setGigStaffing(true);
    } else {
      setGigStaffing(false);
    }
    if (service === 'Enter My Own') {
      handleCustom(true);
    } else {
      handleCustom(false);
      const parent = jobParents.find(item => item.label === service);
      setValues({ ...values, estimatedRate: '', service, typeOfWork: '', typeOfService: '' });
      setJobParent(parent);
      if (parent.hasChildren) {
        const options = jobChildren.filter(item => item.parentJobId === parent.id);
        setChildOptions(options);
        setSelectedJob(null);
      } else {
        setSelectedJob(parent);
        setChildOptions();
      }
    }
  };

  // Function to handle adding new date inputs (time windows)
  const addDateInput = () => {
    if (values.timeWindows.length < MAX_TIME_WINDOW_OPTIONS) {
      setValues({
        ...values,
        timeWindows: [...values.timeWindows, { id: new Date().getTime(), start: '', startDateString: '' }],
      });
    } else {
      toast.error(`You can add up to ${MAX_TIME_WINDOW_OPTIONS} dates only.`);
    }
  };

  // Function to handle time window input changes
  const handleTimeWindowChange = (id, value) => {
    const dateExists = values.timeWindows.some(date => date.startDateString === value);

    if (dateExists) {
      toast.error('This date is already added.');
      return;
    }

    const newTimeWindows = values.timeWindows.map(date => (date.id === id ? { ...date, startDateString: value, start: new Date(value) } : date));
    setValues({ ...values, timeWindows: newTimeWindows });
  };

  // Function to remove a time window input
  const removeDateInput = id => {
    if (values.timeWindows.length > 1) {
      const newTimeWindows = values.timeWindows.filter(date => date.id !== id);
      setValues({ ...values, timeWindows: newTimeWindows });
    }
  };

  function handleOnlyNumber(e) {
    // allow only numbers
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  }

  const autoSaveDraft = async () => {
    await dispatch(updateDraftJob({ sid: draftSid, job: values, companyId: user.companyId })).unwrap();
  };

  return page === 1 ? (
    <Container fluid>
      <div style={{ display: 'flex', position: 'absolute', right: '24px', top: '0', gap: '12px', alignItems: 'center' }}>
        <XIcon
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setDisplayModal(false);
          }}
        />
      </div>
      <Row>
        <TextInput
          value={values.name}
          name='name'
          label='Job Name'
          placeholder={copy ? 'Please Update the Job Name' : 'Enter Your Job Name'}
          onChange={handleChange}
          overrideContainerStyle={{ marginTop: '1em' }}
          disabled={jobName}
        />
        {formErrors?.name ? <InputError>{formErrors.name}</InputError> : null}
        <div>
          <Dropdown
            value={values.service}
            onChange={handleParent}
            extraOption={extraOption}
            label='Select a Service'
            options={jobParents}
            disabled={disableJobSelection}
            oA
            style={{ ...styles.dropdownStyle, color: '#586F7C', cursor: 'pointer' }}
            overrideContainerStyle={{ marginTop: '1em' }}
          />
          {formErrors?.service ? <InputError>{formErrors.service}</InputError> : null}
          {values.service === 'Safety Subscription' ? (
            <div style={{ marginTop: 8 }}>
              For a full list of included services visit:
              <a style={{ marginLeft: 4 }} href='https://www.goyellowbird.com/subscription-services' target='_blank' rel='noreferrer noopener'>
                goyellowbird.com/subscription-services
              </a>
            </div>
          ) : null}
          {childOptions && childOptions.length > 0 && (
            <>
              <Dropdown
                value={values.typeOfService}
                onChange={handleRoleSelect}
                label='Select Type of Service'
                options={childOptions}
                oA
                disabled={disableJobSelection}
                style={{ ...styles.dropdownStyle, color: '#586F7C', cursor: 'pointer' }}
              />
              {formErrors?.typeOfService ? <InputError>{formErrors.typeOfService}</InputError> : null}
            </>
          )}
        </div>
        {enterMyOwn ? (
          <div>
            <TextInput
              name='roleOrService'
              onChange={handleCustomJobText}
              value={values.roleOrService}
              label='Enter the Service That You Need'
              style={styles.dropdownStyle}
              overrideContainerStyle={{ marginTop: '1em' }}
            />
            {formErrors?.roleOrService ? <InputError>{formErrors.roleOrService}</InputError> : null}
          </div>
        ) : null}
        <TextInput
          value={values.requestProName}
          name='requestProName'
          label='Do you have a Specific Pro in Mind?'
          placeholder='Enter Your EHS Pro Request'
          onChange={handleChange}
          overrideContainerStyle={{ marginTop: '1em' }}
        />
        {/* <Row> */}
        <div className='text-input-container'>
          <label className='text-input__label'>{enterMyOwn ? 'Type of Work to be Done:' : 'Job Details:'}</label>
          <TextareaAutosize
            value={values.typeOfWork}
            className='text-input__input'
            name='typeOfWork'
            label='Type Of Work to be Done'
            onChange={handleChange}
            readOnly={!enterMyOwn}
            placeholder='Select a Service from the dropdown above or enter your own details'
            style={{
              borderBottomColor: '#b4b4b4',
              borderTop: 'none',
              borderLeft: 'none',
              resize: 'none',
              borderRight: 'none',
              fontSize: 'inherit',
              textDecoration: 'none',
            }}
          />
          {formErrors?.typeOfWork ? <InputError>{formErrors.typeOfWork}</InputError> : null}
        </div>
        {!enterMyOwn && (
          <TextInput
            value={values.notes}
            name='notes'
            label='Share additional job details'
            placeholder='Add your notes'
            onChange={handleChange}
            overrideContainerStyle={{ marginTop: '1em' }}
          />
        )}
        <Row>
          {enterMyOwn ? (
            <>
              <Col xs={11}>
                <TextInput
                  value={`${values.enteredRate}`}
                  name='enteredRate'
                  label={`Manual ${selectedJob?.priceType ? selectedJob.priceType : 'Hourly'} Rate`}
                  placeholder='Enter Desired Rate'
                  onChange={handleChange}
                ></TextInput>
                {formErrors?.enteredRate ? <InputError>Required Must Be A Number</InputError> : null}
              </Col>
              <Col xs={1} className='d-flex align-items-center'>
                <img src={ToolTipIcon} alt='tooltip' data-tip data-for='manualRate' />
                <StyledReactTooltip id='manualRate' effect='solid' data-multiline data-place='right'>
                  <span>
                    If you entered a Job not listed in the Service drop-down, or you know your exact budget, you can manually enter a rate in the "Enter Hourly
                    Rate" field below. Completing the Job Create process with the pre-filled range or manually entering a rate will provide YellowBird with an
                    idea of your budget. You will not be invoiced and Job will not commence until you approve the Job Order.
                  </span>
                </StyledReactTooltip>
              </Col>
            </>
          ) : (
            <>
              <Col xs={11}>
                <TextInput
                  style={values.enteredRate !== '' ? { color: '#b4b4b4' } : null}
                  disabled={true}
                  value={`${values.estimatedRate}`}
                  label={`${selectedJob?.priceType ? selectedJob.priceType : 'Hourly'} Rate`}
                  onChange={handleChange}
                  overrideContainerStyle={{ marginTop: '1em' }}
                ></TextInput>
              </Col>
              <Col xs={1} className='d-flex align-items-center'>
                <img src={ToolTipIcon} alt='tooltip' data-tip data-for='estimatedRate' />
                <StyledReactTooltip id='estimatedRate' effect='solid' data-place='top'>
                  <span>Selecting an existing Job from the drop-down will add the rate below.</span>
                </StyledReactTooltip>
              </Col>
            </>
          )}
        </Row>
        <Spacer h={24} />
        <Row className='d-flex justify-content-center'>
          <Col sm={6}>
            <Row>
              <Button
                className='btn primary-btn d-flex justify-content-center py-3'
                style={styles.buttonStyle}
                onClick={async () => {
                  if (page !== 5) {
                    schema
                      .validate(values, { abortEarly: false })
                      .then(async valid => {
                        const res = await dispatch(createDraftJob({ job: values, companyId: user.companyId })).unwrap();
                        setDraftSid(res.sid);
                        setFormErrors({});
                        setPage(page + 1);
                      })
                      .catch(e => {
                        let tempObject = {};
                        e.inner.forEach(e => {
                          tempObject[e.path] = e.message;
                        });
                        setFormErrors({ ...tempObject });
                      });
                  } else {
                    setPage(page + 1);
                  }
                }}
              >
                Next
              </Button>
            </Row>
          </Col>
        </Row>
      </Row>
    </Container>
  ) : page === 2 ? (
    <Container fluid>
      <ExitDialog
        setPage={setPage}
        sid={draftSid}
        setDisplayModal={setDisplayModal}
        setIsExitDialogOpen={setShowExitDialog}
        isExitDialogOpen={showExitDialog}
      />
      <CloseModalButton setPage={setPage} setIsExitDialogOpen={setShowExitDialog} setDisplayModal={setDisplayModal} />
      <Row className='mt-4'>
        <div>
          <Dropdown
            onBlur={autoSaveDraft}
            value={values.commuteType}
            onChange={handleDropForCommute}
            label='Where will the work be performed?'
            options={optionsForDropForCommute}
            oA
            style={styles.dropdownStyle}
            overrideContainerStyle={{ marginTop: '1em' }}
          />
          {formErrors?.commuteType ? <InputError>{formErrors.commuteType}</InputError> : null}
        </div>
        {values.commuteType === 'VIRTUAL' || values.commuteType === 'HYBRID' ? (
          <div>
            <TextInput
              onBlur={autoSaveDraft}
              name='commuteDetails'
              onChange={handleCustomCommuteText}
              value={values.commuteDetails}
              label='Virtual Job Details'
              placeholder='Enter any details around the virtual work or deliverables here.'
              overrideContainerStyle={{ marginTop: '1em' }}
            />
          </div>
        ) : null}
        {values.commuteType === 'HYBRID' || values.commuteType === 'ON_SITE' ? (
          <div>
            <Row>
              <TextInput
                onBlur={autoSaveDraft}
                value={values.siteAddress}
                name='siteAddress'
                label='Street Address'
                placeholder='Enter Your Street Address'
                onChange={handleChange}
                overrideContainerStyle={{ marginTop: '1em' }}
              />
              {formErrors?.siteAddress ? <InputError>{formErrors.siteAddress}</InputError> : null}
            </Row>
            <Row className='mt-2'>
              <Col md={7}>
                <TextInput
                  onBlur={autoSaveDraft}
                  value={values.siteCity}
                  name='siteCity'
                  label='City'
                  placeholder='Enter Your City'
                  onChange={handleChange}
                  overrideContainerStyle={{ marginTop: '1em' }}
                />
                {formErrors?.siteCity ? <InputError>{formErrors.siteCity}</InputError> : null}
              </Col>
              <Col md={3}>
                <Dropdown
                  onBlur={autoSaveDraft}
                  onChange={e => {
                    setValues({ ...values, siteState: e.target.value });
                  }}
                  value={values.siteState}
                  label='State'
                  options={stateOptions}
                  oA
                  style={styles.dropdownStyle}
                  overrideContainerStyle={{ marginTop: '1.4em' }}
                />
                {formErrors?.siteState ? <InputError>{formErrors.siteState}</InputError> : null}
              </Col>
              <Col md={2}>
                <TextInput
                  onBlur={autoSaveDraft}
                  value={values.siteZip}
                  name='siteZip'
                  label='Zip'
                  placeholder='Enter Zip'
                  onChange={handleChange}
                  overrideContainerStyle={{ marginTop: '1em' }}
                />
                {formErrors?.siteZip ? <InputError>{formErrors.siteZip}</InputError> : null}
              </Col>
            </Row>
          </div>
        ) : null}
      </Row>
      <Row>
        <Col md={6} style={{ marginTop: '26px' }}>
          <p
            data-tip
            data-for='flexibleStartDates'
            style={{
              background: '#87ddff',
              borderRadius: 50,
              padding: '3px 10px',
              display: 'flex',
              alignItems: 'center',
              gap: 5,
              width: 'fit-content',
              margin: 0,
            }}
          >
            <span style={{ fontWeight: 600, fontStyle: 'italic' }}>NEW</span> Flexible Start Dates Option <Info size={18} style={{ marginLeft: 5 }} />
          </p>
          <StyledReactTooltip id='flexibleStartDates' effect='solid' data-multiline place='right'>
            <span>
              If your job allows for flexible start dates, provide multiple potential dates for the job and verify the expected number of hours this project
              requires.
            </span>
          </StyledReactTooltip>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <div>
            {values.timeWindows.length > 0 && (
              <>
                <label className='text-input__label' style={{ margin: '16px 0 5px' }}>
                  Potential Start Dates
                </label>
                {values.timeWindows.map((date, index) => (
                  <div key={date.id} style={{ marginBottom: '0' }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <TextInput
                        onBlur={autoSaveDraft}
                        className='date-picker'
                        style={{ color: '#586F7C', marginRight: '1em' }}
                        value={date.startDateString}
                        type='date'
                        min={new Date().toISOString().slice(0, 10)}
                        onChange={e => handleTimeWindowChange(date.id, e.target.value)}
                        overrideContainerStyle={{ marginTop: '1em', width: '100%' }}
                      />
                      {values.timeWindows.length > 1 && (
                        <Button type='button' className='btn transparent-btn' onClick={() => removeDateInput(date.id)} style={{ marginTop: '0.1em' }}>
                          <X size={18} />
                        </Button>
                      )}
                    </div>
                    {formErrors && formErrors[`timeWindows[${index}].startDateString`] ? (
                      <InputError>{formErrors[`timeWindows[${index}].startDateString`]}</InputError>
                    ) : null}
                  </div>
                ))}

                <Button
                  onBlur={autoSaveDraft}
                  type='button'
                  className='btn primary-btn mt-4'
                  style={{ fontWeight: 600, display: 'flex', alignItems: 'center' }}
                  onClick={addDateInput}
                  disabled={values.timeWindows.length >= MAX_TIME_WINDOW_OPTIONS}
                >
                  <Plus size={18} style={{ marginRight: '8px' }} />
                  Add Date
                </Button>
              </>
            )}
          </div>
        </Col>
        <Col md={6}>
          <TextInput
            onBlur={autoSaveDraft}
            value={values.totalHours}
            name='totalHours'
            label='Expected Number of Hours'
            placeholder='Enter Hours'
            onChange={handleChange}
            overrideContainerStyle={{ marginTop: '1em' }}
            onKeyPress={handleOnlyNumber}
          />
          {formErrors?.totalHours ? <InputError>Whole Number Required</InputError> : null}
          {!addScheduleNotes && (
            <Button onClick={() => setAddScheduleNotes(true)} className='btn transparent-btn d-flex mt-4 items-center gap-2 p-0'>
              <Plus size={18} /> Add Scheduling Notes
            </Button>
          )}
          <br />
          {addScheduleNotes && (
            <>
              <label htmlFor='schedulingNotes' className='text-input__label'>
                Scheduling Notes
              </label>
              <TextareaAutosize
                onBlur={autoSaveDraft}
                id='schedulingNotes'
                name='schedulingNotes'
                autoFocus
                onChange={handleChange}
                value={values.schedulingNotes}
                className='form-control'
                style={{ minHeight: '130px' }}
              />
            </>
          )}
        </Col>
      </Row>
      <Spacer h={30} />
      <Row style={{ padding: '0 14px' }}>
        <ul className='list-disc'>
          <li>
            <Text>
              Jobs over 2 weeks in length will be billed every 2 weeks, starting with approval of the Job Order. Any variance in hours worked or other
              additional expenses will be reconciled after each pay period.
            </Text>
          </li>
          <li>
            <Text>Premium Pay will be paid out at 1.5x the hourly rate for any worked hours exceeding 8 in a day and 40 in a week.</Text>
          </li>
          <li>
            <Text>
              Per diem will be based on the GSA set rates by state. A 5% management fee will be added.
              <a href='https://www.gsa.gov/travel/plan-book/per-diem-rates?gsaredirect=perdiem' target='_blank' rel='noreferrer noopener'>
                <Text>Per Diem Rates</Text>
              </a>
            </Text>
          </li>
        </ul>
      </Row>
      <Row className='d-flex justify-content-center'>
        <Col sm={6}>
          <Row>
            <Button className='btn primary-btn d-flex justify-content-center py-3' style={styles.buttonStyle} onClick={handleNext}>
              Next
            </Button>
          </Row>
        </Col>
      </Row>
    </Container>
  ) : page === 3 ? (
    <Container fluid>
      <ExitDialog
        setPage={setPage}
        sid={draftSid}
        setDisplayModal={setDisplayModal}
        setIsExitDialogOpen={setShowExitDialog}
        isExitDialogOpen={showExitDialog}
      />
      <CloseModalButton setPage={setPage} setIsExitDialogOpen={setShowExitDialog} setDisplayModal={setDisplayModal} />
      <Row className='text-input-container'>
        <div className='text-input__label' style={{ ...styles.labelText }}>
          Are there additional requirements for this Job?
        </div>
        <div className='d-flex flex-row'>
          <div className='me-3'>
            <label htmlFor='additionalRequirementsYes' style={styles.radioLabelText}>
              Yes
            </label>
            <input
              onBlur={autoSaveDraft}
              id='additionalRequirementsYes'
              type='radio'
              name='additionalRequirements'
              value='yes'
              onChange={handleChange}
              checked={values.additionalRequirements === 'yes'}
            />
            {formErrors?.additionalRequirements ? <InputError>{formErrors.additionalRequirements}</InputError> : null}
          </div>
          <div>
            <label htmlFor='additionalRequirementsNo' style={styles.radioLabelText}>
              No
            </label>
            <input
              onBlur={autoSaveDraft}
              id='additionalRequirementsNo'
              type='radio'
              name='additionalRequirements'
              value='no'
              onChange={handleChange}
              checked={values.additionalRequirements === 'no'}
            />
          </div>
        </div>
      </Row>
      <hr className='mb-0 mt-0' />
      {values.additionalRequirements === 'yes' ? (
        <>
          <Row>
            <Col>
              <TextInput
                onBlur={autoSaveDraft}
                name='requiredSkills'
                label='Specific Certifications or Skills'
                onChange={handleChange}
                value={values.requiredSkills}
                placeholder='Certifications or Skills'
                style={{ marginTop: '0 !important' }}
                overrideContainerStyle={{ marginTop: '1em' }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextInput
                onBlur={autoSaveDraft}
                name='languageRequired'
                label='Language required'
                onChange={handleChange}
                value={values.languageRequired}
                placeholder='Ex. Spanish, French, Mandarin, etc.'
                style={{ marginTop: '0 !important' }}
                overrideContainerStyle={{ marginTop: '1em' }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextInput
                onBlur={autoSaveDraft}
                name='ehsExperience'
                label='Years of Experience'
                onChange={handleChange}
                value={values.ehsExperience}
                placeholder='Enter a Number'
                style={{ marginTop: '0 !important' }}
                overrideContainerStyle={{ marginTop: '1em' }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextInput
                onBlur={autoSaveDraft}
                name='industryExperience'
                label='Industry Experience'
                onChange={handleChange}
                value={values.industryExperience}
                placeholder='Ex. Manufacturing, Construction, Healthcare, etc.'
                style={{ marginTop: '0 !important' }}
                overrideContainerStyle={{ marginTop: '1em' }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextInput
                onBlur={autoSaveDraft}
                name='otherRequirements'
                label='Other requirements or additional information the Pro needs to be aware of?'
                onChange={handleChange}
                value={values.otherRequirements}
                placeholder='Ex. Equipment, NDA, etc.'
                style={{ marginTop: '0 !important' }}
                overrideContainerStyle={{ marginTop: '1em' }}
              />
            </Col>
          </Row>
        </>
      ) : null}
      <Row className='text-input-container'>
        <div className='text-input__label' style={{ ...styles.labelText }}>
          Deliverable Required
        </div>
        <div className='d-flex flex-row'>
          <div className='me-3'>
            <label htmlFor='deliverableYes' style={styles.radioLabelText}>
              Yes
            </label>
            <input
              onBlur={autoSaveDraft}
              id='deliverableYes'
              type='radio'
              name='deliverableRequired'
              value='yes'
              onChange={handleChange}
              checked={values.deliverableRequired === 'yes'}
            />
            {formErrors?.deliverableRequired ? <InputError>{formErrors.deliverableRequired}</InputError> : null}
          </div>
          <div>
            <label htmlFor='deliverableNo' style={styles.radioLabelText}>
              No
            </label>
            <input
              onBlur={autoSaveDraft}
              id='deliverableNo'
              type='radio'
              name='deliverableRequired'
              value='no'
              onChange={handleChange}
              checked={values.deliverableRequired === 'no'}
            />
          </div>
        </div>
      </Row>
      <hr className='mb-0 mt-0' />
      {values.deliverableRequired === 'yes' ? (
        <>
          <Row>
            <Col>
              <Dropdown
                onBlur={autoSaveDraft}
                oA
                options={deliverableTypeOptions}
                name='deliverableTypeOptions'
                label='Deliverable Type'
                value={values.deliverableType}
                onChange={handleDropForDeliverableType}
                style={{ ...styles.dropdownStyle, color: '#586F7C', cursor: 'pointer' }}
                overrideContainerStyle={{ marginTop: '1em' }}
              />
              {formErrors?.deliverableType ? <InputError>{formErrors.deliverableType}</InputError> : null}
            </Col>
          </Row>
          <Row className='text-input-container'>
            <div className='text-input__label' style={styles.labelText}>
              Will you be supplying a template?
            </div>
            <div className='d-flex flex-row'>
              <div className='me-3'>
                <label htmlFor='templateYes' style={styles.radioLabelText}>
                  Yes
                </label>
                <input
                  onBlur={autoSaveDraft}
                  id='templateYes'
                  type='radio'
                  name='templateRequired'
                  value='yes'
                  checked={values.templateRequired === 'yes'}
                  onChange={handleChange}
                />
                {formErrors?.templateRequired ? <InputError>{formErrors.templateRequired}</InputError> : null}
              </div>
              <div>
                <label htmlFor='templateNo' style={styles.radioLabelText}>
                  No
                </label>
                <input id='templateNo' type='radio' name='templateRequired' value='no' checked={values.templateRequired === 'no'} onChange={handleChange} />
              </div>
            </div>
          </Row>
          <hr className='mt-1' />
        </>
      ) : null}
      <Row className='text-input-container'>
        <div className='text-input__label' style={{ ...styles.labelText, whiteSpace: 'break-spaces' }}>
          Do you have other content or documentation that the Professional will be required to use to deliver the service?
        </div>
        <div className='d-flex flex-row'>
          <div className='me-3'>
            <label htmlFor='coAdditionalContentYes' style={styles.radioLabelText}>
              Yes
            </label>
            <input
              onBlur={autoSaveDraft}
              id='coAdditionalContentYes'
              type='radio'
              name='coAdditionalContent'
              value='yes'
              checked={values.coAdditionalContent === 'yes'}
              onChange={handleChange}
            />
            {formErrors?.coAdditionalContent ? <InputError>{formErrors.coAdditionalContent}</InputError> : null}
          </div>
          <div>
            <label htmlFor='coAdditionalContentNo' style={styles.radioLabelText}>
              No
            </label>
            <input
              onBlur={autoSaveDraft}
              id='coAdditionalContentNo'
              type='radio'
              value='no'
              name='coAdditionalContent'
              checked={values.coAdditionalContent === 'no'}
              onChange={handleChange}
            />
          </div>
        </div>
      </Row>
      <hr className='mt-0' />
      {/* <Row>
            <Col className='mt-3 mb-3'>
              <Checkbox
                className='mb-4'
                defaultChecked={values.perDiemOffered}
                onChange={() => setValues({ ...values, perDiemOffered: !values.perDiemOffered, perDiem: '' })}
                value={values.perDiemOffered}
              >
                Per Diem Offered?
              </Checkbox>
            </Col>
          </Row>
          {values.perDiemOffered && <TextInput name='perDiem' onChange={handleChange} value={values.perDiem} placeholder='Enter Per Diem Details' />}
          <Row>
            <Col className='mt-3 mb-3'>
              <Checkbox defaultChecked={values.reportRequired} onChange={() => setValues({ ...values, reportRequired: !values.reportRequired })}>
                Report Required?
              </Checkbox>
            </Col>
          </Row>
          {values.reportRequired && (
            <TextInput name='reportingDetails' onChange={handleChange} value={values.reportingDetails} placeholder='Provide Reporting Details Here' />
          )}
          <Row>
            <Col className='mt-3 mb-3'>
              <Checkbox defaultChecked={values.isOtherReqs} onChange={() => setValues({ ...values, isOtherReqs: !values.isOtherReqs })}>
                Other Specific Requirements?
              </Checkbox>
            </Col>
          </Row>
          <Row>
            <Col className='mt-3 mb-3'>
              <Checkbox
                defaultChecked={values.isSupplierManagementProcess}
                onChange={() => setValues({ ...values, isSupplierManagementProcess: !values.isSupplierManagementProcess })}
              >
                Supplier Management Process?
              </Checkbox>
            </Col>
          </Row>
          {values.isSupplierManagementProcess && (
            <TextInput
              name='supplierManagementDetails'
              onChange={handleChange}
              value={values.supplierManagementDetails}
              placeholder='Enter Supplier Management Details Here if Applicable'
            />
          )} */}
      {/* <div className='uploader-container'>
            <p className='mb-2' style={{ fontWeight: 600, fontSize: '12px' }}>
              Upload Finished Report or Deliverable
            </p>
          </div> */}
      {/* <Button
            style={{ ...styles.headerText, border: 'none', backgroundColor: 'transparent' }}
            onClick={() => setDisplayModal(true)}
            className='pointer-cursor d-flex flex-row justify-content-between'
          >
            <label className='mb-1' for='uploader'>
              Upload Report or Deliverable for Professional
            </label>
            <img src={UploadDocsIcon} alt='doc uploader' />
          </Button> */}
      {/* <Modal size='lg' backdrop show={displayModal} scrollable onHide={() => setDisplayModal(!displayModal)}> */}
      <Row className='ps-0' style={{ marginTop: '1em' }}>
        <CoDocUploader
          style={{ fontSize: '12px', fontWeight: 700 }}
          coTemplates={coTemplates}
          docsToUse={docsToUse}
          setDocsToUse={setDocsToUse}
          onChange={handleChange}
        />
      </Row>
      {/* </Modal> */}
      <Row className='d-flex justify-content-center'>
        <Col sm={6}>
          <Row>
            <Button className='btn primary-btn d-flex justify-content-center py-3' style={styles.buttonStyle} onClick={handleNext}>
              Next
            </Button>
          </Row>
        </Col>
      </Row>
    </Container>
  ) : page === 4 ? (
    <Container fluid>
      <ExitDialog
        setPage={setPage}
        sid={draftSid}
        setDisplayModal={setDisplayModal}
        setIsExitDialogOpen={setShowExitDialog}
        isExitDialogOpen={showExitDialog}
      />
      <CloseModalButton setPage={setPage} setIsExitDialogOpen={setShowExitDialog} setDisplayModal={setDisplayModal} />
      <Row>
        <Col md={5}>
          <TextInput
            onBlur={autoSaveDraft}
            label='First Name'
            value={values.pocFirstName}
            name='pocFirstName'
            placeholder='Enter Your First Name'
            onChange={handleChange}
            overrideContainerStyle={{ marginTop: '1em' }}
          />
          {formErrors?.pocFirstName ? <InputError>{formErrors.pocFirstName}</InputError> : null}
        </Col>
        <Col md={2}></Col>
        <Col md={5}>
          <TextInput
            onBlur={autoSaveDraft}
            label='Last Name'
            value={values.pocLastName}
            name='pocLastName'
            placeholder='Enter Your Last Name'
            onChange={handleChange}
            overrideContainerStyle={{ marginTop: '1em' }}
          />
          {formErrors?.pocLastName ? <InputError>{formErrors.pocLastName}</InputError> : null}
        </Col>
        <TextInput
          onBlur={autoSaveDraft}
          label='Job Title'
          value={values.pocJobTitle}
          name='pocJobTitle'
          placeholder='Enter Your Job Title'
          onChange={handleChange}
          overrideContainerStyle={{ marginTop: '1em' }}
        />
        {formErrors?.pocJobTitle ? <InputError>{formErrors.pocJobTitle}</InputError> : null}
        <>
          <Col md={5}>
            <TextInput
              onBlur={autoSaveDraft}
              label='Email'
              value={values.pocEmail}
              name='pocEmail'
              placeholder='Enter Your Email Address'
              onChange={handleChange}
              overrideContainerStyle={{ marginTop: '1em' }}
            />
            {formErrors?.pocEmail ? <InputError>{formErrors.pocEmail}</InputError> : null}
          </Col>
          <Col md={2}></Col>
          <Col md={5}>
            <TextInput
              onBlur={autoSaveDraft}
              label='Mobile Phone Number'
              value={values.pocPhone}
              name='pocPhone'
              placeholder='Enter Your Phone Number'
              onChange={handleChange}
              overrideContainerStyle={{ marginTop: '1em' }}
            />
            {formErrors?.pocPhone ? <InputError>{formErrors.pocPhone}</InputError> : null}
          </Col>
        </>
        <Dropdown
          onBlur={autoSaveDraft}
          value={values.pocContactMethod}
          onChange={handleDrop}
          label='Preferred Contact Method'
          options={optionsForDrop}
          oA
          style={styles.dropdownStyle}
          overrideContainerStyle={{ marginTop: '1em' }}
        />
        {formErrors?.pocContactMethod ? <InputError>{formErrors.pocContactMethod}</InputError> : null}
        <Row className='d-flex justify-content-center mt-4'>
          <Col sm={6}>
            <Row>
              <Button className='btn primary-btn d-flex justify-content-center mt-5 py-3' style={styles.buttonStyle} onClick={handleNext}>
                Next
              </Button>
            </Row>
          </Col>
        </Row>
      </Row>
    </Container>
  ) : page === 5 ? (
    <Container fluid>
      <ExitDialog
        setPage={setPage}
        sid={draftSid}
        setDisplayModal={setDisplayModal}
        setIsExitDialogOpen={setShowExitDialog}
        isExitDialogOpen={showExitDialog}
      />
      <CloseModalButton setPage={setPage} setIsExitDialogOpen={setShowExitDialog} setDisplayModal={setDisplayModal} />
      <HorizontalRule />
      <Row className='mt-4'>
        <Radio
          onBlur={autoSaveDraft}
          id='stripeRadio'
          name='paymentMethodSelection'
          value='STRIPE'
          onChange={handleChange}
          checked={values.paymentMethodSelection === 'STRIPE'}
          overrideContainerStyle={{ display: 'inline-block', marginBottom: '0px' }}
        >
          <div className='d-flex flex-column'>
            <Text t='greyInline'>Pay with Card or Bank Wire (ACH)</Text>
            <Text t='greyedOut'>{CARD_BANK_FOOTNOTE}</Text>
          </div>
        </Radio>
        {formErrors?.paymentMethodSelection ? <InputError>{formErrors.paymentMethodSelection}</InputError> : null}
      </Row>
      {values.paymentMethodSelection === 'STRIPE' && (
        <Row className='mt-4 pl-11'>
          <div>
            <Text t='mediumBoldBlack'>Your Payment Methods</Text>
            <Text t='greyedOut' className='mb-3 mt-2'>
              {setUpPaymentsDescription}
            </Text>
            <SetUpPaymentsForCompanyButton />
            <PaymentMethodList paymentMethods={paymentMethods} />
          </div>
        </Row>
      )}
      {company[0]?.payWithRegularInvoice ? (
        <>
          <Row className='mt-4'>
            <Radio
              onBlur={autoSaveDraft}
              id='invoiceRadio'
              name='paymentMethodSelection'
              value='REGULAR_INVOICE'
              onChange={handleChange}
              checked={values.paymentMethodSelection === 'REGULAR_INVOICE'}
              overrideContainerStyle={{ display: 'inline-block', marginBottom: '0px' }}
            >
              <div className='d-flex flex-column'>
                <Text t='greyInline'>Pay with Invoice</Text>
                <Text t='greyedOut'>
                  We invoice 100% of the total upon Job completion and require Net 30 day terms, unless you have previously arranged other terms with YellowBird
                  accounting.
                </Text>
              </div>
            </Radio>
            {formErrors?.paymentMethodSelection ? <InputError>{formErrors.paymentMethodSelection}</InputError> : null}
          </Row>
          <HorizontalRule />
        </>
      ) : (
        <Row className='mt-4'>
          <p>
            If you would like to pay with an invoice, please contact us at{' '}
            <a
              href={`mailto:operations@goyellowbird.com?subject=Invoice Payment Request&body=Hello, I would like to pay with an invoice.`}
              target='_blank'
              rel='noopener noreferrer'
            >
              operations@goyellowbird.com
            </a>
          </p>
        </Row>
      )}

      <Row className='d-flex justify-content-center mt-4'>
        <Col sm={6}>
          <Row>
            <Button
              disabled={!values.paymentMethodSelection || (values.paymentMethodSelection === 'STRIPE' && paymentMethods.length === 0)}
              className='btn primary-btn d-flex justify-content-center mt-5 py-3'
              style={styles.buttonStyle}
              onClick={handleNext}
            >
              Continue
            </Button>
          </Row>
        </Col>
      </Row>
    </Container>
  ) : (
    <ReviewAndPost
      sid={draftSid}
      user={user}
      addDateInput={addDateInput}
      copy={copy}
      docsToUse={docsToUse}
      removeDateInput={removeDateInput}
      setDisplayModal={setDisplayModal}
      setPage={setPage}
      setValues={setValues}
      values={values}
      showExitDialog={showExitDialog}
      setShowExitDialog={setShowExitDialog}
    />
  );
}

function ReviewAndPost({
  setShowExitDialog,
  showExitDialog,
  user,
  sid,
  values,
  docsToUse,
  setDisplayModal,
  setPage,
  setValues,
  copy,
  removeDateInput,
  addDateInput,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const paymentMethods = useSelector(state => state.company.paymentMethods);

  const formik = useFormik({
    initialValues: { ...values },
    onSubmit: async (values, { setSubmitting }) => {
      if (values.timeWindows.some(date => date.start === '' || date.start === null)) {
        toast.error('All dates are required.');
        return;
      }

      writeStorage('job-posted', true);
      if (values.priceType === '') {
        values.priceType = 'Hourly';
      }

      if (values.paymentMethodSelection === 'STRIPE') {
        values.footnote = CARD_BANK_FOOTNOTE;
      }

      async function submitAll() {
        try {
          await dispatch(updateDraftJob({ sid: sid, job: values, companyId: user.companyId })).unwrap();
          const createdJob = await dispatch(publishDraftJob({ sid: sid })).unwrap();

          // Link documents
          let docIds = docsToUse.map(doc => doc.id);
          await dispatch(createCoJobDoc({ docIds: docIds, jobId: createdJob.id })).unwrap();
          return createdJob;
        } catch (error) {
          throw new Error('Failed to submit all. Please try again later.');
        }
      }

      toast.promise(submitAll(), {
        loading: 'Creating job...',
        success: createdJob => {
          // Perform actions after success
          dispatch(coRetrieveJobs());
          const amplitude = require('../../lib/amplitude');
          amplitude.sendEvent(Events.CO_CREATED_NEW_JOB);
          navigate(`/job/order-review/${createdJob.referenceNumber}`);
          setDisplayModal(false);
          setSubmitting(false);
          return 'Job created successfully!';
        },
        error: error => {
          setSubmitting(false);
          return 'Failed to create job. Please try again later.';
        },
      });
    },
  });

  function humanize(str) {
    var i,
      frags = str.split('_');
    for (i = 0; i < frags.length; i++) {
      frags[i] = frags[i].toLowerCase();
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  }

  return (
    <Container fluid>
      <ExitDialog setPage={setPage} sid={sid} setDisplayModal={setDisplayModal} setIsExitDialogOpen={setShowExitDialog} isExitDialogOpen={showExitDialog} />
      <CloseModalButton setPage={setPage} setIsExitDialogOpen={setShowExitDialog} setDisplayModal={setDisplayModal} />
      <form onSubmit={formik.handleSubmit}>
        <Row className='text-input-container flex-row'>
          <Col sm={10}>
            <h5 style={styles.labelTextNoInput}>Job Info</h5>
          </Col>
          <Col sm={2} style={styles.editButtonSpacing}>
            <div onClick={() => setPage(1)}>
              <img src={EditIcon} alt='edit icon' className='edit-btn pointer-cursor'></img>
            </div>
          </Col>
        </Row>
        <Row>
          <TextInput overrideContainerStyle={{ marginTop: 'auto' }} id='name' value={values.name} name='name' placeholder='Job Name' disabled />
          {formik.errors.name ? <InputError>{formik.errors.name}</InputError> : null}
          <TextInput type='hidden' id='name' label='' {...formik.getFieldProps('name')} />
        </Row>
        <Row>
          <TextInput overrideContainerStyle={{ marginTop: -19.5 }} id='service' disabled name='service' value={values.service} />
          {values.typeOfService && <TextInput disabled value={values.typeOfService} overrideContainerStyle={{ marginTop: 13.5 }} name='typeOfService' />}
        </Row>
        <Row className='mx-0 mt-3'>
          <TextareaAutosize
            className='text-input__input form-control'
            value={values.typeOfWork}
            disabled
            name='typeOfWork'
            placeholder={copy ? 'Please Update the Type of Work' : 'Type of Work'}
            style={{
              borderBottomColor: '#b4b4b4',
              borderTop: 'none',
              borderLeft: 'none',
              borderRight: 'none',
              background: 'none',
              fontSize: 'inherit',
              resize: 'none',
              textDecoration: 'none',
              paddingLeft: 0,
              color: '#586F7C',
            }}
          />
          <div style={{ paddingLeft: 0 }}>
            {formik.errors.typeOfWork ? <InputError style={{ paddingLeft: 0 }}>{formik.errors.typeOfWork}</InputError> : null}
            <TextInput type='hidden' id='typeOfWork' label='' {...formik.getFieldProps('typeOfWork')} />
          </div>
        </Row>
        <Row>
          <TextInput overrideContainerStyle={{ marginTop: -10, marginBottom: 10 }} id='notes' disabled name='notes' value={values.notes} />
        </Row>
        <Row>
          <Col sm={10}>
            <h5 style={styles.labelTextNoInput}>Job Sites and Timeline</h5>
          </Col>
          <Col sm={2} style={styles.editButtonSpacing}>
            <div onClick={() => setPage(2)}>
              <img src={EditIcon} alt='edit icon' className='edit-btn pointer-cursor'></img>
            </div>
          </Col>
        </Row>

        <Row>
          <div>
            <TextInput
              overrideContainerStyle={{ marginTop: 'auto' }}
              disabled
              value={humanize(values.commuteType)}
              name='commuteType'
              placeholder='Type of Commute'
            />
            {formik.errors.commuteType ? <InputError>{formik.errors.commuteType}</InputError> : null}
            <TextInput type='hidden' id='commuteType' label='' {...formik.getFieldProps('commuteType')} />
          </div>
          {values.commuteType === 'VIRTUAL' ? (
            <div>
              <TextInput
                overrideContainerStyle={{ marginTop: -13.5 }}
                disabled
                name='commuteDetails'
                value={humanize(values.commuteDetails)}
                label='Virtual Job Details'
                placeholder='Enter any details around the virtual work or deliverables here.'
              />
              {formik.errors.commuteDetails ? <InputError>{formik.errors.commuteDetails}</InputError> : null}
              <TextInput type='hidden' id='commuteDetails' label='' {...formik.getFieldProps('commuteDetails')} />
            </div>
          ) : null}

          {values.commuteType === 'HYBRID' ? (
            <div>
              <Row>
                <TextInput
                  overrideContainerStyle={{ marginTop: -13.5 }}
                  disabled
                  name='commuteDetails'
                  value={humanize(values.commuteDetails)}
                  label='Virtual Job Details'
                  placeholder='Enter any details around the virtual work or deliverables here.'
                />
              </Row>
              <Row>
                <TextInput
                  overrideContainerStyle={{ marginTop: 16.5 }}
                  disabled
                  key='siteAddress'
                  value={values.siteAddress}
                  name='siteAddress'
                  label='Street Address'
                  placeholder='Enter Your Street Address'
                />
                {formik.errors.siteAddress ? <InputError>{formik.errors.siteAddress}</InputError> : null}
                <TextInput type='hidden' id='siteAddress' label='' {...formik.getFieldProps('siteAddress')} />
              </Row>
              <Row>
                <Col md={7}>
                  <TextInput
                    overrideContainerStyle={{ marginTop: -13.5 }}
                    disabled
                    value={values.siteCity}
                    name='siteCity'
                    label='City'
                    placeholder='Enter Your City'
                  />
                  {formik.errors.siteCity ? <InputError>{formik.errors.siteCity}</InputError> : null}
                  <TextInput type='hidden' id='siteCity' label='' {...formik.getFieldProps('siteCity')} />
                </Col>
                <Col md={3}>
                  <TextInput overrideContainerStyle={{ marginTop: -13.5 }} disabled value={values.siteState} label='State' />
                  {formik.errors.siteState ? <InputError>{formik.errors.siteState}</InputError> : null}
                  <TextInput type='hidden' id='siteState' label='' {...formik.getFieldProps('siteState')} />
                </Col>
                <Col md={2}>
                  <TextInput overrideContainerStyle={{ marginTop: -13.5 }} value={values.siteZip} name='siteZip' label='Zip' placeholder='Enter Zip' disabled />
                  {formik.errors.siteZip ? <InputError>{formik.errors.siteZip}</InputError> : null}
                  <TextInput type='hidden' id='siteZip' label='' {...formik.getFieldProps('siteZip')} />
                </Col>
              </Row>
            </div>
          ) : null}
          {values.commuteType === 'ON_SITE' ? (
            <div>
              <Row>
                <TextInput
                  overrideContainerStyle={{ marginTop: 'auto' }}
                  disabled
                  value={values.siteAddress}
                  name='siteAddress'
                  label='Street Address'
                  placeholder='Enter Your Street Address'
                />
                {formik.errors.siteAddress ? <InputError>{formik.errors.siteAddress}</InputError> : null}
                <TextInput type='hidden' id='siteAddress' label='' {...formik.getFieldProps('siteAddress')} />
              </Row>
              <Row className='mt-2'>
                <Col md={7}>
                  <TextInput
                    overrideContainerStyle={{ marginTop: -13.5 }}
                    disabled
                    value={values.siteCity}
                    name='siteCity'
                    label='City'
                    placeholder='Enter Your City'
                  />
                  {formik.errors.siteCity ? <InputError>{formik.errors.siteCity}</InputError> : null}
                  <TextInput type='hidden' id='siteCity' label='' {...formik.getFieldProps('siteCity')} />
                </Col>
                <Col md={3}>
                  <TextInput overrideContainerStyle={{ marginTop: -13.5 }} disabled value={values.siteState} label='State' />
                  {formik.errors.siteState ? <InputError>{formik.errors.siteState}</InputError> : null}
                  <TextInput type='hidden' id='siteState' label='' {...formik.getFieldProps('siteState')} />
                </Col>
                <Col md={2}>
                  <TextInput overrideContainerStyle={{ marginTop: -13.5 }} disabled value={values.siteZip} name='siteZip' label='Zip' placeholder='Enter Zip' />
                  {formik.errors.siteZip ? <InputError>{formik.errors.siteZip}</InputError> : null}
                  <TextInput type='hidden' id='siteZip' label='' {...formik.getFieldProps('siteZip')} />
                </Col>
              </Row>
            </div>
          ) : null}
          <div>
            <Row>
              <Col sm={6}>
                <label className='text-input__label' style={{ margin: '16px 0 5px' }}>
                  Potential Start Dates
                </label>
                {formik.values.timeWindows.map((date, index) => (
                  <div key={date.id}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <TextInput
                        className='date-picker'
                        style={{ color: '#586F7C', marginRight: '1em' }}
                        value={date.startDateString}
                        name={`timeWindows[${index}].startDateString`}
                        type='date'
                        min={new Date().toISOString().slice(0, 10)}
                        onChange={e => {
                          const dateExists = formik.values.timeWindows.some(date => date.startDateString === e.target.value);

                          if (dateExists) {
                            toast.error('This date is already added.');
                            return;
                          }

                          const editedTimeWindows = formik.values.timeWindows.map(tw =>
                            tw.id === date.id ? { ...tw, startDateString: e.target.value, start: new Date(e.target.value) } : tw
                          );
                          setValues({ ...values, timeWindows: editedTimeWindows });
                          formik.setFieldValue('timeWindows', editedTimeWindows);
                        }}
                        overrideContainerStyle={{ marginTop: '1em' }}
                      />
                      {formik.values.timeWindows.length > 1 && (
                        <Button
                          type='button'
                          className='btn transparent-btn'
                          style={{ marginTop: '0.1em' }}
                          onClick={() => {
                            if (formik.values.timeWindows.length > 1) {
                              removeDateInput(date.id);
                              formik.setFieldValue(
                                'timeWindows',
                                formik.values.timeWindows.filter(tw => tw.id !== date.id)
                              );
                            }
                          }}
                        >
                          <X size={18} />
                        </Button>
                      )}
                    </div>
                    {formik.errors[`timeWindows[${index}].startDateString`] ? (
                      <InputError>{formik.errors[`timeWindows[${index}].startDateString`]}</InputError>
                    ) : null}
                  </div>
                ))}

                <Button
                  type='button'
                  className='btn primary-btn mt-4'
                  style={{ width: 'fit-content', fontWeight: 600, display: 'flex', alignItems: 'center' }}
                  onClick={() => {
                    if (formik.values.timeWindows.length < MAX_TIME_WINDOW_OPTIONS) {
                      addDateInput();
                      formik.setFieldValue('timeWindows', [...formik.values.timeWindows, { id: new Date().getTime(), startDateString: '', start: '' }]);
                    } else {
                      toast.error(`You can add up to ${MAX_TIME_WINDOW_OPTIONS} dates only.`);
                    }
                  }}
                  disabled={formik.values.timeWindows.length >= MAX_TIME_WINDOW_OPTIONS}
                >
                  <Plus size={18} style={{ marginRight: '8px' }} />
                  Add Date
                </Button>
              </Col>
              <Col sm={6}>
                <Row>
                  <TextInput disabled value={`${values.totalHours} Hours`} placeholder='Expected Number of Hours' label='Expected Number of Hours' />
                  {formik.errors.totalHours ? <InputError>{formik.errors.totalHours}</InputError> : null}
                  <TextInput type='hidden' id='totalHours' label='' overrideContainerStyle={{ marginTop: 16.5 }} />
                </Row>
                <Spacer h={20} />
                {values.schedulingNotes && (
                  <Row>
                    <label htmlFor='schedulingNotes' className='text-input__label'>
                      Scheduling Notes
                    </label>
                    <TextareaAutosize
                      id='schedulingNotes'
                      name='schedulingNotes'
                      disabled
                      value={values.schedulingNotes}
                      className='form-control'
                      style={{ minHeight: '90px' }}
                    />
                  </Row>
                )}
              </Col>
            </Row>
          </div>
        </Row>
        <Spacer h={20} />
        <div className='mt-3'>
          <Row>
            <Col sm={10}>
              <h5 style={styles.labelTextNoInput}>Additional Details</h5>
            </Col>
            <Col sm={2} style={styles.editButtonSpacing}>
              <div onClick={() => setPage(3)}>
                <img src={EditIcon} alt='edit icon' className='edit-btn pointer-cursor'></img>
              </div>
            </Col>
          </Row>
          <Row className='mt-3'>
            <div className='text-input__label' style={{ ...styles.labelText }}>
              Additional Requirements
            </div>
            <>
              <div className='d-flex flex-row'>
                <div className='me-3'>
                  <label htmlFor='additionalRequirementsYes' style={styles.radioLabelText}>
                    Yes
                  </label>
                  <input
                    id='additionalRequirementsYes'
                    readOnly={true}
                    type='radio'
                    name='option13'
                    checked={values.additionalRequirements === 'yes'}
                    disabled
                  />
                </div>
                <div>
                  <label htmlFor='additionalRequirementsNo' style={styles.radioLabelText}>
                    No
                  </label>
                  <input id='additionalRequirementsNo' readOnly={true} type='radio' name='option13' checked={values.additionalRequirements === 'no'} disabled />
                </div>
              </div>
            </>
          </Row>
          <hr className='mt-1' />
          {/* Add specific certs or skill, language required, YOE, Industry experience, other requirements */}
          {values.additionalRequirements === 'yes' ? (
            <>
              {values.requiredSkills && (
                <Row>
                  <TextInput
                    overrideContainerStyle={{ marginTop: 'auto' }}
                    disabled
                    name='requiredSkills'
                    value={humanize(values.requiredSkills)}
                    label='Specific Certifications or Skills'
                    placeholder='Select "Edit" and add Cert or Skill'
                  />
                </Row>
              )}
              {values.languageRequired && (
                <Row>
                  <TextInput
                    overrideContainerStyle={{ marginTop: 16.5 }}
                    disabled
                    name='languageRequired'
                    value={humanize(values.languageRequired)}
                    label='Language required'
                    placeholder='Select "Edit" and add Language'
                  />
                </Row>
              )}
              {values.ehsExperience && (
                <Row>
                  <TextInput
                    overrideContainerStyle={{ marginTop: 16.5 }}
                    disabled
                    name='ehsExperience'
                    value={humanize(values.ehsExperience)}
                    label='Years of Experience'
                    placeholder='Select "Edit" and add Years of Experience'
                  />
                </Row>
              )}
              {values.industryExperience && (
                <Row>
                  <TextInput
                    overrideContainerStyle={{ marginTop: 16.5 }}
                    disabled
                    name='industryExperience'
                    value={humanize(values.industryExperience)}
                    label='Industry Experience'
                    placeholder='Select "Edit" and add Years of Industry Experience'
                  />
                </Row>
              )}
              {values.otherRequirements && (
                <Row>
                  <TextInput
                    overrideContainerStyle={{ marginTop: 16.5 }}
                    disabled
                    name='otherRequirements'
                    value={humanize(values.otherRequirements)}
                    label='Other requirements or additional information the Pro needs to be aware of?
                  Such as Equipment, NDA, etc'
                    placeholder='Select "Edit" and add Other Requirements'
                  />
                </Row>
              )}
            </>
          ) : null}
          <Row className='text-input-container'>
            <div className='text-input__label' style={{ ...styles.labelText }}>
              Deliverable Required
            </div>
            <>
              <div className='d-flex flex-row'>
                <div className='me-3'>
                  <label htmlFor='deliverableYes' style={styles.radioLabelText}>
                    Yes
                  </label>
                  <input id='deliverableYes' readOnly={true} type='radio' name='option7' checked={values.deliverableRequired === 'yes'} disabled />
                </div>
                <div>
                  <label htmlFor='deliverableNo' style={styles.radioLabelText}>
                    No
                  </label>
                  <input id='deliverableNo' readOnly={true} type='radio' name='option7' checked={values.deliverableRequired === 'no'} disabled />
                </div>
              </div>
            </>
          </Row>
          <hr className='mt-1' />
          {values.deliverableRequired === 'yes' ? (
            <>
              <Row style={{ fontWeight: 700, fontSize: '12px' }}>
                <TextInput
                  overrideContainerStyle={{ marginTop: 'auto  ' }}
                  disabled
                  value={`${values.deliverableType ? deliverableTypeOptions.find(obj => obj.value === values.deliverableType).label : ''}`}
                  placeholder='Select "Edit" and select Deliverable Type'
                  name='deliverableType'
                  label='Deliverable Type'
                />
                {formik.errors.deliverableType ? <InputError>{formik.errors.deliverableType}</InputError> : null}
                <TextInput type='hidden' id='deliverableType' label='' {...formik.getFieldProps('deliverableType')} />
              </Row>
              <Row>
                <div className='text-input__label' style={styles.labelText}>
                  Will you be supplying a template?
                </div>
                <div className='d-flex flex-row'>
                  <div className='me-3'>
                    <label htmlFor='templateYes' style={styles.radioLabelText}>
                      Yes
                    </label>
                    <input
                      id='templateYes'
                      readOnly={true}
                      type='radio'
                      name='option8'
                      checked={docsToUse?.length !== 0 || values.templateRequired || values.coAdditionalContent}
                      disabled
                    />
                  </div>
                  <div>
                    <label htmlFor='templateNo' style={styles.radioLabelText}>
                      No
                    </label>
                    <input
                      id='templateNo'
                      readOnly={true}
                      type='radio'
                      name='option8'
                      checked={docsToUse?.length === 0 && !values.templateRequired && !values.coAdditionalContent}
                      disabled
                    />
                  </div>
                </div>
              </Row>
              <hr className='mt-1' />
              {docsToUse.length > 0 ? (
                <Row>
                  <Col>
                    <p>Reports Templates:</p>
                  </Col>
                  <Col>
                    {docsToUse.map(doc => (
                      <p>{doc.fileName}</p>
                    ))}
                  </Col>
                </Row>
              ) : null}
            </>
          ) : null}
          <Row className='text-input-container'>
            <div className='text-input__label' style={{ ...styles.labelText, whiteSpace: 'break-spaces' }}>
              Do you have other content or documentation that the Professional will be required to use to deliver the service?
            </div>
            <>
              <div className='d-flex flex-row'>
                <div className='me-3'>
                  <label htmlFor='coAdditionalContentYes' style={styles.radioLabelText}>
                    Yes
                  </label>
                  <input id='coAdditionalContentYes' readOnly={true} type='radio' name='option11' checked={values.coAdditionalContent === 'yes'} disabled />
                </div>
                <div>
                  <label htmlFor='coAdditionalContentNo' style={styles.radioLabelText}>
                    No
                  </label>
                  <input id='coAdditionalContentNo' readOnly={true} type='radio' name='option11' checked={values.coAdditionalContent === 'no'} disabled />
                </div>
              </div>
            </>
          </Row>
          <hr className='mt-1' />
        </div>
        <div>
          <Row className='mt-4'>
            <Col sm={10}>
              <h5 style={styles.labelTextNoInput}>Job Point of Contact</h5>
            </Col>
            <Col sm={2} style={styles.editButtonSpacing}>
              <div onClick={() => setPage(4)}>
                <img src={EditIcon} alt='edit icon' className='edit-btn pointer-cursor'></img>
              </div>
            </Col>
          </Row>
          <Row>
            <TextInput
              overrideContainerStyle={{ marginTop: 5 }}
              disabled
              value={`Name: ${values.pocFirstName} ${values.pocLastName} `}
              placeholder='pocName'
              name='pocFirstName'
            />
            {formik.errors.pocFirstName ? <InputError>{formik.errors.firstName}</InputError> : null}
            <TextInput overrideContainerStyle={{ marginTop: 0 }} type='hidden' id='firstName' label='' {...formik.getFieldProps('firstName')} />
            <TextInput disabled value={`Job Title: ${values.pocJobTitle}`} placeholder='pocJobTitle' name='pocJobTitle' />
            {formik.errors.pocJobTitle ? <InputError>{formik.errors.pocJobTitle}</InputError> : null}
            <TextInput overrideContainerStyle={{ marginTop: 0 }} type='hidden' id='pocJobTitle' label='' {...formik.getFieldProps('pocJobTitle')} />
            <TextInput disabled value={`POC Email: ${values.pocEmail}`} placeholder='pocEmail' name='pocEmail' />
            {formik.errors.pocEmail ? <InputError>{formik.errors.pocEmail}</InputError> : null}
            <TextInput overrideContainerStyle={{ marginTop: 0 }} type='hidden' id='pocEmail' label='' {...formik.getFieldProps('pocEmail')} />
            <TextInput disabled value={`Phone Number: ${values.pocPhone}`} placeholder='pocPhone' name='pocPhone' />
            {formik.errors.pocPhone ? <InputError>{formik.errors.pocPhone}</InputError> : null}
            <TextInput overrideContainerStyle={{ marginTop: 0 }} type='hidden' id='pocPhone' label='' {...formik.getFieldProps('pocPhone')} />
          </Row>
          <Row>
            <TextInput
              disabled
              value={`Preferred Contact Method: ${values.pocContactMethod ? optionsForDrop.find(obj => obj.value === values.pocContactMethod).label : ''}`}
              placeholder='Preferred Method of Contact'
              name='pocContactMethod'
            />
            {formik.errors.pocContactMethod ? <InputError>{formik.errors.pocContactMethod}</InputError> : null}
            <TextInput type='hidden' id='pocContactMethod' label='' {...formik.getFieldProps('pocContactMethod')} />
          </Row>
        </div>
        <div>
          <Row className='mt-4'>
            <Col sm={10}>
              <h5 style={styles.labelTextNoInput}>Payment Details</h5>
            </Col>
            <Col sm={2} style={styles.editButtonSpacing}>
              <div onClick={() => setPage(5)}>
                <img src={EditIcon} alt='edit icon' className='edit-btn pointer-cursor'></img>
              </div>
            </Col>
            <TextInput
              disabled
              value={`Payment Method: ${values.paymentMethodSelection === 'REGULAR_INVOICE' ? 'Pay with Invoice' : 'Pay with Card or Bank Wire (ACH)'}`}
              name='paymentMethodSelection'
              overrideContainerStyle={{ marginBottom: '1rem' }}
            />
            {values.paymentMethodSelection === 'STRIPE' && <PaymentMethod paymentMethod={paymentMethods.find(pm => pm.is_default)} isModal />}
          </Row>
        </div>
        <Row className='mt-4'>
          <p
            data-tip
            data-for='flexibleStartDates'
            style={{
              background: '#87ddff',
              borderRadius: 50,
              padding: '8px 24px',
              display: 'flex',
              alignItems: 'center',
              gap: 12,
              width: 'fit-content',
              margin: 0,
            }}
          >
            <Info size={48} style={{ marginLeft: 5 }} />
            <span style={{ fontWeight: 600, fontStyle: 'italic' }}>
              Upon clicking Post Job, YellowBird will begin the process of matching your job with a qualified professional.
            </span>
          </p>
        </Row>
        <div className='jobFormBtnWrapper' style={{ display: 'flex', justifyContent: 'center', gap: '12px' }}>
          <button
            type='button'
            onClick={() => setDisplayModal(false)}
            style={{ padding: '12px', backgroundColor: '#52616b', borderColor: '#52616b', color: 'white', width: '100%' }}
            className='profileBuildingUploadBtn'
          >
            Save Draft
          </button>
          <button style={{ width: '100%' }} disabled={formik.isSubmitting} type='submit' className='profileBuildingUploadBtn'>
            {formik.isSubmitting ? (
              <>
                <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true' />
                Posting...
              </>
            ) : (
              'Post Job'
            )}
          </button>
        </div>
      </form>
    </Container>
  );
}
