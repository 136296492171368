import React, { useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';

import AddDoc from '../../assets/images/add-docs-icon.svg';
import UploadDocsIcon from '../../assets/images/upload-docs-new.svg';
import { deleteReport, getReports } from '../../slices/document.slice';
import Loading from '../Loading';

import './reportUploader.scss';

const baseStyle = {
  borderColor: 'black',
  borderWidth: 2,
};

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

export default function ReportUploader({ uploadDocuments, jobId }) {
  const state = useSelector(state => state.document);
  const { reports, isLoading } = state;
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: '.pdf, image/*, .docx, .doc, .txt',
    onDrop: acceptedFiles => {
      acceptedFiles.forEach(file => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          file.dataURL = reader.result.split(',')[1];
        };
      });

      const allFiles = [
        ...files,
        ...acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ];
      setTimeout(() => {
        uploadDocuments(allFiles);
      }, 500);
      setFiles(allFiles);
    },
  });
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach(file => URL.revokeObjectURL(file.preview));
    },
    [files]
  );
  const deleteADoc = reportId => {
    dispatch(deleteReport({ reportId })).then(res => {
      dispatch(getReports({ jobId }));
    });
  };
  useEffect(() => {
    dispatch(getReports({ jobId }));
  }, [dispatch, jobId]);

  if (isLoading) {
    return <Loading />;
  }
  return (
    <div className='uploader-container' style={{ whiteSpace: 'nowrap' }}>
      <p className='mb-4' style={{ fontWeight: 700, fontSize: '15px', whiteSpace: 'nowrap' }}>
        Upload Finished Report or Deliverable
      </p>
      {!isLoading ? (
        <div>
          <div>
            <div className='pointer-cursor d-flex flex-row justify-content-between mb-3 border-none' {...getRootProps({ style })}>
              <input {...getInputProps()} />
              <label htmlFor='uploader' style={{ color: '#586F7C' }}>
                Upload Report or Deliverable
              </label>
              <img src={UploadDocsIcon} alt='doc uploader' />
            </div>
            <hr className='mt-1' />
          </div>
          <br />
          {reports.length > 0 ? (
            <div>
              <p>You've uploaded the following deliverable{reports.length > 1 ? 's' : null}:</p>
              {reports.map(report => (
                <div className='thumb-row' key={report.id}>
                  <div
                    className='pointer-cursor'
                    onClick={() => {
                      deleteADoc(report.id);
                    }}
                  >
                    <img src={AddDoc} alt='' />
                    {/* <MdDelete size={20} /> */}
                  </div>
                  <span className='file-label'>{report.fileName}</span>
                </div>
              ))}
            </div>
          ) : null}
          <br />
        </div>
      ) : null}
    </div>
  );
}
