import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { camelizeKeys, decamelizeKeys } from 'humps';

import apiCamelback from '../api/apiCamelback';

export const updateJobInvite = createAsyncThunk('jobInvites/update', async ({ jobInviteId, preferredDate, standByListStatus }, thunkApi) => {
  try {
    const response = await apiCamelback.post(`/api/v1/job_invites/${jobInviteId}/update`, {
      preferred_date: preferredDate,
      status: standByListStatus,
    });
    if (response.data.errors) {
      return thunkApi.rejectWithValue(response.data.errors);
    }
    return camelizeKeys(response.data);
  } catch (error) {
    return thunkApi.rejectWithValue(error.response.data.errors || error.message);
  }
});
