import React from 'react';
import { Menu } from 'lucide-react';
import { useSelector } from 'react-redux';

import './signedInHeader.css';

import yellowTitle from '../../../assets/icons/landscape-logo.png';
import yellowbird from '../../../assets/icons/yellowbird.png';
import TopNavbarDropdown from '../TopNavbarDropdown';

const SignedInHeader = ({ open, setOpen }) => {
  const user = useSelector(state => state.user.user);

  return (
    <div className='signedin-header-containter'>
      <div style={{ cursor: 'pointer' }} className='signedin-div goyellow'>
        <img className='header-image' src={yellowbird} alt='Yellow Bird icon' />
        <a
          style={{ textDecoration: 'none', color: 'white', fontWeight: '700', fontSize: '14px' }}
          href='https://goyellowbird.com/'
          target='_blank'
          rel='noreferrer'
        >
          goyellowbird.com
        </a>
      </div>
      <div className='header-menu' style={open ? { background: '#49525E' } : {}}>
        <Menu onClick={() => setOpen(!open)} />
      </div>
      <div style={{ alignItems: 'end' }} className='signedin-div'>
        <img className='yellowbird-logo-header' src={yellowTitle} alt='Yellow bird logo' />
        <span className='organization-span'>{user.role === 'user' ? 'Organization' : 'Professional'}</span>{' '}
      </div>
      <TopNavbarDropdown />
    </div>
  );
};

export default SignedInHeader;
