import React from 'react';
import { v4 as uuidv4 } from 'uuid';

const DraftInputRadio = ({ register, field, labelAndValue, onBlur }) => {
  const id = uuidv4();

  return (
    <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
      <div>
        <input value={labelAndValue.toLowerCase()} {...register(field, { required: true, onBlur: onBlur })} id={id} type='radio' />
      </div>
      <label style={{ margin: 0 }} className='labelEditJob' htmlFor={id}>
        {labelAndValue}
      </label>
    </div>
  );
};

export default DraftInputRadio;
