import React, { Fragment, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDown } from 'lucide-react';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import ToolTipIcon from '../../assets/images/tooltip-icon.svg';
import Footer from '../../components/Layout/Footer';
import Header from '../../components/Layout/Header';
import { Events } from '../../lib/amplitude';
import { initialOnboardingTaskList, referralSourceOptions } from '../../lib/options';
import { checkEmailCo, signUpPro } from '../../slices/auth.slice';
import { createOnboardingTasks } from '../../slices/onboarding.slice';
import { createReferral, retrieveReferrerId } from '../../slices/referrals.slice';
import { trackSignupEvent } from '../../utils/googleAnalytics';
import { StyledReactTooltip } from '../OldCreateJob';

import './proRegistration.scss';

const ProRegistration = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [submitting, setSubmitting] = useState(false);
  const { data: nonRegisteredData } = state.nonRegistered;

  let refCode = null;

  if (queryParams) {
    refCode = queryParams.get('code') ?? null;
  }

  const {
    register,
    setError,
    trigger,
    control,
    watch,
    reset,
    resetField,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      terms: false,
      referral: refCode ? 'referralCode' : '',
      referralSource: refCode ?? '',
      address: '',
      city: '',
      state: '',
      zipCode: '',
      mobilePhone: '',
      authorizedToWork: '',
      workedIndustries: [],
      ehsExperience: 0,
      otherLanguages: [],
      certifications: [],
      environmentalCertifications: [],
      dotCertifications: [],
      oshaCertificates: [],
      oshaCourseCertificates: [],
      emergencyResponseCertifications: [],
      environmentalServices: [],
      generalSkills: [],
      training: [],
      mshaBlueCards: [],
    },
  });

  const dispatchVerifyEmail = async () => {
    const email = getValues('email');
    await dispatch(checkEmailCo({ email: email })).unwrap();
  };

  const verifyEmailOnBlur = async () => {
    try {
      await dispatchVerifyEmail();
    } catch (e) {
      console.error(e);
      setSubmitting(false);
      setError('email', { message: 'Email already in use' });
      return;
    }
  };

  const handleInputChange = (e, fieldValue) => {
    const currentValue = fieldValue === 'email' ? e.target.value.toLowerCase() : e.target.value;
    setValue(fieldValue, currentValue);
    trigger(fieldValue);
  };

  const referral = watch('referral');
  const isReferred = referral === 'referralCode' || referral === 'referralPerson';

  const onSubmit = async data => {
    setSubmitting(true);

    try {
      await dispatchVerifyEmail();
    } catch (e) {
      console.error(e);
      setSubmitting(false);
      setError('email', { message: 'Email already in use' });
      return;
    }

    const amplitude = require('../../lib/amplitude');
    amplitude.setUserId(data.email);
    const response = await dispatch(signUpPro(data)).unwrap();
    trackSignupEvent('Professional');
    try {
      localStorage.setItem('firstTimeUser', 'true');
      // @deprecated
      //dispatch(deleteEntry({ type: 'pro', id: nonRegisteredData.id }));
      if (response.worker.id && response.token) {
        await dispatch(createOnboardingTasks({ taskList: initialOnboardingTaskList })).unwrap();
        if (data.referral === 'referralCode' || data.referral === 'referralPerson') {
          try {
            let body = {
              referralType: data.referral === 'referralCode' ? 'referral code' : 'referred',
              referralCode: data.referral === 'referralCode' ? data.referralSource.toUpperCase() : null,
              referrerName: data.referral === 'referralPerson' ? data.referralSource : null,
              refereeEmail: data.email.toLowerCase(),
              refereeUserId: response.user.user.id,
              refereeUserType: 'Professional',
              isReferralFeeEarned: false,
              refereeName: `${data.firstName} ${data.lastName}`,
              dropdownSelection: data.referral,
            };
            if (data.referral === 'referralCode') {
              let code = body.referralCode;
              let idResult;
              if (!refCode) {
                const res = await dispatch(retrieveReferrerId(code)).unwrap();
                idResult = res;
                body.userId = idResult.id;
                body.referrerName = `${idResult.firstName} ${idResult.lastName}`;
                await dispatch(createReferral(body)).unwrap();
                let token = localStorage.getItem('token');
                if (token) {
                  amplitude.sendEvent(Events.PRO_REGISTRATION_COMPLETED);
                  navigate('/profile-building');
                }
              } else {
                const res = await dispatch(retrieveReferrerId(refCode)).unwrap();
                idResult = res;
                body.userId = idResult.id;
                body.referrerName = `${idResult.firstName} ${idResult.lastName}`;
                await dispatch(createReferral(body)).unwrap();
                let token = localStorage.getItem('token');
                if (token) {
                  amplitude.sendEvent(Events.PRO_REGISTRATION_COMPLETED);
                  navigate('/profile-building');
                }
              }
            } else {
              await dispatch(createReferral(body)).unwrap();
              let token = localStorage.getItem('token');
              if (token) {
                amplitude.sendEvent(Events.PRO_REGISTRATION_COMPLETED);
                navigate('/profile-building');
              }
            }
          } catch (error) {
            let token = localStorage.getItem('token');
            if (token) {
              amplitude.sendEvent(Events.PRO_REGISTRATION_COMPLETED);
              navigate('/profile-building');
            }
          }
        } else {
          let token = localStorage.getItem('token');
          if (token) {
            amplitude.sendEvent(Events.PRO_REGISTRATION_COMPLETED);
            navigate('/profile-building');
          }
        }
      }
    } catch (e) {
      setSubmitting(false);
      resetField('terms');
      setError('terms', { message: 'There was a problem when submitting your info. Please try again later.' });
    }
  };

  return (
    <>
      <Header />
      <Container
        fluid
        style={{
          width: '100%',
          maxWidth: 1280,
          margin: '0 auto',
          paddingTop: 100,
          paddingBottom: 100,
        }}
      >
        <>
          <Row>
            <Col xs={12}>
              <p style={{ textAlign: 'center', fontWeight: 600, fontSize: '1.25rem' }}>Let's get started. Tell us about yourself.</p>
            </Col>
          </Row>
        </>
        <form
          style={{ display: 'flex', gap: '20px', flexDirection: 'column', maxWidth: '500px', marginRight: 'auto', marginLeft: 'auto' }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className='registrationFlexSection'>
            <div style={{ position: 'relative' }}>
              <input
                placeholder='First Name'
                className='profileBuildingInput'
                {...register('firstName', { required: 'Required field' })}
                onChange={e => handleInputChange(e, 'firstName')}
              />
              <p className='errorMessageInputProfilebuilding'>{errors?.firstName?.message.toString()}</p>
            </div>
            <div style={{ position: 'relative' }}>
              <input
                placeholder='Last Name'
                className='profileBuildingInput'
                {...register('lastName', { required: 'Required field' })}
                onChange={e => handleInputChange(e, 'lastName')}
              />
              <p className='errorMessageInputProfilebuilding'>{errors.lastName?.message?.toString()}</p>
            </div>
          </div>
          <div style={{ position: 'relative' }}>
            <input
              type='email'
              placeholder='Email Address'
              className='profileBuildingInput'
              {...register('email', {
                required: 'Required field',
                pattern: { value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, message: 'Email is invalid' },
              })}
              onBlur={verifyEmailOnBlur}
              onChange={e => handleInputChange(e, 'email')}
            />
            <p className='errorMessageInputProfilebuilding'>{errors.email?.message?.toString()}</p>
          </div>
          <div style={{ position: 'relative' }}>
            <input
              type='password'
              placeholder='Password'
              className='profileBuildingInput'
              {...register('password', {
                required: 'Required field',
                pattern: {
                  value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\w\s]).{8,}$/,
                  message: 'Password must contain at least 6 characters including 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character.',
                },
              })}
              onChange={e => handleInputChange(e, 'password')}
            />
            <p className='errorMessageInputProfilebuilding'>{errors.password?.message?.toString()}</p>
          </div>
          <div className='registrationFlexSection'>
            <div style={{ position: 'relative' }}>
              <input
                type='number'
                placeholder='Zip Code'
                className='profileBuildingInput'
                {...register('zipCode', {
                  required: 'Required field',
                  validate: value => {
                    if (value.length !== 5) return 'Zip Code must be 5 length';
                  },
                })}
                onChange={e => handleInputChange(e, 'zipCode')}
              />
              <p className='errorMessageInputProfilebuilding'>{errors.zipCode?.message?.toString()}</p>
            </div>
            <div style={{ position: 'relative' }}>
              <div className='d-flex flex-row'>
                <input
                  type='tel'
                  placeholder='Phone Number'
                  className='profileBuildingInput flex-grow-1'
                  {...register('mobilePhone', {
                    required: 'Required field',
                    validate: value => {
                      if (value.length !== 10) return 'Phone Number must be 10 length';
                    },
                  })}
                  onChange={e => handleInputChange(e, 'mobilePhone')}
                />

                <span className='flex-grow-0' style={{ marginTop: '2.2rem' }}>
                  <img src={ToolTipIcon} alt='tooltip' data-tip data-for='textingDesclaimer' />
                  <StyledReactTooltip id='textingDesclaimer' effect='solid' data-multiline data-place='right'>
                    <span>
                      By entering your number, you agree to receive mobile messages, once we match you with a job, ‘Text HELP for help’, ‘Text STOP to cancel’,
                      Standard messaging rates apply. Additional charges may be incurred based on your messaging plan with your mobile carrier. Please contact
                      your carrier for more details on messaging fees. Carriers are not liable for delayed or undelivered messages.
                    </span>
                  </StyledReactTooltip>
                </span>
              </div>
              <p className='errorMessageInputProfilebuilding'>{errors.mobilePhone?.message?.toString()}</p>
            </div>
          </div>
          <Controller
            name='referral'
            control={control}
            defaultValue={''}
            rules={{ required: 'Required field.' }}
            render={({ field }) => {
              return (
                <Listbox {...field}>
                  <div className='dropDownForm'>
                    <Listbox.Button className='dropDownButton'>
                      {field.value ? (
                        <span className={`activeField insideContainer resizeStep3`}>{field.value}</span>
                      ) : (
                        <span className='insideContainer resizeStep3'>Referral Source</span>
                      )}
                      <ChevronDown className='chevrowDownForm' aria-hidden='true' />
                    </Listbox.Button>
                    <p className='errorMessageInputForm'>{errors.referral?.message?.toString()}</p>
                    <Transition
                      as={Fragment}
                      enter='transitio ease-in duration-150'
                      leave='transition ease-in duration-100'
                      enterFrom='opacity-0'
                      leaveFrom='opacity-0'
                      leaveTo='opacity-0'
                    >
                      <Listbox.Options className='optionsDropDown' onBlur={() => trigger('referral')}>
                        {referralSourceOptions.map(option => (
                          <Listbox.Option className={({ active }) => `optionField ${active ? 'activeField' : 'unactiveField'}`} value={option.value}>
                            {({ selected }) => (
                              <>
                                {selected ? (
                                  <span className='checkIconStep3'>
                                    <CheckIcon className='checkIconInside' aria-hidden='true' />
                                  </span>
                                ) : null}
                                <span className={`valueDropDown ${selected ? 'font-medium' : 'font-normal'}`}>{option.label}</span>
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </Listbox>
              );
            }}
          />
          {isReferred && (
            <div style={{ position: 'relative' }}>
              <input
                placeholder={referral === 'referralCode' ? 'Referral Code' : 'Referrer Name'}
                className='profileBuildingInput'
                {...register('referralSource', {
                  required: 'Required field',
                })}
                onChange={e => handleInputChange(e, 'referralSource')}
              />
              <p className='errorMessageInputProfilebuilding'>{errors.referralSource?.message?.toString()}</p>
            </div>
          )}
          <div style={{ display: 'flex', marginTop: '12px' }}>
            <input
              style={{ maxHeight: '24px', maxWidth: '24px' }}
              className='checkBoxMultiStep'
              {...register('terms', { required: true })}
              id='fillOutManually'
              type='checkbox'
            />
            <label style={{ fontSize: '14px' }} htmlFor='fillOutManually'>
              I agree to the&nbsp;
              <a target='_blank' href='https://www.goyellowbird.com/terms'>
                Terms of Services&nbsp;
              </a>
              &&nbsp;
              <a target='_blank' href='https://www.goyellowbird.com/privacy'>
                Privacy Policy
              </a>
              *
            </label>
          </div>
          <button
            style={{ alignSelf: 'center', marginTop: '30px', fontWeight: '700', fontSize: '18px', padding: '12px 100px', minWidth: '300px' }}
            className='profileBuildingUploadBtn'
            disabled={!isValid || submitting}
            type='submit'
          >
            {submitting ? <Spinner size='sm' /> : 'Continue'}
          </button>
        </form>
      </Container>
      <Footer />
    </>
  );
};

export default ProRegistration;
