import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useDispatch } from 'react-redux';

import { deleteDraftJob } from '../../slices/job.slice';

const ExitDialog = ({ isExitDialogOpen, setIsExitDialogOpen, setDisplayModal, setPage, sid }) => {
  const dispatch = useDispatch();

  return (
    <Transition appear show={isExitDialogOpen} as={Fragment}>
      <Dialog style={{ position: 'relative', zIndex: '90' }} as='div' onClose={() => setIsExitDialogOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter='transition-opacity duration-75'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='transition-opacity duration-150'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div style={{ position: 'fixed', inset: '0', backgroundColor: 'rgba(0, 0, 0, 0.2)' }} />
        </Transition.Child>
        <div style={{ position: 'fixed', inset: 0, overflowY: 'auto', padding: '12px' }}>
          <div style={{ display: 'flex', minHeight: '100%', alignItems: 'center', justifyContent: 'center' }}>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <Dialog.Panel style={{ maxWidth: '600px', padding: '24px' }} className='dialogPanelSplashScreen'>
                <Dialog.Title style={{ fontSize: '16px', marginBottom: '24px' }}>Would you like to save this job as a draft to post later?</Dialog.Title>
                <div className='exitDialogMainWrapper'>
                  <div className='exitDialogWrapper'>
                    <button
                      className='profileBuildingUploadBtn'
                      onClick={async () => {
                        setDisplayModal(false);
                        setPage(1);
                      }}
                    >
                      Save Draft
                    </button>
                    <button
                      style={{ backgroundColor: '#49525E', borderColor: '#49525E', color: 'white' }}
                      className='profileBuildingUploadBtn'
                      onClick={() => setIsExitDialogOpen(false)}
                    >
                      Continue Editing
                    </button>
                  </div>
                  <button
                    style={{ backgroundColor: '#F44336', borderColor: '#F44336', color: 'white' }}
                    className='profileBuildingUploadBtn'
                    onClick={async () => {
                      await dispatch(deleteDraftJob(sid)).unwrap();
                      setDisplayModal(false);
                      setPage(1);
                    }}
                  >
                    Don't Save
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ExitDialog;
