import React, { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDown } from 'lucide-react';
import { Controller } from 'react-hook-form';

const DropdownEditJob = ({ control, fieldValue, label, notSelectedValue, arrayToMap, onClickFunction, errors, trigger, autoSaveDraft }) => {
  return (
    <Controller
      name={fieldValue}
      control={control}
      rules={{ required: 'Required Field.' }}
      render={({ field }) => {
        return (
          <Listbox {...field}>
            <div style={{ marginTop: '-6px' }} className='dropDownForm'>
              <p className='labelEditJob'>{label}</p>
              <Listbox.Button style={{ borderBottomColor: '#cdc8c8', borderBottom: '1px solid #000', paddingBottom: '12px' }} className='dropDownButton'>
                {field.value ? (
                  <span className={`activeField insideContainer `}>{field.value}</span>
                ) : (
                  <span style={{ color: '#9f9898' }} className='insideContainer'>
                    {notSelectedValue}
                  </span>
                )}
                <ChevronDown style={{ width: '24px', height: '24px' }} className='chevrowDownForm' aria-hidden='true' />
              </Listbox.Button>
              <p style={{ marginTop: '6px' }} className='errorMessageInputForm'>
                {errors?.[fieldValue]?.message?.toString()}
              </p>
              <Transition
                as={Fragment}
                enter='transitio ease-in duration-150'
                leave='transition ease-in duration-100'
                enterFrom='opacity-0'
                leaveFrom='opacity-0'
                leaveTo='opacity-0'
              >
                <Listbox.Options
                  className='optionsDropDown'
                  onBlur={() => {
                    autoSaveDraft();
                    trigger(fieldValue);
                  }}
                >
                  {arrayToMap.map(option => (
                    <Listbox.Option
                      key={option.label}
                      onClick={() => onClickFunction(option)}
                      className={({ active }) => `optionField ${active ? 'activeField' : 'unactiveField'}`}
                      value={option.value || option.label}
                    >
                      {({ selected }) => (
                        <>
                          {selected ? (
                            <span className='checkIconStep3'>
                              <CheckIcon className='checkIconInside' aria-hidden='true' />
                            </span>
                          ) : null}
                          <span className={`valueDropDown ${selected ? 'font-medium' : 'font-normal'}`}>{option.name || option.label}</span>
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
        );
      }}
    />
  );
};

export default DropdownEditJob;
