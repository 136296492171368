import { Badge, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { formatDateMMDDYYYY } from '../../helpers';
import { useAppSelector } from '../../index';
import { Todo } from '../../types';
import classes from './JobRow.module.scss';

// provide any special friendly names for job statuses for a worker
export const jobStatusLabelForWorker = {
  InProgress: 'In Progress',
  Completed: 'Pending Payment',
  ClosedPaid: 'Completed-Paid',
  ClosedLost: 'Canceled',
  ClosedNoResponse: 'Canceled',
  ClosedDidntComplete: 'Canceled',
  'Closed-Testing': 'Canceled',
} as const;

// provide any special friendly names for job statuses for an org
export const jobStatusLabelForCompany = {
  InProgress: 'In Progress',
  Completed: 'Pending Payment',
  ClosedPaid: 'Completed',
  ClosedLost: 'Canceled',
  ClosedNoResponse: 'Canceled',
  ClosedDidntComplete: 'Canceled',
  'Closed-Testing': 'Canceled',
} as const;

/**
 * Resolve the specific job status to a user specific status depending on the user's role
 * @param statusName  the active status
 * @return a friendly version of the status for the current user's role
 */
export function friendlyJobStatusLabel(
  statusName: keyof typeof jobStatusLabelForWorker | keyof typeof jobStatusLabelForCompany,
  userRole: 'worker' | 'company'
) {
  // match the best label for the status, defaulting on the original one if no specific value is provided.
  const suggestedStatusLabel = userRole === 'worker' ? jobStatusLabelForWorker[statusName] : jobStatusLabelForCompany[statusName];
  // if no specific match found, return the original unmodified status
  return suggestedStatusLabel || statusName;
}

type JobRowProps = {
  job: Todo;
  jobCategory: 'current' | 'past' | 'draft';
};

export default function JobRow({ job, jobCategory }: JobRowProps) {
  const user = useAppSelector(state => state.user.user);

  return (
    <>
      <Row style={{ fontSize: 15 }}>
        <Col className='d-flex align-items-center'>
          <Link to={`/job/${job.referenceNumber}`} className={classes.a}>
            {job.name}
          </Link>
        </Col>
        <Col className='d-flex align-items-center'>{job.service || 'None Provided'}</Col>
        <Col className='d-flex align-items-center'>{job.referenceNumber}</Col>
        <Col className='d-flex align-items-center'>{formatDateMMDDYYYY(job.startDate)}</Col>
        <Col className='d-flex align-items-center'>{friendlyJobStatusLabel(job.status, user.role)}</Col>
        {jobCategory === 'current' && (
          <Col className='d-flex align-items-center'>
            {job.uncompletedTasks > 0 ? (
              <Link to={`/job/${job.referenceNumber}?tab=Tasks`} className={classes.tasks_link}>
                View Tasks
                <Badge pill bg='danger'>
                  {job.uncompletedTasks}
                </Badge>
                <span className='visually-hidden'>incomplete tasks</span>
              </Link>
            ) : (
              <span>-</span>
            )}
          </Col>
        )}
      </Row>
      <hr />
    </>
  );
}
