import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import CircularProgressBar from '../../../components/CircularProgressBar';

import './OnboardingHeading.scss';

const OnboardingHeading = ({ onboardingProgress }) => {
  return (
    <Container fluid className='pb-4'>
      <Row className='onboarding-heading__container'>
        <Col className='circular-progress__container' xs={12} sm={4}>
          <CircularProgressBar progress={onboardingProgress} />
        </Col>
        <Col xs={12} sm={8}>
          <p className='onboarding-heading_message'>
            In order for you to reach “Match Ready” status, you must upload your resume and certifications selected during registration.
          </p>
          <p className='onboarding-heading__message'>
            Each step below needs to be <span>fully completed</span> before moving to the next step.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default OnboardingHeading;
