import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import OtpInput from 'react-otp-input';
import { useDispatch } from 'react-redux';

import { sendOtpCode } from '../../../../slices/auth.slice';
import CountDownTimer from './countDown';
import SubmitOTPButton from './submit-otp-button';

import './step-6.css';

const Step6 = () => {
  const {
    watch,
    getValues,
    formState: { errors },
    setError,
  } = useFormContext();
  const [resendState, setResendState] = useState({
    sent: 0,
    disabled: false,
  });

  const { sent, disabled } = resendState;
  const dispatch = useDispatch();
  const otp = watch('otp');

  useEffect(() => {
    dispatch(sendOtpCode({ email: getValues('emailAddress') }));
  }, []);

  const handleResendCode = async e => {
    e.preventDefault();
    console.log('resendCode clicked');
    if (sent >= 2) {
      setError('otp', { message: 'The code has been sent too many times. Please try again later.' });
    } else {
      try {
        await dispatch(sendOtpCode({ email: getValues('emailAddress') })).unwrap();
        setResendState(prevState => ({
          ...prevState,
          sent: prevState.sent + 1,
          disabled: true,
        }));
      } catch (error) {
        const errorMessage = error.response?.data?.message || 'An error has occurred. Please contact operations@goyellowbird.com for assistance.';
        setError('otp', { message: errorMessage });
      }
    }
  };

  return (
    <div className='multiStepContainer'>
      <h1 className='h1MultiStepForm'>
        Check your <br />
        inbox.
      </h1>
      <p className='pTextOtp'>
        An email has been sent <br /> to you with a code to <br /> verify the account:
      </p>
      <div className='mainContainerOtp'>
        <h1 className='codeOtp'>Code</h1>
        <Controller
          name='otp'
          render={({ field }) => {
            return (
              <div className='inputContainerForm'>
                <OtpInput
                  containerStyle={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '0.25rem',
                  }}
                  inputStyle={{
                    backgroundColor: 'rgba(211, 211, 211, 0.4)',
                    width: '35px',
                    height: '35px',
                    color: 'black',
                    fontSize: '22px',
                    marginBottom: '8px',
                    border: 'none',
                  }}
                  value={field.value}
                  onChange={otp => field.onChange(otp)}
                  numInputs={6}
                  renderInput={props => (
                    <div className='otpDiv'>
                      <input {...props} />
                    </div>
                  )}
                />
                <p className='errorMessageInputForm'>{errors.otp?.message?.toString()}</p>
              </div>
            );
          }}
        />
        <div className='didNot'>
          <span className='receiveCode'>Didn't receive the email? </span>
          <button type='button' disabled={disabled} onClick={handleResendCode} className='sendIt'>
            Resend the code.
          </button>
          {disabled && <CountDownTimer resendState={resendState} setResendState={setResendState} />}
        </div>
      </div>
      <SubmitOTPButton otp={otp} isValid={otp.length == 6} />
    </div>
  );
};

export default Step6;
