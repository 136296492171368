import React from 'react';
import { Link } from 'react-router-dom';

import myProfileIcon from '../../assets/images/my-profile-2.svg';

export default function MyProfile() {
  return (
    <>
      <Link to='/my-profile' className='d-flex flex-column align-items-center my-profile'>
        <img src={myProfileIcon} alt='' />
        <h4 className='my-profile-bottom-text'>My Profile</h4>
      </Link>
      <style>{`
        .my-profile {
          text-decoration: none;
          color: #000;
        }
        .my-profile-bottom-text {
          display: flex;
          font: normal normal bold 20px/35px Poppins;
          color: #586f7c;
          text-align: center;
          margin-top: -5%;
          white-space: nowrap;
        }
      `}</style>
    </>
  );
}
