export const size = ['Self-employed', '2-50 employees', '51-500 employees', '501-5,000 employees', '5,001-10,000 employees', '10,001+ employees'];

export const industry = [
  'Automotive',
  'Commercial Real Estate',
  'Construction',
  'Events Services',
  'Facilities Services',
  'Food Production',
  'Hospital & Health Care',
  'Human Resources',
  'Insurance',
  'Logistics',
  'Manufacturing',
  'Oil & Energy',
  'Transportation',
  'Utilities',
  'Warehousing',
  'InsurTech',
  'Other',
];
