import React from 'react';
import { Spinner } from 'react-bootstrap';
import { toast } from 'react-hot-toast';

import apiCamelback from '../api/apiCamelback';
import Button from '../components/Button';
import { useAppSelector } from '../index';
import classes from '../pages/SingleJob/SingleTaskModal/SingleTaskModal.module.scss';

export default function useStripeSetUpPayoutsForPro({ refreshUrl, returnUrl }: { refreshUrl: string; returnUrl: string }) {
  const [loading, setLoading] = React.useState(false);
  const worker = useAppSelector(state => state.worker.worker);

  const btnText = React.useMemo(() => {
    const stripeAccountID = worker?.stripe_account_id || '';
    const stripeDetailsSubmitted = worker?.stripe_details_submitted || false;
    if (stripeAccountID && !stripeDetailsSubmitted) {
      return 'Finish Set Up Payouts';
    } else if (stripeAccountID && stripeDetailsSubmitted) {
      return 'Edit/Review Payouts';
    } else {
      return 'Set Up Payout';
    }
  }, [worker]);

  const description = React.useMemo(() => {
    const stripeAccountID = worker?.stripe_account_id || '';
    const stripeDetailsSubmitted = worker?.stripe_details_submitted || false;
    const stripeChargesEnabled = worker?.stripe_charges_enabled || false;
    if (stripeAccountID && !stripeDetailsSubmitted) {
      return 'You have not submitted your payout details. Please submit your details to receive payouts.';
    } else if (stripeAccountID && stripeDetailsSubmitted && !stripeChargesEnabled) {
      return 'You have successfully submitted your payout details. We are currently reviewing your account. You will be notified once your account is approved.';
    } else if (stripeAccountID && stripeDetailsSubmitted && stripeChargesEnabled) {
      return 'You have successfully created your account and can now receive payouts. You can expect to be paid for this job within 2 business days of its completion. (EPP jobs are paid on a biweekly basis.)';
    } else {
      return 'You have not yet added a payout method. Please register with Stripe to receive payouts.';
    }
  }, [worker]);

  const handleSetupPayout = React.useCallback(async () => {
    setLoading(true);
    try {
      const res = await apiCamelback.post(`/api/v1/workers/stripe-connect`, {
        refreshUrl: `${window.location.origin}${refreshUrl}`,
        returnUrl: `${window.location.origin}${returnUrl}`,
      });
      if (res.data.url) {
        window.location.href = res.data.url;
      }
    } catch (error) {
      console.log(error);
      toast.error('Error setting up payouts, please try again later');
      setLoading(false);
    }
  }, [refreshUrl, returnUrl]);

  const SetUpPayoutsForProButton = React.useCallback(() => {
    return (
      <Button type='button' onClick={handleSetupPayout} className={classes.button} disabled={loading}>
        {loading && <Spinner animation='border' size='sm' />}
        {btnText}
      </Button>
    );
  }, [btnText, handleSetupPayout, loading]);

  return { description, SetUpPayoutsForProButton };
}
