import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { hasZeroEhsExperience } from 'src/utils/profile-building-check';

import { ADMIN_URL } from '../env';
import { useAppSelector } from '../index';
import { saveRedirectToDeepLink } from '../slices/auth.slice';
import { getUser } from '../slices/user.slice';
import { fetchWorkerById } from '../slices/worker.slice';

export enum ValidateUserState {
  initializing = 'initializing',
  authenticated = 'authenticated',
  unauthenticated = 'unauthenticated',
  error = 'error',
}
const useValidateUser = () => {
  const user = useAppSelector(state => state.user.user);
  const [validateUserState, setValidateUserState] = useState<ValidateUserState>(ValidateUserState.initializing);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    async function fetchUser() {
      try {
        //@ts-ignore TS doesn't recognize the unwrap method
        const userResponse = await dispatch(getUser()).unwrap();
        setValidateUserState(ValidateUserState.authenticated);
        if (userResponse.role === 'worker') {
          //@ts-ignore TS doesn't recognize the unwrap method
          const worker = await dispatch(fetchWorkerById(userResponse.workerId)).unwrap();
          // ehsExperience is a string, therefore we need to check directly to '0', since is not a falsy value.
          if (hasZeroEhsExperience(worker)) {
            navigate('/profile-building');
          }
        }
        if (userResponse.role === 'admin') {
          // forget the user (logout) and replace browser's url with the the birdseye admin webapp
          localStorage.removeItem('token');
          window.location.assign(ADMIN_URL);
        }
      } catch (error: any) {
        if (error.status === 401) {
          const currentUrl = location.pathname + location.search;
          if (currentUrl !== '/') {
            dispatch(saveRedirectToDeepLink(currentUrl));
          }
          setValidateUserState(ValidateUserState.unauthenticated);
          navigate('/');
        } else {
          console.error(error);
          setValidateUserState(ValidateUserState.error);
        }
      }
    }
    fetchUser();
  }, []);

  useEffect(() => {
    if ((user.role ?? '') !== '') {
      setValidateUserState(ValidateUserState.authenticated);
    } else {
      setValidateUserState(ValidateUserState.unauthenticated);
    }
  }, [user]);

  return { validateUserState, user };
};

export default useValidateUser;
