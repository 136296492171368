import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { camelizeKeys, decamelizeKeys } from 'humps';

import api from '../api';

const initialState = {
  error: null,
  isLoading: false,
  data: {},
};

export const createEntry = createAsyncThunk('nonRegistered/createEntry', async ({ type, data }, thunkApi) => {
  try {
    const decamelized = decamelizeKeys(data);
    const response = await api.post(`/api/v1/non_registered_${type}s`, decamelized);

    if (response.data.errors) return thunkApi.rejectWithValue(response.data.errors);

    return camelizeKeys(response.data.data);
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const getDataById = createAsyncThunk('nonRegistered/getDataById', async ({ type, id }, thunkApi) => {
  try {
    const response = await api.get(`/api/v1/non_registered_${type}s`, { params: { id } });
    if (response.data.errors) return thunkApi.rejectWithValue(response.data.errors);

    return camelizeKeys(response.data);
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const getDataByEmail = createAsyncThunk('nonRegistered/getDataByEmail', async ({ type, email }, thunkApi) => {
  try {
    const response = await api.get(`/api/v1/non_registered_${type}s`, { params: { email } });
    if (response.data.errors) return thunkApi.rejectWithValue(response.data.errors);

    return camelizeKeys(response.data);
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const updateEntry = createAsyncThunk('nonRegistered/updateEntry', async ({ type, updates, id }, thunkApi) => {
  try {
    const decamelized = decamelizeKeys(updates);
    const response = await api.put(`/api/v1/non_registered_${type}s/${id}`, decamelized);
    if (response.data.errors) return thunkApi.rejectWithValue(response.data.errors);

    return camelizeKeys(response.data);
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const deleteEntry = createAsyncThunk('nonRegistered/deleteEntry', async ({ type, id }, thunkApi) => {
  try {
    const response = await api.delete(`/api/v1/non_registered_${type}s/${id}`);
    if (response.data.errors) return thunkApi.rejectWithValue(response.data.errors);

    return camelizeKeys(response.data);
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const nonRegisteredSlice = createSlice({
  name: 'nonRegistered',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(createEntry.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createEntry.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(createEntry.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.error = null;
      })

      .addCase(getDataById.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getDataById.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getDataById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.error = null;
      })

      .addCase(getDataByEmail.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getDataByEmail.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getDataByEmail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.error = null;
      })

      .addCase(updateEntry.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateEntry.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(updateEntry.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.error = null;
      })

      .addCase(deleteEntry.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteEntry.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(deleteEntry.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = {};
        state.error = null;
      });
  },
});
