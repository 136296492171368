import React, { useState } from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { Container, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import './invites.scss';

import edit from '../../../assets/icons/edit.png';
import Button from '../../../components/Button';
import { sendEmailInvites } from '../../../slices/referrals.slice';

export default function Invites({ inviteeType }) {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const state = useSelector(state => state);
  const { user } = state.user;
  const dispatch = useDispatch();
  const handleEdit = () => setFocus('optionalMessage');

  const {
    register,
    reset,
    trigger,
    setValue,
    setFocus,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({
    defaultValues: {
      typeOfInviteeOption: inviteeType,
      optionalMessage:
        inviteeType === 'Company'
          ? 'I recommend joining YellowBird to connect with skilled workers to both short and long-term jobs whenever needed.'
          : 'I recommend joining YellowBird to secure more job opportunities!',
      referralCode: user.referralCode,
      userId: user.id,
      invitees: [
        {
          email: '',
          name: '',
        },
      ],
    },
  });

  const handleInputChange = (e, fieldValue) => {
    const currentValue = e.target.value;
    setValue(fieldValue, currentValue);
    trigger(fieldValue);
  };

  const onSubmit = async data => {
    try {
      await dispatch(sendEmailInvites(data)).unwrap();
      reset();
      handleShow();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Container fluid className=''>
      <div className='inputContainerForm'>
        <input
          className='inputMultiStep'
          {...register('invitees.0.name', {
            required: 'Required field.',
            minLength: {
              value: 2,
              message: 'Must be longer than two characters.',
            },
          })}
          onChange={e => handleInputChange(e, 'invitees.0.name')}
          type='text'
          placeholder='Name*'
        />
        <ErrorMessage errors={errors} name='invitees.0.name' render={({ message }) => <p className='errorMessageInputForm'>{message}</p>} />
      </div>
      <div className='inputContainerForm'>
        <input
          className='inputMultiStep'
          {...register('invitees.0.email', {
            required: 'Required field.',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'The email you entered is invalid.',
            },
          })}
          onChange={e => handleInputChange(e, 'invitees.0.email')}
          type='email'
          placeholder='Email Address*'
        />
        <ErrorMessage errors={errors} name='invitees.0.email' render={({ message }) => <p className='errorMessageInputForm'>{message}</p>} />
      </div>
      <div style={{ marginBottom: '8px' }} className='invites-textarea-div'>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <label className='invites-label-textarea'>Optional Message</label>
          <button
            onClick={handleEdit}
            style={{
              border: 'none',
              background: 'none',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            }}
          >
            <img style={{ maxWidth: '20px' }} src={edit} alt='edit' />
            <p style={{ fontSize: '12px', fontWeight: '700' }}>Edit</p>
          </button>
        </div>
        <textarea
          style={{ color: '#808285' }}
          {...register('optionalMessage')}
          label='Optional Message'
          rows='4'
          placeholder='Enter a message if you want'
          className='invites-optional-message'
        />
      </div>
      <button disabled={!isValid} onClick={handleSubmit(onSubmit)} className='navigationButtonForm navigationNext'>
        Submit
      </button>
      <Modal style={{ borderRadius: '12px' }} show={show} onHide={handleClose} centered dialogClassName='border-radius-2'>
        <Modal.Body style={{ border: 'transparent', borderRadius: '12px' }} className='my-4'>
          <span className='d-flex justify-content-center'>Thank you! We love referrals! And to show our</span>
          <span className='d-flex justify-content-center'>gratitude, you will receive {inviteeType === 'Company' ? '$250' : '$20'} for every</span>
          <span className='d-flex justify-content-center'>successful referral.</span>
        </Modal.Body>
        <div className='d-flex justify-content-center mb-5'>
          <Button className='button primary-btn' style={{ fontWeight: 600 }} variant='primary' type='click' onClick={handleClose}>
            Next
          </Button>
        </div>
      </Modal>
    </Container>
  );
}
