import React from 'react';
import { useDispatch } from 'react-redux';

import './forgotPasswordContainer.scss';

import ForgotPasswordForm from './ForgotPasswordForm';

const ForgotPasswordContainer = () => {
  const dispatch = useDispatch();

  return <ForgotPasswordForm dispatch={dispatch} />;
};

export default ForgotPasswordContainer;
