import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { signUpCo } from '../../../../slices/auth.slice';
import { createReferral, retrieveReferrerId } from '../../../../slices/referrals.slice';
import { trackSignupEvent } from '../../../../utils/googleAnalytics';

import './otp.css';

const SubmitOTPButton = ({ isValid }) => {
  const [validateState, setValidateState] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector(state => state);
  const { isLoading: authLoading } = state.auth;
  const { isLoading: refLoading } = state.referrals;
  const { handleSubmit, setError } = useFormContext();

  const onSubmit = async data => {
    setValidateState(true);
    let userCreated = false;
    try {
      await dispatch(signUpCo(data))
        .unwrap()
        .then(res => {
          localStorage.setItem('firstTimeUser', 'true');
          console.log('After executing SignUpCo');
          trackSignupEvent('Company');
          userCreated = true;
        });
      const redirectTo = state.auth.redirectToDeepLink || '/dashboard';
      if ((data.referral.value === 'referralCode' || data.referral.value === 'referralPerson') && userCreated) {
        let body = {
          referralType: data.referral.value === 'referralCode' ? 'referral code' : 'referred',
          refereeEmail: data.emailAddress.toLowerCase(),
          referralCode: data.referral.code,
          refereeUserType: 'Company',
          isReferralFeeEarned: false,
          refereeName: `${data.firstName} ${data.lastName}`,
          dropdownSelection: data.referral.value,
        };
        if (data.referral.value === 'referralCode') {
          let code = body.referralCode;
          const idResult = code ? null : await dispatch(retrieveReferrerId(code)).unwrap();
          body.userId = idResult;
          await dispatch(createReferral(body)).unwrap();
          if (!refLoading && !authLoading) {
            let token = localStorage.getItem('token');
            if (token) {
              navigate(redirectTo);
            }
          }
        } else {
          await dispatch(createReferral(body)).unwrap();
          console.log('Second execution of createReferral');
          if (!refLoading && !authLoading) {
            let token = localStorage.getItem('token');
            if (token) {
              navigate(redirectTo);
            }
          }
        }
      } else {
        console.log('Pushing into congratulations');
        let token = localStorage.getItem('token');
        if (token) {
          navigate(redirectTo);
        }
      }
    } catch (error) {
      console.error(error);
      const errorMessage = error.response?.data?.message || 'An error has occurred. Please contact operations@goyellowbird.com for assistance.';
      setError('otp', { message: errorMessage });
      setValidateState(false);
    }
  };

  return (
    <button className='navigationButtonForm navigationNext' disabled={!isValid || validateState} onClick={handleSubmit(onSubmit)}>
      Validate Code
    </button>
  );
};

export default SubmitOTPButton;
