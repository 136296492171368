import { FormProvider, useForm } from 'react-hook-form';

import { defaultValues } from '../../utils/co-onboarding/default-values';
import MultiStepForm from '../coOnboarding/layout/form';

import './signUpCoOnboarding.css';

import landScape from '../../assets/icons/landscape-logo.png';

const SignUpCoOnboarding = () => {
  const methods = useForm({
    defaultValues: defaultValues,
  });

  return (
    <FormProvider {...methods}>
      <div className='signUpMain'>
        <div>
          <div className='landscapeDiv'>
            <a href='https://goyellowbird.com/' target='_blank' rel='noreferrer'>
              <img className='landscape' src={landScape} alt='' />
            </a>
          </div>
          <div className='containerForm'>
            <MultiStepForm />
          </div>
        </div>
      </div>
    </FormProvider>
  );
};

export default SignUpCoOnboarding;
