import React from 'react';
import { Briefcase, LayoutDashboard, RefreshCcw, Search, User2 } from 'lucide-react';
import { useSelector } from 'react-redux';

import yellowbird from '../../../assets/icons/yellowbird.png';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import CustomNavlink from './navlink';
import PostAJobButton from './postJob';

import './sidebar.scss';

export default function Sidebar({ open, setOpen }) {
  const user = useSelector(state => state.user.user);
  const { isDesktop } = useWindowDimensions();

  return (
    <div
      className={`z-10 sidebar-background${!isDesktop ? '-mobile' : ''} ${!isDesktop && open ? 'sidebar-opened' : ''} ${
        !isDesktop ? (user.role === 'worker' ? 'probackground' : 'cobackground') : ''
      }`}
    >
      <CustomNavlink setOpen={setOpen} icon={<LayoutDashboard className='sidebar-navlink-image' />} path='/dashboard' name='My Dashboard' />
      {user.role === 'worker' ? (
        <CustomNavlink setOpen={setOpen} icon={<User2 className='sidebar-navlink-image' />} path='/my-profile' name='My Profile' />
      ) : (
        <PostAJobButton name={'Post a Job'} icon={<Briefcase />} setOpen={setOpen} />
      )}

      <CustomNavlink setOpen={setOpen} icon={<RefreshCcw className='sidebar-navlink-image' />} path='/my-jobs/current' name='My Jobs' />

      {user.role === 'user' && (
        <CustomNavlink setOpen={setOpen} icon={<Search className='sidebar-navlink-image' />} path='/co-available-services' name='Explore Services' />
      )}
      <div className='sidebar-divider'></div>
      <CustomNavlink setOpen={setOpen} icon='' path='/referrals' name='Refer & Earn' />
      <CustomNavlink setOpen={setOpen} icon='' path='/myaccount' name='Manage Settings' />

      {!isDesktop && (
        <div
          style={{
            marginTop: '20px',
            cursor: 'pointer',
            display: 'flex',
            gap: '8px',
            paddingLeft: '50px',
          }}
        >
          <img style={{ width: '20px' }} src={yellowbird} alt='Yellow Bird icon' />
          <a
            style={{ textDecoration: 'none', color: 'white', fontWeight: '700', fontSize: '14px' }}
            href='https://goyellowbird.com/'
            target='_blank'
            rel='noreferrer'
          >
            goyellowbird.com
          </a>
        </div>
      )}
    </div>
  );
}
