import React from 'react';
import { Container, Row } from 'react-bootstrap';

import PageNotFoundSVG from '../../assets/images/resource.png';

function PageNotFound() {
  return (
    <Container>
      <section className='banner-1 banner-height'>
        <div className='container'>
          <div className='banner-details'>
            <div className='row'>
              <div className='col-md-12'>
                <h1>
                  <center>PAGE NOT FOUND</center>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Row>
        <img className='d-flex justify-content-center mt-4 mb-5' src={PageNotFoundSVG} alt='' />
      </Row>
    </Container>
  );
}

export default PageNotFound;
