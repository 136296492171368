import React from 'react';

import './dropdown.scss';

const Dropdown = ({
  label = null,
  oA,
  disabledLabel = null,
  options,
  extraOption,
  customValue = null,
  customLabel = null,
  disabled = false,
  overrideContainerStyle,
  ...props
}) => (
  <div className='dropdown-input-container' style={overrideContainerStyle || {}}>
    {label ? <label className='dropdown-input__label'>{label}</label> : null}
    <select {...props} className={oA ? 'o-dropdown-input__input' : 'dropdown-input__input'} disabled={disabled}>
      <option key='disabled-select-option' className='placeholder-style' value='' disabled>
        {disabledLabel ? disabledLabel : 'Select'}
      </option>
      {options.map(option => (
        <option key={customValue ? option[customValue] : option.value} value={customValue ? option[customValue] : option.value}>
          {customLabel ? option[customLabel] : option.label}
        </option>
      ))}
      {extraOption ? <option>{extraOption.label}</option> : null}
    </select>
  </div>
);

export default Dropdown;
