import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';

import LeftSVG from '../../assets/images/chevron-left.svg';
import RightSVG from '../../assets/images/right-icon.svg';
import Button from '../../components/Button';
import ShadowedBox from '../../components/Containers/ShadowedBox';
import Loading from '../../components/Loading';
import NewButton from '../../components/NewButton';
import Spacer from '../../components/Spacer';
import Text from '../../components/Text';
import useValidateUser from '../../hooks/useValidateUser';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { getCoReviews, getReviews, selectCoToReview, selectProToReview, submitCoReview, submitReview } from '../../slices/proReviewByCo.slice';
import CompletedReview from './CompletedReview';
import StarButtons from './StarButtons';

const NewReview = () => {
  const {user, validateUserState} = useValidateUser();
  const { width } = useWindowDimensions();
  const isMobile = width < 760;
  const state = useSelector(state => state);
  const { role } = user;
  const { job, worker, company } = state.proReviewByCo.reviewObj;
  const [questions, setQuestions] = useState([]);
  const { isLoading } = state.proReviewByCo;
  const [page, setPage] = useState(0);
  const [showNext, setShowNext] = useState(false);
  const [selected, setSelected] = useState(null);
  const [answers, setAnswers] = useState({});
  const [alreadyReviewed, setAlreadyReviewed] = useState(false);
  const [isReviewable, setIsReviewable] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);
  const [completedReview, setCompletedReview] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { jobId } = useParams();
  const userId = user.id;


  useEffect(() => {
    (async () => {
      const { user } = state.user;
      const { role, workerId, companyId } = user;

      const getReviewsResponseHandler = (reviewsResponse, checkId, setReviewable, setAlreadyReviewed) => {
        reviewsResponse.forEach(review => {
          if (review.jobId === jobId && review[checkId] === user[checkId]) {
            setReviewable(true);
            setAlreadyReviewed(true);
            let parsedData = { ...review, payload: JSON.parse(review.payload) };
            setCompletedReview(parsedData);
          }
        });
      };

      if (role === 'worker') {
        const [selectCoToReviewResponse, getCoReviewsResponse] = await Promise.all([
          dispatch(selectCoToReview(jobId)).unwrap(),
          dispatch(getCoReviews(workerId)).unwrap(),
        ]);

        if (selectCoToReviewResponse.worker.id === workerId) {
          setIsReviewable(true);
        }

        getReviewsResponseHandler(getCoReviewsResponse, 'workerId', setIsReviewable, setAlreadyReviewed);
      } else {
        const [selectProToReviewResponse, getReviewsResponse] = await Promise.all([
          dispatch(selectProToReview(jobId)).unwrap(),
          dispatch(getReviews(companyId)).unwrap(),
        ]);

        if (selectProToReviewResponse.company.id === companyId) {
          setIsReviewable(true);
        }

        getReviewsResponseHandler(getReviewsResponse, 'companyId', setIsReviewable, setAlreadyReviewed);
      }
    })();
  }, [jobId]);

  useEffect(() => {
    if (state.user.user.role === 'worker' && job && company && !isLoading) {
      setQuestions([
        {
          id: 1,
          question: 'How was communication and scheduling with the ' + company.name + ' Point of Contact?',
          answerType: 'buttons',
          quantity: 5,
          required: true,
        },
        { id: 2, question: 'Was the scope of work at ' + company.name + ' as expected and described?', answerType: 'buttons', quantity: 5, required: true },
        {
          id: 3,
          question: 'Regarding the work I performed as a YellowBird Professional, I anticipate ' + company.name + "'s level of satisfaction will be",
          answerType: 'buttons',
          quantity: 5,
          required: true,
        },
        {
          id: 4,
          question: 'Given the opportunity, I would entertain additional projects with ' + company.name,
          answerType: 'textButtons',
          quantity: 2,
          required: true,
          options: [
            {
              text: 'I welcome the opportunity',
              value: 'I welcome the opportunity',
            },
            {
              text: 'I prefer to pass',
              value: 'I prefer to pass',
            },
            // {
            //   text: 'Other',
            //   value: 'Other',
            // }
          ],
        },
        {
          id: 5,
          question: 'As a YellowBird Professional, I would recommend the following training or services as future engagements for ' + company.name,
          answerType: 'text',
          quantity: 0,
          required: false,
        },
        {
          id: 6,
          question:
            'We want to know! Please share any feedback you may have. This can include the process, tools, or anything else related to your experience with YellowBird.',
          subText: 'This can include the process, tools, or anything else related to your experience with YellowBird.',
          answerType: 'text',
          quantity: 0,
          required: false,
        },
      ]);
    } else if (state.user.user.role === 'user' && job && worker) {
      setQuestions([
        { id: 1, question: 'Scheduling and Punctuality', answerType: 'buttons', quantity: 5, required: true },
        { id: 2, question: 'Preparedness For Job', answerType: 'buttons', quantity: 5, required: true },
        { id: 3, question: 'Communication Effectiveness', answerType: 'buttons', quantity: 5, required: true },
        { id: 4, question: 'Knowledge Of Job Performed', answerType: 'buttons', quantity: 5, required: true },
        {
          id: 5,
          question:
            '(Optional) Leave any additional comments about your experience with ' +
            worker.firstName +
            ' and Yellowbird here. \nThis will not be visible to anyone besides the Yellowbird team.',
          answerType: 'text',
          quantity: 0,
          required: false,
        },
        {
          id: 6,
          question: 'Did we earn your future business? Please share ' + company.name + "'s thoughts on using YellowBird services in the future",
          answerType: 'textButtons',
          quantity: 3,
          required: true,
          options: [
            {
              text: 'Absolutely',
              value: 'Absolutely',
            },
            {
              text: 'Not Sure Yet',
              value: 'Not Sure Yet',
            },
            {
              text: 'Sorry, Not Likely',
              value: 'Sorry, Not Likely',
            },
          ],
        },
        {
          id: 7,
          question: 'How can YellowBird best support ' + company.name + ' over the next 180 days?',
          answerType: 'textButtons',
          quantity: 7,
          required: true,
          options: [
            {
              text: 'Industrial Hygiene Assessments',
              value: 'Industrial Hygiene Assessments',
            },
            {
              text: 'OSHA Training',
              value: 'OSHA Training',
            },
            {
              text: 'EHS Training (ex: Fall Prevention, CPR & First Aid, Forklift)',
              value: 'EHS Training (ex: Fall Prevention, CPR & First Aid, Forklift)',
            },
            {
              text: 'Workplace Safety & Mock OSHA Inspection',
              value: 'Workplace Safety & Mock OSHA Inspection',
            },
            {
              text: 'Policy Development',
              value: 'Policy Development',
            },
            {
              text: 'Onsite Safety Consultation',
              value: 'Onsite Safety Consultation',
            },
            {
              text: 'Loss Control Audits',
              value: 'Loss Control Audits',
            },
          ],
        },
      ]);
    }
  }, [job, company, isLoading, state.user.user, worker]);

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      width: '100%',
      height: '100%',
      alignItems: 'center',
      paddingTop: 48,
      paddingBottom: 48,
      // borderStyle: 'dashed'
    },
    iconDiv: {
      margin: 40,
      cursor: 'pointer',
    },
    icon: {
      width: 16,
    },
  };
  const onFinish = async e => {
    let scoresArr = [];
    for (const property in answers) {
      if (!isNaN(parseInt(answers[property].a))) {
        scoresArr.push(parseInt(answers[property].a));
      }
    }
    const rating = (await scoresArr.reduce((a, b) => a + b)) / scoresArr.length;

    const parsedAnswers = JSON.stringify(answers);
    let review = {
      jobId: job.id,
      coId: company.id,
      proId: worker.id,
      payload: parsedAnswers,
      rating,
      userId,
      jobName: job.name,
      proName: `${worker.firstName} ${worker.lastName}`,
      coName: company.name,
    };
    if (role === 'user') {
      dispatch(submitReview(review)).then(res => {
        setShowThankYou(true);
      });
    } else if (role === 'worker') {
      dispatch(submitCoReview(review)).then(res => {
        setShowThankYou(true);
      });
    }
  };

  if (isLoading  || validateUserState === 'initializing' || !job) return <Loading />;

  if (alreadyReviewed) return <CompletedReview review={completedReview} />;

  return (
    <div style={styles.container}>
      {!isMobile ? <Spacer h={176} /> : null}
      <div style={{ textAlign: 'center', width: isMobile ? '90%' : '100%' }}>
        <Text notBoldHeading>Review of {role === 'user' ? worker.firstName + ' ' + worker.lastName : company.name}</Text>
        <Spacer h={24} />
        <Text heading>{`For Job: ${job.name}`.toUpperCase()}</Text>
        <Spacer h={38} />
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
        {page !== 0 && questions[page].answerType !== 'buttons' ? (
          <div onClick={() => setPage(page - 1)} style={styles.iconDiv}>
            <img style={styles.icon} src={LeftSVG} alt='left chevron' />
          </div>
        ) : (
          <Spacer w={72} />
        )}
        <ShadowedBox containerStyle={{ width: '60%', alignItems: 'center' }} style={{ width: '100%' }}>
          <div style={{ width: '100%' }}>
            {questions.length > 0 ? (
              <StarButtons
                showNext={showNext}
                setShowNext={setShowNext}
                questions={questions}
                onFinish={onFinish}
                answers={answers}
                setAnswers={setAnswers}
                selected={selected}
                setSelected={setSelected}
                page={page}
                setPage={setPage}
                question={questions[page]}
                questionLength={questions.length - 1}
              />
            ) : null}
          </div>
        </ShadowedBox>
        {(showNext && page < questions.length - 1) || (questions[page]?.required === false && page < questions.length - 1) ? (
          <div
            tabIndex={0}
            onKeyDown={e => {
              if (e.key === 'Enter' || e.key === ' ') {
                if (page < questions.length - 1) {
                  setPage(page + 1);
                  setShowNext(false);
                } else {
                  onFinish();
                }
              }
            }}
            onClick={
              page < questions.length - 1
                ? () => {
                    setPage(page + 1);
                    setShowNext(false);
                  }
                : onFinish
            }
            style={styles.iconDiv}
          >
            <img style={styles.icon} src={RightSVG} alt='right icon' />
          </div>
        ) : (
          <Spacer w={72} />
        )}
      </div>
      <div style={{ width: '50%' }}>
        {showNext && page === questions.length - 1 ? (
          <div style={{ display: 'flex', width: '100%', justifyContent: 'center', marginTop: 48 }}>
            <NewButton main onClick={onFinish} label='Finish' />
          </div>
        ) : (
          <Spacer w={72} />
        )}
      </div>
      <Modal contentClassName='roundBorder' show={showThankYou} centered onHide={() => setShowThankYou(!showThankYou)}>
        <Modal.Body>
          <div style={{ textAlign: 'center', padding: 24 }}>
            <Text>
              Thank You! We love referrals! And to show our gratitude you will receive $250 for every successful COMPANY referral, and $20 for every successful
              PROFESSIONAL referral!
            </Text>
            <Spacer h={24} />
            <Button
              className={`button primary-btn mt-3 py-2 justify-content-center ${width >= 768 ? 'main-button-desktop mx-5' : 'main-button-mobile mx-2'}`}
              onClick={() => navigate('/referrals')}
            >
              <Text buttonText>Next</Text>
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default NewReview;
