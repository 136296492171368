import { Fragment, useContext } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDown } from 'lucide-react';
import { Controller, useFormContext } from 'react-hook-form';

import './step-3.css';

import { industry, size } from '../../../../utils/co-onboarding/select-options';
import ConditionedInput from '../../conditionedInput';
import { MethodsContext } from '../../context';
import Navigation from '../../layout/navigation';

const Step3 = () => {
  const {
    register,
    control,
    formState: { isValid, errors },
    trigger,
    watch,
  } = useFormContext();
  const { handleInputChange } = useContext(MethodsContext);
  const industryValue = watch('industry');

  return (
    <div className='multiStepContainer'>
      <div className='mainContainer1'>
        <h1 className='h1MultiStepForm'>
          Tell us <br /> about your organization.
        </h1>
        <div className='inputContainerForm'>
          <input
            {...register('organizationName', { required: 'This field is required' })}
            className='inputMultiStep'
            type='text'
            placeholder='Organization Name*'
            onChange={e => handleInputChange(e, 'organizationName')}
          />
          <p className='errorMessageInputForm'>{errors.organizationName?.message?.toString()}</p>
        </div>
        <Controller
          name='organizationSize'
          control={control}
          defaultValue={''}
          rules={{ required: 'Required field.' }}
          render={({ field }) => {
            return (
              <Listbox {...field}>
                <div className='dropDownForm'>
                  <Listbox.Button className='dropDownButton'>
                    {field.value ? (
                      <span className={`activeField insideContainer ${field.value === '5,001-10,000 employees' && 'resizeStep3'}`}>{field.value}</span>
                    ) : (
                      <span className='insideContainer'>Organization Size*</span>
                    )}
                    <ChevronDown className='chevrowDownForm' aria-hidden='true' />
                  </Listbox.Button>
                  <p className='errorMessageInputForm'>{errors.organizationSize?.message?.toString()}</p>
                  <Transition
                    as={Fragment}
                    enter='transitio ease-in duration-150'
                    leave='transition ease-in duration-100'
                    enterFrom='opacity-0'
                    leaveFrom='opacity-0'
                    leaveTo='opacity-0'
                  >
                    <Listbox.Options className='optionsDropDown' onBlur={() => trigger('organizationSize')}>
                      {size.map(option => (
                        <Listbox.Option className={({ active }) => `optionField ${active ? 'activeField' : 'unactiveField'}`} value={option}>
                          {({ selected }) => (
                            <>
                              {selected ? (
                                <span className='checkIconStep3'>
                                  <CheckIcon className='checkIconInside' aria-hidden='true' />
                                </span>
                              ) : null}
                              <span className={`valueDropDown ${selected ? 'font-medium' : 'font-normal'}`}>{option}</span>
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Listbox>
            );
          }}
        />
        <Controller
          name='industry'
          control={control}
          defaultValue={''}
          rules={{ required: 'Required field.' }}
          render={({ field }) => {
            return (
              <Listbox {...field}>
                <div className='dropDownForm'>
                  <Listbox.Button className='dropDownButton'>
                    {field.value ? (
                      <span
                        className={`activeField insideContainer ${(field.value === 'Commercial Real Estate' || field.value === 'Hospital & Health Care') && 'resizeStep3'}`}
                      >
                        {field.value}
                      </span>
                    ) : (
                      <span className='insideContainer'>Industry*</span>
                    )}
                    <ChevronDown className='chevrowDownForm' aria-hidden='true' />
                  </Listbox.Button>
                  <p className='errorMessageInputForm'>{errors.industry?.message?.toString()}</p>
                  <Transition
                    as={Fragment}
                    enter='transitio ease-in duration-150'
                    leave='transition ease-in duration-100'
                    enterFrom='opacity-0'
                    leaveFrom='opacity-0'
                    leaveTo='opacity-0'
                  >
                    <Listbox.Options className='optionsDropDown' onBlur={() => trigger('industry')}>
                      {industry.map(option => (
                        <Listbox.Option className={({ active }) => `optionField ${active ? 'activeField' : 'unactiveField'}`} value={option}>
                          {({ selected }) => (
                            <>
                              {selected ? (
                                <span className='checkIconStep3'>
                                  <CheckIcon className='checkIconInside' aria-hidden='true' />
                                </span>
                              ) : null}
                              <span className={`valueDropDown ${selected ? 'font-medium' : 'font-normal'}`}>{option}</span>
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Listbox>
            );
          }}
        />
        {industryValue === 'Other' && (
          <ConditionedInput fieldValue='otherIndustry' error={errors.otherIndustry?.message?.toString()} placeholder='Please indicate industry*' />
        )}
      </div>
      <Navigation onBack disabled={isValid} />
    </div>
  );
};

export default Step3;
