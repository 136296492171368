import React, { useEffect, useMemo, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Col, Row } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TextareaAutosize from 'react-textarea-autosize';

import DiskSVG from '../../../assets/images/disk-icon.svg';
import CoReportDownloader from '../../../components/CoReportDownloader';
import Modal from '../../../components/Modal';
import NewButton from '../../../components/NewButton';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { checkDocsForPro } from '../../../slices/document.slice';
import { copyJob, coRetrieveJobs, getWorkerForJob, markJobCompleteAsCo, updateJobDetails } from '../../../slices/job.slice';
import CoDocUploader from '../../OldCreateJob/CoDocUploader';
import ConfirmModal from '../ConfirmModal';
import BannerModal from './BannerModal';

const styles = {
  textInputContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '19.5px',
  },
  textInputLabel: {
    fontSize: '15px',
    whiteSpace: 'nowrap',
    marginBottom: '19.5px',
    fontWeight: 700,
  },
  textInputInput: {
    borderRadius: 0,
    color: '#586F7C',
    outline: 'none',
    border: '1px solid #b4b4b4aa',
    borderLeft: 'none',
    borderRight: 'none',
    borderTop: 'none',
    fontWeight: 400,
    boxShadow: 'none',
    background: 'transparent',
    paddingLeft: 0,
    paddingBottom: '16.5px',
    fontSize: '1rem',
    resize: 'none',
    overflowY: 'hidden',
  },
  radioLabelText: {
    marginBottom: '16.5px',
    fontSize: '18px',
  },
};

export default function CompanyJob() {
  const dispatch = useDispatch();
  const { reports, isLoading, coTemplates } = useSelector(state => state.document);
  const { reviews } = useSelector(state => state.proReviewByCo);
  const job = useSelector(state => state.job.jobOrder);
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const { promoBanner: promoBannerFlag } = useFlags();

  const [selectedOption, setSelectedOption] = useState(null);
  const [docsToUse, setDocsToUse] = useState([]);
  const [displayConfirmModal, setDisplayConfirmModal] = useState(false);
  const [reasonToDecline, setReasonToDecline] = useState('');
  const [workerForJob, setWorkerForJob] = useState();
  const [isBannerModalOpen, setIsBannerModalOpen] = useState(false);

  useEffect(() => {
    if (
      job.status !== 'Posted' &&
      job.status !== 'Closed-No Response' &&
      job.status !== 'Closed-Lost' &&
      job.status !== 'Negotiating' &&
      job.status !== 'Approved'
    ) {
      dispatch(getWorkerForJob(job.id)).then(res => {
        let fullName = res.payload?.firstName + ' ' + res.payload?.lastName;
        setWorkerForJob(fullName);
      });
    }
  }, [job.id, job.status, dispatch]);

  function companyAcceptMarkJobAsCompleted() {
    // TODO call api to update job status
    setDisplayConfirmModal(false);
    if (selectedOption === 'Please confirm you want to accept this job as complete') {
      async function allPromises() {
        try {
          const deadline = job.deadline;

          if (!deadline) {
            await dispatch(updateJobDetails({ jobId: job.id, deadline: job.startDate })).unwrap();
          }
          await dispatch(
            markJobCompleteAsCo({
              jobId: job.id,
              status: 5,
              completedFromPro: job.completedFromPro,
              completedFromCo: true,
              reasonToDeclineCompletion: null,
            })
          ).unwrap();
          if (!deadline) {
            await dispatch(updateJobDetails({ jobId: job.id, deadline: 'clear' })).unwrap();
          }
        } catch (error) {
          throw error;
        }
      }

      toast.promise(allPromises(), {
        loading: 'Accepting job as complete...',
        success: () => {
          if (promoBannerFlag && promoBannerFlag.enabled) {
            setIsBannerModalOpen(true);
          } else {
            dispatch(coRetrieveJobs());
            onClickReview();
          }
          return 'You have marked this job as completed!';
        },
        error: error => {
          console.error(error);
          return 'Error accepting job as complete';
        },
      });
    } else {
      async function allPromises() {
        try {
          const deadline = job.deadline;

          if (!deadline) {
            await dispatch(updateJobDetails({ jobId: job.id, deadline: job.startDate })).unwrap();
          }
          await dispatch(
            markJobCompleteAsCo({
              jobId: job.id,
              status: 4,
              completedFromPro: false,
              completedFromCo: false,
              reasonToDeclineCompletion: reasonToDecline,
            })
          ).unwrap();
          if (!deadline) {
            await dispatch(updateJobDetails({ jobId: job.id, deadline: 'clear' })).unwrap();
          }
        } catch (error) {
          throw error;
        }
      }

      toast.promise(allPromises(), {
        loading: 'Rejecting job as complete...',
        success: () => {
          dispatch(coRetrieveJobs());
          return 'You have rejected completion of this job!';
        },
        error: error => {
          console.error(error);
          return 'Error rejecting job as complete';
        },
      });
    }
  }

  const isReviewed = useMemo(() => {
    return reviews && reviews.some(item => item.jobId === job.id);
  }, [reviews, job.id]);

  const showReview = useMemo(() => {
    return job.status === 'Completed' || job.completedFromPro || job.status === 'Closed-Paid' || job.status === "Closed-Didn't Complete";
  }, [job.status, job.completedFromPro]);

  function onClickReview() {
    navigate(`new-review/${job.id}`);
  }

  async function onClickCopy() {
    try {
      await dispatch(copyJob(job)).unwrap();
      toast.success('Job copied successfully');
      navigate('/dashboard/?action=post-a-job&copy=true');
    } catch (error) {
      toast.error('Error copying job');
    }
  }

  async function onClickAcceptPromo() {
    navigate(`/dashboard?action=post-a-job&parentId=${promoBannerFlag.promo_parent_id}&childId=${promoBannerFlag.promo_children_id}`);
  }

  useEffect(() => {
    dispatch(checkDocsForPro({ jobId: job.id }));
  }, [job.id, dispatch]);

  return (
    <>
      {width < 768 ? (
        <>
          {/* TOP BUTTONS */}
          <Row className='mt-5'>
            <Col className='d-flex flex-column gap-3'>
              {showReview ? (
                <NewButton h={40} w='100%' blue={isReviewed} main={!isReviewed} label={isReviewed ? 'View Review' : 'Write Review'} onClick={onClickReview} />
              ) : null}

              <NewButton onClick={onClickCopy} blue w='100%' label='Copy and Post Again'>
                <img style={{ width: 16, marginRight: 12 }} src={DiskSVG} alt='disk' />
              </NewButton>
            </Col>
          </Row>
          <Row>
            <div style={styles.textInputContainer}>
              <label htmlFor='typeOfWork' style={styles.textInputLabel}>
                Type of Work To Be Done
              </label>
              <TextareaAutosize id='typeOfWork' value={job.typeOfWork} className='form-control' style={styles.textInputInput} />
            </div>
          </Row>
          <Row>
            <div style={styles.textInputContainer}>
              <label htmlFor='pointOfContact' style={styles.textInputLabel}>
                Point of Contact
              </label>
              <TextareaAutosize
                id='pointOfContact'
                value={`${job.pocFirstName} ${job.pocLastName} \n${job.pocPhone} \n${job.pocEmail}`}
                className='form-control'
                rows={3}
                style={styles.textInputInput}
              />
            </div>
          </Row>
          {job.schedule && (
            <Row>
              <div style={styles.textInputContainer}>
                <label htmlFor='schedule' style={styles.textInputLabel}>
                  Schedule
                </label>
                <TextareaAutosize id='schedule' value={job.schedule} className='form-control' style={styles.textInputInput} />
              </div>
            </Row>
          )}
          <Row>
            <div style={styles.textInputContainer}>
              <label style={styles.textInputLabel}>Job Site</label>
              <>
                {job.commuteType !== null ? (
                  <>
                    {job.commuteType.toLowerCase() === 'virtual' ? (
                      <>
                        <TextareaAutosize value='This is a Virtual Job' className='form-control text-nowrap' rows={1} style={styles.textInputInput} />
                      </>
                    ) : (
                      <>
                        <TextareaAutosize
                          value={`${job.siteAddress || null}, ${job.siteCity || null}, ${job.siteState || null} ${job.siteZip || null}`}
                          className='form-control text-nowrap'
                          style={styles.textInputInput}
                        />
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <TextareaAutosize
                      value={`${job.siteAddress || null}, ${job.siteCity || null},${job.siteState || null} ${job.siteZip || null}`}
                      className='form-control text-nowrap'
                      style={styles.textInputInput}
                    />
                  </>
                )}
              </>
            </div>
          </Row>
          <Row>
            <div style={styles.textInputContainer}>
              <label htmlFor='schedule' style={styles.textInputLabel}>
                Schedule
              </label>
              <TextareaAutosize id='schedule' value={job.schedule || 'Not Specified'} className='form-control' style={styles.textInputInput} />
            </div>
          </Row>
          <Row>
            <div style={styles.textInputContainer}>
              <label htmlFor='approvedHours' style={styles.textInputLabel}>
                Total Approved Hours
              </label>
              <TextareaAutosize id='approvedHours' value={job.totalHours} className='form-control' rows={1} style={styles.textInputInput} disabled />
            </div>
          </Row>
          <Row className='d-flex justify-content-between mt-5'>
            <h6 style={{ color: '#586f7c', fontWeight: 700 }}>Compensation</h6>
          </Row>
          <Row>
            <div style={styles.textInputContainer}>
              <label htmlFor='pay' style={styles.textInputLabel}>
                Pay
              </label>
              <textarea
                id='pay'
                value={`$${job.coHourlyRateCents / 100} ${job.priceType || ''}`}
                className='form-control'
                rows={1}
                readOnly
                style={styles.textInputInput}
              />
            </div>
          </Row>
          {job.perDiem && (
            <Row>
              <div style={styles.textInputContainer}>
                <label htmlFor='perDiem' style={styles.textInputLabel}>
                  Per Diem
                </label>
                <textarea
                  id='perDiem'
                  value={job.perDiem || 'Not Specified'}
                  className='form-control'
                  rows={1}
                  style={styles.textInputInput}
                  disabled
                  readOnly
                />
              </div>
            </Row>
          )}
          {job.additionalCompensation && (
            <Row>
              <div style={styles.textInputContainer}>
                <label htmlFor='premiumPay' style={styles.textInputLabel}>
                  Premium Pay
                </label>
                <TextareaAutosize
                  id='premiumPay'
                  value={job.additionalCompensation || 'Not Specified'}
                  className='form-control'
                  rows={1}
                  style={styles.textInputInput}
                  disabled
                  readOnly
                />
              </div>
            </Row>
          )}
          {job.mileageReimbursement && (
            <Row>
              <div style={styles.textInputContainer}>
                <label htmlFor='mileageReimbursement' style={styles.textInputLabel}>
                  Mileage Reimbursement
                </label>
                <TextareaAutosize
                  id='mileageReimbursement'
                  value={job.mileageReimbursement || 'Not Specified'}
                  className='form-control'
                  style={styles.textInputInput}
                  disabled
                />
              </div>
            </Row>
          )}
          <Row>
            <div style={styles.textInputContainer}>
              <label htmlFor='totalAmount' style={styles.textInputLabel}>
                Total Amount
              </label>
              <textarea
                id='totalAmount'
                value={`$${job.estimatedTotalCents / 100 || ''}`}
                className='form-control'
                rows={1}
                style={styles.textInputInput}
                disabled
                readOnly
              />
            </div>
          </Row>

          <Row className='d-flex justify-content-between mt-5'>
            <h6 style={{ color: '#586f7c', fontWeight: 700 }}>Templates and Deliverables</h6>
          </Row>

          <Row style={styles.textInputContainer}>
            <div style={styles.textInputLabel}>Deliverable Required</div>
            <div className='d-flex flex-row'>
              <div className='me-3'>
                <label htmlFor='deliverableYes' style={styles.radioLabelText}>
                  Yes
                </label>
                <input id='deliverableYes' readOnly={true} type='radio' name='option7' checked={job.deliverableRequired} disabled />
              </div>
              <div>
                <label htmlFor='deliverableNo' style={styles.radioLabelText}>
                  No
                </label>
                <input id='deliverableNo' readOnly={true} type='radio' name='option7' checked={!job.deliverableRequired} disabled />
              </div>
            </div>
            <hr className='mt-1' />
          </Row>
          <Row>
            <div style={styles.textInputLabel}>Will you be supplying a template?</div>
            <div className='d-flex flex-row'>
              <div className='me-3'>
                <label htmlFor='templateYes' style={styles.radioLabelText}>
                  Yes
                </label>
                <input
                  id='templateYes'
                  readOnly={true}
                  type='radio'
                  name='option8'
                  checked={docsToUse?.length !== 0 || job.templateRequired || job.coAdditionalContent}
                  disabled
                />
              </div>
              <div>
                <label htmlFor='templateNo' style={styles.radioLabelText}>
                  No
                </label>
                <input
                  id='templateNo'
                  readOnly={true}
                  type='radio'
                  name='option8'
                  checked={docsToUse?.length === 0 && !job.templateRequired && !job.coAdditionalContent}
                  disabled
                />
              </div>
            </div>
            <hr className='mt-1' />
          </Row>
          {job.deliverableRequired && (
            <Row>
              <div style={styles.textInputContainer}>
                <label htmlFor='deliverableType' style={styles.textInputLabel}>
                  Deliverable Type
                </label>
                <textarea id='deliverableType' value={job.deliverableType} className='form-control' rows={1} style={styles.textInputInput} disabled readOnly />
              </div>
            </Row>
          )}
          {job.deliverablesWithDueDate && (
            <Row>
              <div style={styles.textInputContainer}>
                <label htmlFor='deliverableDueDate' style={styles.textInputLabel}>
                  Deliverable Due Date
                </label>
                <textarea
                  id='deliverableDueDate'
                  value={job.deliverablesWithDueDate || 'Not Specified'}
                  className='form-control'
                  rows={1}
                  style={styles.textInputInput}
                  readOnly
                />
              </div>
            </Row>
          )}
          <Row>
            <Col xs={12} style={styles.textInputContainer}>
              <CoDocUploader jobId={job.id} coTemplates={coTemplates} detailView docsToUse={docsToUse} setDocsToUse={setDocsToUse} />
            </Col>
            {isLoading ? null : (
              <>
                {reports.length > 0 ? (
                  <>
                    <Col xs={12}>
                      <div style={styles.textInputContainer}>
                        <label htmlFor='deliverableTemplate' style={styles.textInputLabel}>
                          Download Finished Report or Deliverable
                        </label>
                        <TextareaAutosize
                          id='deliverableTemplate'
                          value='Download finished deliverables below'
                          className='form-control'
                          style={styles.textInputInput}
                          disabled
                        />
                        <hr className='mt-1' />
                      </div>
                    </Col>
                    <Col xs={12}>
                      <CoReportDownloader jobId={job.id} />
                    </Col>
                  </>
                ) : null}
              </>
            )}
          </Row>
          <Row>
            {job.completedFromPro && (
              <div style={{ width: '100%' }}>
                {job.completedFromCo ? (
                  <h5 style={{ fontWeight: 700, textAlign: 'center' }}>You have Accepted Completion of This Job</h5>
                ) : (
                  <div className='d-flex flex-column justify-content-between gap-3' style={{ fontWeight: 700 }}>
                    <NewButton
                      main
                      w='100%'
                      h={50}
                      style={{ fontWeight: 700 }}
                      onClick={() => {
                        setDisplayConfirmModal(true);
                        setSelectedOption('Please confirm you want to accept this job as complete');
                      }}
                    >
                      Accept Completion
                    </NewButton>
                    <NewButton
                      blue
                      w='100%'
                      h={50}
                      style={{ fontWeight: 700 }}
                      onClick={() => {
                        setDisplayConfirmModal(true);
                        setSelectedOption('Are you sure you want to reject the Pro “Marked as Complete” status?');
                      }}
                    >
                      Reject Completion
                    </NewButton>
                  </div>
                )}
              </div>
            )}
          </Row>
        </>
      ) : (
        <Row>
          <Col>
            <Row className='d-flex justify-content-between mt-5'>
              <Col md={5}>
                <NewButton
                  onClick={() => navigate(`/job/order-review/${job.referenceNumber}`)}
                  blue={job.status !== 'Posted'}
                  main={job.status === 'Posted'}
                  h={40}
                  w='fit-content'
                  label='Job Order'
                />
              </Col>
              <Col md={2}></Col>
              <Col md={5}>
                <div className='d-flex align-items-center justify-content-between'>
                  {showReview ? (
                    <NewButton
                      w='100%'
                      h={40}
                      blue={isReviewed}
                      main={!isReviewed}
                      label={isReviewed ? 'View Review' : 'Write Review'}
                      onClick={onClickReview}
                    />
                  ) : null}
                  <NewButton onClick={onClickCopy} h={40} w={'100%'} blue label='Copy Job'></NewButton>
                </div>
              </Col>
            </Row>
            <Row className='mt-5'>
              <h6 style={{ color: '#586f7c', fontWeight: 700 }}>Job Details</h6>
            </Row>
            <Row>
              <div style={styles.textInputContainer}>
                <label htmlFor='typeOfWork' style={styles.textInputLabel}>
                  Type of Work To Be Done
                </label>
                <TextareaAutosize id='typeOfWork' value={job.typeOfWork || job.typeOfWork} className='form-control' style={styles.textInputInput} disabled />
              </div>
              <div style={styles.textInputContainer}>
                <label htmlFor='pointOfContact' style={styles.textInputLabel}>
                  Point of Contact
                </label>
                <TextareaAutosize
                  id='pointOfContact'
                  value={`${job.pocFirstName} ${job.pocLastName} \n${job.pocPhone} \n${job.pocEmail}`}
                  className='form-control'
                  rows={3}
                  style={styles.textInputInput}
                  disabled
                />
              </div>

              <div style={styles.textInputContainer}>
                <label style={styles.textInputLabel}>Job Site</label>
                {job.commuteType !== null ? (
                  <>
                    {job.commuteType.toLowerCase() === 'virtual' ? (
                      <>
                        <TextareaAutosize value='This is a Virtual Job' className='form-control text-nowrap' rows={1} style={styles.textInputInput} />
                      </>
                    ) : (
                      <>
                        <TextareaAutosize
                          rows={2}
                          value={`${job.siteAddress || null}, ${job.siteCity || null}, ${job.siteState || null} ${job.siteZip || null}`}
                          className='form-control'
                          style={styles.textInputInput}
                        />
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <TextareaAutosize
                      value={`${job.siteAddress || null}, ${job.siteCity || null},${job.siteState || null} ${job.siteZip || null}`}
                      rows={2}
                      className='form-control'
                      style={styles.textInputInput}
                    />
                  </>
                )}
              </div>
              <div style={styles.textInputContainer}>
                <label htmlFor='schedule' style={styles.textInputLabel}>
                  Schedule
                </label>
                <TextareaAutosize id='schedule' value={job.schedule || 'Not Specified'} className='form-control' style={styles.textInputInput} disabled />
              </div>
              <div style={styles.textInputContainer}>
                <label htmlFor='approvedHours' style={styles.textInputLabel}>
                  Total Approved Hours
                </label>
                <textarea id='approvedHours' value={job.totalHours} className='form-control' rows={1} style={styles.textInputInput} disabled readOnly />
              </div>
              {workerForJob ? (
                <div style={styles.textInputContainer}>
                  <label htmlFor='ehsProRequest' style={styles.textInputLabel}>
                    Pro that accepted:
                  </label>
                  <textarea id='ehsProRequest' value={workerForJob} className='form-control' rows={1} style={styles.textInputInput} disabled readOnly />
                </div>
              ) : null}
            </Row>

            <Row className='mb-2 mt-5'>
              <h6 style={{ color: '#586f7c', fontWeight: 700 }}>Compensation</h6>
            </Row>
            <Row>
              <div style={styles.textInputContainer}>
                <label htmlFor='pay' style={styles.textInputLabel}>
                  Pay
                </label>
                <textarea
                  id='pay'
                  value={`$${job.coHourlyRateCents / 100} ${job.priceType || ''}`}
                  className='form-control'
                  rows={1}
                  style={styles.textInputInput}
                  disabled
                  readOnly
                />
              </div>
              <div style={styles.textInputContainer}>
                <label htmlFor='totalAmount' style={styles.textInputLabel}>
                  Total Amount
                </label>
                <textarea
                  id='totalAmount'
                  value={`$${job.estimatedTotalCents / 100 || ''}`}
                  className='form-control'
                  rows={1}
                  style={styles.textInputInput}
                  disabled
                  readOnly
                />
              </div>
              {job.perDiem ? (
                <div style={styles.textInputContainer}>
                  <label htmlFor='perDiem' style={styles.textInputLabel}>
                    Per Diem
                  </label>
                  <textarea
                    id='perDiem'
                    value={job.perDiem || 'Not Specified'}
                    className='form-control'
                    rows={1}
                    style={{ ...styles.textInputInput, visibility: job.perDiem ? 'visible' : 'hidden' }}
                    disabled
                    readOnly
                  />
                </div>
              ) : null}
              {job.additionalCompensation ? (
                <div style={styles.textInputContainer}>
                  <label htmlFor='premiumPay' style={styles.textInputLabel}>
                    Premium Pay
                  </label>
                  <TextareaAutosize
                    id='premiumPay'
                    value={job.additionalCompensation || 'Not Specified'}
                    className='form-control'
                    style={{ ...styles.textInputInput, visibility: job.perDiem ? 'visible' : 'hidden', whiteSpace: 'nowrap' }}
                    disabled
                    readOnly
                  />
                </div>
              ) : null}

              {job.mileageReimbursement && (
                <div style={styles.textInputContainer}>
                  <label htmlFor='mileageReimbursement' style={styles.textInputLabel}>
                    Mileage Reimbursement
                  </label>
                  <TextareaAutosize
                    id='mileageReimbursement'
                    value={job.mileageReimbursement || 'Not Specified'}
                    className='form-control'
                    style={styles.textInputInput}
                    disabled
                    readOnly
                  />
                </div>
              )}
            </Row>

            <Row className='mb-2 mt-5'>
              <h6 style={{ color: '#586f7c', fontWeight: 700 }}>Templates and Deliverables</h6>
            </Row>

            <Row>
              <div style={styles.textInputContainer}>
                <div style={styles.textInputLabel}>Deliverable Required</div>
                <div className='d-flex flex-row'>
                  <div className='me-3'>
                    <label htmlFor='deliverableYes' style={styles.radioLabelText}>
                      Yes
                    </label>
                    <input id='deliverableYes' readOnly={true} type='radio' name='option7' checked={job.deliverableRequired} disabled />
                  </div>
                  <div>
                    <label htmlFor='deliverableNo' style={styles.radioLabelText}>
                      No
                    </label>
                    <input id='deliverableNo' readOnly={true} type='radio' name='option7' checked={!job.deliverableRequired} disabled />
                  </div>
                </div>
                <hr className='mt-1' />
              </div>

              <div style={styles.textInputContainer}>
                <div style={styles.textInputLabel}>Will you be supplying a template?</div>
                <div className='d-flex flex-row'>
                  <div className='me-3'>
                    <label htmlFor='templateYes' style={styles.radioLabelText}>
                      Yes
                    </label>
                    <input
                      id='templateYes'
                      readOnly={true}
                      type='radio'
                      name='option9'
                      checked={docsToUse?.length !== 0 || job.templateRequired || job.coAdditionalContent}
                      disabled
                    />
                  </div>
                  <div>
                    <label htmlFor='templateNo' style={styles.radioLabelText}>
                      No
                    </label>
                    <input
                      id='templateNo'
                      readOnly={true}
                      type='radio'
                      name='option9'
                      checked={docsToUse?.length === 0 && !job.templateRequired && !job.coAdditionalContent}
                      disabled
                    />
                  </div>
                </div>
                <hr className='mt-1' />
              </div>
              {job.deliverableRequired && (
                <div style={styles.textInputContainer}>
                  <label htmlFor='deliverableType' style={styles.textInputLabel}>
                    Deliverable Type
                  </label>
                  <textarea
                    id='deliverableType'
                    value={job.deliverableType}
                    className='form-control'
                    rows={1}
                    style={styles.textInputInput}
                    disabled
                    readOnly
                  />
                </div>
              )}
              {job.deliverablesWithDueDate && (
                <div style={styles.textInputContainer}>
                  <label htmlFor='deliverableDueDate' style={styles.textInputLabel}>
                    Deliverable Due Date
                  </label>
                  <textarea
                    id='deliverableDueDate'
                    value={job.deliverablesWithDueDate || 'Not Specified'}
                    className='form-control'
                    rows={1}
                    style={styles.textInputInput}
                    disabled
                    readOnly
                  />
                </div>
              )}
              <div style={styles.textInputContainer}>
                <CoDocUploader jobId={job.id} coTemplates={coTemplates} detailView docsToUse={docsToUse} setDocsToUse={setDocsToUse} />
              </div>
              <div style={styles.textInputContainer}>
                <div>
                  <label htmlFor='deliverableTemplate' style={styles.textInputLabel}>
                    Download Finished Report or Deliverable
                  </label>
                  <TextareaAutosize
                    id='deliverableTemplate'
                    value='Download finished deliverables below'
                    className='form-control'
                    style={styles.textInputInput}
                    disabled
                    readOnly
                  />
                </div>
                <CoReportDownloader jobId={job.id} />
              </div>
              {job.completedFromPro && (
                <div className='d-flex flex-row justify-content-center my-3' style={{ fontWeight: 700 }}>
                  {job.completedFromCo ? (
                    <h5>You have Accepted Completion of This Job</h5>
                  ) : (
                    <>
                      <div className='w-50'>
                        <NewButton
                          className='me-1'
                          main
                          h={40}
                          w={50}
                          onClick={() => {
                            setDisplayConfirmModal(true);
                            setSelectedOption('Please confirm you want to accept this job as complete');
                          }}
                        >
                          Accept Completion
                        </NewButton>
                      </div>
                      <div className='w-50'>
                        <NewButton
                          blue
                          h={40}
                          w={50}
                          className=' ms-1'
                          onClick={() => {
                            setDisplayConfirmModal(true);
                            setSelectedOption('Are you sure you want to reject the Pro “Marked as Complete” status?');
                          }}
                        >
                          Reject Completion
                        </NewButton>
                      </div>
                    </>
                  )}
                </div>
              )}
            </Row>
          </Col>
        </Row>
      )}
      {/* CONFIRM MODAL */}
      <Modal size='md' show={displayConfirmModal} centered onHide={() => setDisplayConfirmModal(!displayConfirmModal)}>
        <ConfirmModal
          onClickYes={companyAcceptMarkJobAsCompleted}
          message={selectedOption}
          reasonToDecline={reasonToDecline}
          setReasonToDecline={setReasonToDecline}
        />
      </Modal>

      {/* BANNER MODAL */}
      <BannerModal
        show={isBannerModalOpen}
        onHide={() => setIsBannerModalOpen(false)}
        onAccept={onClickAcceptPromo}
        onDecline={() => {
          dispatch(coRetrieveJobs());
          onClickReview();
        }}
      />
    </>
  );
}
