import React from 'react';
import * as Sentry from '@sentry/react';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import MainLayout from './components/Layout/MainLayout';
import SignedInLayout from './components/Layout/SignedInLayout';
import SignUpCoOnboarding from './components/SignUpCoOnboarding/signUpCoOnboarding';
import { LAUNCH_DARKLY_CLIENT_ID } from './env';
import useHotJar from './hooks/useHotJar';
import { useAppDispatch, useAppSelector } from './index';
import AllServices from './pages/AllServices';
import AvailableService from './pages/AvailableService';
import CoReviewsPro from './pages/CoReviewsPro';
import Dashboard from './pages/Dashboard';
import EditJob from './pages/EditJob';
import ForgotPassword from './pages/ForgotPassword';
import HowItWorksPage from './pages/HowItWorksPage';
import MyAccount from './pages/MyAccount';
import MyJobsScreen from './pages/MyJobsScreen';
import NewJobOrder from './pages/NewJobOrder';
import NewReview from './pages/NewReview';
import PageNotFound from './pages/PageNotFound';
import PostPrepaidJob from './pages/PostPrepaidJob';
import JobInvite from './pages/ProAcceptJob/job-invite';
import ProfileBuilding from './pages/ProfileBuilding';
import ProRegistration from './pages/ProRegistration';
import { ProRegProvider } from './pages/ProRegistration/ProRegContext';
import ProResources from './pages/ProResources';
import Referrals from './pages/Referrals';
import ResetPassword from './pages/ResetPassword';
import SignIn from './pages/SignIn';
import SingleJobPage from './pages/SingleJob';
import WorkerProfile from './pages/WorkerProfile';
import { fetchProfessionalDetails } from './slices/professionalDetails.slice';
import { initGA, logPageView } from './utils/googleAnalytics';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

// This component is separated from the App component because we need to use useLocation hook and it must be used inside a component that is rendered by a Route component.
function App() {
  const dispatch = useAppDispatch();
  const staticData = useAppSelector(state => state.staticData);
  const location = useLocation();

  // initialize HotJar integration
  useHotJar();

  React.useEffect(() => {
    if (!window.GA_INITIALIZED) {
      initGA();
      window.GA_INITIALIZED = true;
    }
  }, []);

  React.useEffect(() => {
    logPageView(location.pathname);
  }, [location]);

  React.useEffect(() => {
    dispatch(fetchProfessionalDetails(staticData));
  }, []);

  return (
    <SentryRoutes>
      <Route
        path='/'
        element={
          <MainLayout>
            <SignIn />
          </MainLayout>
        }
      />
      <Route
        path='/register-pro'
        element={
          <ProRegProvider>
            <ProRegistration />
          </ProRegProvider>
        }
      />
      <Route path='/signup' element={<Navigate to='/register' replace />} />
      <Route path='/register' element={<SignUpCoOnboarding />} />
      <Route
        path='/forgot-password'
        element={
          <MainLayout>
            <ForgotPassword />
          </MainLayout>
        }
      />
      <Route
        path='/reset-password'
        element={
          <MainLayout>
            <ResetPassword />
          </MainLayout>
        }
      />
      <Route
        path='/dashboard'
        element={
          <SignedInLayout>
            <Dashboard />
          </SignedInLayout>
        }
      />
      <Route
        path='/myaccount'
        element={
          <SignedInLayout>
            <MyAccount />
          </SignedInLayout>
        }
      />
      <Route
        path='/job/order-review/:referenceNumber'
        element={
          <SignedInLayout>
            <NewJobOrder />
          </SignedInLayout>
        }
      />
      <Route
        path='/job/edit-job/:sid'
        element={
          <SignedInLayout>
            <EditJob />
          </SignedInLayout>
        }
      />
      <Route
        path='/referrals'
        element={
          <SignedInLayout>
            <Referrals />
          </SignedInLayout>
        }
      />
      <Route
        path='/project-invite/:referenceNumber'
        element={
          <SignedInLayout>
            <JobInvite />
          </SignedInLayout>
        }
      />
      <Route
        path='/professional-resources'
        element={
          <SignedInLayout>
            <ProResources />
          </SignedInLayout>
        }
      />
      <Route
        path='/co-available-service/:id'
        element={
          <SignedInLayout>
            <AvailableService />
          </SignedInLayout>
        }
      />
      <Route
        path='/pro-available-service/:id'
        element={
          <SignedInLayout>
            <AvailableService />
          </SignedInLayout>
        }
      />
      <Route
        path='/how-it-works'
        element={
          <MainLayout>
            <HowItWorksPage />
          </MainLayout>
        }
      />
      <Route
        path='/co-review-pro'
        element={
          <MainLayout>
            <CoReviewsPro />
          </MainLayout>
        }
      />
      <Route
        path='/my-profile'
        element={
          <SignedInLayout>
            <WorkerProfile />
          </SignedInLayout>
        }
      />
      <Route path='/new-review/:jobId' element={<NewReview />} />
      <Route
        path='/my-jobs/:category'
        element={
          <SignedInLayout>
            <MyJobsScreen />
          </SignedInLayout>
        }
      />
      <Route
        path='/co-available-services'
        element={
          <SignedInLayout>
            <AllServices />
          </SignedInLayout>
        }
      />

      <Route
        path='/job/:referenceNumber'
        element={
          <SignedInLayout>
            <SingleJobPage />
          </SignedInLayout>
        }
      />

      <Route path='/profile-building' element={<ProfileBuilding />} />
      <Route
        path='/post-prepaid-job/:parentId/:childId'
        element={
          <MainLayout>
            <PostPrepaidJob />
          </MainLayout>
        }
      />
      <Route
        path='/*'
        element={
          <MainLayout>
            <PageNotFound />
          </MainLayout>
        }
      />
    </SentryRoutes>
  );
}

// Set clientSideID to your own Client-side ID. You can find this in
// your LaunchDarkly portal under Account settings / Projects
export default withLDProvider({
  clientSideID: LAUNCH_DARKLY_CLIENT_ID,
})(App);
