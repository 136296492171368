import React from 'react';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import EditIcon from '../../../assets/images/edit-icon.svg';
import LogoutIcon from '../../../assets/images/logout-icon.svg';
import SaveIcon from '../../../assets/images/save-icon.svg';
import Dropdown from '../../../components/Dropdown';
import InputError from '../../../components/InputError';
import TextInput from '../../../components/TextInput';
import { stateOptions } from '../../../lib/options';
import { updateCoInfo } from '../../../slices/company.slice';
import { updateUser } from '../../../slices/user.slice';
import { updateWorkerById } from '../../../slices/worker.slice';

import './updateInfo.scss';

const styles = {
  headerText: {
    color: '#586f7c',
    fontWeight: 700,
  },
};

UpdateInfoForm.propTypes = {
  values: PropTypes.object,
  coValues: PropTypes.object,
  editModePro: PropTypes.bool,
  setEditModePro: PropTypes.func,
  editModeCoCompanyInfo: PropTypes.bool,
  setEditModeCoCompanyInfo: PropTypes.func,
};

export default function UpdateInfoForm({
  values: initialValues,
  coValues: initialCoValues,
  editModePro,
  setEditModePro,
  editModeCoCompanyInfo,
  setEditModeCoCompanyInfo,
}) {
  const formRef = React.useRef(null);
  const dispatch = useDispatch();

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values = {},
    errors,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: initialValues.firstName || '',
      lastName: initialValues.lastName || '',
      email: initialValues.email || '',
      mobilePhone: initialValues.mobilePhone || '',
      address: (initialValues.workerId ? initialValues.address : initialCoValues.address) || '',
      city: (initialValues.workerId ? initialValues.city : initialCoValues.city) || '',
      state: (initialValues.workerId ? initialValues.state : initialCoValues.state) || '',
      zipCode: initialValues.zipCode || '',

      workerId: initialValues.workerId || '',
      name: initialCoValues?.name || '',
      businessPhone: initialCoValues?.businessPhone || '',
      website: initialCoValues?.website || '',
      //address: initialCoValues?.address || '',
      //city: initialCoValues?.city || '',
      //state: initialCoValues?.state || '',
      zip: initialCoValues?.zip || '',
      coId: initialCoValues?.id || '',
      numOfEmployees: initialCoValues?.numOfEmployees || '',
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required('First name is required'),
      lastName: Yup.string().required('Last name is required'),
      email: Yup.string().email('Invalid email').required('Email is required'),
      mobilePhone: Yup.string().when('workerId', {
        is: workerId => workerId,
        then: Yup.string().required('Mobile phone is required'),
      }),
      address: Yup.string().when('workerId', {
        is: workerId => workerId,
        then: Yup.string().required('Address is required'),
      }),
      city: Yup.string().when('workerId', {
        is: workerId => workerId,
        then: Yup.string().required('City is required'),
      }),
      state: Yup.string().when('workerId', {
        is: workerId => workerId,
        then: Yup.string().required('State is required'),
      }),
      zipCode: Yup.string().when('workerId', {
        is: workerId => workerId,
        then: Yup.string().required('Zip code is required'),
      }),
      coName: Yup.string().when('coID', {
        is: coID => coID,
        then: Yup.string().required('Company name is required'),
      }),
      businessPhone: Yup.string().when('coID', {
        is: coID => coID,
        then: Yup.string().required('Business phone is required'),
      }),
      website: Yup.string().when('coID', {
        is: coID => coID,
        then: Yup.string().required('Website is required'),
      }),
      coAddress: Yup.string().when('coID', {
        is: coID => coID,
        then: Yup.string().required('Company address is required'),
      }),
      coCity: Yup.string().when('coID', {
        is: coID => coID,
        then: Yup.string().required('Company city is required'),
      }),
      coState: Yup.string().when('coID', {
        is: coID => coID,
        then: Yup.string().required('Company state is required'),
      }),
      coZip: Yup.string().when('coID', {
        is: coID => coID,
        then: Yup.string().required('Company zip code is required'),
      }),
    }),

    onSubmit: (values, { setSubmitting }) => {
      setTimeout(() => {
        if (values.workerId) {
          dispatch(updateWorkerById({ workerId: values.workerId, updates: values }))
            .unwrap()
            .then(res => {});
        }

        if (values.coId) {
          dispatch(updateCoInfo({ coId: initialCoValues.id, updates: values }))
            .unwrap()
            .then(res => {});
        }

        dispatch(updateUser({ updates: { email: values.email, firstName: values.firstName, lastName: values.lastName } }));
        setSubmitting(false);

        if (setEditModePro) {
          setEditModePro(false);
        }

        if (setEditModeCoCompanyInfo) {
          setEditModeCoCompanyInfo(false);
        }
      }, 200);
    },
  });

  return (
    <Container fluid className='px-0'>
      <form className='mt-3' onSubmit={handleSubmit} ref={formRef}>
        <Row className='ps-0'>
          <Col>
            <h6 style={styles.headerText}>My Account</h6>
          </Col>
          <Col className='d-flex justify-content-end'>
            <div>
              {editModePro ? (
                <div>
                  <button type='submit' style={{ backgroundColor: 'transparent', border: 'none', fontSize: '14px', color: '#586f7c' }}>
                    <img style={{ fontSize: '4px' }} src={SaveIcon} alt='' />
                    <p style={{ fontWeight: 500, color: '#586f7c' }}>Save</p>
                  </button>

                  <button type='cancel' style={{ backgroundColor: 'transparent', border: 'none', fontSize: '14px' }} onClick={() => setEditModePro(false)}>
                    <img style={{ fontSize: '14px' }} src={LogoutIcon} alt='' />
                    <p style={{ fontWeight: 500, color: '#586f7c', marginTop: '5px' }}>Cancel</p>
                  </button>
                </div>
              ) : (
                <button type='button' style={{ backgroundColor: 'transparent', border: 'none', fontSize: '14px' }} onClick={() => setEditModePro(true)}>
                  <img style={{ fontSize: '14px' }} src={EditIcon} alt='' />
                  <p style={{ fontWeight: 500, color: '#586f7c', marginRight: '7px' }}>Edit</p>
                </button>
              )}
            </div>
          </Col>
        </Row>
        <hr className='my-0' />
        <Row>
          <Col md={5}>
            <TextInput
              id='firstName'
              name='firstName'
              label='First Name'
              placeholder='Enter your first name'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.firstName}
              style={editModePro ? { color: 'black', fontSize: '14px' } : { color: '#5a5a5a', fontSize: '14px' }}
              disabled={!editModePro}
            />
          </Col>
          <Col md={2}></Col>
          <Col md={5}>
            <TextInput
              id='lastName'
              name='lastName'
              label='Last Name'
              placeholder='Enter your last name'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.lastName}
              style={editModePro ? { color: 'black', fontSize: '14px' } : { color: '#5a5a5a', fontSize: '14px' }}
              disabled={!editModePro}
            />
          </Col>
        </Row>
        <Row>
          <Col md={5}>
            <TextInput
              id='email'
              name='email'
              label='Email'
              placeholder='Enter your email'
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.email}
              style={editModePro ? { color: 'black', fontSize: '14px' } : { color: '#5a5a5a', fontSize: '14px' }}
              disabled={!editModePro}
            />
          </Col>
        </Row>
        {values.workerId ? (
          <>
            <Row>
              <Col>
                <TextInput
                  id='mobilePhone'
                  name='mobilePhone'
                  label='Mobile Phone'
                  placeholder='Enter your phone number'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.mobilePhone}
                  style={editModePro ? { color: 'black', fontSize: '14px' } : { color: '#5a5a5a', fontSize: '14px' }}
                  disabled={!editModePro}
                />
              </Col>
            </Row>
            <Row>
              <TextInput
                id='address'
                name='address'
                label='Address'
                placeholder='Enter your address'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.address}
                style={editModePro ? { color: 'black', fontSize: '14px' } : { color: '#5a5a5a', fontSize: '14px' }}
                disabled={!editModePro}
              />
              {errors.address && <InputError>{errors.address}</InputError>}
            </Row>
            <Row>
              <Col md={7}>
                <TextInput
                  id='city'
                  name='city'
                  label='City'
                  placeholder='Enter your city'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.city}
                  style={editModePro ? { color: 'black', fontSize: '14px' } : { color: '#5a5a5a', fontSize: '14px' }}
                  disabled={!editModePro}
                />
                {errors.city && <InputError>{errors.city}</InputError>}
              </Col>

              <Col xs={8} sm={8} md={3}>
                <Dropdown
                  id='state'
                  name='state'
                  label='State'
                  oA
                  options={stateOptions}
                  value={values.state}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={!editModePro}
                  overrideContainerStyle={{ marginTop: '17px' }}
                />
                {errors.state && <InputError>{errors.state}</InputError>}
              </Col>
              <Col xs={4} sm={4} md={2}>
                <TextInput
                  id='zipCode'
                  name='zipCode'
                  label='Zip Code'
                  placeholder='Enter your Zip'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.zipCode}
                  style={editModePro ? { color: 'black', fontSize: '14px' } : { color: '#5a5a5a', fontSize: '14px' }}
                  disabled={!editModePro}
                />
                {errors.zipCode && <InputError>{errors.zipCode}</InputError>}
              </Col>
            </Row>
          </>
        ) : (
          <Container fluid className='px-0'>
            <Row className='ps-0 mt-3'>
              <Col className='mt-4' style={{ color: '#586f7c' }}>
                <h6 style={styles.headerText}>Company Info</h6>
              </Col>
              <Col className='d-flex justify-content-end mt-4'>
                <div>
                  {editModeCoCompanyInfo ? (
                    <div>
                      <button type='submit' style={{ backgroundColor: 'transparent', border: 'none', fontSize: '14px', color: '#586f7c', marginLeft: '10px' }}>
                        <img style={{ fontSize: '4px' }} src={SaveIcon} alt='' />
                        <p style={{ fontWeight: 500, color: '#586f7c' }}>Save</p>
                      </button>
                      <button
                        type='cancel'
                        style={{ backgroundColor: 'transparent', border: 'none', fontSize: '14px', color: '#586f7c' }}
                        onClick={() => setEditModeCoCompanyInfo(false)}
                      >
                        <img style={{ fontSize: '14px' }} src={LogoutIcon} alt='' />
                        <p style={{ fontWeight: 500, color: '#586f7c' }}>Cancel</p>
                      </button>
                    </div>
                  ) : (
                    <button
                      type='button'
                      style={{ backgroundColor: 'transparent', border: 'none', fontSize: '14px' }}
                      onClick={() => setEditModeCoCompanyInfo(true)}
                    >
                      <img style={{ fontSize: '14px' }} src={EditIcon} alt='' />
                      <p style={{ fontWeight: 500, color: '#586f7c', marginLeft: '7px' }}>Edit</p>
                    </button>
                  )}
                </div>
              </Col>
            </Row>
            <hr className='my-0' />
            <Row>
              <Col md={5}>
                <TextInput
                  id='name'
                  name='name'
                  label='Company Name'
                  placeholder='Enter Company Name'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  style={editModeCoCompanyInfo ? { color: 'black', fontSize: '14px' } : { color: '#5a5a5a', fontSize: '14px' }}
                  disabled={!editModeCoCompanyInfo}
                />
              </Col>
              <Col md={2}></Col>
              <Col md={5}>
                <TextInput
                  id='address'
                  name='address'
                  label='Company Address'
                  placeholder='Enter Company Street Address'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.address}
                  style={editModeCoCompanyInfo ? { color: 'black', fontSize: '14px' } : { color: '#5a5a5a', fontSize: '14px' }}
                  disabled={!editModeCoCompanyInfo}
                />
                {errors.address && <InputError>{errors.address}</InputError>}
              </Col>
            </Row>
            <Row>
              <Col md={5}>
                <TextInput
                  id='businessPhone'
                  name='businessPhone'
                  label='Phone Number'
                  placeholder='Enter Business Phone Number'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.businessPhone}
                  style={editModeCoCompanyInfo ? { color: 'black', fontSize: '14px' } : { color: '#5a5a5a', fontSize: '14px' }}
                  disabled={!editModeCoCompanyInfo}
                />
              </Col>
              <Col md={2}></Col>
              <Col md={5}>
                <TextInput
                  id='website'
                  name='website'
                  label='Company Website'
                  placeholder='Enter Company Website Address'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.website}
                  style={editModeCoCompanyInfo ? { color: 'black', fontSize: '14px' } : { color: '#5a5a5a', fontSize: '14px' }}
                  disabled={!editModeCoCompanyInfo}
                />
              </Col>
            </Row>
            <Row>
              <Col md={7}>
                <TextInput
                  id='city'
                  name='city'
                  label='Company City'
                  placeholder='Enter Company City'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.city}
                  style={editModeCoCompanyInfo ? { color: 'black', fontSize: '14px' } : { color: '#5a5a5a', fontSize: '14px' }}
                  disabled={!editModeCoCompanyInfo}
                />
                {errors.city && <InputError>{errors.city}</InputError>}
              </Col>
              <Col className='state-container' xs={8} sm={8} md={3}>
                <Dropdown
                  id='state'
                  name='state'
                  oA
                  options={stateOptions}
                  value={values.state}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  label='State'
                  overrideContainerStyle={{ marginTop: '17px' }}
                />
                {errors.state && <InputError>{errors.state}</InputError>}
              </Col>

              <Col xs={4} sm={4} md={2}>
                <TextInput
                  id='zip'
                  name='zip'
                  label='Company Zip'
                  placeholder='Enter Company Zip'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.zip}
                  style={
                    editModeCoCompanyInfo
                      ? { color: 'black', fontSize: '14px', whiteSpace: 'nowrap' }
                      : { color: '#5a5a5a', fontSize: '14px', whiteSpace: 'nowrap' }
                  }
                  disabled={!editModeCoCompanyInfo}
                  className='text-nowrap'
                />
                {errors.zip && <InputError>{errors.zip}</InputError>}
              </Col>
            </Row>
          </Container>
        )}
      </form>
    </Container>
  );
}
