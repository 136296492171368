import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { BiLinkExternal } from 'react-icons/bi';
import { MdWarning } from 'react-icons/md';
import { RiFileDownloadFill } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TextareaAutosize from 'react-textarea-autosize';

import DownIcon from '../../../assets/images/down-icon.svg';
import UpIcon from '../../../assets/images/up-icon.svg';
import Button from '../../../components/Button';
import InputError from '../../../components/InputError';
import Loading from '../../../components/Loading';
import Modal from '../../../components/Modal';
import NewButton from '../../../components/NewButton';
import ReportUploader from '../../../components/ReportUploader';
import Text from '../../../components/Text';
import TextInput from '../../../components/TextInput';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { checkDocsForPro, getReports, uploadReport } from '../../../slices/document.slice';
import { getQbeJob, getQbeJobSsoLink, markJobCompleteAsPro, proRetrieveJobs } from '../../../slices/job.slice';
import ConfirmModal from '../ConfirmModal';

const styles = {
  textInputContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '19.5px',
  },
  textInputLabel: {
    fontSize: '15px',
    whiteSpace: 'nowrap',
    marginBottom: '19.5px',
    fontWeight: 700,
  },
  textInputInput: {
    borderRadius: 0,
    color: '#586F7C',
    outline: 'none',
    border: '1px solid #b4b4b4aa',
    borderLeft: 'none',
    borderRight: 'none',
    borderTop: 'none',
    fontWeight: 400,
    boxShadow: 'none',
    background: 'transparent',
    paddingLeft: 0,
    paddingBottom: '16.5px',
    fontSize: '1rem',
    resize: 'none',
    overflowY: 'hidden',
  },
  radioLabelText: {
    marginBottom: '16.5px',
    fontSize: '18px',
  },
};

export default function ProJob() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const { coTemplates } = useSelector(state => state.document);
  const { reviews } = useSelector(state => state.proReviewByCo);
  const job = useSelector(state => state.job.jobOrder);
  const [showTemplate, setShowTemplate] = useState(true);
  const [selectedOption, setSelectedOption] = useState(null);
  const [docsToUse, setDocsToUse] = useState([]);
  const [externalLink, setExternalLink] = useState(null);
  const [externalLinkLoading, setExternalLinkLoading] = useState(false);
  const [displayConfirmModal, setDisplayConfirmModal] = useState(false);
  const [reasonToDecline, setReasonToDecline] = useState('');
  const [formError, setFormError] = useState(false);
  const jobInvite = job.jobInvite;
  const [values, setValues] = useState({
    milesDriven: job.milesDriven,
    jobId: job.id,
  });

  const onClickNo = () => {
    setDisplayConfirmModal(false);
  };

  const handleExternalLinkClick = e => {
    e.preventDefault();
    setExternalLinkLoading(true);
    if (externalLink) {
      setExternalLinkLoading(false);
      window.open(externalLink);
      return;
    }
    dispatch(getQbeJob({ jobId: job.id }))
      .unwrap()
      .then(response => {
        // response contains qbe_job
        if (response.sso_link && new Date(response.sso_link_expire) > Date.now()) {
          setExternalLink(response.sso_link);
          window.open(response.sso_link);
        } else {
          // we need to fetch a new link here
          dispatch(getQbeJobSsoLink({ inspectionId: response.inspection_id }))
            .unwrap()
            .then(ssoLinkRes => {
              setExternalLink(ssoLinkRes.Url);
              window.open(ssoLinkRes.Url);
            })
            .catch(ssoLinkErr => {
              console.error(ssoLinkErr);
            });
        }
        setExternalLinkLoading(false);
      })
      .catch(err => {
        console.log(err);
        setExternalLinkLoading(false);
      });
  };

  const markJobAsCompleted = async () => {
    async function allPromises() {
      try {
        await dispatch(
          markJobCompleteAsPro({
            jobId: job.id,
            milesDriven: parseFloat(values.milesDriven),
          })
        ).unwrap();
        await dispatch(proRetrieveJobs()).unwrap();
      } catch (error) {
        throw error;
      }
    }

    toast.promise(allPromises(), {
      loading: 'Accepting job as complete...',
      success: () => {
        navigate(`/new-review/${job.id}`);
        return 'You have marked this job as completed!';
      },
      error: error => {
        setDisplayConfirmModal(false);
        console.error(error);
        return error?.message || 'Error accepting job as complete';
      },
    });
  };

  const isReviewed = reviews && reviews.some(item => item.jobId === job.id);

  const showReview = job.status === 'Completed' || job.completedFromPro || job.status === 'Closed-Paid';

  const onClickReview = () => {
    navigate(`/new-review/${job.id}`);
  };

  const uploadDocuments = doc => {
    if (doc.length > 0) {
      const docs = doc.map(doc => {
        return {
          data: doc.dataURL,
          type: doc.type,
          name: doc.name,
        };
      });
      dispatch(uploadReport({ docs, jobId: job.id })).then(() => {
        dispatch(getReports({ jobId: job.id }));
      });
    } else {
      return;
    }
  };

  useEffect(() => {
    dispatch(checkDocsForPro({ jobId: job.id }));
  }, [dispatch, job.id]);

  return (
    <>
      {width < 768 ? (
        <>
          {/* TOP BUTTON */}
          {showReview ? (
            <Row className='mt-5'>
              <NewButton h={40} blue={isReviewed} main={!isReviewed} label={isReviewed ? 'View Review' : 'Write Review'} onClick={() => onClickReview()} />
            </Row>
          ) : null}
          <Row>
            <div style={styles.textInputContainer}>
              <label htmlFor='typeOfWork' style={styles.textInputLabel}>
                Type of Work To Be Done
              </label>
              <TextareaAutosize id='typeOfWork' value={job.typeOfWork || job.typeOfWork} className='form-control' style={styles.textInputInput} disabled />
            </div>
          </Row>
          <Row>
            <div style={styles.textInputContainer}>
              <label htmlFor='pointOfContact' style={styles.textInputLabel}>
                Point Of Contact
              </label>
              <TextareaAutosize
                id='pointOfContact'
                value={`${job.pocFirstName} ${job.pocLastName} \n${job.pocPhone} \n${job.pocEmail}`}
                className='form-control'
                rows={3}
                style={styles.textInputInput}
                disabled
              />
            </div>
          </Row>
          {job.schedule ? (
            <Row>
              <div style={styles.textInputContainer}>
                <label htmlFor='schedule' style={styles.textInputLabel}>
                  Schedule
                </label>
                <TextareaAutosize id='schedule' value={job.schedule} className='form-control' rows={1} style={styles.textInputInput} disabled />
              </div>
            </Row>
          ) : null}

          <Row>
            <div style={styles.textInputContainer}>
              <label style={styles.textInputLabel}>Job Site</label>
              <>
                {job.commuteType !== null ? (
                  <>
                    {job.commuteType.toLowerCase() === 'virtual' ? (
                      <>
                        <TextareaAutosize value='This is a Virtual Job' className='form-control text-nowrap' rows={1} style={styles.textInputInput} disabled />
                      </>
                    ) : (
                      <>
                        <TextareaAutosize
                          value={`${job.siteAddress || null} ${job.siteCity || null}, ${job.siteState || null} ${job.siteZip || null}`}
                          className='form-control text-nowrap'
                          style={styles.textInputInput}
                          disabled
                        />
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <TextareaAutosize
                      value={`${job.siteAddress || null} ${job.siteCity || null}, ${job.siteState || null} ${job.siteZip || null}`}
                      className='form-control'
                      style={styles.textInputInput}
                      disabled
                    />
                  </>
                )}
                {jobInvite.isProDriving !== null && jobInvite.isProDriving ? (
                  <div style={styles.textInputContainer}>
                    <label htmlFor='milesDriven' style={styles.textInputLabel}>
                      Miles Driven *
                    </label>
                    <TextInput
                      value={jobInvite.milesDriven}
                      id='milesDriven'
                      name='milesDriven'
                      placeholder={'Enter Amount of Miles Driven to Site'}
                      className='form-control'
                      checked={docsToUse?.length === 0 && !job.milesDriven}
                      onChange={e => {
                        setValues({ ...values, milesDriven: e.target.value });
                      }}
                      style={styles.textInputInput}
                    />
                    {formError ? <InputError>Required</InputError> : null}
                  </div>
                ) : null}
              </>
            </div>
          </Row>
          <Row>
            <div style={styles.textInputContainer}>
              <label htmlFor='totalHours' style={styles.textInputLabel}>
                Total Approved Hours
              </label>
              <TextareaAutosize
                id='totalHours'
                value={job.totalHours || 'Not Specified'}
                className='form-control'
                rows={1}
                style={styles.textInputInput}
                disabled
              />
            </div>
          </Row>
          <Row className='mt-5'>
            <h6 style={{ color: '#586f7c', fontWeight: 700 }}>Compensation</h6>
          </Row>
          <Row>
            <div style={styles.textInputContainer}>
              <label htmlFor='pay' style={styles.textInputLabel}>
                Pay
              </label>
              <TextareaAutosize
                id='pay'
                value={`$${job.professionalHourlyRateCents / 100} ${job.priceType || ''}`}
                className='form-control'
                rows={1}
                style={styles.textInputInput}
                disabled
              />
            </div>
          </Row>
          <Row>
            <div style={styles.textInputContainer}>
              <label htmlFor='totalAmount' style={styles.textInputLabel}>
                Total Amount
              </label>
              <TextareaAutosize
                id='totalAmount'
                value={`$${job.payCents / 100 || ''}`}
                className='form-control'
                rows={1}
                style={styles.textInputInput}
                disabled
              />
            </div>
            {job.additionalCompensation && (
              <div style={styles.textInputContainer}>
                <label htmlFor='additionalCompensation' style={styles.textInputLabel}>
                  Additional Compensation
                </label>
                <TextareaAutosize
                  id='additionalCompensation'
                  value={job.additionalCompensation}
                  className='form-control'
                  style={styles.textInputInput}
                  disabled
                />
              </div>
            )}
          </Row>
          <Row className='mt-5'>
            <h6 style={{ color: '#586f7c', fontWeight: 700 }}>Templates and Deliverables</h6>
          </Row>
          <Row style={styles.textInputContainer}>
            <div style={styles.textInputLabel}>Template Supplied</div>
            {coTemplates?.length !== 0 ? (
              <>
                <div className='d-flex flex-row'>
                  <div className='me-3'>
                    <label htmlFor='templateYes' style={styles.radioLabelText}>
                      Yes
                    </label>
                    <input
                      id='templateYes'
                      readOnly={true}
                      type='radio'
                      name='option8'
                      checked={docsToUse?.length !== 0 || job.templateRequired || job.coAdditionalContent}
                      disabled
                    />
                  </div>
                  <div>
                    <label htmlFor='templateNo' style={styles.radioLabelText}>
                      No
                    </label>
                    <input
                      id='templateNo'
                      readOnly={true}
                      type='radio'
                      name='option8'
                      checked={docsToUse?.length === 0 && !job.templateRequired && !job.coAdditionalContent}
                      disabled
                    />
                  </div>
                </div>
                <hr className='mt-1' />
                <div style={styles.textInputContainer}>
                  <label htmlFor='downloadDeliverableTemplate' style={styles.textInputLabel}>
                    Download Deliverable Template
                  </label>
                  {coTemplates.length !== 0 ? (
                    <div
                      onClick={() => setShowTemplate(!showTemplate)}
                      className='d-flex flex-row justify-content-between py-2 pb-3'
                      style={{ cursor: 'pointer' }}
                    >
                      <h6 style={{ color: '#586f7c' }} className='mt-3'>
                        Company Supplies
                      </h6>
                      {showTemplate ? <img style={{ cursor: 'pointer' }} src={UpIcon} alt='' /> : <img style={{ cursor: 'pointer' }} src={DownIcon} alt='' />}
                    </div>
                  ) : (
                    <div
                      onClick={() => setShowTemplate(!showTemplate)}
                      className='d-flex flex-row justify-content-between py-2 pb-3'
                      style={{ cursor: 'pointer' }}
                    >
                      <h6 style={{ color: '#586f7c' }}>YellowBird Template</h6>
                      {showTemplate ? <img style={{ cursor: 'pointer' }} src={UpIcon} alt='' /> : <img style={{ cursor: 'pointer' }} src={DownIcon} alt='' />}
                    </div>
                  )}
                  {coTemplates.length !== 0 && showTemplate ? (
                    <>
                      <div>
                        <div>
                          {coTemplates.map(template => {
                            return (
                              <div key={template.id}>
                                <a style={{ color: '#b4b4b4' }} target='_blank' href={template.s3Url} rel='noreferrer'>
                                  {template.fileName} <RiFileDownloadFill />
                                </a>
                              </div>
                            );
                          })}
                        </div>
                        <hr className='mt-1' />
                      </div>
                    </>
                  ) : (
                    <>
                      {showTemplate ? (
                        <h6>
                          <div className='template-line'>
                            <a href='https://yb-reportbucket.s3.amazonaws.com/Templates/Loss+Control+Report+.docx'>
                              Loss Control Survey Report
                              <RiFileDownloadFill className='download-icon' />
                            </a>
                          </div>
                          <div className='template-line'>
                            <a href='https://yb-reportbucket.s3.amazonaws.com/Templates/OSHA+-+Safety+Assessment+Report+Template+(1).docx'>
                              Safety Assessment/Mock Osha Inspection
                              <RiFileDownloadFill className='download-icon' />
                            </a>
                          </div>
                          <hr />
                        </h6>
                      ) : null}
                    </>
                  )}
                </div>
              </>
            ) : (
              <>
                <div className='d-flex flex-row'>
                  <div className='me-3'>
                    <label htmlFor='templateYes' style={styles.radioLabelText}>
                      Yes
                    </label>
                    <input
                      id='templateYes'
                      readOnly={true}
                      type='radio'
                      name='option8'
                      checked={docsToUse?.length !== 0 || job.templateRequired || job.coAdditionalContent}
                      disabled
                    />
                  </div>
                  <div>
                    <label htmlFor='templateNo' style={styles.radioLabelText}>
                      No
                    </label>
                    <input
                      id='templateNo'
                      readOnly={true}
                      type='radio'
                      name='option8'
                      checked={docsToUse?.length === 0 && !job.templateRequired && !job.coAdditionalContent}
                      disabled
                    />
                  </div>
                </div>
                <hr className='mt-1' />
                <>
                  <label htmlFor='downLoadDeliverableTemplate' style={styles.textInputLabel}>
                    Download Deliverable Template
                  </label>
                  {coTemplates.length !== 0 ? (
                    <div
                      onClick={() => setShowTemplate(!showTemplate)}
                      className='d-flex flex-row justify-content-between py-2 pb-3'
                      style={{ cursor: 'pointer' }}
                    >
                      <h6 style={{ color: '#586f7c' }} className='mt-3'>
                        Company Supplies
                      </h6>
                      {showTemplate ? <img style={{ cursor: 'pointer' }} src={UpIcon} alt='' /> : <img style={{ cursor: 'pointer' }} src={DownIcon} alt='' />}
                    </div>
                  ) : (
                    <div
                      onClick={() => setShowTemplate(!showTemplate)}
                      className='d-flex flex-row justify-content-between py-2 pb-3'
                      style={{ cursor: 'pointer' }}
                    >
                      <h6 style={{ color: '#586f7c' }}>YellowBird Template</h6>
                      {showTemplate ? <img style={{ cursor: 'pointer' }} src={UpIcon} alt='' /> : <img style={{ cursor: 'pointer' }} src={DownIcon} alt='' />}
                    </div>
                  )}
                  {coTemplates.length !== 0 && showTemplate ? (
                    <>
                      <div>
                        <div>
                          {coTemplates.map(template => {
                            return (
                              <div key={template.id}>
                                <a style={{ color: '#b4b4b4' }} target='_blank' href={template.s3Url} rel='noreferrer'>
                                  {template.fileName} <RiFileDownloadFill />
                                </a>
                              </div>
                            );
                          })}
                        </div>
                        <hr className='mt-1' />
                      </div>
                    </>
                  ) : (
                    <>
                      {showTemplate ? (
                        <h6>
                          <div className='template-line'>
                            <a href='https://yb-reportbucket.s3.amazonaws.com/Templates/Loss+Control+Report+.docx'>
                              Loss Control Survey Report
                              <RiFileDownloadFill className='download-icon' />
                            </a>
                          </div>
                          <div className='template-line'>
                            <a href='https://yb-reportbucket.s3.amazonaws.com/Templates/OSHA+-+Safety+Assessment+Report+Template+(1).docx'>
                              Safety Assessment/Mock Osha Inspection
                              <RiFileDownloadFill className='download-icon' />
                            </a>
                          </div>
                          <hr />
                        </h6>
                      ) : null}
                    </>
                  )}
                </>
              </>
            )}
          </Row>
          <Row style={styles.textInputContainer}>
            <div style={styles.textInputLabel}>Deliverable Required</div>
            <div className='d-flex flex-row'>
              <div className='me-3'>
                <label htmlFor='deliverableYes' style={styles.radioLabelText}>
                  Yes
                </label>
                <input id='deliverableYes' readOnly={true} type='radio' name='option7' checked={job.deliverableRequired} disabled />
              </div>
              <div>
                <label htmlFor='deliverableNo' style={styles.radioLabelText}>
                  No
                </label>
                <input id='deliverableNo' readOnly={true} type='radio' name='option7' checked={!job.deliverableRequired} disabled />
              </div>
            </div>
            <hr className='mt-1' />
          </Row>
          {job.deliverableRequired && (
            <Row>
              <div style={{ ...styles.textInputContainer, marginTop: 8.5 }}>
                <label htmlFor='deliverableType' style={styles.textInputLabel}>
                  Deliverable Type
                </label>
                <textarea
                  id='deliverableType'
                  value={job.deliverableType || 'Not Sepecified'}
                  className='form-control'
                  rows={1}
                  style={styles.textInputInput}
                  disabled
                />
              </div>
            </Row>
          )}
          {job.deliverablesWithDueDate ? (
            <Row>
              <div style={styles.textInputContainer}>
                <label htmlFor='deliverableDueDate' style={styles.textInputLabel}>
                  Deliverable Due Date
                </label>
                <textarea
                  id='deliverableDueDate'
                  value={job.deliverablesWithDueDate}
                  className='form-control'
                  rows={1}
                  style={styles.textInputInput}
                  disabled
                />
              </div>
            </Row>
          ) : null}

          {job.external ? (
            <Button disabled={externalLinkLoading} onClick={handleExternalLinkClick} className='button primary-btn btn-text-white my-4'>
              {externalLinkLoading ? (
                <Loading removeText />
              ) : (
                <>
                  <span>LC360</span> <BiLinkExternal />
                </>
              )}
            </Button>
          ) : (
            <div style={styles.textInputContainer}>
              <ReportUploader uploadDocuments={doc => uploadDocuments(doc)} jobId={job.id} />
            </div>
          )}

          <Row>
            {job.status === 'InProgress' && !job.completedFromPro && (
              <div>
                <div>
                  {job.reasonToDeclineCompletion && (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <MdWarning size={36} color='red' />
                      <div style={{ marginLeft: '16px' }}>
                        <p>The company rejected previous completion of this job.</p>
                        <p>Reason: {job.reasonToDeclineCompletion}</p>
                        <p>Please make the necessary corrections before marking the job as complete again.</p>
                      </div>
                    </div>
                  )}
                </div>
                <br />
                {jobInvite.isProDriving === null || jobInvite.isProDriving === false ? (
                  <div className='d-flex flex-row justify-content-center'>
                    <Button
                      className='btn primary-btn py-3 px-5'
                      style={{ fontWeight: 600, borderRadius: '12px' }}
                      onClick={() => {
                        setDisplayConfirmModal(true);
                        setSelectedOption('Are you sure you want to mark this job as completed?');
                      }}
                    >
                      Mark this Job as Completed
                    </Button>
                  </div>
                ) : (
                  <div className='d-flex flex-row justify-content-center'>
                    <Button
                      className='btn primary-btn py-3 px-5'
                      style={{ fontWeight: 600, borderRadius: '12px' }}
                      onClick={() => {
                        if (values.milesDriven != null) {
                          setDisplayConfirmModal(true);
                          setSelectedOption('Are you sure you want to mark this job as completed?');
                        } else {
                          setFormError(true);
                        }
                      }}
                    >
                      Mark this Job as Completed
                    </Button>
                  </div>
                )}
              </div>
            )}
            <br />

            {job.completedFromPro && (
              <div className='d-flex justify-content-center' style={{ fontWeight: 700 }}>
                <h5>Job has been marked as completed and sent to Company for review.</h5>
              </div>
            )}
          </Row>
        </>
      ) : (
        <Row>
          <Col>
            {showReview ? (
              <Row className='mt-5 mb-3 d-flex align-items-center justify-content-between'>
                <Col md={4}>
                  <NewButton
                    h={40}
                    w='60%'
                    blue={isReviewed}
                    main={!isReviewed}
                    label={isReviewed ? 'View Review' : 'Write Review'}
                    onClick={() => onClickReview()}
                  />
                </Col>
              </Row>
            ) : null}
            <Row>
              <div style={styles.textInputContainer}>
                <label htmlFor='typeOfWork' style={styles.textInputLabel}>
                  Type of Work To Be Done
                </label>
                <TextareaAutosize id='typeOfWork' value={job.typeOfWork || job.typeOfWork} className='form-control' style={styles.textInputInput} disabled />
              </div>
              <div style={styles.textInputContainer}>
                <label htmlFor='pointOfContact' style={styles.textInputLabel}>
                  Point Of Contact
                </label>
                <TextareaAutosize
                  id='pointOfContact'
                  value={`${job.pocFirstName} ${job.pocLastName} \n${job.pocPhone} \n${job.pocEmail}`}
                  className='form-control'
                  rows={3}
                  style={styles.textInputInput}
                  disabled
                />
              </div>
              <div style={styles.textInputContainer}>
                <label style={styles.textInputLabel}>Job Site</label>
                {job.commuteType !== null ? (
                  <>
                    {job.commuteType.toLowerCase() === 'virtual' ? (
                      <>
                        <TextareaAutosize value='This is a Virtual Job' className='form-control text-nowrap' rows={1} style={styles.textInputInput} disabled />
                      </>
                    ) : (
                      <>
                        <TextareaAutosize
                          value={`${job.siteAddress || null}, ${job.siteCity || null}, ${job.siteState || null} ${job.siteZip || null}`}
                          className='form-control text-nowrap'
                          style={styles.textInputInput}
                          disabled
                        />
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <TextareaAutosize
                      value={`${job.siteAddress || null} ${job.siteCity || null}, ${job.siteState || null} ${job.siteZip || null}`}
                      className='form-control text-nowrap'
                      style={styles.textInputInput}
                      disabled
                    />
                  </>
                )}
              </div>
              {jobInvite.isProDriving !== null && jobInvite.isProDriving ? (
                <div style={styles.textInputContainer}>
                  <label htmlFor='milesDriven' style={styles.textInputLabel}>
                    Miles Driven *
                  </label>
                  <TextInput
                    value={jobInvite.milesDriven}
                    id='milesDriven'
                    name='milesDriven'
                    placeholder={'Enter Total Miles Driven'}
                    className='form-control'
                    checked={docsToUse?.length === 0 && !job.milesDriven}
                    onChange={e => {
                      setValues({ ...values, milesDriven: e.target.value });
                    }}
                    style={styles.textInputInput}
                  />
                  {formError ? <InputError>Required</InputError> : null}
                </div>
              ) : null}
              <div style={styles.textInputContainer}>
                <label htmlFor='schedule' style={styles.textInputLabel}>
                  Schedule
                </label>
                <TextareaAutosize
                  id='schedule'
                  value={job.schedule || 'Not Specified'}
                  className='form-control'
                  rows={1}
                  style={styles.textInputInput}
                  disabled
                />
              </div>
              <div style={styles.textInputContainer}>
                <label htmlFor='totalHours' style={styles.textInputLabel}>
                  Total Approved Hours
                </label>
                <TextareaAutosize
                  id='totalHours'
                  value={job.totalHours || 'Not Specified'}
                  className='form-control'
                  rows={1}
                  style={styles.textInputInput}
                  disabled
                />
              </div>
            </Row>
            <Row className='mt-5'>
              <h6 style={{ color: '#586f7c', fontWeight: 700 }}>Compensation</h6>
            </Row>
            <Row>
              <div style={styles.textInputContainer}>
                <label htmlFor='pay' style={styles.textInputLabel}>
                  Pay
                </label>
                <TextareaAutosize
                  id='pay'
                  value={`$${job.professionalHourlyRateCents / 100} ${job.priceType || ''}`}
                  className='form-control'
                  rows={1}
                  style={styles.textInputInput}
                  disabled
                />
              </div>
              <div style={styles.textInputContainer}>
                <label htmlFor='totalAmount' style={styles.textInputLabel}>
                  Total Amount
                </label>
                <textarea id='totalAmount' value={`$${job.payCents / 100 || ''}`} className='form-control' rows={1} style={styles.textInputInput} disabled />
              </div>
              {job.additionalCompensation && (
                <div style={styles.textInputContainer}>
                  <label htmlFor='additionalCompensation' style={styles.textInputLabel}>
                    Additional Compensation
                  </label>
                  <TextareaAutosize
                    id='additionalCompensation'
                    value={job.additionalCompensation || 'Not Specified'}
                    className='form-control'
                    style={styles.textInputInput}
                    disabled
                  />
                </div>
              )}
            </Row>
            <Row className='mt-5'>
              <h6 style={{ color: '#586f7c', fontWeight: 700 }}>Templates and Deliverables</h6>
            </Row>
            <Row>
              <div style={styles.textInputContainer}>
                <div style={styles.textInputLabel}>Deliverable Required</div>
                <div className='d-flex flex-row'>
                  <div className='me-3'>
                    <label htmlFor='deliverableYes' style={styles.radioLabelText}>
                      Yes
                    </label>
                    <input id='deliverableYes' readOnly={true} type='radio' name='option7' checked={job.deliverableRequired} disabled />
                  </div>
                  <div>
                    <label htmlFor='deliverableNo' style={styles.radioLabelText}>
                      No
                    </label>
                    <input id='deliverableNo' readOnly={true} type='radio' name='option7' checked={!job.deliverableRequired} disabled />
                  </div>
                </div>
                <hr className='mt-1' />
              </div>

              <div style={styles.textInputContainer}>
                <div style={styles.textInputLabel}>Template Supplied</div>
                <div className='d-flex flex-row'>
                  <div className='me-3'>
                    <label htmlFor='templateYes' style={styles.radioLabelText}>
                      Yes
                    </label>
                    <input
                      id='templateYes'
                      readOnly={false}
                      type='radio'
                      name='option9'
                      checked={docsToUse?.length !== 0 || job.templateRequired || job.coAdditionalContent}
                    />
                  </div>
                  <div>
                    <label htmlFor='templateNo' style={styles.radioLabelText}>
                      No
                    </label>
                    <input
                      id='templateNo'
                      readOnly={false}
                      type='radio'
                      name='option9'
                      checked={docsToUse?.length === 0 && !job.templateRequired && !job.coAdditionalContent}
                    />
                  </div>
                </div>
                <hr className='mt-1' />
              </div>
            </Row>
            <Row>
              {job.deliverableRequired && (
                <div style={styles.textInputContainer}>
                  <label htmlFor='deliverableType' style={styles.textInputLabel}>
                    Deliverable Type
                  </label>
                  <TextareaAutosize id='deliverableType' value={job.deliverableType} className='form-control' rows={1} style={styles.textInputInput} disabled />
                </div>
              )}
              {job.deliverablesWithDueDate && (
                <div style={styles.textInputContainer}>
                  <label htmlFor='deliverableDueDate' style={styles.textInputLabel}>
                    Deliverable Due Date
                  </label>
                  <TextareaAutosize
                    id='deliverableDueDate'
                    value={job.deliverablesWithDueDate || 'Not Specified'}
                    className='form-control'
                    rows={1}
                    style={styles.textInputInput}
                    disabled
                  />
                </div>
              )}
            </Row>
            <Row>
              <div style={styles.textInputContainer}>
                <label htmlFor='downLoadDeliverableTemplate' style={styles.textInputLabel}>
                  Download Deliverable Template
                </label>
                {coTemplates.length !== 0 ? (
                  <div
                    onClick={() => setShowTemplate(!showTemplate)}
                    className='d-flex flex-row justify-content-between py-2 pb-3'
                    style={{ cursor: 'pointer' }}
                  >
                    <h6 style={{ color: '#586f7c' }} className='mt-3'>
                      Company Supplies
                    </h6>
                    {showTemplate ? <img style={{ cursor: 'pointer' }} src={UpIcon} alt='' /> : <img style={{ cursor: 'pointer' }} src={DownIcon} alt='' />}
                  </div>
                ) : (
                  <div
                    onClick={() => setShowTemplate(!showTemplate)}
                    className='d-flex flex-row justify-content-between py-2 pb-3'
                    style={{ cursor: 'pointer' }}
                  >
                    <h6 style={{ color: '#586f7c' }}>YellowBird Template</h6>
                    {showTemplate ? <img style={{ cursor: 'pointer' }} src={UpIcon} alt='' /> : <img style={{ cursor: 'pointer' }} src={DownIcon} alt='' />}
                  </div>
                )}
                {coTemplates.length !== 0 && showTemplate ? (
                  <>
                    <div>
                      <div>
                        {coTemplates.map(template => {
                          return (
                            <div key={template.id}>
                              <a style={{ color: '#b4b4b4' }} target='_blank' href={template.s3Url} rel='noreferrer'>
                                {template.fileName} <RiFileDownloadFill />
                              </a>
                            </div>
                          );
                        })}
                      </div>
                      <hr />
                    </div>
                  </>
                ) : (
                  <>
                    {showTemplate ? (
                      <h6>
                        <div className='template-line'>
                          <a style={{ color: '#b4b4b4' }} href='https://yb-reportbucket.s3.amazonaws.com/Templates/Loss+Control+Report+.docx'>
                            Loss Control Survey Report
                            <RiFileDownloadFill className='download-icon' />
                          </a>
                        </div>
                        <div className='template-line'>
                          <a
                            style={{ color: '#b4b4b4' }}
                            href='https://yb-reportbucket.s3.amazonaws.com/Templates/OSHA+-+Safety+Assessment+Report+Template+(1).docx'
                          >
                            Safety Assessment/Mock Osha Inspection
                            <RiFileDownloadFill className='download-icon' />
                          </a>
                        </div>
                        <hr />
                      </h6>
                    ) : null}
                  </>
                )}
              </div>

              {job.external ? (
                <Button disabled={externalLinkLoading} onClick={handleExternalLinkClick} className='button primary-btn btn-text-white mt-2'>
                  {externalLinkLoading ? (
                    <Loading removeText />
                  ) : (
                    <>
                      <span>LC360</span> <BiLinkExternal />
                    </>
                  )}
                </Button>
              ) : (
                <div className='mt-3'>
                  <ReportUploader uploadDocuments={doc => uploadDocuments(doc)} jobId={job.id} />
                </div>
              )}
            </Row>
            <Row>
              {job.status === 'InProgress' && !job.completedFromPro && (
                <div>
                  <div>
                    {job.reasonToDeclineCompletion && (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <MdWarning size={36} color='red' />
                        <div style={{ marginLeft: '16px' }}>
                          <p>The company rejected previous completion of this job.</p>
                          <p>Reason: {job.reasonToDeclineCompletion}</p>
                          <p>Please make the necessary corrections before marking the job as complete again.</p>
                        </div>
                      </div>
                    )}
                  </div>
                  <br />
                  {jobInvite.isProDriving === null || jobInvite.isProDriving === false ? (
                    <div className='d-flex flex-row justify-content-center'>
                      <Button
                        className='btn primary-btn py-3 px-5'
                        style={{ fontWeight: 600, borderRadius: '12px' }}
                        onClick={() => {
                          setDisplayConfirmModal(true);
                          setSelectedOption('Are you sure you want to mark this job as completed?');
                        }}
                      >
                        <Text>Mark this Job as Completed</Text>
                      </Button>
                    </div>
                  ) : (
                    <div className='d-flex flex-row justify-content-center'>
                      <Button
                        className='btn primary-btn py-3 px-5'
                        style={{ fontWeight: 600, borderRadius: '12px' }}
                        onClick={() => {
                          if (values.milesDriven != null) {
                            setDisplayConfirmModal(true);
                            setSelectedOption('Are you sure you want to mark this job as completed?');
                          } else {
                            setFormError(true);
                          }
                        }}
                      >
                        <Text>Mark this Job as Completed</Text>
                      </Button>
                    </div>
                  )}
                </div>
              )}
              <br />
              {job.completedFromPro && (
                <div className='d-flex justify-content-center' style={{ fontWeight: 700 }}>
                  <h5>Job has been marked as completed and sent to Company for review.</h5>
                </div>
              )}
            </Row>
          </Col>
        </Row>
      )}
      {/* CONFIRM MODAL */}
      <Modal size='md' show={displayConfirmModal} centered onHide={() => setDisplayConfirmModal(false)}>
        <ConfirmModal
          onClickYes={markJobAsCompleted}
          onClickNo={onClickNo}
          message={selectedOption}
          reasonToDecline={reasonToDecline}
          setReasonToDecline={setReasonToDecline}
        />
      </Modal>
    </>
  );
}
