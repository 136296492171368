import React from 'react';

const OperationsEmail = () => {
  return (
    <>
      <a style={{ textDecoration: 'none', color: 'inherit', margin: 0 }} href='mailto:operations@goyellowbird.com'>
        operations@goyellowbird.com
      </a>
      .
    </>
  );
};

export default OperationsEmail;
