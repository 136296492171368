import React from 'react';
import { withFormik } from 'formik';
import { Alert, Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import { changePassword } from '../../../../slices/auth.slice';

import './resetPasswordForm.scss';

import Button from '../../../../components/Button';
import InputError from '../../../../components/InputError';
import TextInput from '../../../../components/TextInput';

const ResetPassword = ({ handleSubmit, handleChange, handleBlur, values, errors, touched, onCancel }) => {
  const auth = useSelector(state => state.auth);

  return (
    <form onSubmit={handleSubmit}>
      <Row className='mt-3'>
        <Col>
          <TextInput
            id='newPassword'
            name='newPassword'
            label='Enter New Password*'
            placeholder=''
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.newPassword}
            type='password'
          />
          {touched.newPassword && errors.newPassword && <InputError>{errors.newPassword}</InputError>}
        </Col>
      </Row>
      <Row className='mt-3'>
        <Col>
          <TextInput
            id='confirmNewPassword'
            name='confirmNewPassword'
            label='Confirm Password*'
            placeholder=''
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.confirmNewPassword}
            type='password'
          />
          {touched.confirmNewPassword && errors.confirmNewPassword && <InputError>{errors.confirmNewPassword}</InputError>}
        </Col>
      </Row>
      <Row className='mt-3'>
        <Col>
          <Button disabled={auth.isLoading} type='submit' className='button submit-btn btn-text-white reset-password__submit'>
            {auth.isLoading ? 'Loading...' : 'Update Password'}
          </Button>
        </Col>
      </Row>
      {auth.auth === 'success' && (
        <Row className='mt-3'>
          <Col>
            <Alert variant='success'>Password changed successfully.</Alert>
          </Col>
        </Row>
      )}
    </form>
  );
};

const ResetPasswordForm = withFormik({
  mapPropsToValues: () => ({
    newPassword: '',
    confirmNewPassword: '',
  }),
  validationSchema: Yup.object({
    newPassword: Yup.string().required('Required').min(6, 'Password must be at least 6 characters long'),
    confirmNewPassword: Yup.string()
      .required('Required')
      .oneOf([Yup.ref('newPassword')], 'Passwords must match'),
  }),
  handleSubmit: (values, { props, setSubmitting }) => {
    props.dispatch(
      changePassword({
        reset_password_token: props.token,
        password: values.newPassword,
        password_confirmation: values.confirmNewPassword,
      })
    );
  },
  displayName: 'ResetPassword',
})(ResetPassword);

export default ResetPasswordForm;
