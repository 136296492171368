import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import Footer from '../Footer';
import Header from '../Header';

import './mainLayout.scss';

export default function MainLayout({ children }: { children: React.ReactNode }) {
  return (
    <>
      <Header />
      <main role='main' className='flex-shrink-0'>
        <Container fluid className=' d-flex justify-content-center align-items-center px-0' style={{ maxWidth: '1280px', width: '100%' }}>
          <Row className='main__content-container'>
            <Col></Col>
            <Col style={{ width: '100%' }} className='justify-content-center d-flex' sm={12}>
              {children}
            </Col>
            <Col></Col>
          </Row>
        </Container>
      </main>
      <Footer />
    </>
  );
}
