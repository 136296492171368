import { useContext, useState } from 'react';
import { Eye, EyeOff } from 'lucide-react';
import { useFormContext } from 'react-hook-form';

import { MethodsContext } from '../context';
import Navigation from '../layout/navigation';

import './step-1.css';

const Step1 = () => {
  const {
    register,
    formState: { isValid, errors },
  } = useFormContext();
  const [showPassword, setShowPassword] = useState(false);
  const { handleInputChange } = useContext(MethodsContext);
  const freeEmailDomains = require('free-email-domains');

  return (
    <div className='multiStepContainer'>
      <h1 className='h1MultiStepForm'>
        Let's get to <br />
        know you!
      </h1>
      <div className='mainContainer1'>
        <div className='inputContainerForm'>
          <input
            className='inputMultiStep'
            {...register('firstName', {
              required: 'Required field.',
              minLength: {
                value: 2,
                message: 'Must be longer than two characters.',
              },
            })}
            type='text'
            placeholder='First Name*'
            onChange={e => handleInputChange(e, 'firstName')}
          />
          <p className='errorMessageInputForm'>{errors.firstName?.message?.toString()}</p>
        </div>
        <div className='inputContainerForm'>
          <input
            className='inputMultiStep'
            {...register('lastName', {
              required: 'Required field.',
              minLength: {
                value: 2,
                message: 'Must be longer than two characters.',
              },
            })}
            onChange={e => handleInputChange(e, 'lastName')}
            type='text'
            placeholder='Last Name*'
          />
          <p className='errorMessageInputForm'>{errors.lastName?.message?.toString()}</p>
        </div>
        <div className='inputContainerForm'>
          <input
            className='inputMultiStep'
            {...register('emailAddress', {
              required: 'Required field.',
              validate: value => {
                const domain = value.split('@')[1];
                if (freeEmailDomains.includes(domain)) return 'YellowBird requires business email use only.';
              },
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'The email you entered is invalid.',
              },
            })}
            type='email'
            placeholder='Work Email Address*'
            onChange={e => handleInputChange(e, 'emailAddress')}
          />
          <p className='errorMessageInputForm'>{errors.emailAddress?.message?.toString()}</p>
        </div>
        <div className='inputContainerForm'>
          <input
            className='inputMultiStep passwordStep0'
            {...register('password', {
              required: 'Required field.',
              pattern: {
                value:
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#(){}[\]¨´+\-_.'¿¡?=!><^`~«\\≠ºª∞¢£“∞§¶•/])[A-Za-z\d@$!%*?&#(){}[\]¨´+\-_.'¿¡?=!><^`~«\\≠ºª∞¢£“∞§¶•/]{8,24}$/,
                message:
                  'Must be longer than 8 characters that includes one lowercase and one uppercase letter, one number, and one of the specified special characters.',
              },
            })}
            onChange={e => handleInputChange(e, 'password')}
            type={showPassword ? 'text' : 'password'}
            placeholder='Password*'
          />
          <div onClick={() => setShowPassword(!showPassword)} className='eye'>
            {showPassword ? <Eye /> : <EyeOff />}
          </div>
          <p className='errorMessageInputForm'>{errors.password?.message?.toString()}</p>
        </div>
      </div>
      <Navigation disabled={isValid} />
    </div>
  );
};

export default Step1;
