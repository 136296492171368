import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';

import api from '../api';
import apiCamelback from '../api/apiCamelback';
import { Todo } from '../types';

export const getUser = createAsyncThunk<
  Todo, // The type of the return value of the payload creator
  void, // The type of the first argument to the payload creator
  {
    rejectValue: Todo; // The type for `thunkApi.rejectWithValue`, if error handling is customized
  }
>('users/getUser', async (_, thunkApi) => {
  try {
    const response = await apiCamelback.get('/api/v1/users/me');
    if (response.data.errors) {
      return thunkApi.rejectWithValue(response.data.errors);
    }
    const camelized = camelizeKeys(response.data.user);
    return camelized;
  } catch (error: unknown) {
    // Handle errors in axios request (includes HTTP status code errors)
    if (axios.isAxiosError(error) && error.response) {
      // If the error response is available and contains the response data
      return thunkApi.rejectWithValue(error.response.data?.message || 'Unknown server error');
    } else {
      // If the error is not from axios (not an AxiosError)
      console.error('An unknown error occurred', error);
      return thunkApi.rejectWithValue('An unknown error occurred');
    }
  }
});

export const updateUser = createAsyncThunk<
  Todo, // The type of the return value of the payload creator
  Todo, // The type of the first argument to the payload creator
  {
    rejectValue: Todo; // The type for `thunkApi.rejectWithValue`, if error handling is customized
  }
>('user/updateUser', async ({ updates }, thunkApi) => {
  try {
    let decamelized = decamelizeKeys(updates);
    const response = await api.put('/api/v1/users/me', decamelized);
    if (response.data.errors) {
      thunkApi.rejectWithValue(response.data.errors);
    }
    return camelizeKeys(response.data);
  } catch (error: unknown) {
    // Handle errors in axios request (includes HTTP status code errors)
    if (axios.isAxiosError(error) && error.response) {
      // If the error response is available and contains the response data
      return thunkApi.rejectWithValue(error.response.data?.message || 'Unknown server error');
    } else {
      // If the error is not from axios (not an AxiosError)
      console.error('An unknown error occurred', error);
      return thunkApi.rejectWithValue('An unknown error occurred');
    }
  }
});

export const updateReferralCode = createAsyncThunk<
  Todo, // The type of the return value of the payload creator
  Todo, // The type of the first argument to the payload creator
  {
    rejectValue: Todo; // The type for `thunkApi.rejectWithValue`, if error handling is customized
  }
>('user/updateReferralCode', async (firstName, thunkApi) => {
  const code = firstName.split(' ')[0].toUpperCase() + Math.floor(1000 + Math.random() * 9000);
  try {
    const response = await apiCamelback.post('/api/v1/user/referral', { code: code });
    if (response.data.errors) {
      thunkApi.rejectWithValue(response.data.errors);
    }
    return camelizeKeys(response.data);
  } catch (error: unknown) {
    // Handle errors in axios request (includes HTTP status code errors)
    if (axios.isAxiosError(error) && error.response) {
      // If the error response is available and contains the response data
      return thunkApi.rejectWithValue(error.response.data?.message || 'Unknown server error');
    } else {
      // If the error is not from axios (not an AxiosError)
      console.error('An unknown error occurred', error);
      return thunkApi.rejectWithValue('An unknown error occurred');
    }
  }
});

export const getQuickbooksRedirectUrl = createAsyncThunk<
  Todo, // The type of the return value of the payload creator
  Todo, // The type of the first argument to the payload creator
  {
    rejectValue: Todo; // The type for `thunkApi.rejectWithValue`, if error handling is customized
  }
>('workers/auth', async (_, thunkApi) => {
  try {
    const response = await api.get('/api/v1/workers/auth');
    if (response.data.errors) {
      thunkApi.rejectWithValue(response.data.errors);
    }
    return response.data;
  } catch (error: unknown) {
    // Handle errors in axios request (includes HTTP status code errors)
    if (axios.isAxiosError(error) && error.response) {
      // If the error response is available and contains the response data
      return thunkApi.rejectWithValue(error.response.data?.message || 'Unknown server error');
    } else {
      // If the error is not from axios (not an AxiosError)
      console.error('An unknown error occurred', error);
      return thunkApi.rejectWithValue('An unknown error occurred');
    }
  }
});

export const checkQbTokens = createAsyncThunk<
  Todo, // The type of the return value of the payload creator
  Todo, // The type of the first argument to the payload creator
  {
    rejectValue: Todo; // The type for `thunkApi.rejectWithValue`, if error handling is customized
  }
>('workers/checkTokens', async (_, thunkApi) => {
  try {
    const response = await api.get('/api/v1/workers/check_tokens');
    if (response.data.errors) {
      thunkApi.rejectWithValue(response.data.errors);
    }
    return response.data;
  } catch (error: unknown) {
    // Handle errors in axios request (includes HTTP status code errors)
    if (axios.isAxiosError(error) && error.response) {
      // If the error response is available and contains the response data
      return thunkApi.rejectWithValue(error.response.data?.message || 'Unknown server error');
    } else {
      // If the error is not from axios (not an AxiosError)
      console.error('An unknown error occurred', error);
      return thunkApi.rejectWithValue('An unknown error occurred');
    }
  }
});

export const sendQuickbooksAuthTokens = createAsyncThunk<
  Todo, // The type of the return value of the payload creator
  Todo, // The type of the first argument to the payload creator
  {
    rejectValue: Todo; // The type for `thunkApi.rejectWithValue`, if error handling is customized
  }
>('workers/sendQuickbooksAuthTokens', async (worker, thunkApi) => {
  try {
    const response = await api.post('/api/v1/workers/step2', { worker });
    if (response.data.errors) {
      thunkApi.rejectWithValue(response.data.errors);
    }
    return response.data;
  } catch (error: unknown) {
    // Handle errors in axios request (includes HTTP status code errors)
    if (axios.isAxiosError(error) && error.response) {
      // If the error response is available and contains the response data
      return thunkApi.rejectWithValue(error.response.data?.message || 'Unknown server error');
    } else {
      // If the error is not from axios (not an AxiosError)
      console.error('An unknown error occurred', error);
      return thunkApi.rejectWithValue('An unknown error occurred');
    }
  }
});

interface UserState {
  error: Todo;
  isLoading: boolean;
  user: Todo;
}

const initialState: UserState = {
  error: null,
  isLoading: false,
  user: {},
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getUser.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(getUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(getUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
      })

      .addCase(updateUser.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(updateUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(updateUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
      });
  },
});
