import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import { MethodsContext } from '../context';
import ReferralStep from '../steps/referral/referalStep';
import Step0 from '../steps/step-0';
import Step1 from '../steps/step-1';
import Step2 from '../steps/step-2';
import Step3 from '../steps/step-3/step-3';
import Step4 from '../steps/step-4/step-4';
import Step5 from '../steps/step-5';
import Step6 from '../steps/step-6/step-6';
import Header from './header';

import './form.css';

export default function MultiStepForm() {
  const [percentage, setPercentage] = useState(0);
  const next = () => setPercentage(percentage + 14);
  const back = () => setPercentage(percentage - 14);
  const { trigger, setValue } = useFormContext();
  const handleInputChange = (e, fieldValue) => {
    const currentValue = fieldValue === 'emailAddress' ? e.target.value.toLowerCase() : e.target.value;
    setValue(fieldValue, currentValue);
    trigger(fieldValue);
  };
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  useEffect(() => {
    if (queryParams.get('code')) {
      const code = queryParams.get('code') ?? null;
      setValue('referral.value', 'referralCode');
      setValue('referral.code', code);
    }
  }, []);

  return (
    <MethodsContext.Provider value={{ percentage, next, back, handleInputChange }}>
      <form className='formMain'>
        <Header />
        <div className='formGrow'>
          {percentage === 0 && <Step0 />}
          {percentage === 14 && <Step1 />}
          {percentage === 28 && <ReferralStep />}
          {percentage === 42 && <Step2 />}
          {percentage === 56 && <Step3 />}
          {percentage === 70 && <Step4 />}
          {percentage === 84 && <Step5 />}
          {percentage === 98 && <Step6 />}
        </div>
      </form>
    </MethodsContext.Provider>
  );
}
