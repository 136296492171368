import React from 'react';

import { c } from '../../constants/colors';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import Text from '../Text';

export default function NewButton({ label, main, onClick, blue, h, w, children, white }) {
  const { width } = useWindowDimensions();
  const styles = {
    main: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: w || width < 700 ? '100%' : '70%',
      height: h || 48,
      backgroundColor: '#ffcd03',
      border: 'none',
      borderRadius: 6,
      marginRight: 0,
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    },
    blue: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: w || width < 700 ? '100%' : '70%',
      height: h || 40,
      backgroundColor: c.lightBlue,
      border: 'none',
      borderRadius: 6,
      marginRight: 0,
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    },
    white: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: w || width < 700 ? '100%' : '70%',
      height: h || 40,
      backgroundColor: c.white,
      borderColor: c.grey,
      borderWidth: 1,
      borderRadius: 6,
      marginRight: 0,
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    },
  };
  return (
    <button onClick={onClick} style={main ? styles.main : blue ? styles.blue : white ? styles.white : null}>
      <Text t={blue ? 'whiteButtonText' : 'buttonText'}>
        {children}
        {label}
      </Text>
    </button>
  );
}
