import React from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Col, Container, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { z } from 'zod';

import Button from '../../components/Button';
import InputError from '../../components/InputError';
import Loading from '../../components/Loading';
import TextInput from '../../components/TextInput';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { useAppDispatch, useAppSelector } from '../../index';
import * as amplitude from '../../lib/amplitude';
import { loginUser } from '../../slices/auth.slice';
import { getUser } from '../../slices/user.slice';

import './signIn.scss';

const signInSchema = z.object({
  email: z.string().email('Invalid email address').min(1, 'Required'),
  password: z.string().min(1, 'Required'),
});

type SignInFormValues = z.infer<typeof signInSchema>;

export default function SignInPage() {
  const dispatch = useAppDispatch();
  const { error: authError, isLoading, redirectToDeepLink } = useAppSelector(state => state.auth);
  const navigate = useNavigate();
  const { width } = useWindowDimensions();

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<SignInFormValues>({
    mode: 'onBlur',
    resolver: zodResolver(signInSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  async function onSubmit(values: SignInFormValues) {
    try {
      const newValues = {
        email: values.email.toLowerCase(),
        password: values.password,
      };
      amplitude.setUserId(newValues.email);
      await dispatch(loginUser(newValues)).unwrap();
      amplitude.sendEvent(amplitude.Events.SIGN_IN_COMPLETE);
      const redirectTo = redirectToDeepLink || (redirect && `/${redirect}`);
      if (redirectTo) {
        navigate(redirectTo);
      } else {
        navigate('/dashboard');
      }
    } catch (error) {
      console.error(error);
    }
  }

  React.useEffect(() => {
    amplitude.setUserId('');
    amplitude.sendEvent(amplitude.Events.SIGN_IN_PAGE);
  }, []);

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  let redirect: string | null = null;

  if (params.redirect) {
    redirect = params.redirect;
  }

  if (params.token) {
    localStorage.setItem('token', params.token);
  }

  React.useEffect(() => {
    async function fetchUser() {
      const user = await dispatch(getUser()).unwrap();
      if (user && user.status === 'ACTIVE') {
        const redirectTo = redirectToDeepLink || (redirect && `/${redirect}`);
        if (redirectTo !== null) {
          navigate(redirectTo);
        } else {
          navigate('/dashboard');
        }
      }
    }
    if (localStorage.getItem('token')) {
      fetchUser();
    }
  }, [redirect, redirectToDeepLink, dispatch, navigate]);

  const errorMessage = React.useMemo(() => {
    if (authError) {
      if (authError === 'Unauthorized') {
        return 'Invalid credentials. Please try again.';
      } else if (authError === 'archived') {
        return 'Account locked. Please contact YellowBird.';
      } else {
        return 'An error occurred. Please try again.';
      }
    }
    return null;
  }, [authError]);

  return (
    <Container fluid style={{ maxWidth: '425px' }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h2 className='d-flex justify-content-center pb-3 pe-2' style={{ fontSize: '18px', fontWeight: 700 }}>
          Sign In
        </h2>
        <Row className='mb-3'>
          <Col>
            <TextInput id='email' type='email' label='Email' placeholder='Enter your email' autoComplete='email' {...register('email')} />
            {errors.email && <InputError>{errors.email.message}</InputError>}
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col>
            <TextInput
              id='password'
              type='password'
              label='Password'
              placeholder='Enter your password'
              autoComplete='current-password'
              {...register('password')}
            />
            {errors.password && <InputError>{errors.password.message}</InputError>}
            {errorMessage && <InputError>{errorMessage}</InputError>}
          </Col>
        </Row>
        <Row className='my-3 forgot-password' style={{ fontSize: '12px' }}>
          <Link to='/forgot-password' className='forgot-password-text'>
            Forgot Password?
          </Link>
        </Row>
        <Row>
          <Button
            disabled={isLoading}
            className={`button primary-btn mt-3 py-2 justify-content-center ${width >= 768 ? 'sign-in-button-desktop mx-5' : 'sign-in-button-mobile mx-2'}`}
            style={{ fontWeight: 700, fontSize: '16px' }}
            type='submit'
          >
            {isLoading ? <Loading removeText /> : 'Sign In'}
          </Button>
        </Row>
        <Row className='d-flex justify-content-center pt-2'>
          <p className='no-account' style={{ fontSize: '12px' }}>
            Don't have an account yet?
            <Link to='/register' className='signup-text ps-1'>
              Sign Up
            </Link>
          </p>
        </Row>
      </form>
    </Container>
  );
}
