import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import './availableService.scss';

import DownIcon from '../../assets/images/down-icon.svg';
import LeftArrow from '../../assets/images/left-icon.svg';
import UpIcon from '../../assets/images/up-icon.svg';
import Dropdown from '../../components/Dropdown';
import Loading from '../../components/Loading';
import NewButton from '../../components/NewButton';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { coRetrieveJobs, getMatrixData } from '../../slices/job.slice';

const mockAvailableServices = [
  {
    id: 1,
    name: 'Safety Manager',
    payRange: '$42 - $84+',
    priceType: 'Hourly',
    requirements: [
      'Safety Coordinator includes a basic level of EHS knowledge with 1 - 2 years of experience in related field.',
      'Mid Level Safety Manager includes 3 - 6 years of experience in related field and an ASP or equivalent.',
      'Senior Level Safety Manager includes 7 - 10 years of experience in related field and an CSP or equivalent. ',
      'Senior Level Safety Professional includes 10+ years of experience in related field and an CSP or equivalent.',
    ],
    description: [
      "Work with team members to assist in day-to-day activities of the department; assist in oversight of daily activities; assisting in coordination and preparation of reports, training manuals, and programs; assisting with job hazard analysis; conducting need analysis; implementing and overseeing company's employee safety at work; facilitating compliance with OSHA and local occupational health and safety guidelines; Conduct safety/environmental incident investigations and complete associated infraction reports; Inspect construction sites and company properties to ensure compliance and promote prevention; Conduct safety/environmental training; and maintaining personal protective equipment (PPE). ",
    ],
  },
  {
    id: 2,
    name: 'OSHA Mock Assessment',
    payRange: '$550 - $1,575',
    priceType: 'Flat Rate',
    requirements: ['5+ Years of Safety Expereince', ' CSP preferred but no required'],
    description: [
      'A comprehensive mock-OSHA inspection includes a review of your OSHA mandated written health and safety programs, employee safety training records, and injury / illness records. The audit also involves a walk through of your operations to identify safety hazards that represent violations of OSHA standards. Observe potential compliance gaps, review documentation, policy, and procedures, and perform a physical facility walk-thru.',
      'Note: Pay depends on size and complexity of facility.',
    ],
  },
  {
    id: 3,
    name: 'Loss Control Audit',
    payRange: '$245 - $1,050',
    priceType: 'Flat Rate',
    requirements: ['5+ Years of Safety Expereince', 'Prior Loss Control Experience', 'Experience with Loss Control Software and Reports preferred'],
    description: [
      'Comprehensive job site audits to determine adherence to the site specific safety program and identify gaps in management systems. The main purpose in conducting the field inspections is to evaluate all aspects of the facility in conjunction with safe work practices and identify potential loss exposures, hazardous conditions, and regulatory violations. The Pro will generate a field survey report based on the observed site conditions, including photographs and recommendations focused on eliminating potential loss exposures and ensuring workforce compliance.',
      'Note: Pay depends on size and complexity of facility.',
    ],
  },
  {
    id: 4,
    name: 'Policy Development',
    payRange: '$70 - $150',
    priceType: 'Hourly',
    requirements: ['5+ years of safety experience in related field.'],
    description: ['Developing Safety Plans, policies, and procedures according to the specific industry.', 'Note: Pay depends on Virtual or Onsite need'],
  },
  {
    id: 5,
    name: 'OSHA 10/30 Training',
    payRange: '$1,540 / $4,200',
    priceType: 'Flat Rate',
    requirements: ['1+ years of Safety Experience', 'OSHA 501/500/510/511 Required'],
    description: [
      'The 10-hour safety course covers general safety and health hazards for entry-level workers.',
      '2 days, minimum 3 students, max 30 students.',
      'The 30-hour safety course provides a greater variety of safety subjects and in-depth, industry-specific training and is intended for supervisors and workers with safety and health responsibility.',
      '4 or more days, minimum 3 students, max 30 students',
    ],
  },
  {
    id: 6,
    name: 'Industrial Hygiene',
    payRange: '$70 - $125',
    priceType: 'Hourly',
    requirements: [
      "Industrial Hygiene Specialists require a bachelor's degree as a minimum. With a BS or BA in mechanical engineering, chemical engineering, chemistry, physics, biology, industrial hygiene, industrial safety, or a related field.",
      "Certified Industrial Hygienists (CIH) require a master's degree; at least 4 years of professional level comprehensive industrial hygiene practice; and currently practicing 'broad-scope' industrial hygiene.",
    ],
    description: [
      'Industrial Hygienist jobs include advising and collaborating with management to resolve occupational health issues; creating and executing studies to monitor and assess employees’ exposure to health risks, and to measure the effectiveness of occupational health programs, policies, and procedures; overseeing biological monitoring programs; assessing and analyzing research data, summarizing statistics, and compiling reports; proposing and leading the implementation of programs to protect employee health, and implementing ergonomics; conducting regular audits of programs; ensuring employee awareness of occupational health and safety concerns, programs, and regulations by conducting training and presentations.',
    ],
  },
];

export default function AvailableService() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.user);
  const { jobParents, jobChildren } = useSelector(state => state.job);

  const [jobParent, setJobParent] = useState();
  const [jobChild, setJobChild] = useState('Select');
  const [childOptions, setChildOptions] = useState([]);
  const [service, setService] = useState();
  const [showRequirements, setShowRequirements] = useState(true);
  const [values, setValues] = useState({
    typeOfWork: '',
    jobType: '',
    service: '',
    typeOfService: '',
    estimatedRate: '',
    priceType: '',
    enteredRate: '',
  });
  const { id } = useParams();

  const { width } = useWindowDimensions();

  useEffect(() => {
    dispatch(coRetrieveJobs());
    dispatch(getMatrixData());
  }, []);

  const handleRoleSelect = async e => {
    const typeOfService = e.target.value;
    setValues({ ...values, typeOfService: typeOfService });
    let job = await jobChildren.find(item => item.label === e.target.value);
    setJobChild(job);
  };

  useEffect(() => {
    if (user?.role === 'worker') {
      const tempService = mockAvailableServices.find(x => x.id === parseInt(id));
      setJobParent(tempService);
      setService(tempService);
    } else {
      // if jobParents have children,
      const jobParentObj = jobParents.find(x => x.id === parseInt(id));
      if (jobParentObj?.hasChildren) {
        if (id === '10') {
          let forkliftOption = jobChildren.filter(item => item.label === 'Forklift Training');
          setChildOptions(forkliftOption);
          setJobParent(jobParentObj);
          setJobChild(forkliftOption[0]);
        } else {
          let options = jobChildren.filter(item => item.parentJobId === jobParentObj.id);
          setChildOptions(options);
          setJobParent(jobParentObj);
          setJobChild(options[1]);
        }
      } else {
        setJobParent(jobParentObj);
      }
    }
  }, [id, jobParents, jobChildren, user?.role]);

  if (!jobParent) {
    return (
      <>
        <Loading />
        <p>job parent</p>
      </>
    );
  }

  if (user?.role === 'worker') {
    // pro
    return (
      <Container fluid style={{ padding: '40px', marginBottom: '3rem', maxHeight: width >= 768 ? '130vh' : '100vh' }}>
        <Row className='mb-5' style={{ fontWeight: 600, fontSize: '22px' }}>
          <div
            onClick={() => navigate('/dashboard')}
            onKeyDown={e => e.key === 'Enter' && navigate('/dashboard')}
            className='d-flex flex-row ps-0 available-service-return'
          >
            <img className='pe-2' width='18' height='30' src={LeftArrow} alt='' />
            <span>{service.name}</span>
          </div>
        </Row>
        <Row className='d-flex flex-row '>
          <Col className='ps-0'>
            <h5 style={{ fontWeight: 600 }} className='pb-3'>
              Details
            </h5>
            <div className='d-flex flex-row justify-content-between' style={{ borderBottom: '1px solid #b4b4b4' }}>
              <h5 style={{ color: '#707070', fontSize: '18px', fontWeight: 600 }}>Pay Range</h5>
              <p>{service.payRange}</p>
            </div>
            <div
              onClick={() => setShowRequirements(!showRequirements)}
              className='d-flex flex-row justify-content-between py-2 pb-3'
              style={{ cursor: 'pointer' }}
            >
              <h5 style={{ color: '#707070', fontSize: '18px', fontWeight: 600 }}>Requirements</h5>
              {showRequirements ? <img style={{ cursor: 'pointer' }} src={UpIcon} alt='' /> : <img style={{ cursor: 'pointer' }} src={DownIcon} alt='' />}
            </div>
            {showRequirements
              ? service.requirements.map(requirement => {
                  return (
                    <>
                      <p className='ms-1'>{requirement}</p>
                    </>
                  );
                })
              : null}
          </Col>
          <Col md={1}></Col>
          <Col className='description-card p-4 ms-0 ps-md-4 ms-md-4'>
            <h5 style={{ fontWeight: 600 }}>Description</h5>
            {service.description.map(description => {
              return (
                <>
                  <p className='ms-1'>{description}</p>
                </>
              );
            })}
          </Col>
        </Row>
      </Container>
    );
  } else {
    // co
    return (
      <Container fluid style={{ padding: '40px' }}>
        <Row className='mb-5' style={{ fontWeight: 600, fontSize: '22px' }}>
          <div
            onClick={() => navigate('/dashboard')}
            onKeyDown={e => e.key === 'Enter' && navigate('/dashboard')}
            className='d-flex flex-row ps-0 available-service-return'
          >
            <img className='pe-2' width='18' height='30' src={LeftArrow} alt='' />
            <span>{jobParent.label}</span>
          </div>
        </Row>
        <Row className='description-body d-flex flex-column gap-20'>
          <Col className='ps-0'>
            <h5 style={{ fontWeight: 700 }} className='pb-3'>
              Details
            </h5>
            <div>
              {childOptions && childOptions.length > 0 && (
                <Dropdown
                  value={jobChild?.label || ''}
                  onChange={e => handleRoleSelect(e)}
                  label='Select Type of Service'
                  placeholder='Select'
                  style={{ cursor: 'pointer' }}
                  options={childOptions}
                  oA
                />
              )}
            </div>
            <Row className='d-flex justify-content-between'>
              <Col md={jobChild.priceType === 'Hourly' ? 9 : 7} style={{ color: '#707070', fontWeight: 700 }}>
                Price
              </Col>
              <Col md={jobChild.priceType === 'Hourly' ? 3 : 4} style={{ color: '#707070', whiteSpace: 'nowrap' }}>
                <>
                  {jobChild?.price} {jobChild.priceType === 'Flat' ? `${jobChild?.priceType} Rate` : jobChild?.priceType}
                </>
              </Col>
            </Row>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 24, width: '100%' }}>
              <NewButton
                onClick={() => {
                  navigate(`/dashboard/?action=post-a-job&parentId=${jobParent.id}&childId=${jobChild.id}`);
                }}
                main
                label='Post This Job'
              />
            </div>
          </Col>
          <Col md={1}></Col>
          {jobChild?.description ? (
            <Col className='description-card p-4'>
              <h5 style={{ fontWeight: 600 }}>Job Details</h5>
              <p>{jobChild?.description || null}</p>
            </Col>
          ) : null}
        </Row>
      </Container>
    );
  }
}
