import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';

import { MethodsContext } from './context';

const ConditionedInput = ({ fieldValue, placeholder, error }) => {
  const { register } = useFormContext();
  const { handleInputChange } = useContext(MethodsContext);

  return (
    <div className='inputContainerForm'>
      <input
        className='inputMultiStep'
        autoFocus
        {...register(fieldValue, { required: 'Required field.' })}
        type='text'
        placeholder={placeholder}
        onChange={e => handleInputChange(e, fieldValue)}
      />
      <p className='errorMessageInputForm'>{error}</p>
    </div>
  );
};

export default ConditionedInput;
