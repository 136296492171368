import React from 'react';

import './textInput.scss';

interface TextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  overrideContainerStyle?: React.CSSProperties;
}

const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(({ label, overrideContainerStyle, ...props }, ref) => {
  return (
    <div className='text-input-container' style={overrideContainerStyle || {}}>
      {label ? <label className='text-input__label'>{label}</label> : null}
      <input {...props} ref={ref || null} className='text-input__input form-control' />
    </div>
  );
});

export default TextInput;
