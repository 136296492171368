import React from 'react';

import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import TextInput from '../../../components/TextInput';

import './ConfirmationModal.scss';

import { CONTACT_US_1800_NUMBER } from '../../../env';

export default function ConfirmationModal({ response, modalOpen, setModalOpen, declineJob, acceptJob }) {
  const reasonsForDeclineJob = [
    {
      id: 'Unavailable on dates provided',
      name: 'Unavailable on dates provided',
    },
    {
      id: 'Not available at the scheduled time',
      name: 'Not available at the scheduled time',
    },
    {
      id: 'Hourly rate too low',
      name: 'Hourly rate too low',
    },
    {
      id: 'Don’t have the required skills/experience/certs',
      name: 'Don’t have the required skills/experience/certs',
    },
    {
      id: 'Not interested in YellowBird Jobs',
      name: 'Not interested in YellowBird Jobs',
    },
  ];

  const getSelectedReasons = () => {
    if (!document.getElementById('job-invite__modal-decline-form')) return '';
    let selectedReasons = [];
    const inputs = document.getElementById('job-invite__modal-decline-form').getElementsByTagName('input');
    for (let i = 0; i < inputs.length; i++) {
      if (inputs[i].type === 'checkbox' && inputs[i].checked) {
        selectedReasons.push(inputs[i].id);
      } else if (inputs[i].type === 'text' && inputs[i].value.trim() !== '') {
        selectedReasons.push(inputs[i].value);
      }
    }
    return selectedReasons.join(', ');
  };

  return (
    <Modal show={modalOpen} onHide={() => setModalOpen(false)}>
      {response === 'accepted' && (
        <>
          <h5 className='job-invite__modal-important-label'>IMPORTANT!</h5>
          <p className='job-invite__modal-text'>
            Please confirm you can <span style={{ fontWeight: 'bold' }}>meet all requirements</span> in the Job Invite. By clicking Confirm below, you are
            committing to delivering the requirements as defined in the timeline stated, and that you have the skills, experience and certifications listed as
            required.
          </p>
          <Button className='button primary-btn btn-text-black job-invite__modal-button' onClick={acceptJob}>
            Accept Project
          </Button>
          <p className='job-invite__modal-text'>
            If you need to change your answer, please reach out to{' '}
            <a className='job-invite__modal-contact-link' href='mailto:sales@goyellowbird.com'>
              sales@goyellowbird.com
            </a>{' '}
            or call{' '}
            <a className='job-invite__modal-contact-link job-invite__modal-contact-link__phone' href={`tel:${CONTACT_US_1800_NUMBER}`}>
              {CONTACT_US_1800_NUMBER}
            </a>
            .
          </p>
        </>
      )}
      {response === 'declined' && (
        <>
          <p className='job-invite__modal-text'>
            Are you sure you want to <span style={{ fontWeight: 'bold' }}>decline</span> the project?
          </p>
          <p className='job-invite__modal-text'>Please specify your reasons to decline:</p>
          <form id='job-invite__modal-decline-form'>
            <div className='job-invite__modal-decline-choices'>
              {reasonsForDeclineJob.map((reason, _index) => (
                <div className='job-invite__modal-decline-choice'>
                  <input type='checkbox' name='job-invite__modal-decline-options' id={reason.id} />
                  <span className='job-invite__modal-decline-options__tick'></span>
                  <label for={reason.id}>{reason.name}</label>
                  <br />
                </div>
              ))}
            </div>
            <TextInput type='text' name='otherReason' id='resonForDeclineOther' placeholder='Enter other reason' />
          </form>
          <Button className='button primary-btn btn-text-black job-invite__modal-button' onClick={() => declineJob(getSelectedReasons())}>
            Decline Project
          </Button>
          <p className='job-invite__modal-text'>
            If you need to change your answer, please reach out to{' '}
            <a className='job-invite__modal-contact-link' href='mailto:sales@goyellowbird.com'>
              sales@goyellowbird.com
            </a>{' '}
            or call{' '}
            <a className='job-invite__modal-contact-link job-invite__modal-contact-link__phone' href={`tel:${CONTACT_US_1800_NUMBER}`}>
              {CONTACT_US_1800_NUMBER}
            </a>
            .
          </p>
        </>
      )}
    </Modal>
  );
}
