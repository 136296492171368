import React from 'react';
import { useFormContext } from 'react-hook-form';

const CheckboxOption = ({ value, text }) => {
  const { register } = useFormContext();

  return (
    <div className='checkBoxesForm'>
      <input className='checkBoxMultiStep' {...register(`types.${value}`)} id={value} type='checkbox' />
      <label className='labelTextForm' htmlFor={value}>
        {text}
      </label>
    </div>
  );
};

export default CheckboxOption;
