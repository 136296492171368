import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import Button from '../../../components/Button';
import InputError from '../../../components/InputError';
import TextInput from '../../../components/TextInput';
import { updatePassword } from '../../../slices/auth.slice';

import './updatePassword.scss';

UpdatePasswordForm.propTypes = {
  onCancel: PropTypes.func,
};

export default function UpdatePasswordForm({ onCancel }) {
  const dispatch = useDispatch();
  const { handleSubmit, handleChange, handleBlur, values, errors, touched } = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      passwordChangeStatus: null,
    },
    validationSchema: Yup.object({
      currentPassword: Yup.string().required('Required'),
      newPassword: Yup.string().required('Required').min(6, 'Password must be at least 6 characters long'),
      confirmPassword: Yup.string()
        .required('Required')
        .oneOf([Yup.ref('newPassword')], 'Passwords must match'),
    }),
    onSubmit: values => {
      dispatch(updatePassword({ password: values.newPassword }));
      values.passwordChangeStatus = 'Password changed successfully.';
      values.currentPassword = '';
      values.newPassword = '';
      values.confirmPassword = '';
      setTimeout(() => {
        onCancel();
      }, 1000);
    },
  });
  return (
    <Container fluid className='px-0'>
      <Row>
        <Col style={{ width: 768 }}>
          <div style={{ fontWeight: 600, fontSize: '1.25rem' }}>Change Password</div>
        </Col>
      </Row>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <TextInput
              id='currentPassword'
              name='currentPassword'
              label='Current Password'
              placeholder='Enter Your Current Password'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.currentPassword}
              type='password'
            />
            {touched.currentPassword && errors.currentPassword ? <InputError>{errors.currentPassword}</InputError> : null}
          </Col>
        </Row>
        <Row className='mt-3'>
          <Col>
            <TextInput
              id='newPassword'
              name='newPassword'
              label='New Password'
              placeholder='Enter Your New Password'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.newPassword}
              type='password'
            />
            {touched.newPassword && errors.newPassword ? <InputError>{errors.newPassword}</InputError> : null}
          </Col>
        </Row>
        <Row className='mt-3'>
          <Col>
            <TextInput
              id='confirmPassword'
              name='confirmPassword'
              label='Confirm Password'
              placeholder='Confirm Your New Password'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.confirmPassword}
              type='password'
            />
            {touched.confirmPassword && errors.confirmPassword ? <InputError>{errors.confirmPassword}</InputError> : null}
          </Col>
        </Row>
        {values.passwordChangeStatus && (
          <Row className='mt-3'>
            <Col>
              <Alert variant='success'>{values.passwordChangeStatus}</Alert>
            </Col>
          </Row>
        )}
        <Row className='my-3'>
          <Col sm={12} className='d-flex justify-content-center'>
            <Button className='button primary-btn change-password-submit' type='submit' style={{ fontWeight: 700, fontSize: '16px' }}>
              Reset Password
            </Button>
          </Col>
        </Row>
      </form>
    </Container>
  );
}
