import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { camelizeKeys } from 'humps';

import apiCamelback from '../api/apiCamelback';
import { Todo } from '../types';

type Stage = 'restart' | 'complete';

type GlobalTaskPayload = {
  stage: Stage;
  globalTaskType: 'SETUP_PAYOUT';
};

type CustomTaskPayload = {
  taskSid: string;
  stage: Stage;
};

export const updateGlobalSetupPayoutTaskForPro = createAsyncThunk<
  Todo, // The type of the return value of the payload creator
  GlobalTaskPayload, // The type of the first argument to the payload creator
  {
    rejectValue: Todo; // The type for `thunkApi.rejectWithValue`, if error handling is customized
  } // The type of the `rejectWithValue` method on the `thunkApi` argument
>('task/updateGlobalSetupPayoutTaskForPro', async ({ globalTaskType, stage }, thunkApi) => {
  try {
    // const response = await apiCamelback.put(`/api/v1/user/workers/job-tasks/update-global-payout-task/${stage}`);
    const response = await apiCamelback.post(`/api/v1/user/workers/job-tasks/global/${globalTaskType}`, { stage });
    if (response.data.errors) {
      return thunkApi.rejectWithValue(response.data.errors);
    }
    const camelized = camelizeKeys(response.data);
    return camelized;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      return thunkApi.rejectWithValue(error.response.data?.message || 'Unknown server error');
    } else {
      console.error('An unknown error occurred', error);
      return thunkApi.rejectWithValue('An unknown error occurred');
    }
  }
});

export const updateGlobalSetupPayoutTaskForCompany = createAsyncThunk<
  Todo, // The type of the return value of the payload creator
  GlobalTaskPayload, // The type of the first argument to the payload creator
  {
    rejectValue: Todo; // The type for `thunkApi.rejectWithValue`, if error handling is customized
  } // The type of the `rejectWithValue` method on the `thunkApi` argument
>('task/updateGlobalSetupPayoutTaskForCompany', async ({ globalTaskType, stage }, thunkApi) => {
  try {
    const response = await apiCamelback.post(`/api/v1/user/company/job-tasks/global/${globalTaskType}`, { stage });
    if (response.data.errors) {
      return thunkApi.rejectWithValue(response.data.errors);
    }
    const camelized = camelizeKeys(response.data);
    return camelized;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      return thunkApi.rejectWithValue(error.response.data?.message || 'Unknown server error');
    } else {
      console.error('An unknown error occurred', error);
      return thunkApi.rejectWithValue('An unknown error occurred');
    }
  }
});

export const updateCustomTaskForPro = createAsyncThunk<
  Todo, // The type of the return value of the payload creator
  CustomTaskPayload, // The type of the first argument to the payload creator
  {
    rejectValue: Todo; // The type for `thunkApi.rejectWithValue`, if error handling is customized
  }
>('task/updateCustomTaskForPro', async ({ taskSid, stage }, thunkApi) => {
  try {
    const response = await apiCamelback.post(`/api/v1/user/workers/job-tasks/${taskSid}`, { stage });
    if (response.data.errors) {
      return thunkApi.rejectWithValue(response.data.errors);
    }
    const camelized = camelizeKeys(response.data);
    return camelized;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      return thunkApi.rejectWithValue(error.response.data?.message || 'Unknown server error');
    } else {
      console.error('An unknown error occurred', error);
      return thunkApi.rejectWithValue('An unknown error occurred');
    }
  }
});

export const updateCustomTaskForCompany = createAsyncThunk<
  Todo, // The type of the return value of the payload creator
  CustomTaskPayload, // The type of the first argument to the payload creator
  {
    rejectValue: Todo; // The type for `thunkApi.rejectWithValue`, if error handling is customized
  }
>('task/updateCustomTaskForCompany', async ({ taskSid, stage }, thunkApi) => {
  try {
    const response = await apiCamelback.post(`/api/v1/user/company/job-tasks/${taskSid}`, { stage });
    if (response.data.errors) {
      return thunkApi.rejectWithValue(response.data.errors);
    }
    const camelized = camelizeKeys(response.data);
    return camelized;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      return thunkApi.rejectWithValue(error.response.data?.message || 'Unknown server error');
    } else {
      console.error('An unknown error occurred', error);
      return thunkApi.rejectWithValue('An unknown error occurred');
    }
  }
});

// Fetch uncompleted tasks for a worker (Pro)
export const fetchProUncompletedTasks = createAsyncThunk<
  Todo, // The type of the return value of the payload creator
  void, // The type of the first argument to the payload creator
  {
    rejectValue: Todo; // The type for `thunkApi.rejectWithValue`, if error handling is customized
  }
>('task/fetchProUncompletedTasks', async (_, thunkApi) => {
  try {
    const response = await apiCamelback.get(`/api/v1/user/workers/job-tasks/amount`);
    if (response.data.errors) {
      return thunkApi.rejectWithValue(response.data.errors);
    }
    const camelized = camelizeKeys(response.data);
    return camelized;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      return thunkApi.rejectWithValue(error.response.data?.message || 'Unknown server error');
    } else {
      console.error('An unknown error occurred', error);
      return thunkApi.rejectWithValue('An unknown error occurred');
    }
  }
});

// Fetch uncompleted tasks for a company
export const fetchCompanyUncompletedTasks = createAsyncThunk<
  Todo, // The type of the return value of the payload creator
  void, // The type of the first argument to the payload creator
  {
    rejectValue: Todo; // The type for `thunkApi.rejectWithValue`, if error handling is customized
  }
>('task/fetchCompanyUncompletedTasks', async (_, thunkApi) => {
  try {
    const response = await apiCamelback.get(`/api/v1/user/company/job-tasks/amount`);
    if (response.data.errors) {
      return thunkApi.rejectWithValue(response.data.errors);
    }
    const camelized = camelizeKeys(response.data);
    return camelized;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      return thunkApi.rejectWithValue(error.response.data?.message || 'Unknown server error');
    } else {
      console.error('An unknown error occurred', error);
      return thunkApi.rejectWithValue('An unknown error occurred');
    }
  }
});

type TasksState = {
  error: string | null;
  isLoading: boolean;
  task: Todo;
  tasksCount: Todo;
};

const initialState: TasksState = {
  error: null,
  isLoading: false,
  task: {},
  tasksCount: {},
};

export const tasksSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      // updateCustomTaskForPro
      .addCase(updateCustomTaskForPro.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateCustomTaskForPro.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(updateCustomTaskForPro.fulfilled, (state, action) => {
        state.task = action.payload;
        state.error = null;
        state.isLoading = false;
      })
      // updateCustomTaskForCompany
      .addCase(updateCustomTaskForCompany.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateCustomTaskForCompany.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(updateCustomTaskForCompany.fulfilled, (state, action) => {
        state.task = action.payload;
        state.error = null;
        state.isLoading = false;
      })
      // fetchProUncompletedTasks
      .addCase(fetchProUncompletedTasks.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchProUncompletedTasks.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchProUncompletedTasks.fulfilled, (state, action) => {
        state.tasksCount = action.payload;
        state.error = null;
        state.isLoading = false;
      })
      // fetchCompanyUncompletedTasks
      .addCase(fetchCompanyUncompletedTasks.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchCompanyUncompletedTasks.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchCompanyUncompletedTasks.fulfilled, (state, action) => {
        state.tasksCount = action.payload;
        state.error = null;
        state.isLoading = false;
      });
  },
});
