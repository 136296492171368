import { Menu, Transition } from '@headlessui/react';
import { LogOut as LogOutIcon, Settings as SettingsIcon, User as UserIcon } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'src';
import ProfilePictureInitials from 'src/components/ProfilePictureInitials';
import useSignOut from 'src/hooks/useSignOut';

export default function TopNavbarDropdown() {
  const { user } = useAppSelector(state => state.user);
  const navigate = useNavigate();
  const signOut = useSignOut();

  return (
    <div className='z-10'>
      <Menu as='div' className='relative inline-block text-left'>
        <Menu.Button>
          <div className='w-10 h-10'>
            <ProfilePictureInitials firstName={user.firstName} lastName={user.lastName} />
          </div>
        </Menu.Button>
        <Transition
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items className='p-3 absolute right-0 mt-2 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none'>
            <div className='flex gap-2 text-black items-center mb-2.5'>
              <div className='w-10 h-10'>
                <ProfilePictureInitials firstName={user.firstName} lastName={user.lastName} />
              </div>
              <div>
                <div className='text-slate-600 font-semibold text-lg'>
                  <span>{user.firstName} </span>
                  <span>{user.lastName}</span>
                </div>
                <p className='text-gray-500 text-sm'>{user.email}</p>
              </div>
            </div>
            <div className='px-1 py-1 grid gap-2'>
              {user.role === 'worker' && (
                <Menu.Item>
                  <div
                    onClick={() => navigate('/my-profile')}
                    className={`text-black flex gap-2 cursor-pointer rounded p-2 hover:bg-gray-100 duration-200 transition`}
                  >
                    <UserIcon />
                    My Profile
                  </div>
                </Menu.Item>
              )}
              <Menu.Item>
                <div
                  onClick={() => navigate('/myaccount')}
                  className={`text-black flex gap-2 cursor-pointer rounded p-2 hover:bg-gray-100 duration-200 transition`}
                >
                  <SettingsIcon />
                  Manage Settings
                </div>
              </Menu.Item>
            </div>
            <div className='px-1 py-1'>
              <Menu.Item>
                <div onClick={signOut} className={`text-black flex gap-2 cursor-pointer rounded p-2 hover:bg-gray-100 duration-200 transition`}>
                  <LogOutIcon />
                  <button>Log Out</button>
                </div>
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
