import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import Loading from '../../components/Loading';
import { chargeCard, chargeCardPub, getCompanyPaymentMethods } from '../../slices/company.slice';
import { retrieveSingleCompanyJob, updateJobDetails } from '../../slices/job.slice';
import DeclineModal from './DeclineModal';
import InfoModal from './InfoModal';
import JobOrder from './JobOrder';

export default function NewJobOrder() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { referenceNumber } = useParams();
  const { jobOrder } = useSelector(state => state.job);
  const { paymentMethods, isLoading: paymentLoading } = useSelector(state => state.company);
  const [loading, setLoading] = useState(false);
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [showModal, setShowModal] = useState(true);
  const [step, setStep] = useState(1);
  const [notAgain, setNotAgain] = useState(false);

  useEffect(() => {
    let storedNotAgain = localStorage.getItem('notAgain');
    if (storedNotAgain === 'true') {
      setShowModal(false);
    }
    async function fetchSingleCompanyJob() {
      if (referenceNumber) {
        setLoading(true);
        try {
          const job = await dispatch(retrieveSingleCompanyJob({ referenceNumber })).unwrap();
          if (job) {
            dispatch(getCompanyPaymentMethods());
          }
        } catch (err) {
          console.error(err);
          toast.error('Error retrieving job order. Please try again later.');
        } finally {
          setLoading(false);
        }
      }
    }
    fetchSingleCompanyJob();
  }, [referenceNumber, dispatch]);

  const handleNext = () => {
    if (notAgain) {
      localStorage.setItem('notAgain', 'true');
    }
    setShowModal(false);
  };

  const onResponseToJobOrder = async (jobId, response, reasonToDecline) => {
    try {
      if (response === 'accepted' && jobOrder.paymentMethodSelection === 'Card' && jobOrder.stripePaymentMethod) {
        const token = localStorage.getItem('token');
        if (!token) {
          const payload = await dispatch(
            chargeCardPub({ payment_method: jobOrder.stripePaymentMethod, amount: jobOrder.estimatedTotalCents, reference_number: referenceNumber })
          ).unwrap();
          if (payload === 'Your card was declined.') {
            setShowDeclineModal(true);
          } else {
            await dispatch(updateJobDetails({ jobId, orderStatus: response, reasonToDecline })).unwrap();
            navigate('/dashboard');
          }
        } else {
          const payload = await dispatch(chargeCard({ payment_method: jobOrder.stripePaymentMethod, amount: jobOrder.estimatedTotalCents })).unwrap();
          if (payload === 'Your card was declined.') {
            setShowDeclineModal(true);
          } else {
            await dispatch(updateJobDetails({ jobId, orderStatus: response, reasonToDecline })).unwrap();
            navigate('/dashboard');
          }
        }
      } else {
        await dispatch(updateJobDetails({ jobId, orderStatus: response, reasonToDecline })).unwrap();
        navigate('/dashboard');
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  if (!jobOrder || loading) return <Loading />;

  return (
    <div style={{ width: '100%', height: '100%', padding: '40px' }}>
      {showDeclineModal ? <DeclineModal jobId={jobOrder.id} showModal={showDeclineModal} setShowModal={setShowDeclineModal} /> : null}
      {referenceNumber && <JobOrder paymentLoading={paymentLoading} paymentMethods={paymentMethods} responseToJobOrder={onResponseToJobOrder} />}
      {/*
      <InfoModal
        showModal={showModal}
        setShowModal={setShowModal}
        handleNext={handleNext}
        notAgain={notAgain}
        setNotAgain={setNotAgain}
        step={step}
        setStep={setStep}
      />
      */}
    </div>
  );
}
