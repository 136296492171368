import { memo } from 'react';
import Countdown from 'react-countdown';

const CountDownTimer = memo(({ setResendState, resendState }) => {
  const renderer = ({ seconds }) => <p className='countDown'>00:{seconds < 10 ? `0${seconds}` : seconds}</p>;

  const onCompleteCountDown = () => {
    setResendState({ ...resendState, disabled: false });
  };

  return <Countdown autoStart onComplete={onCompleteCountDown} renderer={renderer} date={Date.now() + 59000} />;
});

export default CountDownTimer;
