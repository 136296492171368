import React, { useEffect, useState } from 'react';

import './signedInLayout.scss';

import Container from 'react-bootstrap/Container';
import { useSelector } from 'react-redux';

import yellowbirdlogo from '../../../assets/icons/yellowbirdlogo.png';
import useValidateUser from '../../../hooks/useValidateUser';
import FirstTimeUserModal from '../../FirstTimeUserModal';
import Sidebar from '../Sidebar';
import SignedInHeader from '../SignedInHeader';

export default function SignedInLayout({ children }) {
  const { validateUserState, user } = useValidateUser();
  const isLoading = useSelector(state => state.user.isLoading);
  const [open, setOpen] = useState(0);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('firstTimeUser') === 'true') {
      setModal(true);
    }
  }, []);

  const closeModal = () => {
    localStorage.removeItem('firstTimeUser');
    setModal(false);
  };

  if (isLoading || validateUserState === 'initializing') {
    return (
      <div className='loadingLayout'>
        <img className='yellowbird-logo-loadingstate' src={yellowbirdlogo} alt='Yellow bird logo' />
      </div>
    );
  }

  return (
    <div style={{ backgroundColor: user.role === 'worker' ? '#97B2C3' : '#66798c', transition: '0.5s' }} className='signedin-container'>
      {!modal ? null : <FirstTimeUserModal onClose={closeModal} />}
      <div style={{ width: '100%', maxWidth: '1280px', position: 'relative' }}>
        <SignedInHeader open={open} setOpen={setOpen} />
        <Container fluid className='d-flex px-0'>
          <Sidebar open={open} setOpen={setOpen} />
          <div style={{ width: '100%' }} className='signedin-dashboard'>
            {children}
          </div>
        </Container>
      </div>
    </div>
  );
}
