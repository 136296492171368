import React, { useEffect, useState } from 'react';

import './numberButtons.scss';

import Button from '../../components/Button';
import Loading from '../../components/Loading';

const NumberButtons = ({ answers, setAnswers, question, setPage, page, questionLength, selected, setSelected, onFinish, scores, setScores }) => {
  const [showNext, setShowNext] = useState(false);
  let numButtons = [];
  let textButtons = [];

  useEffect(() => {
    if (answers[page]?.a) {
      setShowNext(true);
    }
  }, [answers, page]);

  if (question.answerType === 'buttons') {
    for (let i = 0; i < question.quantity; i++) {
      let value = i + 1;
      let text = i + 1;
      numButtons.push({ value, text });
    }
  } else if (question.answerType === 'textButtons') {
    for (let i = 0; i < question.quantity; i++) {
      let { value, text } = question.options[i];
      textButtons.push({ value, text });
    }
  } else {
    console.log('in else');
  }

  const handleText = e => {
    const questionText = question.question;
    setAnswers({ ...answers, [page]: { q: questionText, a: e.target.value } });
    setShowNext(true);
  };

  const onPress = e => {
    const questionText = question.question;
    const value = e.target.getAttribute('value');
    setSelected(value);
    setAnswers({ ...answers, [page]: { q: questionText, a: value } });
    setShowNext(true);
  };

  const QuestionAndAnswer = () => {
    if (question.answerType === 'buttons' && numButtons.length === question.quantity) {
      return (
        <div>
          <div className='question-div'>
            <h4>{question.question}</h4>
          </div>
          <div className='button-row'>
            {numButtons.map(button => (
              <div
                value={button.value}
                onClick={e => onPress(e)}
                className={answers[page]?.a !== button.value ? 'number-button' : 'selected-number-button'}
                key={button.value}
              >
                {button.text}
              </div>
            ))}
          </div>
          <div className='label-row'>
            <div className='left-label'>
              <p>Needs Work</p>
            </div>
            <div className='right-label'>
              <p>Exceeds Expectations</p>
            </div>
          </div>
        </div>
      );
    }

    if (question.answerType === 'textButtons' && textButtons.length === question.quantity) {
      return (
        <div>
          <div className='question-div'>
            <h4>{question.question}</h4>
          </div>
          <div className='button-column'>
            {textButtons.map(button => (
              <div
                value={button.value}
                onClick={e => onPress(e)}
                className={answers[page]?.a !== button.value ? 'number-button' : 'selected-number-button'}
                key={button.value}
              >
                {button.text}
              </div>
            ))}
          </div>
        </div>
      );
    }

    if (question.answerType === 'text') {
      return (
        <div>
          <div className='question-div'>
            <h4>{question.question}</h4>
          </div>
          <div className='row'>
            <textarea rows={7} className='text-box-styling-role-or-service' type='text' value={answers[page]?.a} onChange={e => handleText(e)} />
          </div>
        </div>
      );
    }
    return <Loading />;
  };

  return (
    <div className='question-container'>
      {QuestionAndAnswer()}
      <div className='prev-next-row'>
        {page !== 0 ? (
          <Button className='btn primary-btn btn-text-white' onClick={e => setPage(page - 1)}>
            Back
          </Button>
        ) : (
          <div></div>
        )}
        {page < questionLength ? (
          <>
            {showNext || question.required === false ? (
              <Button
                className='btn primary-btn btn-text-white'
                onClick={e => {
                  setPage(page + 1);
                  setShowNext(false);
                }}
              >
                Next
              </Button>
            ) : null}
          </>
        ) : (
          <Button className='btn primary-btn btn-text-white' onClick={e => onFinish()}>
            Finish
          </Button>
        )}
      </div>
    </div>
  );
};

export default NumberButtons;
