import React from 'react';
import { Alert, Col, Container, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import useQuery from '../../../hooks/useQuery';

import './resetPasswordContainer.scss';

import ResetPasswordForm from './ResetPasswordForm';

export default function ResetPasswordContainer() {
  const dispatch = useDispatch();
  const query = useQuery();

  const token = query.get('reset_password_token');

  return (
    <Container fluid className='reset-password-container'>
      <Row>
        <Col className='text-center'>
          <h1 className='reset-password__header'>Reset Password</h1>
        </Col>
      </Row>
      {token ? (
        <ResetPasswordForm dispatch={dispatch} token={token} />
      ) : (
        <Alert variant='danger'>
          <strong>Missing reset token.</strong>
          <p className='mt-2 mb-0'>Please click the link from the last password reset email you received to reset your password.</p>
        </Alert>
      )}
    </Container>
  );
}
