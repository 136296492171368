import React from 'react';
import { RiFileDownloadFill } from 'react-icons/ri';

import './proResources.scss';

const ProResources = () => {
  return (
    <div>
      <h2>Professional Resources</h2>
      <br />
      <div>
        <h4>Report Templates</h4>
        <br />
        <p>Click to download the reporting template that you need.</p>
        <div>
          <div className='template-line'>
            <a href='https://yb-reportbucket.s3.amazonaws.com/Templates/Loss+Control+Report+.docx'>
              Loss Control Survey Report
              <RiFileDownloadFill className='download-icon' />
            </a>
          </div>
          <div className='template-line'>
            <a href='https://yb-reportbucket.s3.amazonaws.com/Templates/OSHA+-+Safety+Assessment+Report+Template+(1).docx'>
              Safety Assessment/Mock Osha Inspection
              <RiFileDownloadFill className='download-icon' />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProResources;
