import React, { useEffect } from 'react';
import { RiFileDownloadFill } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';

import { getReports } from '../../slices/document.slice';

import './coReportDownloader.scss';

const CoReportDownloader = ({ jobId }) => {
  const state = useSelector(state => state.document);
  const { reports, isLoading } = state;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getReports({ jobId }));
  }, []);
  return (
    <>
      {isLoading ? null : (
        <>
          {reports.length > 0 ? (
            <div className='downloader-container'>
              {reports.map(report => (
                <div className='d-flex' key={report.id}>
                  <div className='pointer-cursor'>
                    <a target='_blank' href={report.s3Url} rel='noreferrer noopener'>
                      <div className='link-container'>
                        <p className='link-text' style={{ color: '#b4b4b4', wordBreak: 'break-all', borderBottom: 'none', textDecoration: 'underline' }}>
                          {report.fileName}
                        </p>
                        <RiFileDownloadFill size={24} style={{ color: '#b4b4b4' }} />
                      </div>
                    </a>
                  </div>
                </div>
              ))}
            </div>
          ) : null}
        </>
      )}
    </>
  );
};

export default CoReportDownloader;
